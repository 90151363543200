import React from 'react';
import { ImageFile } from '~/data/types/images';

const CloseIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M22.6674 9.3217L9.33418 22.6549"
        stroke="black"
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6735 22.6609L9.32757 9.31501"
        stroke="black"
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default CloseIcon;
