import React from 'react';
import { ImageFile } from '~/data/types/images';

const insuranceSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
    >
      <circle cx="42" cy="43.5391" r="15.2197" fill="#00D6D1" />
      <path
        d="M41.7135 67.6938C42.8264 67.6938 58.954 66.3433 63.9575 41.5016C64.4345 38.5478 64.8248 33.657 65.0513 30.3901C65.1765 28.5928 63.8611 27.0075 62.0686 26.8196C51.8437 25.7499 45.2972 19.1919 43.1722 16.8742C42.5265 16.1658 41.4279 16.108 40.7292 16.7682C34.682 22.4877 31.0229 25.88 22.5809 26.5449C17.8785 26.8196 18.663 32.2593 19.8357 40.7613C20.4863 46.5446 24.2436 58.4878 31.8535 64.4066"
        stroke="#262626"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 45.3211L39.0035 49.3689C39.5717 49.7551 44 52 42 54C39.7381 56.2619 37.2173 53.7093 38.5 52L49 35"
        stroke="#262626"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default insuranceSelectedIcon;
