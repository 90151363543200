import { MarketingLeadDetailsDataType } from '~/data/lead/marketing';
import { MetaType } from '~/data/types/paginationTypes';
import { HttpClientService } from '~/services/HttpsClientService';

class MarketingAdapter {
  static readonly endpoint = `/api/marketing-popups?populate=*`;

  // eslint-disable-next-line class-methods-use-this
  async getMarketingData() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: MarketingLeadDetailsDataType[];
    }>(`${MarketingAdapter.endpoint}`);

    return res;
  }
}

const marketingAdapter = new MarketingAdapter();
export default marketingAdapter;
