import { HttpClientService } from '~/services/HttpsClientService';
import { IPrivateLeasingPageData } from '~/data/types/privateLeasingPageContent/privateLeasingPageContent.types';

class PrivateLeasingPageContentAdapter {
  static readonly endpointGetPrivateLeasingPage = `/api/private-leasing-page/content`;

  // eslint-disable-next-line class-methods-use-this
  async getPrivateLeasingPageContent() {
    const res = await HttpClientService.get<IPrivateLeasingPageData | null>(
      `${PrivateLeasingPageContentAdapter.endpointGetPrivateLeasingPage}?populate=*`,
    );
    return res;
  }
}

const privateLeasingPageContentAdapter = new PrivateLeasingPageContentAdapter();
export default privateLeasingPageContentAdapter;
