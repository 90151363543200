import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '~/data/routes';

export function useIsLeaseCar() {
  const router = useRouter();
  const isLeasing = useMemo(
    () => router.asPath.includes(ROUTES.PRIVATE_LEASING),
    [router.asPath],
  );
  return isLeasing;
}
