import styled from '@mui/system/styled';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const useStyles = () => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px 20px',
  },
  rootParent: {
    width: '90%',
    borderBottom: `1px solid ${COLORS.white.dark}`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 0 0 0',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '10px 0px',
  },
  optionLine: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '6px 0',
    gap: '4px',
  },
  icon: {
    height: '28px',
    width: '28px',
  },
});
export const checkboxTextStyle = {
  fontWeight: 500,
  fontSize: '16px',
};
export const headerTextStyle = {
  textAlign: 'right',
  marginLeft: '2px',
  fontSize: '18px',
};
export const arrowIconStyle = {
  minWidth: '10px',
  minHeight: '10px',
  marginRight: '8px',
};
export const arrowIconRotateStyle = { transform: 'rotate(180deg)' };

export const CategoryTypography = { fontSize: '18px' };

export const CheckboxSelectionIcon = styled('img')(() => ({
  height: '28px',
  width: '28px',
}));
