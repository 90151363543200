// hooks/useUtmParams.ts
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { saveUtmParamsToSessionStorage } from '~/utils/utmStorage';
import { EUtmParams } from './useGetUtmToLead';

export const useUtmParams = () => {
  const router = useRouter();
  const queryParameters = new URLSearchParams(router.asPath.split('?')[1]);

  const campaign = queryParameters.get(EUtmParams.CAMPAIGN);
  const medium = queryParameters.get(EUtmParams.MEDIUM);
  const source = queryParameters.get(EUtmParams.SOURCE);
  const content = queryParameters.get(EUtmParams.CONTENT);
  const term = queryParameters.get(EUtmParams.TERM);
  const subMedia = queryParameters.get(EUtmParams.SUB_MEDIA);

  useEffect(() => {
    const utmFromQuery = {
      campaign,
      medium,
      source,
      content,
      term,
      subMedia,
    };

    saveUtmParamsToSessionStorage(utmFromQuery);
  }, [campaign, medium, source, content, term, subMedia]);
};
