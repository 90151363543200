export interface sortByFilter {
  title: string;
  value: string;
}

export const resultPageCarFilterOptions: sortByFilter[] = [
  {
    title: 'מחיר נמוך לגבוה',
    value: 'asc',
  },
  {
    title: 'מחיר גבוה לנמוך',
    value: 'desc',
  },
];
