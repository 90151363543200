import { styled } from '@mui/system'; // Import styled function
import theme from '~/theme';
import { Dialog, Box } from '@mui/material';

export const root = {
  borderRadius: '16px 16px 16px 16px',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  justifyContent: 'flex-start',
  alignItem: 'center',
  backgroundColor: 'white',
  maxHeight: `calc(100% - 48px)`,
  maxWidth: `calc(100% - 24px)`,
  boxShadow: ' 0px 0px 0px 9999px rgba(0, 0, 0, 0.46)',
  zIndex: '400',
  [theme.breakpoints.up('md')]: {
    maxWidth: '575px',
    maxHeight: `calc(100% - 48px)`,
  },
};

export const layoutContainer = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  margin: '24px 12px',
};

export const closeButtonStyle = {
  position: 'absolute',
  opacity: 0.7,
  left: 8,
  top: 8,
  zIndex: 3,
};

export const ApprovalFlowPopupDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
});

export const LayoutImage = styled('img')({
  width: '100%',
  borderRadius: '16px 16px 0px 0px',
});

export const ModalWidget = styled('div')({
  borderRadius: '16px 16px 16px 16px',
  backgroundColor: 'white',
  pointerEvents: 'auto',
  boxShadow: ' 0px 0px 0px 9999px rgba(0, 0, 0, 0.46)',
  zIndex: '21',
  [theme.breakpoints.up('md')]: {
    maxWidth: '575px',
    maxHeight: `calc(100% - 48px)`,
  },
});

export const ContentBoxWidget = styled(Box)({
  '&::-webkit-scrollbar': {
    appearance: 'none',
    width: '7px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
  },
});
