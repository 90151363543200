import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ACCURATE_FLOW_FILTER_OPTION_PARAMS,
  FILTER_OPTION_TYPE_PARAMS,
} from '~/data/filtersParams';
import {
  CarBodyTypeData,
  carDescriptionHighlights,
} from '~/data/types/car/cars';
import {
  ICategoriesType,
  IDMVCategoriesType,
  ILikeNewCategoriesType,
} from '~/data/types/filters/filterCategoriesType';
import { BrandAndModel } from '~/data/types/resultPageType';
import { ACCURATE_FLOW_USED_CAR_FILTER_OPTION_PARAMS } from '~/data/utils/usedCarFiltersParams';
import filtersAdapter from '~/redux-toolkit/adapters/filtersAdapter';
import resultPageLeaseAdapter from '~/redux-toolkit/adapters/resultPageLeaseAdapter';
import resultPageAdapter from '../adapters/resultPageAdapter';
import usedCarAdapter from '../adapters/usedCarAdapter';

export const getFiltersCategories = createAsyncThunk(
  '/api/filters/categories',
  async (): Promise<ICategoriesType[]> => {
    try {
      const response = await filtersAdapter.getFiltersOption(
        FILTER_OPTION_TYPE_PARAMS.CATEGORY,
      );
      const { data } = response;

      return data as ICategoriesType[];
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getDMVFiltersCategories = createAsyncThunk(
  '/api/dmv-categories',
  async (): Promise<{ data: IDMVCategoriesType[] }> => {
    try {
      const response = await filtersAdapter.getDMVCategories();
      const { data } = response;

      return data;
    } catch (error) {
      console.log(error);
      return { data: [] };
    }
  },
);

export const getLikeNewsCategories = createAsyncThunk(
  '/api/getLikeNewsCategories',
  async (): Promise<ILikeNewCategoriesType[]> => {
    try {
      const response = await filtersAdapter.getLikeNewsCategories();
      const { data } = response;

      return data;
    } catch (error) {
      return [];
    }
  },
);

export const getAccurateFlowFiltersOptions = createAsyncThunk(
  'accurate-flow-filters',
  async (): Promise<{
    categories: ICategoriesType[];
    manufactureWithModels: BrandAndModel[];
  }> => {
    try {
      const [manufactureWithModelsRes, categoriesRes] = await Promise.all(
        Object.values(ACCURATE_FLOW_FILTER_OPTION_PARAMS).map((optionType) =>
          filtersAdapter.getFiltersOption(optionType),
        ),
      );

      return {
        manufactureWithModels: manufactureWithModelsRes.data as BrandAndModel[],
        categories: categoriesRes.data as ICategoriesType[],
      };
    } catch (error) {
      console.log(error);
      return {
        manufactureWithModels: [],
        categories: [],
      };
    }
  },
);

export const getAccurateLeaseFlowFiltersOptions = createAsyncThunk(
  'accurate-flow-filters-lease',
  async (): Promise<{
    categories: ICategoriesType[];
    manufactureWithModels: BrandAndModel[];
  }> => {
    try {
      const [manufactureWithModelsRes, categoriesRes] = await Promise.all(
        Object.values(ACCURATE_FLOW_FILTER_OPTION_PARAMS).map((optionType) =>
          resultPageLeaseAdapter.getFiltersOptions(optionType),
        ),
      );

      return {
        manufactureWithModels: manufactureWithModelsRes.data as BrandAndModel[],
        categories: categoriesRes.data as ICategoriesType[],
      };
    } catch (error) {
      console.log(error);
      return {
        manufactureWithModels: [],
        categories: [],
      };
    }
  },
);

export const getUsedCarAccurateFlowFiltersOptions = createAsyncThunk(
  'accurate-flow-used-car-filters',
  async (): Promise<{
    categories: ICategoriesType[];
    manufactureWithModels: BrandAndModel[];
  }> => {
    try {
      const [manufactureWithModelsRes, categoriesRes] = await Promise.all(
        Object.values(ACCURATE_FLOW_USED_CAR_FILTER_OPTION_PARAMS).map(
          (optionType) => filtersAdapter.getUsedCarFiltersOption(optionType),
        ),
      );

      return {
        manufactureWithModels: manufactureWithModelsRes.data as BrandAndModel[],
        categories: categoriesRes.data as ICategoriesType[],
      };
    } catch (error) {
      console.log(error);
      return {
        manufactureWithModels: [],
        categories: [],
      };
    }
  },
);

export const getUsedCarHighlightsOptions = createAsyncThunk(
  'used-car-highlights',
  async (): Promise<carDescriptionHighlights[]> => {
    try {
      const data = await usedCarAdapter.getUsedCarHighlightsFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getHighlightsOptions = createAsyncThunk(
  'highlights',
  async (): Promise<carDescriptionHighlights[]> => {
    try {
      const data = await resultPageAdapter.getHighlightsFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getLeaseHighlightsOptions = createAsyncThunk(
  'highlights-lease',
  async (): Promise<carDescriptionHighlights[]> => {
    try {
      const data = await resultPageLeaseAdapter.getHighlightsFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getCarBodyTypeOptions = createAsyncThunk(
  'car-body-types',
  async (): Promise<CarBodyTypeData[]> => {
    try {
      const data = await filtersAdapter.getCarBodyTypeOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getLeaseCarBodyTypeOptions = createAsyncThunk(
  'lease-car-body-types',
  async (): Promise<CarBodyTypeData[]> => {
    try {
      const data = await filtersAdapter.getCarBodyTypeOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getUsedCarBodyTypeOptions = createAsyncThunk(
  'used-car-body-types',
  async (): Promise<CarBodyTypeData[]> => {
    try {
      const data = await filtersAdapter.getUsedCarBodyTypeOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
