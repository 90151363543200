import { COLORS } from '~/data/ui/colors';
import { CSSProperties } from 'react';
import styled from '@mui/system/styled';
import { scrollBar } from '~/styles/useStyleGlobal';
import theme from '~/theme';

export const useStyles = () => ({
  icon: {
    height: '28px ',
    width: '28px',
  },
  formControl: {
    '&:hover': {
      border: ` 1px solid ${COLORS.primary.main} !important`,
    },
    '&:focus': {
      border: ` 1px solid ${COLORS.primary.main}`,
    },
  },
  formControlForCatagory: {
    maxWidth: '150px !important',
    minWidth: '112px',
    '&:hover': {
      border: ` 1px solid ${COLORS.primary.main} !important`,
    },
    '&:focus': {
      border: ` 1px solid ${COLORS.primary.main}`,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '220px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    zIndex: '102',
    ...scrollBar,
  },
  optionLine: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    width: '100%',
    padding: '6px 0px 4px 4px ',
    transition: 'background-color 0.5s ease ',
    gap: '4px',
    '&:hover': {
      background: COLORS.white.dark,
      opacity: 0.8,
    },
    '&:focus-visible': {
      outline: 'none !important',
      border: 'none !important',
    },
  },
  selected: {
    width: '100%',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    height: '120%',
    minHeight: '20px',
    marginTop: '6px',
    '&:focus-visible': {
      backgroundColor: `${COLORS.white.main} !important`,
    },
  },
  optionsWidget: {
    position: 'absolute',
    backgroundColor: COLORS.white.main,
    top: '45px',
    right: '0px',
    borderRadius: '8px',
    zIndex: 102,
    border: `1px solid ${COLORS.gray.light3}`,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.22)',
    padding: '16px',
    width: '100%',
    '&:focus': {
      border: ` 1px solid ${COLORS.gray.light3}`,
    },
  },
  optionsWidgetPortal: {
    position: 'fixed',
    backgroundColor: COLORS.white.main,
    top: '135px',
    right: 0,
    left: 0,
    margin: '0 auto',
    borderRadius: '8px',
    zIndex: 102,
    border: `1px solid ${COLORS.gray.light3}`,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.22)',
    padding: '2px',
    width: '95vw',
    '&:focus': {
      border: ` 1px solid ${COLORS.gray.light3}`,
    },
  },
  header: {
    padding: '5px 0px',
    alignSelf: 'flex-start',
  },
  closePortalBtn: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    cursor: 'pointer',
    '& svg': {
      width: '26px',
      height: '26px',
    },
  },
});

export default useStyles;

export const formControlActiveStyle = {
  border: ` 1px solid ${COLORS.primary.main}`,
  '&:focus': {
    border: ` 1px solid ${COLORS.primary.main}`,
  },
};

export const formControlSelectedStyle = {
  border: ` 1px solid ${COLORS.primary.main}`,
  '&:focus': {
    border: ` 1px solid ${COLORS.primary.main}`,
  },
  backgroundColor: COLORS.gray.light,
};
export const formControlStyle = (
  isCategoryFilter: boolean,
  isLarger?: boolean,
  xlInputs?: boolean,
  fullWidth?: boolean,
) => {
  return {
    margin: '4px',
    border: `1px solid ${COLORS.gray.main}`,
    maxWidth: fullWidth
      ? '100%'
      : xlInputs
      ? '215px'
      : isLarger
      ? '170px'
      : '170px',
    minWidth: xlInputs ? '215px' : isCategoryFilter ? '172px' : '170px',
    width: fullWidth ? '' : '100%',
    borderRadius: '8px',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: '9px 11px 5px 5px',
    alignItems: 'center',
    '&.hover': {
      border: `1px solid ${COLORS.black.main}`,
    },
    '&:focus': {
      border: ` 1px solid ${COLORS.black.main}`,
    },
  };
};

export const selectedStyle = {
  background: COLORS.primary.light2,
  '&:focus-visible': {
    background: `#a3cacf !important`,
    outline: 'none !important',
    border: 'none !important',
  },
};

export const subtitleStyle = {
  fontSize: '18px',
  textAlign: 'start',
};

export const menuItemTextStyle = {
  display: 'flex',
  justifyContent: 'center',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '1.5',
  textAlign: 'right',
  fontFamily: 'EricaSansFOT, GillSans',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  direction: 'rtl',
};

export const checkboxIconStyle = {
  height: '20px',
  width: '20px',
};
export const closeIconButtonStyle: CSSProperties = {
  position: 'absolute',
  maxHeight: '28px',
  maxWidth: '28px',
  top: '9px',
  left: '8px',
  cursor: 'pointer',
  zIndex: 99,
};

export const checkboxStyle = {
  color: COLORS.primary.main,
  borderRadius: '50px',
  fontSize: '18px',
};

export const selectedItemCounterStyle = {
  position: 'absolute',
  color: COLORS.black.main,
  backgroundColor: COLORS.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  height: '22px',
  width: '23px',
  top: '9px',
  left: '35px',
  textAlign: 'center',
  paddingTop: '2px',
  fontSize: '16px',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.black.secondary}`,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: `${COLORS.primary.main}`,
  },
};
export const closeCounterStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  height: '22px',
  width: '23px',
  top: '9px',
  left: '35px',
  textAlign: 'center',
  paddingTop: '2px',
};

export const CloseCounterWidgetStyle = styled('div')(() => ({
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: COLORS.gray.main,
    transition: 'background-color 0.3s ease',
  },
}));

export const portalContainer: CSSProperties = {
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
};

export const arrowIconButton: React.CSSProperties = {
  top: '50%',
  left: '10px',
  height: '22px',
  position: 'absolute',
  transform: 'translateY(-50%)',
};
export const arrowRotateIconButton: React.CSSProperties = {
  transform: 'rotate(180deg) translateY(50%)',
};

export const textInputStyle: React.CSSProperties = {
  maxWidth: '62%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginTop: '-6px',
};

export const disableTextInputStyle: React.CSSProperties = {
  color: COLORS.gray.main,
};

export const selectInputStyle = {
  display: 'flex',
  height: '42px',
  borderRadius: '8px',
  '&.MuiMenuItem-root': {
    padding: 0,
  },
};

export const cardCheckbox: CSSProperties = {
  height: '40px',
  width: '40px',
  color: COLORS.gray.light6,
};

export const disableFormStyle = {
  border: `1px solid ${COLORS.gray.light2}`,
  cursor: 'default',
  '&.hover': {
    border: `1px solid ${COLORS.gray.light2}`,
  },
};

export const CheckBoxIcon = styled('img')(() => ({
  height: '28px ',
  width: '28px',
}));
