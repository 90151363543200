import {
  CSSProperties,
  FC,
  ReactNode,
  SVGProps,
  useCallback,
  useMemo,
  useState,
} from 'react';
import RESOURCES from '~/resources';
import { SxProps } from '@mui/material';
import { TextFieldStyled, textFieldSx } from './useStyles';

const INPUT_MODES = {
  TEXT: 'text',
  EMAIL: 'email',
  SEARCH: 'search',
  TEL: 'tel',
  URL: 'url',
  NUMERIC: 'numeric',
  DECIMAL: 'decimal',
  NONE: 'none',
} as const;

export type InputModeOption = (typeof INPUT_MODES)[keyof typeof INPUT_MODES];

type ITextFieldProps = {
  placeholder?: string;
  className?: CSSProperties;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  ariaLabel?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onKeyDown?: (event: React.KeyboardEvent) => void;
  icon?: SVGProps<SVGSVGElement>;
  label?: string;
  error?: boolean;
  helperText?: string;
  type?: string;
  value?: string;
  name?: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  inputMode?: InputModeOption;
  customStyle?: SxProps;
  textFieldContainerStyles?: SxProps;
  maxLength?: number;

  inputTypeName?:
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'phone'
    | 'cities'
    | 'branches'
    | 'id'
    | 'address';
  // eslint-disable-next-line no-unused-vars
};

const CustomTextFieldV2: FC<ITextFieldProps> = ({
  className,
  fullWidth,
  placeholder,
  variant = 'filled',
  onChange,
  onKeyDown,
  icon,
  disabled,
  label,
  error,
  helperText,
  value,
  name,
  multiline = false,
  rows,
  type = 'text',
  ariaLabel,
  required,
  customStyle,
  inputMode,
  maxLength,
  inputTypeName,
}) => {
  const [isTouched, setIsTouched] = useState(false);

  const handleOnBlur = useCallback(() => {
    setIsTouched(true);
  }, []);

  const handleOnFocus = useCallback(() => {
    setIsTouched(false);
  }, []);

  const inputTypeNameHelperText = useMemo(() => {
    switch (inputTypeName) {
      case 'firstName':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_NAME;
      case 'lastName':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_LAST;
      case 'email':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_EMAIL;
      case 'phone':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_PHONE;
      case 'cities':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_BRANCHES;
      case 'branches':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_BRANCHES;
      case 'id':
        return RESOURCES.VALIDATION_HELPER_TEXT_INVALID_ID;
      case 'address':
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD_ADDRESS;
      default:
        return RESOURCES.VALIDATION_HELPER_TEXT_REQUIRED_FIELD;
    }
  }, [inputTypeName]);

  const validationHelperText = useMemo(() => {
    let valueWithOutSpaces = '';
    // remove empty spaces, this is in case a required field will
    // have a value of '   '
    if (value) {
      valueWithOutSpaces = value.trim();
    }
    // if the field is required and no value (also no value of '  ')
    // then return the default text required helperText
    // else return the helperText from the props
    return required && !valueWithOutSpaces.length
      ? inputTypeNameHelperText
      : helperText;
  }, [value, helperText, required, inputTypeNameHelperText]);

  return (
    <TextFieldStyled
      required
      multiline={multiline}
      rows={rows}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      error={isTouched && error}
      value={value}
      helperText={isTouched && error && validationHelperText}
      label={value && value?.length > 0 ? label : ''}
      disabled={disabled}
      className={`${className}`}
      fullWidth={fullWidth}
      variant={variant}
      placeholder={placeholder}
      size="small"
      sx={{ ...customStyle }}
      type={type}
      onChange={onChange}
      name={name || undefined}
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
      tab-index={0}
      InputProps={{
        endAdornment: icon as ReactNode,
      }}
      inputProps={{ inputMode, type, maxLength, 'aria-label': label || '' }}
      InputLabelProps={{
        sx: {
          ...textFieldSx,
          ...(variant === 'outlined'
            ? { transform: 'translate(14px, -6px) scale(0.85)' }
            : { transform: 'translate(14px, 14px) scale(1)' }),
        },
      }}
    />
  );
};

export default CustomTextFieldV2;
