import { IKContext } from 'imagekitio-react';

const publicKey = process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY;
const urlEndpoint = process.env.NEXT_PUBLIC_IMAGEKIT_URL;

interface IImageKitProvider {
  children: JSX.Element;
}
export const ImageKitProvider = ({ children }: IImageKitProvider) => (
  <IKContext
    publicKey={publicKey}
    urlEndpoint={urlEndpoint}
    transformationPosition="path"
  >
    {children}
  </IKContext>
);
