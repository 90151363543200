import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { approvalFlowDataType } from '~/data/types/approvalFlow/approvalFlowType';
import { DENIED_STEP_INDEX } from '~/data/approvalFlowComponent';
import { SALARY_INCOME } from '~/data/ApprovalFlowData';
import RESOURCES from '~/resources';
import {
  checkFindingCodeFromLocalStorage,
  getFindingCodeFromLocalStorage,
  setFindingCodeFromLocalStorage,
} from '~/utils/ApprovalFlow';
import { IUserDetails } from '~/data/lead/interfaces';
import { ESourceEventOptions } from '~/data/lead/enums';

const GET_APPROVAL_STEP_INDEX = 4;

interface ApprovalFlow {
  approvalFlowData: approvalFlowDataType;
  details: IUserDetails;
  approvalCode: string;
  isApprovalFlowOpen: boolean;
  flowIsCompleted: boolean;
  approvalActiveStep: number;
  source?: ESourceEventOptions | undefined | null;
}
const approvalCode = getFindingCodeFromLocalStorage();

const initialState: ApprovalFlow = {
  approvalFlowData: {
    ageConfirmed: '',
    monthlySalary: 0,
    employmentStatus: '',
    lastThreeYearsProblems: '',
    creditConfirmed: '',
  },
  details: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    mailsApproved: false,
  },
  approvalCode: approvalCode,
  isApprovalFlowOpen: false,
  flowIsCompleted: !!approvalCode,
  approvalActiveStep: approvalCode ? GET_APPROVAL_STEP_INDEX : 0,
  source: null,
};

const isFundingApprove = ({
  ageConfirmed,
  monthlySalary,
  employmentStatus,
  lastThreeYearsProblems,
  creditConfirmed,
}: approvalFlowDataType): boolean => {
  return (
    ageConfirmed === RESOURCES.APPROVAL_ANSWER_YES &&
    monthlySalary.toString() !== SALARY_INCOME.options[0] &&
    monthlySalary.toString() !== SALARY_INCOME.options[1] &&
    employmentStatus !== RESOURCES.APPROVAL_OPTION_UNEMPLOYED &&
    lastThreeYearsProblems !== RESOURCES.APPROVAL_ANSWER_YES &&
    creditConfirmed === RESOURCES.APPROVAL_ANSWER_YES
  );
};

const approvalFlowSlice = createSlice({
  name: 'approvalFlow',
  initialState,
  reducers: {
    setFlowIsCompleted(state, action) {
      state.flowIsCompleted = action.payload;
    },
    setIsApprovalFlowOpen(state, action: { payload: boolean }) {
      state.isApprovalFlowOpen = action.payload;
    },
    setAgeConfirmed(state, action) {
      state.approvalFlowData.ageConfirmed = action.payload;
    },
    setMonthlySalary(state, action) {
      state.approvalFlowData.monthlySalary = action.payload;
    },
    setEmploymentStatus(state, action) {
      state.approvalFlowData.employmentStatus = action.payload;
    },
    setLastThreeYearsProblems(state, action) {
      state.approvalFlowData.lastThreeYearsProblems = action.payload;
    },
    setCreditConfirmed(state, action) {
      state.approvalFlowData.creditConfirmed = action.payload;
    },
    setFirstName(state, action) {
      state.details.firstName = action.payload;
    },
    setLastName(state, action) {
      state.details.lastName = action.payload;
    },
    setPhoneNumber(state, action) {
      state.details.phone = action.payload;
    },
    setEmail(state, action) {
      state.details.email = action.payload;
    },
    setMailsApprove(state, action) {
      state.details.mailsApproved = action.payload;
    },
    setApprovalCode(state, action) {
      state.approvalCode = action.payload;
      setFindingCodeFromLocalStorage(action.payload);
      checkFindingCodeFromLocalStorage(action.payload, 'set', 'no');
    },
    setApprovalActiveStep(state, action: PayloadAction<number>) {
      state.approvalActiveStep = action.payload;
      if (action.payload === 2 && !isFundingApprove(state.approvalFlowData)) {
        state.approvalActiveStep = DENIED_STEP_INDEX;
      } else {
        state.approvalActiveStep = action.payload;
      }
    },
    setSource(state, action: { payload: ESourceEventOptions | undefined }) {
      state.source = action.payload;
    },

    initState() {
      const currentApprovalCode = getFindingCodeFromLocalStorage();
      return {
        ...initialState,
        approvalActiveStep: currentApprovalCode ? GET_APPROVAL_STEP_INDEX : 0,
        approvalCode: currentApprovalCode,
        flowIsCompleted: !!currentApprovalCode,
      };
    },
  },
});

export const {
  setIsApprovalFlowOpen,
  setFlowIsCompleted,
  setAgeConfirmed,
  setMonthlySalary,
  setEmploymentStatus,
  setLastThreeYearsProblems,
  setCreditConfirmed,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setEmail,
  setMailsApprove,
  setApprovalCode,
  setApprovalActiveStep,
  setSource,
  initState,
} = approvalFlowSlice.actions;

export default approvalFlowSlice.reducer;
