export const carSpecFieldMap = {
  BODY_TYPE: 'carSpec.dimensionsAndMeasures.bodyType',
  SEATS_NUMBER: 'carSpec.general.seatsNumber',
  BOOT_VOLUME_LITER: 'carSpec.dimensionsAndMeasures.bootVolumeLiter',
  ENGINE_TYPE: 'carSpec.engineAndPerformance.engineType',
  ENGINE_VOLUME: 'engineVolume',
  ENGINE_POWER: 'enginePower',
  DRIVE_RANGE: 'carSpec.airPollutionAndEnergy.motorRange',
};

export const initialAboutCarTechSpecFields: { [key: string]: any[] } = {
  [carSpecFieldMap.BODY_TYPE]: [],
  [carSpecFieldMap.SEATS_NUMBER]: [],
  [carSpecFieldMap.BOOT_VOLUME_LITER]: [],
  [carSpecFieldMap.ENGINE_TYPE]: [],
  [carSpecFieldMap.ENGINE_VOLUME]: [],
  [carSpecFieldMap.ENGINE_POWER]: [],
};

export const initialAboutElectricCarTechSpecFields: { [key: string]: any[] } = {
  [carSpecFieldMap.BODY_TYPE]: [],
  [carSpecFieldMap.SEATS_NUMBER]: [],
  [carSpecFieldMap.BOOT_VOLUME_LITER]: [],
  [carSpecFieldMap.ENGINE_TYPE]: [],
  [carSpecFieldMap.DRIVE_RANGE]: [],
  [carSpecFieldMap.ENGINE_POWER]: [],
};

export const carSpecLabelMap = {
  [carSpecFieldMap.BODY_TYPE]: 'צורת גוף',
  [carSpecFieldMap.SEATS_NUMBER]: 'מספר מושבים',
  [carSpecFieldMap.BOOT_VOLUME_LITER]: 'נפח תא מטען (ליטר)',
  [carSpecFieldMap.ENGINE_TYPE]: 'סוג מנוע',
  [carSpecFieldMap.ENGINE_VOLUME]: 'נפח מנוע (סמ"ק)',
  [carSpecFieldMap.ENGINE_POWER]: 'הספק (כוח סוס)',
  [carSpecFieldMap.DRIVE_RANGE]: 'טווח נסיעה (ק״מ)',
};
