import {
  ICommunityPageRes,
  IFreeSbeActivityRes,
} from '~/data/types/communityPage/communityPageType';
import { MetaType } from '~/data/types/paginationTypes';
import { HttpClientService } from '~/services/HttpsClientService';

class CommunityPageAdapter {
  static readonly endpoint = `/api/our-activities-page?populate=deep,4`;

  static readonly activitiesEndpoint = `/api/freesbe-activities?populate=deep,3`;

  // eslint-disable-next-line class-methods-use-this
  async getCommunityPage() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: ICommunityPageRes;
    }>(`${CommunityPageAdapter.endpoint}`);

    return res.data?.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getFreesbeActivities() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: IFreeSbeActivityRes[];
    }>(`${CommunityPageAdapter.activitiesEndpoint}`);

    return res.data?.data;
  }
}

const communityPageAdapter = new CommunityPageAdapter();
export default communityPageAdapter;
