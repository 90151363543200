import { createSlice } from '@reduxjs/toolkit';
import { citiesList } from '~/data/lead/citiesList';
import {
  ECreateMeetingStage,
  ECreateTestDriveStage,
  ESourceEventOptions,
} from '~/data/lead/enums';
import {
  IChildTerritoryTestDrive,
  IChiledTerritory,
  IDayItem,
  IShowroomTestDrive,
  ITerritory,
  ITerritoryModel,
  IUserDetails,
} from '~/data/lead/interfaces';
import { BrandLeadData, CarCardType } from '~/data/types/car/cars';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { EContactUsFlow } from '~/data/ContactUsPopup/enums';
import {
  createLead,
  createMeeting,
  createTestDrive,
  createUpsertCase,
  getCaseOptions,
  getMeetingCityTerritories,
  getMeetingCityTerritorySlots,
  getTestDriveCityTerritories,
  getTestDriveTerritorySlots,
} from '~/redux-toolkit/thunks/contactUsThunks';
import {
  isExistOpenLead,
  validateOTP,
} from '~/redux-toolkit/thunks/usedCarThunks';
import RESOURCES from '~/resources';
import { CampaignNumber } from '~/services/LeadService/enums';
import { ICase, UpsertCase } from '~/data/types/upsert/upsertCase.types';
import { HYDRATE } from 'next-redux-wrapper';
import { mockDates, mockShowrooms } from '~/data/lead/testDriveMocks';
import { getCookie, setCookie } from '~/utils/cookieStorageHandlers';

interface ContactUsState {
  isContactUsModalOpen: boolean;
  campaignNumber: CampaignNumber;
  flow: EContactUsFlow;
  loading: boolean;
  loadingTerritorySlots: boolean;
  isOtpStep: boolean;
  leadDetails: IUserDetails;
  leadId: string | undefined | null;
  isSuccessProduct: string | undefined | null;
  lineOfBusiness?: string;
  subMedia?: string;
  note?: string;
  leadTitle?: string;
  showNote?: boolean;
  source?: ESourceEventOptions | undefined | null;
  scheduleMeetingData: {
    citiesList: string[];
    territories: ITerritory[];
    currentStage: ECreateMeetingStage;
    scheduledTime: string;
    cityTerritories: IChiledTerritory[];
    selectedCityTerritory: IChiledTerritory | null;
    pickSelectedCityTerritory?: string | null;
  };
  error: string;
  car: CarCardType | null;
  usedCarContactUs: UsedCarType | null;
  isPriceExposed: boolean;
  isSuccessCall: boolean;
  redirectTo: string;
  leadLocation: string | null;
  upsertCaseData: ICase[];
  caseSubject: string;
  caseSubSubject: string;
  upsertCaseID: string;
  isContactUsModalBrandsLead: boolean;
  brandsLeadDataDropdownOptions: BrandLeadData[];
  screen: string;
  scheduleTestDriveData: {
    citiesList: string[];
    currentStage: ECreateTestDriveStage;
    scheduledTime: string;
    familyTerritories: IChildTerritoryTestDrive[];
    selectedFamilyTerritory: IShowroomTestDrive | null;
    territoryTimeSlots: IDayItem[];
    errorGetTimeSlots: boolean;
    selectedModel: ITerritoryModel;
  };
  familyId: string;
}

const initialState: ContactUsState = {
  isContactUsModalOpen: false,
  flow: EContactUsFlow.NONE,
  campaignNumber: CampaignNumber.GENERAL,
  lineOfBusiness: '',
  subMedia: '',
  note: '',
  source: null,
  leadDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    mailsApproved: false,
  },
  leadTitle: '',
  showNote: false,
  loading: false,
  loadingTerritorySlots: false,
  leadId: null,
  isSuccessProduct: null,

  scheduleMeetingData: {
    citiesList: citiesList,
    territories: [],
    cityTerritories: [],
    currentStage: ECreateMeetingStage.USER_DETAILS,
    scheduledTime: '',
    selectedCityTerritory: null,
    pickSelectedCityTerritory: null,
  },
  error: '',
  car: null,
  usedCarContactUs: null,
  isOtpStep: false,
  isPriceExposed: false,
  redirectTo: '',
  leadLocation: null,
  upsertCaseData: [],
  upsertCaseID: '',
  caseSubject: '',
  caseSubSubject: '',
  isContactUsModalBrandsLead: false,
  brandsLeadDataDropdownOptions: [],
  isSuccessCall: false,
  screen: '',
  scheduleTestDriveData: {
    citiesList: citiesList,
    familyTerritories: mockShowrooms,
    currentStage: ECreateTestDriveStage.USER_DETAILS,
    scheduledTime: '',
    selectedFamilyTerritory: null,
    territoryTimeSlots: mockDates,
    errorGetTimeSlots: false,
    selectedModel: {
      modelName: '',
      modelSkill: '',
    },
  },
  familyId: '',
};

const contactUsSlice = createSlice({
  name: 'ContactUsState',
  initialState,
  reducers: {
    setSelectedCaseSubject(state, action: { payload: UpsertCase }) {
      state.caseSubject = action.payload.subject;
      state.caseSubSubject = action.payload.subSubject ?? '';
    },
    setOpenContactUsModal(state, action) {
      state.isContactUsModalOpen = action.payload;
    },
    setFlow(state, action) {
      state.flow = action.payload;
    },
    setCampaignNumber(state, action: { payload: CampaignNumber }) {
      state.campaignNumber = action.payload;
    },
    setLineOfBusiness(state, action: { payload: string }) {
      state.lineOfBusiness = action.payload;
    },
    setSubMedia(state, action: { payload: string }) {
      state.subMedia = action.payload;
    },
    setNote(state, action: { payload: string }) {
      state.note = action.payload;
    },
    setLeadTitle(state, action: { payload: string }) {
      state.leadTitle = action.payload;
    },
    setShowNote(state, action: { payload: boolean }) {
      state.showNote = action.payload;
    },
    setLeadId(state, action: { payload: string }) {
      state.leadId = action.payload;
    },
    setIsSuccessProduct(state, action: { payload: string }) {
      state.isSuccessProduct = action.payload;
    },
    setSource(
      state,
      action: { payload: ESourceEventOptions | undefined | null },
    ) {
      state.source = action.payload;
    },
    setLeadDetails(state, action: { payload: IUserDetails }) {
      state.leadDetails = action.payload;
    },
    setFirstName(state, action: { payload: string }) {
      state.leadDetails.firstName = action.payload;
    },
    setLastName(state, action: { payload: string }) {
      state.leadDetails.lastName = action.payload;
    },
    setEmail(state, action: { payload: string }) {
      state.leadDetails.email = action.payload;
    },
    setPhone(state, action: { payload: string }) {
      state.leadDetails.phone = action.payload;
    },
    setCity(state, action: { payload: string }) {
      state.leadDetails.city = action.payload;
    },
    setLeadLocation(state, action: { payload: string | null }) {
      state.leadLocation = action.payload;
    },
    setMailsApproved(state, action: { payload: boolean }) {
      state.leadDetails.mailsApproved = action.payload;
    },
    setReferralReason(state, action: { payload: string }) {
      state.leadDetails.referralReason = action.payload;
    },
    setSubReferralReason(state, action: { payload: string }) {
      state.leadDetails.subReferralReason = action.payload;
    },
    setBrand(state, action: { payload: string }) {
      state.leadDetails.brand = action.payload;
    },
    setMeetingCar(state, action) {
      state.car = action.payload;
    },
    setMeetingUsedCar(state, action) {
      state.usedCarContactUs = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },

    setMeetingTerritories(state, action: { payload: ITerritory[] }) {
      state.scheduleMeetingData.territories = action.payload;
    },
    setMeetingCurrentStage(state, action: { payload: ECreateMeetingStage }) {
      state.scheduleMeetingData.currentStage = action.payload;
      state.error = '';
    },
    setMeetingCityTerritory(state, action: { payload: IChiledTerritory }) {
      state.scheduleMeetingData.selectedCityTerritory = action.payload;
    },
    initScheduleMeetingData(state) {
      state.scheduleMeetingData = initialState.scheduleMeetingData;
    },

    initState(state) {
      const { upsertCaseData, upsertCaseID, familyId } = state;
      return { ...initialState, upsertCaseData, upsertCaseID, familyId };
    },
    setIsOtpStep(state, action) {
      state.isOtpStep = action.payload;
    },
    setIsPriceExposed(state, action) {
      state.isPriceExposed = action.payload;
    },
    setRedirectTo(state, action) {
      state.redirectTo = action.payload;
    },
    resetUpsertCaseID(state) {
      state.upsertCaseID = '';
    },
    setIsSuccessCall(state, action) {
      state.isSuccessCall = action.payload;
    },
    setIsContactUsModalBrandsLead(state, action) {
      state.isContactUsModalBrandsLead = action.payload;
    },
    setBrandsLeadDataDropdownOption(state, action) {
      state.brandsLeadDataDropdownOptions = action.payload;
    },
    setScreen(state, action) {
      state.screen = action.payload;
    },
    setCar(state, action) {
      state.car = action.payload;
    },
    initLeadDetails(state) {
      state.leadDetails = initialState.leadDetails;
      state.note = initialState.note;
      state.showNote = false;
    },
    initLead(state) {
      state.leadDetails = initialState.leadDetails;
      state.error = initialState.error;
      state.isSuccessCall = initialState.isSuccessCall;
      state.leadId = initialState.leadId;
      state.isSuccessProduct = initialState.isSuccessProduct;
    },
    setDriveTestCurrentStage(
      state,
      action: { payload: ECreateTestDriveStage },
    ) {
      state.scheduleTestDriveData.currentStage = action.payload;
      state.error = '';
    },
    setTestDriveSelectedTerritory(
      state,
      action: { payload: IShowroomTestDrive },
    ) {
      state.scheduleTestDriveData.selectedFamilyTerritory = action.payload;
    },
    setTestDriveSelectedModel(state, action: { payload: ITerritoryModel }) {
      state.scheduleTestDriveData.selectedModel = action.payload;
    },
    initScheduleTestDrive(state) {
      state.scheduleTestDriveData = initialState.scheduleTestDriveData;
      state.error = '';
    },
    setFamilyId(state, action) {
      state.familyId = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createLead.fulfilled, (state, action) => {
      state.loading = false;
      state.error = action.payload?.errorMessage || '';
      const leadId =
        (action.payload?.upsertLead.isSuccessLead === 'true' &&
          action.payload?.upsertLead.leadSfid) ||
        null;
      state.isSuccessProduct = action.payload?.upsertLead.isSuccessProcucts;
      state.leadId = leadId;
      state.isSuccessCall = Boolean(!action.payload?.errorMessage);

      const priceExposed = JSON.parse(getCookie('onlinePriceExposed') || '{}');
      setCookie(
        'onlinePriceExposed',
        JSON.stringify({
          ...priceExposed,
          lead: {
            details: priceExposed?.lead?.details,
            leadDetails: {
              ...priceExposed?.lead?.leadDetails,
              leadId,
            },
          },
        }),
        1,
      );
    });
    builder.addCase(createLead.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createLead.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });

    builder.addCase(getMeetingCityTerritories.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(getMeetingCityTerritories.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getMeetingCityTerritories.fulfilled, (state, action) => {
      try {
        state.loading = false;
        const res = action.payload;
        if (res) {
          const { territories, leadId } = res;
          const { getChildTerritoryList } = territories;
          const { relevantTerritories } = getChildTerritoryList;
          const [selectedTerritory] = relevantTerritories;
          state.leadId = leadId;
          state.error =
            territories.errorMessage || getChildTerritoryList.errorDescription;
          state.scheduleMeetingData.cityTerritories = relevantTerritories;
          state.scheduleMeetingData.selectedCityTerritory = selectedTerritory;
          state.scheduleMeetingData.currentStage =
            ECreateMeetingStage.SCHEDULE_SLOTS;
          state.scheduleMeetingData.pickSelectedCityTerritory =
            selectedTerritory.territoryId;
        } else {
          state.error = RESOURCES.GENERIC_ERROR;
        }
      } catch (e) {
        state.error = RESOURCES.GENERIC_ERROR;
      }
    });
    builder.addCase(getMeetingCityTerritorySlots.rejected, (state) => {
      state.loadingTerritorySlots = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(getMeetingCityTerritorySlots.pending, (state) => {
      state.loadingTerritorySlots = true;
      state.error = '';
    });
    builder.addCase(getMeetingCityTerritorySlots.fulfilled, (state, action) => {
      try {
        state.loadingTerritorySlots = false;
        const res = action.payload;
        const { getSlotsRestSingleTerritory } = res || {};
        state.error = getSlotsRestSingleTerritory?.errorDescription || '';
        if (
          getSlotsRestSingleTerritory &&
          getSlotsRestSingleTerritory.data.territories.length > 0
        ) {
          state.scheduleMeetingData.territories =
            getSlotsRestSingleTerritory.data.territories;
        } else {
          state.error = RESOURCES.GENERIC_ERROR;
        }
      } catch (e) {
        state.error = RESOURCES.GENERIC_ERROR;
      }
    });

    builder.addCase(createMeeting.fulfilled, (state, action) => {
      state.scheduleMeetingData.scheduledTime = action.meta.arg.scheduledTime;
      state.loading = false;
      if (action.payload?.bookSlot.isBookSuccessInSF === 'true') {
        state.scheduleMeetingData.currentStage = ECreateMeetingStage.FINISH;
      }
      state.error =
        action.payload?.errorMessage ||
        action.payload?.bookSlot.errorDescription ||
        '';
    });
    builder.addCase(createMeeting.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createMeeting.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(validateOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(validateOTP.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(validateOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(isExistOpenLead.fulfilled, (state, action) => {
      const isOpenLead = Boolean(action.payload);
      if (!isOpenLead) {
        state.leadId = null;
      } else {
        state.leadId = action.payload;
      }
    });
    builder.addCase(getCaseOptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCaseOptions.fulfilled, (state, action) => {
      state.loading = false;
      const upsertCaseData = action.payload?.data[0].attributes.cases || [];
      state.upsertCaseData = upsertCaseData;
    });
    builder.addCase(getCaseOptions.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(createUpsertCase.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createUpsertCase.fulfilled, (state, action) => {
      state.loading = false;
      const {
        errorMessage,
        upsertCase: { caseSFID },
      } = action.payload;
      if (errorMessage) {
        state.error = errorMessage;
      }
      state.upsertCaseID = caseSFID;
      state.isSuccessCall = Boolean(!errorMessage);
    });
    builder.addCase(createUpsertCase.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(getTestDriveCityTerritories.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(getTestDriveCityTerritories.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getTestDriveCityTerritories.fulfilled, (state, action) => {
      try {
        state.loading = false;
        const res = action.payload;
        if (res) {
          const { territories, leadId } = res;
          const { getChildTerritoryListTestDrive } = territories;
          const { data } = getChildTerritoryListTestDrive;

          state.leadId = leadId;

          const errorMessage =
            territories.errorMessage ||
            getChildTerritoryListTestDrive.errorDescription;
          state.error = errorMessage;

          if (errorMessage || !data || data.length === 0) {
            state.error = errorMessage || RESOURCES.GENERIC_ERROR2;
          } else {
            state.scheduleTestDriveData.familyTerritories = data;
            state.scheduleTestDriveData.currentStage =
              ECreateTestDriveStage.SELECT_SHOWROOM;
          }
        } else {
          state.error = RESOURCES.GENERIC_ERROR2;
        }
      } catch (e) {
        state.error = RESOURCES.GENERIC_ERROR2;
      }
    });
    builder.addCase(getTestDriveTerritorySlots.rejected, (state) => {
      state.loadingTerritorySlots = false;
      state.error = RESOURCES.GENERIC_ERROR;
      state.scheduleTestDriveData.errorGetTimeSlots = true;
    });
    builder.addCase(getTestDriveTerritorySlots.pending, (state) => {
      state.loadingTerritorySlots = true;
      state.error = '';
    });
    builder.addCase(getTestDriveTerritorySlots.fulfilled, (state, action) => {
      try {
        state.loadingTerritorySlots = false;
        const res = action.payload;
        const { getSlotsRestSingleTerritory } = res || {};
        state.error = getSlotsRestSingleTerritory?.errorDescription || '';
        if (
          getSlotsRestSingleTerritory &&
          getSlotsRestSingleTerritory.data.territories.length > 0
        ) {
          state.scheduleTestDriveData.territoryTimeSlots =
            getSlotsRestSingleTerritory.data.territories[0].dayList;
          state.scheduleTestDriveData.currentStage =
            ECreateTestDriveStage.SCHEDULE_SLOTS;
        } else {
          state.error = RESOURCES.GENERIC_ERROR;
          state.scheduleTestDriveData.errorGetTimeSlots = true;
        }
      } catch (e) {
        state.error = RESOURCES.GENERIC_ERROR;
        state.scheduleTestDriveData.errorGetTimeSlots = true;
      }
    });
    builder.addCase(createTestDrive.fulfilled, (state, action) => {
      state.scheduleTestDriveData.scheduledTime = action.meta.arg.scheduledTime;
      state.loading = false;
      if (action.payload?.bookSlot.isBookSuccessInSF === 'true') {
        state.scheduleTestDriveData.currentStage = ECreateTestDriveStage.FINISH;
      }
      state.error =
        action.payload?.errorMessage ||
        action.payload?.bookSlot.errorDescription ||
        '';
    });
    builder.addCase(createTestDrive.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createTestDrive.rejected, (state) => {
      state.loading = false;
      state.error = RESOURCES.GENERIC_ERROR;
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const upsertCaseData =
        action.payload?.contactUsSlice?.upsertCaseData || [];
      const familyId = action.payload?.contactUsSlice?.familyId;
      return {
        ...state,
        upsertCaseData,
        familyId,
      };
    });
  },
});

export const {
  resetUpsertCaseID,
  setOpenContactUsModal,
  initState,
  setFlow,
  setCampaignNumber,
  setLeadDetails,
  setMeetingCurrentStage,
  setMeetingCityTerritory,
  initScheduleMeetingData,
  setLineOfBusiness,
  setSubMedia,
  setNote,
  setLeadTitle,
  setShowNote,
  setReferralReason,
  setSubReferralReason,
  setSource,
  setIsSuccessCall,
  setMeetingCar,
  setIsOtpStep,
  setIsPriceExposed,
  setRedirectTo,
  setMeetingTerritories,
  setMeetingUsedCar,
  setFirstName,
  setCity,
  setEmail,
  setLastName,
  setMailsApproved,
  setPhone,
  initLead,
  setBrand,
  setLeadId,
  setLoading,
  setLeadLocation,
  setIsContactUsModalBrandsLead,
  setBrandsLeadDataDropdownOption,
  setScreen,
  setSelectedCaseSubject,
  setDriveTestCurrentStage,
  setTestDriveSelectedTerritory,
  setTestDriveSelectedModel,
  initScheduleTestDrive,
  setFamilyId,
  setCar,
  initLeadDetails,
} = contactUsSlice.actions;

export default contactUsSlice.reducer;
