/* eslint-disable no-unused-vars */
import { Typography, Button as MButton } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import {
  buttonSizes,
  buttonTypes,
} from '~/components/Categories/CategoryCard/types';
import { FC, useMemo, useCallback } from 'react';
import { BUTTON_SIZES, BUTTON_TYPES, KEYBOARD_TYPES } from '~/data/constants';
import { useStyles, labelSx, loaderStyle, fullWidthStyle } from './useStyles';
import { buttonSizeFormatter, buttonTypeFormatter } from './utils';
import ArrowLeftIcon from '~/assets/icons/jsx/ArrowLeft';

export interface IButtonProps {
  label: string;
  isDisabled?: boolean;
  icon?: JSX.Element;
  additionalIcon?: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refItem?: any;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onClickFunc?: (param: any) => void;
  customStyle?: React.CSSProperties | SxProps;
  typographyStyle?: React.CSSProperties;
  type?: buttonTypes;
  size?: buttonSizes;
  loading?: boolean;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  isIconOnRight?: boolean;
  isExpended?: 'true' | 'false';
  roleVar?: string;
  tabbable?: boolean;
  fullWidth?: boolean;
  id?: string;
  placeholder?: string | null;
}

const Button: FC<IButtonProps> = ({
  label,
  icon,
  additionalIcon,
  isDisabled,
  refItem,
  customStyle,
  typographyStyle,
  type = BUTTON_TYPES.MAIN,
  size = BUTTON_SIZES.LARGE,
  loading = false,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  onClickFunc,
  isIconOnRight = false,
  isExpended,
  roleVar,
  tabbable = true,
  fullWidth = false,
  id,
  placeholder = null,
}) => {
  const classes = useStyles;
  const buttonType = useMemo(() => {
    return buttonTypeFormatter(type, classes);
  }, [classes, type]);

  const buttonSize = useMemo(() => {
    return fullWidth
      ? { ...buttonSizeFormatter(size), ...fullWidthStyle }
      : buttonSizeFormatter(size);
  }, [fullWidth, size]);

  const buttonProps = {
    ...(isExpended ? { 'aria-expanded': isExpended } : {}),
    ...(roleVar ? { role: roleVar } : {}),
    ...(id ? { id: id } : {}),
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isDisabled && !loading && onClickFunc) {
        onClickFunc(event);
      }
    },
    [onClickFunc, isDisabled, loading],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (
        (event.key === KEYBOARD_TYPES.ENTER ||
          event.key === KEYBOARD_TYPES.SPACE) &&
        !isDisabled &&
        !loading &&
        onClickFunc
      ) {
        event.preventDefault();
        onClickFunc(event);
      }
    },
    [onClickFunc, isDisabled, loading],
  );

  const sxStyle = { ...classes.root, ...buttonType, ...customStyle };

  return (
    <MButton
      aria-label={ariaLabel}
      tabIndex={tabbable ? 0 : -1}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      onClick={(event) => handleClick(event)}
      onKeyDown={handleKeyDown}
      aria-disabled={isDisabled || loading}
      disableRipple
      ref={refItem}
      sx={{ ...buttonSize, ...sxStyle }}
      {...buttonProps}
    >
      {!isIconOnRight && icon}
      {isIconOnRight && additionalIcon}
      <Typography
        sx={{
          ...labelSx,
          ...typographyStyle,
          ...(placeholder && classes.placeholderStyle),
        }}
      >
        {label}
        {placeholder && (
          <Typography sx={classes.placeholder}>{placeholder}</Typography>
        )}
      </Typography>

      {isIconOnRight && icon}
      {!isIconOnRight && additionalIcon}
      {loading && <CircularProgress size={20} sx={loaderStyle} />}
      {type === BUTTON_TYPES.SECONDARY_YELLOW && (
        <Box>
          <ArrowLeftIcon />
        </Box>
      )}
    </MButton>
  );
};
export default Button;
