import { styled } from '@mui/system';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const useStyles = () => ({
  root: {
    fontSize: '14px',
    fontWeight: '400',
    background: COLORS.gray.light2,
    color: COLORS.black.main,
    borderRadius: '8px',
    padding: '10px',
    lineHeight: '150%',
    isolation: 'isolate',
    direction: 'rtl',
    textAlign: 'right',
    filter: 'drop-shadow(0 2px 14px rgba(0, 0, 0, 0.14))',
    '& .MuiTooltip-arrow': {
      '&::before': {
        background: COLORS.gray.light2,
      },
    },
  },
  iconStyle: {
    [theme.breakpoints.down('md')]: {
      width: '20px',
      height: '20px',
    },
  },
  hiddenTooltipLabel: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: '0',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: '0',
  },
});

export const iconStyle = styled('svg')(() => ({
  [theme.breakpoints.down('md')]: {
    width: '20px',
    height: '20px',
  },
}));

export const iconButtonStyles = {
  position: 'relative',
  padding: '0px !important',
} as const;
