import RESOURCES from '~/resources';
import { PAYMENT_METHOD } from './searchFlowData';
import {
  CarCardType,
  FamilyCar,
  ISearchCar,
  mapCarFamilyToCarCardType,
  mapSearchCarsToCarCardType,
} from './types/car/cars';
import { FilterBudgetType } from './types/resultPageType';

export const getTheMinPriceWithRange = (
  isPaymentMethod: boolean,
  arr: number[],
  min: number | null,
  max: number | null,
) => {
  if (arr.length === 0) {
    return 0;
  }
  if (!isPaymentMethod) {
    return Math.min(...arr);
  }
  if (min !== null && max !== null) {
    const inRangeArr = arr.filter((number) => number >= min && number <= max);
    return inRangeArr.length > 0 ? Math.min(...inRangeArr) : Math.min(...arr);
  }

  return Math.min(...arr);
};

interface IRange {
  min: number | null;
  max: number | null;
}

export const minimumInRange = (arr: number[], range: IRange) => {
  const { min, max } = range;
  if (min !== null && max !== null) {
    const inRangeArr = arr.filter((number) => number >= min && number <= max);

    return inRangeArr.length > 0 ? Math.min(...inRangeArr) : Math.min(...arr);
  }

  return (arr && Math.min(...arr)) ?? 0;
};

export const mappedCarsCards = (
  cars: FamilyCar[],
  budget: FilterBudgetType,
) => {
  return cars?.map((car: FamilyCar) => {
    const mappedCar = mapCarFamilyToCarCardType(car);
    const { priceRange, paymentMethod } = budget;
    const range: IRange = {
      min: priceRange.minPrice,
      max: priceRange.maxPrice,
    };

    const minPriceNewCar =
      !mappedCar?.newCarPrice || mappedCar?.newCarPrice?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.monthly
        ? Math.min(...mappedCar.newCarPrice)
        : minimumInRange(mappedCar.newCarPrice, range) ?? 0;

    const newCarLoanMinPrice =
      !mappedCar?.newCarPrice || mappedCar?.newCarLoanPrice?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.oneTime
        ? Math.min(...mappedCar.newCarLoanPrice)
        : minimumInRange(mappedCar.newCarLoanPrice, range) ?? 0;

    const leaseMinPrice =
      mappedCar.leaseCarPrice?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.oneTime
        ? Math.min(...mappedCar.leaseCarPrice)
        : minimumInRange(mappedCar.leaseCarPrice, range) ?? 0;

    const leaseMinPriceList =
      mappedCar.leaseCarPriceList?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.monthly
        ? Math.min(...mappedCar.leaseCarPriceList)
        : minimumInRange(mappedCar.leaseCarPriceList, range) ?? 0;

    mappedCar.newCarMinPrice = minPriceNewCar;
    mappedCar.newCarLoanMinPrice = newCarLoanMinPrice;
    mappedCar.leaseMinPrice = leaseMinPrice;
    mappedCar.leaseMinPriceList = leaseMinPriceList;

    return mappedCar;
  });
};
export const mappedSearchCarsCards = (
  cars: ISearchCar[],
  budget: FilterBudgetType,
) => {
  return cars?.map((car: ISearchCar) => {
    const mappedCar = mapSearchCarsToCarCardType(car);
    const { priceRange, paymentMethod } = budget;
    const range: IRange = {
      min: priceRange.minPrice,
      max: priceRange.maxPrice,
    };

    const minPriceNewCar =
      !mappedCar.newCarPrice || mappedCar.newCarPrice?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.monthly
        ? Math.min(...mappedCar.newCarPrice)
        : minimumInRange(mappedCar.newCarPrice, range) ?? 0;

    const newCarLoanMinPrice =
      !mappedCar.newCarPrice || mappedCar.newCarLoanPrice?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.oneTime
        ? Math.min(...mappedCar.newCarLoanPrice)
        : minimumInRange(mappedCar.newCarLoanPrice, range) ?? 0;

    const leaseMinPrice =
      mappedCar.leaseCarPrice?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.oneTime
        ? Math.min(...mappedCar.leaseCarPrice)
        : minimumInRange(mappedCar.leaseCarPrice, range) ?? 0;

    const leaseMinPriceList =
      mappedCar.leaseCarPriceList?.length === 0
        ? 0
        : paymentMethod === PAYMENT_METHOD.monthly
        ? Math.min(...mappedCar.leaseCarPriceList)
        : minimumInRange(mappedCar.leaseCarPriceList, range) ?? 0;

    mappedCar.newCarMinPrice = minPriceNewCar;
    mappedCar.newCarLoanMinPrice = newCarLoanMinPrice;
    mappedCar.leaseMinPrice = leaseMinPrice;
    mappedCar.leaseMinPriceList = leaseMinPriceList;
    return mappedCar;
  });
};
export const sortCarsXTRAILLPromotedFirst = (cars: CarCardType[]) => {
  const carsAUSTRALPromoted =
    cars?.filter((c) => c.dmvModelName === 'X-TRAIL') || [];
  const carsNotAUSTRALPromoted =
    cars?.filter((c) => c.dmvModelName !== 'X-TRAIL') || [];

  return [...carsAUSTRALPromoted, ...carsNotAUSTRALPromoted];
};
export const sortCarsByAsdPrices = (
  cars: CarCardType[],
  paramType: string | null,
) => {
  let sortedCars: CarCardType[] = [];

  if (paramType === RESOURCES.NAV_PARAM_PRIVATE_LEASING_CAR) {
    sortedCars = cars
      ?.filter((c) => c.leaseMinPrice > 0)
      .sort((p, pr) => p.leaseMinPrice - pr.leaseMinPrice);
  } else {
    const carsOfCarasso = cars
      ?.filter((c) => c.newCarMinPrice > 0)
      .sort((p, pr) => p.newCarMinPrice - pr.newCarMinPrice);
    const carsLeaseOnly = cars
      ?.filter((c) => c.newCarMinPrice === 0)
      .sort((p, pr) => p.leaseMinPrice - pr.leaseMinPrice);
    sortedCars = [...carsOfCarasso, ...carsLeaseOnly];
  }

  return sortedCars;
};
