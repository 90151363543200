import { Box, Typography } from '@mui/material';
import Button from '~/components/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import RESOURCES from '~/resources';
import { CSSProperties, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import cryptoRandomString from 'crypto-random-string';
import { RootState } from '~/redux-toolkit/store/store';
import {
  setApprovalCode,
  setIsApprovalFlowOpen,
  setApprovalActiveStep,
} from '~/redux-toolkit/slices/approvalFlowSlice';
import {
  EActionEvent,
  EBusinessLineEvent,
  ECategoryEvent,
  useEvents,
} from '~/hooks/useEvents';
import { ARIA_LABELS_RESOURCES } from '~/resources/resources';
import useStyles, {
  buttonCustomStyle,
  descriptionStyle,
  offerDescriptionStyle,
  offerTitleStyle,
  root,
  titleStyle,
} from './useStyles';
import CircleIcon from '~/assets/images/jsx/Circle';

const CheckResultStep = () => {
  const { isApprovalFlowOpen, approvalActiveStep, source } = useSelector(
    (state: RootState) => state.approvalFlow,
  );
  const events = useEvents();
  const dispatch = useAppDispatch();

  useEffect(() => {
    events.sendEvent(
      {
        action: EActionEvent.APPROVAL_FLOW_RESULT,
        category: ECategoryEvent.FUNDING_APPROVAL,
      },
      {
        approvalLocation: source,
        approvalStatus: EActionEvent.APPROVAL_FLOW_APPROVED,
        businessLine: EBusinessLineEvent.FUNDING,
      },
    );
  }, [events, source]);

  const onClickOpenApprovalFlow = useCallback(() => {
    dispatch(setIsApprovalFlowOpen(!isApprovalFlowOpen));
  }, [dispatch, isApprovalFlowOpen]);

  const handleNext = useCallback(() => {
    dispatch(setApprovalActiveStep(approvalActiveStep + 1));
    dispatch(setApprovalCode(cryptoRandomString(8)));
  }, [approvalActiveStep, dispatch]);

  const classes = useStyles();
  return (
    <Box
      sx={root}
      aria-label={ARIA_LABELS_RESOURCES.FUNDING_FLOW_APPROVAL}
      aria-live="assertive"
    >
      <Typography variant="h2" sx={titleStyle}>
        {RESOURCES.APPROVAL_CHECK_RESULT_TITLE}
      </Typography>
      <Typography sx={descriptionStyle}>
        {RESOURCES.APPROVAL_CHECK_RESULT_DESCRIPTION}
      </Typography>
      <Box sx={classes.boxStyle}>
        <CircleIcon style={classes.animationStyle as CSSProperties} />
        <Typography variant="h3" sx={offerTitleStyle}>
          {RESOURCES.APPROVAL_CHECK_RESULT_BOX_TITLE}
        </Typography>
        <Typography sx={offerDescriptionStyle}>
          {RESOURCES.APPROVAL_CHECK_RESULT_BOX_DESCRIPTION}
        </Typography>
      </Box>
      <Box sx={classes.buttonsWrapper}>
        <Button
          label={RESOURCES.APPROVAL_CHECK_RESULT_BUTTON_NEXT_TIME}
          type={BUTTON_TYPES.SECONDARY}
          size={BUTTON_SIZES.LARGE}
          customStyle={buttonCustomStyle}
          onClickFunc={onClickOpenApprovalFlow}
        />
        <Button
          label={RESOURCES.APPROVAL_CHECK_RESULT_BUTTON_CREATE_CODE}
          type={BUTTON_TYPES.MAIN}
          size={BUTTON_SIZES.LARGE}
          customStyle={buttonCustomStyle}
          onClickFunc={handleNext}
        />
      </Box>
    </Box>
  );
};

export default CheckResultStep;
