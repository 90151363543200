import { IWarrantyDetailsType } from '~/components/Warranty/Warranty';
import {
  CarCardType,
  CarColorsAndImages,
  FamilyCar,
} from '~/data/types/car/cars';
import { MetaType } from '~/data/types/paginationTypes';
import { OfferData } from '~/data/types/usedCar/usedCarData';
import { HttpClientService } from '~/services/HttpsClientService';

class CarPageAdapter {
  static readonly endpoint = {
    getCarById: `/api/family-cars/`,
    getLeaseCarById: `/api/family-lease-cars/`,
    getCarWarranty: `api/car-warranty/`,
    getSimilarCars: '/api/family-cars/',
    getCarColorsAndImages: '/api/car-configurator/cars-color-images',
    endpointOfferCard: '/api/offer-components',
    getPopularFamilyCars:
      '/api/family-cars?sort=pageViewsOneMonthAgo:desc&pagination[limit]=6&filters[pageViewsOneMonthAgo][$notNull]=true',
    getPopularLeaseCars:
      '/api/family-lease-cars?sort=pageViewsOneMonthAgo:desc&pagination[limit]=6&filters[pageViewsOneMonthAgo][$notNull]=true',
  };

  // eslint-disable-next-line class-methods-use-this
  async getPopularFamilyCars() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarCardType[];
    }>(`${CarPageAdapter.endpoint.getPopularFamilyCars}`);

    return res.data.data
      .map((item) => item.attributes)
      .filter((item): item is CarCardType => item !== undefined);
  }

  async getPopularLeaseCars() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarCardType[];
    }>(`${CarPageAdapter.endpoint.getPopularLeaseCars}`);

    return res.data.data
      .map((item) => item.attributes)
      .filter((item): item is CarCardType => item !== undefined);
  }

  async getCarById(id: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: FamilyCar;
    }>(`${CarPageAdapter.endpoint.getCarById}${id}?populate=deep,4`);

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLeaseCarById(id: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: FamilyCar;
    }>(`${CarPageAdapter.endpoint.getLeaseCarById}${id}?populate=deep,4`);
    return res;
  }

  async getNewAndLeaseCarByModelAndManufacturer(
    modelName: string,
    isZeroKm: boolean,
    carId: number,
  ) {
    const res = await HttpClientService.get<IWarrantyDetailsType[]>(
      `${CarPageAdapter.endpoint.getCarWarranty}?dmvModelName=${modelName}&isZeroKm=${isZeroKm}&carId=${carId}`,
    );

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSimilarCars(similarCarsData: {
    excludeId: string;
    manufacturerName: string;
  }) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: FamilyCar[];
    }>(
      `${CarPageAdapter.endpoint.getSimilarCars}?populate=deep,4&filters[dmvManufacturerName][$eq]=${similarCarsData.manufacturerName}&filters[id][$ne]=${similarCarsData.excludeId}`,
    );

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCarColorsAndImages(dmvModelName: string, carId: number) {
    const res = await HttpClientService.get<CarColorsAndImages[]>(
      `${CarPageAdapter.endpoint.getCarColorsAndImages}?dmvModelName=${dmvModelName}&carId=${carId}`,
    );

    return res;
  }
  // eslint-disable-next-line class-methods-use-this
  async getUsedCarsOfferCard(): Promise<OfferData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: OfferData[];
    }>(
      `${CarPageAdapter.endpoint.endpointOfferCard}?populate=deep,4&filters[page][name][$eqi]=new-car-for-sale`,
    );

    return res.data.data;
  }
}

const carPageAdapter = new CarPageAdapter();
export default carPageAdapter;
