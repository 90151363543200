import { ITerritory } from '~/data/lead/interfaces';
import { ERequestType } from '../enums';
import { GetSlotsRestSingleTerritory } from '../interfaces';
import { LeadRequest } from './LeadRequest';

export interface GetSlotsRestSingleTerritoryResponse {
  getSlotsRestSingleTerritory: {
    errorDescription: string;
    data: {
      territories: ITerritory[];
      processedBranchId: string;
    };
  };
}

export class GetSlotsRestSingleTerritoryRequest extends LeadRequest {
  getSlotsRestSingleTerritory: GetSlotsRestSingleTerritory;

  constructor(params: GetSlotsRestSingleTerritory) {
    super(ERequestType.GetSlotsRestSingleTerritory);
    this.getSlotsRestSingleTerritory = params;
  }
}
