import { CSSProperties, useCallback } from 'react';
import { Checkbox, Box, Typography } from '@mui/material';
import { OptionType } from '~/data/types/resultPageType';
import { COLORS } from '~/data/ui/colors';
import {
  checkboxTextStyle,
  useStyles,
  arrowIconRotateStyle,
  arrowIconStyle,
  headerTextStyle,
  CheckboxSelectionIcon,
} from './useStyles';
import CheckedBoxIcon from '~/assets/icons/jsx/CheckedboxIcon';
import DownIcon from '~/assets/icons/jsx/DownIcon';
import CheckBoxIcon from '~/assets/icons/jsx/CheckBoxIcon';
import ColorIcon from '../ColorIcon';

interface CheckBoxSectionListProps {
  category: string;
  options?: OptionType[];
  selected?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  isColor?: boolean;
  children?: JSX.Element;
  isOpen?: boolean;
  dropDownStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  onCategoryToggle?: (category: string) => void;
}

const CheckBoxSectionList = ({
  category,
  options,
  selected = [],
  onClick,
  isColor,
  children,
  isOpen,
  dropDownStyle,
  headerStyle,
  onCategoryToggle,
}: CheckBoxSectionListProps) => {
  const onClickCheckBoxLine = useCallback(
    (option: OptionType) => {
      const isSelectedAlready = selected.some((s) => s === option.title);
      if (isSelectedAlready) {
        const filterSelected = selected.filter((s) => s !== option.title);
        onClick(filterSelected);
      } else {
        onClick([...selected, option.title]);
      }
    },
    [onClick, selected],
  );

  const isChecked = useCallback(
    (option: OptionType) => {
      return selected.some((s) => s === option.title);
    },
    [selected],
  );

  const onClickCategory = useCallback(() => {
    if (onCategoryToggle) {
      onCategoryToggle(category);
    }
  }, [category, onCategoryToggle]);

  const classes = useStyles();
  return (
    <Box sx={classes.rootParent}>
      <Box sx={{ ...classes.root, ...dropDownStyle }}>
        <Box sx={classes.title} onClick={onClickCategory}>
          <DownIcon
            style={
              isOpen
                ? { ...arrowIconStyle, ...arrowIconRotateStyle }
                : arrowIconStyle
            }
          />
          <Typography variant="h3" sx={{ ...headerTextStyle, ...headerStyle }}>
            {category}
            <Typography component="span" color={COLORS.primary.dark2}>
              {selected.join(', ')}
            </Typography>
          </Typography>
        </Box>
        {isOpen && (
          <Box sx={classes.content}>
            {children && children}
            {options?.map((option) => (
              <Box
                key={option.title}
                sx={classes.optionLine}
                onClick={() => onClickCheckBoxLine(option)}
              >
                <Typography variant="h5" style={checkboxTextStyle}>
                  {option.title}
                </Typography>
                {option.icon &&
                  (isColor ? (
                    <ColorIcon colorHex={option.icon} />
                  ) : (
                    <CheckboxSelectionIcon
                      src={option.icon}
                      alt={option.title}
                    />
                  ))}
                <Checkbox
                  icon={<CheckBoxIcon />}
                  checkedIcon={<CheckedBoxIcon />}
                  checked={isChecked(option)}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckBoxSectionList;
