export const COLORS = {
  gray: {
    main: '#c1c1c1',
    light: '#F5F8F9',
    light2: '#d8d8d8',
    light3: '#e7e7e7',
    light4: '#F5F5F5',
    light6: '#9E9E9E',
    light7: '#FAFAFA',
    light8: '#FFFEFE',
    light9: '#F9F9F9',
    light10: '#343434',
    light11: '#8C8C8C',
    light12: '#757575',
    light14: '#DADADA',
    light13: '#A5A5A5',
    dark: '#595959',
    dark2: '#818181',
    dark3: '#605F62',
    dark4: '#A7A7A7',
    dark5: '#CFCFCF',
    dark6: '#3A3A3A',
    dark7: '#595959',
    dark8: '#ABABAB',
  },
  primary: {
    main: '#00D6D1',
    light: '#F1F9F9',
    light2: '#E5F6F8',
    light3: '#E2F1F1',
    light4: '#E3F9F8',
    light5: '#8AEDEC',
    light6: '#AEECEB',
    dark: '#0D4B4A',
    dark2: '#0E8482',
  },
  white: {
    main: '#ffffff',
    secondary: '#FFFEFE',
    dark: '#ECECEC',
  },
  black: {
    main: '#262626',
    secondary: '#080808',
    dark: '#000000',
    light: '#767676',
    light2: '#454444',
  },
  green: {
    main: '#23bf58',
    light: '#0DA37A',
    light2: '#54B742',
    light3: '#12A54D',
    light4: '#0C9351',
    light5: '#E5F6F8',
    dark: 'green',
    dark2: '#057949',
    dark3: '#116642',
    dark4: '#28513E',
    dark5: '#0E8482',
    olive: '#A8CF3E',
  },
  yellow: {
    light1: '#FFF9CC',
    main: '#FFE500', // FBE64D
    dark: '#F5E35D',
    dark2: '#E1D052',
    dark3: '#FEBF10',
    dark4: '#FBE64D',
  },
  orange: {
    main: '#ED6B27',
    light: '#E42C04',
    dark: '#F4A525',
    dark2: '#F78322',
    dark3: '#F15823',
  },
  red: {
    main: '#C72026',
    dark: '#DB3A26',
    dark1: '#FFF6EC',
    dark2: '#C73E2B',
    dark3: '#A92A33',
    dark4: '#CC0404',
    dark5: '#E42C04',
    error: '#FB4118',
  },
  blue: {
    main: '#1A649A',
    light: '#4092CD',
    dark: '#0B4F7D',
    dark2: '#0A3F62',
    light2: '#B9EEED',
  },
};
