import { createSlice } from '@reduxjs/toolkit';
import { getCarFleetPageData } from '../thunks/carFleetPageContentThunks';
import { ICarFleetPageData } from '~/data/types/CarFleetPageContent/CarFleetPageContent.types';

interface CarFleetPageState {
  carFleetPage: ICarFleetPageData | null;
}

const initialState: CarFleetPageState = {
  carFleetPage: null,
};

const carFleetPageSlice = createSlice({
  name: 'carFleetPageContentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCarFleetPageData.fulfilled, (state, action) => {
      state.carFleetPage = action.payload ?? null;
    });
  },
});

export const {} = carFleetPageSlice.actions;

export default carFleetPageSlice.reducer;
