/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum SubscriptionPlansType {
  STARTER = 'STARTER',
  BASIC = 'BASIC',
  ALL_IN = 'ALL-IN',
}

export enum CarAcquireType {
  REGULAR = 'regular',
  SUBSCRIPTION = 'subscription',
}

export type SubscriptionColorType = {
  code: number;
  colorHex: string;
  colorHexRoof: string;
  title: string;
};

export enum SUBSCRIPTION_ADVANCE_PAYMENT {
  ELECTRIC = 2000,
}
export type AccessoryCard = {
  title: string;
  description?: string;
  price: number;
  id: string;
  essenceCode?: string;
  sfCode?: string;
  imgUrl?: string;
  type?: string;
};

export const ACCESSORIES: AccessoryCard[] = [
  {
    id: '1',
    title: '',
    price: 0,
    imgUrl: '',
  },
];
