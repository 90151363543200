import { useCallback } from 'react';
import FormValidation from '~/Validators/FormValidation';

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum EOnKeyDownValidation {
  LETTERS = 'lettersOnly',
  DIGITS = 'digitsOnly',
}

export const useValidateOnKeyDown = (validateFor: EOnKeyDownValidation) => {
  const validateOnKeyDownLettersOnlyText = useCallback(
    (event: React.KeyboardEvent) => {
      if (!FormValidation.validateText(event.key)) {
        event.preventDefault();
      }
    },
    [],
  );

  const validateOnKeyDownDigitsOnly = useCallback(
    (event: React.KeyboardEvent) => {
      if (!FormValidation.validateDigitsOnly(event.key)) {
        event.preventDefault();
      }
    },
    [],
  );

  switch (validateFor) {
    case EOnKeyDownValidation.LETTERS:
      return validateOnKeyDownLettersOnlyText;
    case EOnKeyDownValidation.DIGITS:
      return validateOnKeyDownDigitsOnly;
    default:
      return validateOnKeyDownLettersOnlyText;
  }
};
