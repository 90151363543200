import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWarrantyDetailsType } from '~/components/Warranty/Warranty';
import {
  CarCardType,
  CarColorsAndImages,
  FamilyCar,
} from '~/data/types/car/cars';
import { OfferData } from '~/data/types/usedCar/usedCarData';
import carPageAdapter from '~/redux-toolkit/adapters/carPageAdaptor';

export const getFamilyCarById = createAsyncThunk(
  '/api/familyCar',
  async (id: string): Promise<FamilyCar> => {
    const response = await carPageAdapter.getCarById(id);
    const { data } = response;
    return data.data;
  },
);

export const getPopularFamilyCars = createAsyncThunk(
  '/api/getPopularFamilyCar',
  async (): Promise<CarCardType[]> => {
    const response = await carPageAdapter.getPopularFamilyCars();
    return response;
  },
);

export const getPopularLeaseCars = createAsyncThunk(
  '/api/getPopularLeaseCar',
  async (): Promise<CarCardType[]> => {
    const response = await carPageAdapter.getPopularLeaseCars();
    return response;
  },
);

export const getFamilyLeaseCarById = createAsyncThunk(
  '/api/familyLeaseCar',
  async (id: string): Promise<FamilyCar> => {
    const response = await carPageAdapter.getLeaseCarById(id);
    const { data } = response;

    return data.data;
  },
);

export const getNewAndLeaseCarByModelAndManufacturer = createAsyncThunk(
  '/api/car-warranty',
  async (carData: {
    modelName: string;
    isZeroKm: boolean;
    carId: number;
  }): Promise<IWarrantyDetailsType[]> => {
    const response =
      await carPageAdapter.getNewAndLeaseCarByModelAndManufacturer(
        carData.modelName,
        carData.isZeroKm,
        carData.carId,
      );

    const { data } = response;
    return data;
  },
);

export const getSimilarFamilyCars = createAsyncThunk(
  '/api/family-cars/?filters[dmvManufacturerName][$eq]',
  async (similarCarsData: {
    excludeId: string;
    manufacturerName: string;
  }): Promise<FamilyCar[]> => {
    try {
      const response = await carPageAdapter.getSimilarCars(similarCarsData);
      const { data } = response;
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getCarColorsAndImages = createAsyncThunk(
  '/api/car-configurator/cars-color-images?dmvManufacturerName',
  async (dataCar: {
    dmvModelName: string;
    carId: number;
  }): Promise<CarColorsAndImages[]> => {
    try {
      const response = await carPageAdapter.getCarColorsAndImages(
        dataCar.dmvModelName,
        dataCar.carId,
      );
      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getUsedCarsOfferCard = createAsyncThunk(
  '/api/offer-components',
  async (): Promise<OfferData[]> => {
    const response = await carPageAdapter.getUsedCarsOfferCard();

    return response;
  },
);
