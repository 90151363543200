import { COLORS } from '~/data/ui/colors';

const useStyles = () => ({
  codeContainer: {
    border: `1px solid ${COLORS.gray.light2}`,
    borderRadius: '8px',
  },

  codeBlock: {
    display: 'flex',
    justifyContent: 'center',
    border: `1px dashed ${COLORS.primary.main}`,
    backgroundColor: `${COLORS.primary.light2}`,
    borderRadius: '8px',
    alignItems: 'center',
    margin: '24px',
  },
});

export default useStyles;

export const root = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '24px 32px',
};

export const descriptionCodeStyle = {
  marginBottom: '24px',
  lineHight: '25px',
  color: `${COLORS.gray.dark}`,
  textAlign: 'center',
};

export const expiredTextStyle = {
  alignItems: 'start',
  margin: '0 24px ',
};
export const expiredTitle = {
  margin: '0 24px',
  paddingBottom: '5px',
  fontSize: '18px',
  fontWeight: 400,
};

export const codeStyle = {
  padding: '9px',
  margin: '0 24px',
};

export const buttonStyle = {
  width: '100%',
  marginTop: '24px',
};
