import { createSlice } from '@reduxjs/toolkit';
import { IUsedCarForSalePageData } from '~/data/types/usedCarForSalePageContent/usedCarForSalePage.types';
import { getUsedCarForSalePageData } from '../thunks/usedCarForSalePageContentThunks';

interface UsedCarForSalePageState {
  usedCarForSalePage: IUsedCarForSalePageData | null;
}

const initialState: UsedCarForSalePageState = {
  usedCarForSalePage: null,
};

const usedCarForSalePageSlice = createSlice({
  name: 'usedCarForSalePageContentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsedCarForSalePageData.fulfilled, (state, action) => {
      state.usedCarForSalePage = action.payload ?? null;
    });
  },
});

export const {} = usedCarForSalePageSlice.actions;

export default usedCarForSalePageSlice.reducer;
