import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

const useStyles = () => ({
  boxStyle: {
    border: `1px solid ${COLORS.gray.light2}`,
    borderRadius: '8px',
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '24px',
  },

  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },

  specialPriceTagStyle: {
    position: 'absolute',
    left: '0',
  },
  animationStyle: {
    position: 'absolute',
    top: '16px',
    left: '152px',
    zIndex: -1,
  },
});

export default useStyles;

export const root = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const titleStyle = {
  fontSize: '24px',
  marginBottom: '16px',
  lineHeight: '1.5',
  fontWeight: '600',
};

export const descriptionStyle = {
  marginBottom: '24px',
  lineHight: '25px',
  color: `${COLORS.gray.dark}`,
};

export const offerTitleStyle = {
  marginTop: '24px',
  marginBottom: '8px',
  fontWeight: '600',
  fontSize: '18px',
};

export const offerDescriptionStyle = {
  marginBottom: '24px',
  lineHight: '25px',
  color: `${COLORS.gray.dark}`,
  textAlign: 'center',
  maxWidth: '317px',
};

export const buttonCustomStyle = {
  width: 'fit-content',
  [theme.breakpoints.up('md')]: {
    width: '242px',
  },
};
