import { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { scrollUpConstants } from '~/data/constants';
import ICONS from '~/data/constants/icons';
import classes, { header } from './useStyles';

const ScrollUpButton = () => {
  const handleClickScrollUp = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const firstFocusableElement: HTMLElement | null = document.querySelector(
      'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  }, []);

  return (
    <Button onClick={handleClickScrollUp}>
      <Box sx={classes.root}>
        <Typography sx={header}>{scrollUpConstants.takeMeToTheTop}</Typography>
        <img src={ICONS.scrollUpBtn.src} alt="" />
      </Box>
    </Button>
  );
};

export default ScrollUpButton;
