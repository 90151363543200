import { createAsyncThunk } from '@reduxjs/toolkit';
import { CampaignNumber } from '~/services/LeadService/enums';
import { LeadService } from '~/services/LeadService/LeadService';
import { UpsertLeadResponse } from '~/services/LeadService/requests/UpsertLeadRequest';
import { BookSlotResponse } from '~/services/LeadService/requests/BookSlotRequest';
import { UpsertLead } from '~/services/LeadService/UpsertData';
import { GetChildTerritoryListResponse } from '~/services/LeadService/requests/GetChildTerritoryListRequest';
import { GetSlotsRestSingleTerritoryResponse } from '~/services/LeadService/requests/GetSlotsRestSingleTerritoryRequest';
import {
  CaseOptionsResponse,
  CreateUpsertCaseResponse,
  UpsertCase,
} from '~/data/types/upsert/upsertCase.types';
import { MetaType } from '~/data/types/paginationTypes';
import contactUsAdapter from '../adapters/contactUsAdapter';
import { GetChildTerritoryListTestDriveResponse } from '~/services/LeadService/requests/GetChildTerritoryListTestDriveRequest';

export const createLead = createAsyncThunk(
  'CreateLead',
  async (data: {
    upsertLead: UpsertLead;
  }): Promise<UpsertLeadResponse | null> => {
    try {
      const res = await LeadService.createNewLeadRequest(data.upsertLead);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const addLeadProducts = createAsyncThunk(
  'addLeadProducts',
  async (data: {
    upsertLead: UpsertLead;
  }): Promise<UpsertLeadResponse | null> => {
    try {
      const res = await LeadService.addLeadProductsRequest(data.upsertLead);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const getMeetingCityTerritories = createAsyncThunk(
  'GetMeetingCityTerritories',
  async (data: {
    upsertLead: UpsertLead;
  }): Promise<{
    leadId: string;
    territories: GetChildTerritoryListResponse;
  } | null> => {
    try {
      const res = await LeadService.getCityTerritories(data.upsertLead);
      if (res) {
        return { leadId: res.leadId, territories: res.territories.data };
      }
      return null;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const getMeetingCityTerritorySlots = createAsyncThunk(
  'getMeetingCityTerritorySlots',
  async (data: {
    leadId: string;
    campaignNumber: CampaignNumber;
    territoryId: string;
  }): Promise<GetSlotsRestSingleTerritoryResponse | null> => {
    try {
      const res = await LeadService.getTerritorySlots(
        data.leadId,
        data.campaignNumber,
        data.territoryId,
      );
      return res?.data || null;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const createMeeting = createAsyncThunk(
  'CreateMeeting',
  async (data: {
    leadId: string;
    selectedTerritoryId: string;
    scheduledTime: string;
    campaignNumber: CampaignNumber;
  }): Promise<BookSlotResponse | null> => {
    try {
      const res = await LeadService.createNewMeetingRequest(
        {
          leadId: data.leadId,
          selectedTerritoryId: data.selectedTerritoryId,
          scheduledTime: data.scheduledTime,
          campaignNumber: data.campaignNumber,
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const createTestDrive = createAsyncThunk(
  'createTestDrive',
  async (data: {
    leadId: string;
    selectedTerritoryId: string;
    scheduledTime: string;
    modelSkill: string;
  }): Promise<BookSlotResponse | null> => {
    try {
      const res = await LeadService.createNewMeetingRequest(
        {
          leadId: data.leadId,
          selectedTerritoryId: data.selectedTerritoryId,
          scheduledTime: data.scheduledTime,
          modelSkill: data.modelSkill,
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const getCaseOptions = createAsyncThunk(
  'GetCaseOptions',
  async ({
    type,
  }: {
    type: string;
  }): Promise<{
    meta: MetaType;
    data: CaseOptionsResponse[];
  } | null> => {
    try {
      const res = await contactUsAdapter.getCaseOptions({
        type,
      });

      return res.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const createUpsertCase = createAsyncThunk(
  'createUpsertCase',
  async (data: Partial<UpsertCase>): Promise<CreateUpsertCaseResponse> => {
    try {
      const location = `freesbe.com${window.location.pathname}`;

      const res = await contactUsAdapter.createUpsertCase({
        data,
        source: 'freesbe',
        location,
      });
      return res.data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  },
);

export const getTestDriveCityTerritories = createAsyncThunk(
  'getTestDriveCityTerritories',
  async (data: {
    upsertLead: UpsertLead;
    familyId: string,
  }): Promise<{
    leadId: string;
    territories: GetChildTerritoryListTestDriveResponse;
  } | null> => {
    try {
      const res = await LeadService.getTestDriveTerritories(data.upsertLead, data.familyId);
      if (res) {
        return { leadId: res.leadId, territories: res.territories.data };
      }
      return null;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const getTestDriveTerritorySlots = createAsyncThunk(
  'getTestDriveTerritorySlots',
  async (data: {
    leadId: string;
    campaignNumber: CampaignNumber;
    territoryId: string;
    modelSkill: string;
  }): Promise<GetSlotsRestSingleTerritoryResponse | null> => {
    try {
      const res = await LeadService.getTerritorySlots(
        data.leadId,
        data.campaignNumber,
        data.territoryId,
        data.modelSkill,
      );
      return res?.data || null;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);