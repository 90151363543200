import { AxiosResponse } from 'axios';
import { MetaType } from '~/data/types/paginationTypes';
import {
  CaseOptionsResponse,
  CreateUpsertCaseResponse,
  UpsertCase,
} from '~/data/types/upsert/upsertCase.types';
import { HttpClientService } from '~/services/HttpsClientService';

class ContactUsAdapter {
  static readonly endpoint = {
    getCaseOptions: `/api/upsert-case-pages`,
    createUpsertCase: `/api/case-api`,
  };

  // eslint-disable-next-line class-methods-use-this
  async getCaseOptions({ type }: { type?: string }) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CaseOptionsResponse[];
    }>(
      `${ContactUsAdapter.endpoint.getCaseOptions}?populate=deep,3${
        type ? `&filters[location][$eq]=${type}` : ''
      }`,
    );

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async createUpsertCase(data: {
    data: Partial<UpsertCase>;
    source: string;
    location: string;
  }): Promise<AxiosResponse<CreateUpsertCaseResponse>> {
    const res = await HttpClientService.post<CreateUpsertCaseResponse>(
      `${ContactUsAdapter.endpoint.createUpsertCase}`,
      data,
    );
    return res;
  }
}

const contactUsAdapter = new ContactUsAdapter();
export default contactUsAdapter;
