import React from 'react';
import { ImageFile } from '~/data/types/images';

const fiveStarsIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="22"
      viewBox="0 0 77 22"
    >
      <path
        d="M2.35804 9.93523C1.95857 9.67689 2.12435 9.0582 2.59947 9.03422L6.79745 8.82227C7.4606 8.78879 8.02648 8.33144 8.19834 7.69007L9.28344 3.64041C9.40612 3.18257 10.0426 3.14822 10.2138 3.5902L11.737 7.52152C11.9754 8.13702 12.5817 8.53023 13.241 8.49695L17.4583 8.28402C17.9328 8.26007 18.1604 8.85751 17.7903 9.15533L14.53 11.7787C14.0108 12.1965 13.8217 12.9023 14.0624 13.5237L15.5742 17.4257C15.7458 17.8687 15.25 18.2723 14.8511 18.0143L11.3052 15.7213C10.7509 15.3628 10.0293 15.4002 9.515 15.814L6.23027 18.4571C5.86098 18.7542 5.32695 18.4062 5.44963 17.9484L6.53474 13.8987C6.70659 13.2574 6.4452 12.5783 5.88763 12.2178L2.35804 9.93523Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M16.7868 9.93553C16.3873 9.6772 16.5531 9.05851 17.0282 9.03452L21.2262 8.82257C21.8893 8.78909 22.4552 8.33175 22.627 7.69038L23.7122 3.64072C23.8348 3.18288 24.4713 3.14853 24.6425 3.59051L26.1657 7.52183C26.4041 8.13733 27.0104 8.53054 27.6697 8.49725L31.887 8.28433C32.3615 8.26038 32.5891 8.85782 32.219 9.15563L28.9587 11.779C28.4395 12.1968 28.2504 12.9026 28.4911 13.524L30.0029 17.426C30.1746 17.869 29.6787 18.2726 29.2798 18.0146L25.7339 15.7216C25.1796 15.3631 24.458 15.4005 23.9437 15.8143L20.659 18.4574C20.2897 18.7545 19.7557 18.4065 19.8783 17.9487L20.9634 13.899C21.1353 13.2577 20.8739 12.5786 20.3163 12.2181L16.7868 9.93553Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M31.216 9.93547C30.8165 9.67714 30.9823 9.05845 31.4574 9.03446L35.6554 8.82251C36.3185 8.78903 36.8844 8.33169 37.0562 7.69032L38.1414 3.64066C38.264 3.18282 38.9005 3.14847 39.0717 3.59045L40.5949 7.52177C40.8333 8.13727 41.4396 8.53048 42.0989 8.49719L46.3162 8.28427C46.7907 8.26032 47.0183 8.85776 46.6482 9.15557L43.3879 11.779C42.8687 12.1967 42.6796 12.9025 42.9203 13.5239L44.4321 17.426C44.6038 17.869 44.1079 18.2726 43.709 18.0146L40.1631 15.7215C39.6088 15.3631 38.8872 15.4004 38.3729 15.8143L35.0882 18.4573C34.7189 18.7545 34.1849 18.4065 34.3075 17.9486L35.3926 13.899C35.5645 13.2576 35.3031 12.5786 34.7455 12.218L31.216 9.93547Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M45.6437 9.93523C45.2442 9.67689 45.41 9.0582 45.8851 9.03422L50.0831 8.82227C50.7462 8.78879 51.3121 8.33144 51.484 7.69007L52.5691 3.64041C52.6918 3.18257 53.3282 3.14822 53.4995 3.5902L55.0226 7.52152C55.2611 8.13702 55.8674 8.53023 56.5266 8.49695L60.744 8.28402C61.2184 8.26007 61.446 8.85751 61.0759 9.15533L57.8156 11.7787C57.2964 12.1965 57.1073 12.9023 57.3481 13.5237L58.8599 17.4257C59.0315 17.8687 58.5356 18.2723 58.1367 18.0143L54.5909 15.7213C54.0366 15.3628 53.3149 15.4002 52.8006 15.814L49.5159 18.4571C49.1466 18.7542 48.6126 18.4062 48.7353 17.9484L49.8204 13.8987C49.9922 13.2574 49.7308 12.5783 49.1733 12.2178L45.6437 9.93523Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M60.0724 9.93523C59.6729 9.67689 59.8387 9.0582 60.3138 9.03422L64.5118 8.82227C65.175 8.78879 65.7408 8.33144 65.9127 7.69007L66.9978 3.64041C67.1205 3.18257 67.757 3.14822 67.9282 3.5902L69.4513 7.52152C69.6898 8.13702 70.2961 8.53023 70.9553 8.49695L75.1727 8.28402C75.6471 8.26007 75.8747 8.85751 75.5046 9.15533L72.2443 11.7787C71.7251 12.1965 71.536 12.9023 71.7768 13.5237L73.2886 17.4257C73.4602 17.8687 72.9644 18.2723 72.5654 18.0143L69.0196 15.7213C68.4653 15.3628 67.7436 15.4002 67.2294 15.814L63.9446 18.4571C63.5753 18.7542 63.0413 18.4062 63.164 17.9484L64.2491 13.8987C64.4209 13.2574 64.1596 12.5783 63.602 12.2178L60.0724 9.93523Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
    </Component>
  );
};

export default fiveStarsIcon;
