import { createSlice } from '@reduxjs/toolkit';
import { getPrimeRate } from '~/redux-toolkit/thunks/fundingThunks';
import { HYDRATE } from 'next-redux-wrapper';
import { ILoanRange } from '~/data/types/approvalFlow/approvalFlowType';

const DEFAULT_CAR_PRICE = 100000;
const DEFAULT_PRIME_RATE = 6;
const DEFAULT_BASE_RATE = 2.95;
export const DEFAULT_USED_CAR_PRIME_RATE = 10.5;

export const USED_CAR_ADVANCED_LIMIT_PERCENTAGE = 0.8;
export const NEW_CAR_ADVANCED_LIMIT_PERCENTAGE = 0.7;

export const USED_CAR_MIN_CAR_ADVANCED_PERCENTAGE = 0.15;
export const NEW_CAR_MIN_CAR_ADVANCED_PERCENTAGE = 0.15;

export const NEW_CAR_END_OF_PERIOD_LIMIT_PERCENTAGE = 0.3;
export const USED_END_OF_PERIOD_LIMIT_PERCENTAGE = 0.3;

export const BUDGET_MONTHLY_RANGE = {
  min: 500,
  max: 10000,
  step: 100,
} as const;

export const BUDGET_ADVANCE_RANGE = {
  min: 5000,
  max: 80000,
  step: 500,
} as const;

export interface IFundingCalculator {
  id?: number;
  advancedLimitPercentage: number;
  endOfPeriodLimitPercentage: number;
  minCarAdvancedPercentage: number;
}
interface FundingState {
  carPrice: number;
  advancedPayment: number;
  endOfPeriodPayment: number;
  numberOfMonths: number;
  primeRate: number;
  newCarBaseRate: number;
  usedCarPrimeRate: number;
  usedCarTooltipDescFinance?: string;
  newCarTooltipDesc: string;
  newCarTooltipDescFinance?: string;
  usedCarTooltipDesc: string;
  activeStep: number;
  usedCarFinanceCalculator: IFundingCalculator;
  newCarFinanceCalculator: IFundingCalculator;
  usedCarLoanRange: ILoanRange[];
  newCarLoanRange: ILoanRange[];
}

const initialState: FundingState = {
  carPrice: DEFAULT_CAR_PRICE,
  advancedPayment: DEFAULT_CAR_PRICE * NEW_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
  endOfPeriodPayment: 0,
  numberOfMonths: 60,
  primeRate: DEFAULT_PRIME_RATE,
  newCarBaseRate: DEFAULT_BASE_RATE,
  activeStep: 0,
  usedCarPrimeRate: DEFAULT_USED_CAR_PRIME_RATE,
  usedCarTooltipDesc: ' ',
  newCarTooltipDesc: '',
  newCarTooltipDescFinance: '',
  usedCarTooltipDescFinance: '',
  usedCarFinanceCalculator: {
    advancedLimitPercentage: USED_CAR_ADVANCED_LIMIT_PERCENTAGE,
    endOfPeriodLimitPercentage: USED_END_OF_PERIOD_LIMIT_PERCENTAGE,
    minCarAdvancedPercentage: USED_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
  },
  newCarFinanceCalculator: {
    advancedLimitPercentage: NEW_CAR_ADVANCED_LIMIT_PERCENTAGE,
    endOfPeriodLimitPercentage: NEW_CAR_END_OF_PERIOD_LIMIT_PERCENTAGE,
    minCarAdvancedPercentage: NEW_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
  },
  usedCarLoanRange: [],
  newCarLoanRange: [],
};

const fundingPage = createSlice({
  name: 'fundingPage',
  initialState,
  reducers: {
    initState() {
      return initialState;
    },
    setAdvancedPayment(state, action) {
      state.advancedPayment = action.payload;
    },
    setEndOfPeriodPayment(state, action) {
      state.endOfPeriodPayment = action.payload;
    },
    setNumberOfMonths(state, action) {
      state.numberOfMonths = action.payload;
    },
    setCarPrice(state, action) {
      state.carPrice = action.payload.price;
      // state.advancedPayment =
      //   action.payload.price * action.payload.advancedPayment;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrimeRate.fulfilled, (state, action) => {
      if (action.payload) {
        state.primeRate = action.payload.primeRate;
        state.newCarBaseRate = action.payload.newCarBaseRate;
        state.usedCarPrimeRate = action.payload.usedCarPrimeRate;
        state.newCarTooltipDesc = action.payload.newCarTooltipDesc;
        state.newCarTooltipDescFinance =
          action.payload.newCarTooltipDescFinance || '';
        state.usedCarTooltipDesc = action.payload.usedCarTooltipDesc;
        state.usedCarTooltipDescFinance =
          action.payload.usedCarTooltipDescFinance || '';
        state.usedCarFinanceCalculator = {
          advancedLimitPercentage:
            action.payload.usedCarFinanceCalculator?.advancedLimitPercentage ??
            USED_CAR_ADVANCED_LIMIT_PERCENTAGE,
          endOfPeriodLimitPercentage:
            action.payload.usedCarFinanceCalculator
              ?.endOfPeriodLimitPercentage ??
            USED_END_OF_PERIOD_LIMIT_PERCENTAGE,
          minCarAdvancedPercentage:
            action.payload.usedCarFinanceCalculator?.minCarAdvancedPercentage ??
            USED_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
        };
        state.newCarFinanceCalculator = {
          advancedLimitPercentage:
            action.payload.newCarFinanceCalculator?.advancedLimitPercentage ??
            NEW_CAR_ADVANCED_LIMIT_PERCENTAGE,
          endOfPeriodLimitPercentage:
            action.payload.newCarFinanceCalculator
              ?.endOfPeriodLimitPercentage ??
            NEW_CAR_END_OF_PERIOD_LIMIT_PERCENTAGE,
          minCarAdvancedPercentage:
            action.payload.newCarFinanceCalculator?.minCarAdvancedPercentage ??
            NEW_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
        };
        state.usedCarLoanRange = action.payload.usedCarLoanRange;
        state.newCarLoanRange = action.payload.newCarLoanRange;
      }
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { funding } = action.payload;

      return {
        ...state,
        ...funding,
      };
    });
  },
});
export const {
  initState,
  setAdvancedPayment,
  setEndOfPeriodPayment,
  setNumberOfMonths,
  setCarPrice,
  setActiveStep,
} = fundingPage.actions;
export default fundingPage.reducer;
