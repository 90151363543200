import { createSlice } from '@reduxjs/toolkit';
import { IBlog } from '~/data/types/blog/blogType';
import {
  getBlogContent,
  getBlogByUrlName,
} from '~/redux-toolkit/thunks/blogThunks';
import defaultBlogPreview from '~/assets/images/defaultBlogPreview.svg';
import { HYDRATE } from 'next-redux-wrapper';

interface BlogState {
  blogs: IBlog[];
  selectedBlog: IBlog | null;
  fetchingBlog: boolean;
  isErrorFetchingCar: boolean;
}

const initialState: BlogState = {
  blogs: [],
  selectedBlog: null,
  fetchingBlog: false,
  isErrorFetchingCar: false,
};

const blogSlice = createSlice({
  name: 'blogData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlogContent?.fulfilled, (state, action) => {
      try {
        state.blogs = action.payload
          .map((blogData) => {
            const { attributes } = blogData;
            const mainCardImg = attributes.mainCardPicture?.data?.attributes;
            const mainPageImg = attributes.mainPagePicture?.data?.attributes;
            const blogLabel = attributes.blogLabel?.data?.attributes;

            return {
              title: attributes.title,
              subtitle: attributes.subtitle ?? '',
              contentText: attributes.contentText.replaceAll('#', ''),
              mainCardPictureUrl: mainCardImg
                ? mainCardImg.url
                : defaultBlogPreview,
              mainCardPictureName: mainCardImg ? mainCardImg.name : '',
              mainPagePictureUrl: mainPageImg
                ? mainPageImg.url
                : defaultBlogPreview,
              mainPagePictureName: mainPageImg ? mainPageImg.name : '',
              readingTime: attributes.readingTime,
              labelTitle: blogLabel?.labelTitle || '',
              labelColorHex: blogLabel?.labelColorHex || '',
              labelPath: blogLabel?.labelPath || '',
              id: blogData.id,
              publishedAt: attributes.publishedAt,
              urlTitle: attributes.urlTitle || '',
              metaTitle: attributes.metaTitle || '',
              metaDescription: attributes.metaDescription || '',
              ctaButton: attributes.CTAButton,
              createdAt: attributes.createdAt,
            };
          })
          .sort((a, b) => parseInt(b.id) - parseInt(a.id));
      } catch (e) {
        console.warn('Error', e);
      }
    });

    builder.addCase(getBlogByUrlName.pending, (state) => {
      state.isErrorFetchingCar = false;
      state.fetchingBlog = true;
    });
    builder.addCase(getBlogByUrlName.rejected, (state) => {
      state.isErrorFetchingCar = true;
      state.fetchingBlog = false;
    });
    builder.addCase(getBlogByUrlName.fulfilled, (state, action) => {
      try {
        if (!action.payload) {
          state.isErrorFetchingCar = true;
        } else {
          state.fetchingBlog = false;
          state.isErrorFetchingCar = false;
          const { id, attributes } = action.payload;
          const mainCardImg = attributes.mainCardPicture?.data?.attributes;
          const mainPageImg = attributes.mainPagePicture?.data?.attributes;
          const blogLabel = attributes.blogLabel?.data?.attributes;

          state.selectedBlog = {
            title: attributes.title,
            subtitle: attributes.subtitle ?? '',
            contentText: attributes.contentText,
            mainCardPictureUrl: mainCardImg
              ? mainCardImg.url
              : defaultBlogPreview,
            mainCardPictureName: mainCardImg ? mainCardImg.name : '',
            mainPagePictureUrl: mainPageImg
              ? mainPageImg.url
              : defaultBlogPreview,
            mainPagePictureName: mainPageImg ? mainPageImg.name : '',
            readingTime: attributes.readingTime,
            labelTitle: blogLabel?.labelTitle || '',
            labelColorHex: blogLabel?.labelColorHex || '',
            labelPath: blogLabel?.labelPath || '',
            id: id,
            publishedAt: attributes.publishedAt,
            urlTitle: attributes.urlTitle || '',
            metaTitle: attributes.metaTitle || '',
            metaDescription: attributes.metaDescription || '',
            ctaButton: attributes.CTAButton,
            createdAt: attributes.createdAt,
          };
        }
      } catch (e) {
        console.warn('Error', e);
      }
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { blog } = action.payload;

      return {
        ...state,
        ...blog,
      };
    });
  },
});

export default blogSlice.reducer;
