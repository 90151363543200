/* eslint-disable no-shadow */

import { ECategoryEvent } from '~/hooks/useEvents';
import { SEARCH_FLOW } from '../consts';
import { ROUTES } from '../routes';

/* eslint-disable no-unused-vars */
export enum ECreateMeetingStage {
  USER_DETAILS,
  SCHEDULE_SLOTS,
  FINISH,
  FINISH_PHONE,
}

export enum ESourceOptions {
  HOME_PAGE = 'עמוד הבית',
  SEARCH_FLOW = 'פלואו חיפוש',
  INSURANCE = 'ביטוח',
  CONFIGURATOR = 'קונפיגורטור',
  SUMMARY_STEP = 'פלואו רכב - עמוד סיכום',
  CAR_PAGE = 'עמוד רכב',
  MARKETING_POPUP = 'פופאפ שיווקי',
}

export enum ESourceEventOptions {
  HOME_PAGE = 'HomePage',
  HOME_PAGE_MARKETING_BANNER = 'HomePageMarketingBanner',
  SEARCH_FLOW = 'SearchFlow',
  INSURANCE = 'Insurance',
  FUNDING_PAGE = 'FundingPage',
  CONFIGURATOR = 'CarConfigurator',
  SUMMARY_STEP = 'SummaryPage',
  CAR_PAGE = 'NewCarPage',
  LEASE_CAR_PAGE = 'LeaseCarPage',
  MARKETING_POPUP = 'MarketingPopup',
  RESULT_PAGE = 'ResultPage',
  EV_GENERAL = 'EVGeneral',
  EV_BUSINESS = 'EVBusiness',
  EV_CONDO = 'EVCondo',
  EV_PRIVATE = 'EVPrivate',
  USED_CAR_PAGE = 'UsedCarPage',
  CONTACT_US_PAGE = 'ContactUsPage',
  SHOW_ONLINE_PRICE = 'ShowOnlinePrice',
  NEW_CAR_RESULT_PAGE = 'NewCarResultsPage',
  USED_CAR_RESULT_PAGE = 'UsedCarResultsPage',
  LEASE_CAR_RESULT_PAGE = 'LeaseCarResultsPage',
  BUSINESS_LEASING_PAGE = 'businessLeasing',
  PROMOTION_BANNER = 'PromotionBanner',
  PROMOTION_CARD = 'PromotionCard',
  HOME_PAGE_SEARCH_PLANS = 'homePageSearchPlans',
  LOBBY_LEAVE_DETAILS = 'lobbyLeaveDetails',
  CAR_FLEET_LEAVE_DETAILS = 'carFleetLeaveDetails',
  NEW_CAR_CONFIGURATOR = 'NewCarConfigurator',
  NEW_CAR_SUMMARY = 'NewCarSummary',
  LEASE_CAR_CONFIGURATOR = 'LeaseCarConfigurator',
  LEASE_CAR_SUMMARY = 'LeaseCarSummary',
  NAV_BAR = 'navBar',
  COMPARISON_PAGE = 'ComparisonPage',
  SEARCH_FLOW_BUDGET_P = 'SearchFlowBudget',
  SEARCH_FLOW_BUDGET_P1 = 'searchFlowLobSelection',
  SEARCH_FLOW_BUDGET_P2 = 'searchFlowLobPayment',
  SEARCH_FLOW_BUDGET_P3 = 'searchFlowResults',
}

export const ESourceEventByRoutes = {
  [ROUTES.HOMEPAGE]: 'Homepage',
  [ROUTES.RESULT_PAGE]: 'NewCarResultPage',
  [`${ROUTES.RESULT_PAGE}/flow`]: 'CarConfigurator',
  [`${ROUTES.CAR_PAGE}/[brand]/[model]/configurator/[carFlowId]`]:
    'CarConfigurator',
  [`${ROUTES.CAR_PAGE}/[brand]/[model]/[...params]`]: 'NewCarPage',
  [`${ROUTES.USED_CAR}/[brand]/[model]/[...params]`]: 'UsedCarPage',
  [`${ROUTES.PRIVATE_LEASING}/[brand]/[model]/[...params]`]: 'LeaseCarPage',
  [`${ROUTES.RESULT_PAGE}/flow/[carFlowId]`]: 'LeaseCarConfigurator',
  [`${ROUTES.CAR_PAGE}/flow/[carFlowId]`]: 'NewCarConfigurator',
  [`${ROUTES.PRIVATE_LEASING}/flow/[carFlowId]`]: 'CarConfigurator',
  [ROUTES.SERVICE_AGREEMENT]: 'ServiceAgreement',
  [ROUTES.FINANCE]: 'Finance',
  [ROUTES.SAFETY_AND_POLLUTION_PAGE]: 'SafetyAndPollutionPage',
  [ROUTES.WISHLIST_PAGE]: 'WishlistPage',
  [ROUTES.ACCESSIBILITY]: 'Accessibility',
  [ROUTES.INSURANCE_PAGE]: 'InsurancePage',
  [ROUTES.TERMS_OF_USE]: 'TermsOfUse',
  [ROUTES.TERMS_AND_CONDITIONS]: 'TermsAndConditions',
  [ROUTES.EVEASY_PACKAGES]: 'EveasyPackages',
  [ROUTES.PRIVACY]: 'Privacy',
  [ROUTES.REFUNDS]: 'Refunds',
  [ROUTES.REDIRECT]: 'Redirect',
  [ROUTES.ABOUT_PAGE]: 'AboutPage',
  [ROUTES.BLOG_PAGE]: 'BlogPage',
  [ROUTES.CHARGING_SOLUTIONS]: 'ChargingSolutions',
  [ROUTES.RESULT_PAGE_USED_CAR]: 'UsedCarResultPage',
  [ROUTES.RESULT_PAGE_LEASE]: 'LeaseCarResultPage',
  [ROUTES.SEARCH_FLOW]: 'SearchFlow',
  [`${ROUTES.SEARCH_FLOW}/[...type]`]: 'SearchFlow',
  [ROUTES.LOCATION_PAGE]: 'LocationPage',
  [ROUTES.CONTACT_US_PAGE]: 'ContactUsPage',
  [ROUTES.VOLUNTEER_PAGE]: 'VolunteerPage',
  [ROUTES.COMMUNITY_PAGE]: 'CommunityPage',
  [`${ROUTES.SEARCH_FLOW}/${SEARCH_FLOW.ACCURATE_SEARCH}`]:
    'SearchFlowAccurate',
  [`${ROUTES.SEARCH_FLOW}/${SEARCH_FLOW.BUDGET}`]: 'SearchFlowBudget',
  [`${ROUTES.SEARCH_FLOW}/${SEARCH_FLOW.GENERAL_SEARCH}`]: 'SearchFlowGeneral',
  ['LeaseCarPage']: 'LeaseCarPage',
  ['NewCarPage']: 'NewCarPage',
  ['UsedCarPage']: 'UsedCarPage',
  ['NewCarConfigurator']: 'NewCarConfigurator',
  ['LeaseCarConfigurator']: 'LeaseCarConfigurator',
  [ROUTES.PRIVATE_LEASING]: 'LobbyLeaseCarPage',
  [ROUTES.CAR_PAGE]: 'LobbyNewCarPage',
  [ROUTES.USED_CAR]: 'LobbyUsedCarPage',
  [ROUTES.BUSINESS_LEASING_PAGE]: 'businessLeasing',
};

export const getCategoryFromSourceLocation: Record<string, ECategoryEvent> = {
  Homepage: ECategoryEvent.HOMEPAGE,
  NewCarResultPage: ECategoryEvent.RESULT_PAGE,
  UsedCarResultPage: ECategoryEvent.RESULT_PAGE,
  LeaseCarResultPage: ECategoryEvent.RESULT_PAGE,
  ServiceAgreement: ECategoryEvent.LEAD,
  Finance: ECategoryEvent.APPROVAL_FLOW,
  SafetyAndPollutionPage: ECategoryEvent.CAR_PAGE,
  WishlistPage: ECategoryEvent.HOMEPAGE,
  Accessibility: ECategoryEvent.HOMEPAGE,
  InsurancePage: ECategoryEvent.INSURANCE,
  TermsOfUse: ECategoryEvent.LEAD,
  TermsAndConditions: ECategoryEvent.LEAD,
  EveasyPackages: ECategoryEvent.HOMEPAGE,
  Privacy: ECategoryEvent.HOMEPAGE,
  Refunds: ECategoryEvent.HOMEPAGE,
  Redirect: ECategoryEvent.HOMEPAGE,
  AboutPage: ECategoryEvent.HOMEPAGE,
  BlogPage: ECategoryEvent.BLOG,
  ChargingSolutions: ECategoryEvent.HOMEPAGE,
  UsedCar: ECategoryEvent.RESULT_PAGE,
  LocationPage: ECategoryEvent.LOCATIONS_PAGE,
  ContactUsPage: ECategoryEvent.CONTACT_US_PAGE,
  VolunteerPage: ECategoryEvent.HOMEPAGE,
  CommunityPage: ECategoryEvent.HOMEPAGE,
  SearchFlow: ECategoryEvent.SEARCH_FLOW,
  searchFlowLobSelection: ECategoryEvent.SEARCH_FLOW,
  SearchFlowAccurate: ECategoryEvent.SEARCH_FLOW,
  SearchFlowBudget: ECategoryEvent.SEARCH_FLOW,
  SearchFlowGeneral: ECategoryEvent.SEARCH_FLOW,
  searchFlowLobPayment: ECategoryEvent.SEARCH_FLOW,
  searchFlowResults: ECategoryEvent.SEARCH_FLOW,
  CarConfigurator: ECategoryEvent.CAR_CONFIGURATION,
  LeaseCarPage: ECategoryEvent.CONTACT_US,
  NewCarPage: ECategoryEvent.CONTACT_US,
  UsedCarPage: ECategoryEvent.CONTACT_US,
  businessLeasing: ECategoryEvent.BUSINESS_LEASING,
};

/* eslint-disable no-unused-vars */
export enum ECreateTestDriveStage {
  USER_DETAILS,
  SELECT_SHOWROOM,
  SCHEDULE_SLOTS,
  FINISH,
}
export enum EAppointmentType {
  MEETING = 'תיאום פגישה',
  TEST_DRIVE = 'תיאום נסיעת מבחן',
}
