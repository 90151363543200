import {
  ICategoriesType,
  IDMVCategoriesType,
  ILikeNewCategoriesType,
} from '~/data/types/filters/filterCategoriesType';
import { HttpClientService } from '~/services/HttpsClientService';

import {
  CarBodyTypeData,
  carDescriptionHighlights,
} from '~/data/types/car/cars';
import { MetaType } from '~/data/types/paginationTypes';
import { BrandAndModel } from '~/data/types/resultPageType';

class FiltersAdapter {
  static readonly filtersEndpoint = `/api/filters`;

  static readonly usedCarFiltersEndpoint = `/api/used-cars-filters`;

  static readonly dmvEndpoint = '/api/dmv-categories?populate=*';

  static readonly likeNewCategoriesEndpoint = '/api/like-new-categories';

  static readonly usedCarHighlightsEndpoint = '/api/car-highlights';

  static readonly highlightsEndpoint = '/api/car-description-labels';

  static readonly newCarBodyTypeEndpoint = '/api/car-body-types?isNewCar=true';

  static readonly leaseCarBodyTypeEndpoint =
    '/api/car-body-types?isLeaseCar=true';

  static readonly usedCarBodyTypeEndpoint =
    '/api/car-body-types?isUsedCar=true';

  // eslint-disable-next-line class-methods-use-this
  async getFiltersOption(filterType: string) {
    const res = await HttpClientService.get<
      ICategoriesType[] | BrandAndModel[]
    >(`${FiltersAdapter.filtersEndpoint}/${filterType}`);

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsedCarFiltersOption(filterType: string) {
    const res = await HttpClientService.get<
      ICategoriesType[] | BrandAndModel[]
    >(`${FiltersAdapter.usedCarFiltersEndpoint}/${filterType}`);

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getDMVCategories() {
    const res = await HttpClientService.get<{ data: IDMVCategoriesType[] }>(
      `${FiltersAdapter.dmvEndpoint}`,
    );
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLikeNewsCategories() {
    const res = await HttpClientService.get<ILikeNewCategoriesType[]>(
      `${FiltersAdapter.likeNewCategoriesEndpoint}`,
    );
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHighlightsOptions(): Promise<carDescriptionHighlights[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: carDescriptionHighlights[];
    }>(`${FiltersAdapter.highlightsEndpoint}?populate=deep,2`);
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsedCarHighlightsOptions(): Promise<carDescriptionHighlights[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: carDescriptionHighlights[];
    }>(`${FiltersAdapter.usedCarHighlightsEndpoint}?populate=deep,2`);
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCarBodyTypeOptions(): Promise<CarBodyTypeData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarBodyTypeData[];
    }>(`${FiltersAdapter.newCarBodyTypeEndpoint}&populate=deep,2`);
    return res.data.data;
  }

  async getLeaseCarBodyTypeOptions(): Promise<CarBodyTypeData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarBodyTypeData[];
    }>(`${FiltersAdapter.leaseCarBodyTypeEndpoint}&populate=deep,2`);
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsedCarBodyTypeOptions(): Promise<CarBodyTypeData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarBodyTypeData[];
    }>(`${FiltersAdapter.usedCarBodyTypeEndpoint}&populate=deep,2`);
    return res.data.data;
  }
}

const filterAdapter = new FiltersAdapter();
export default filterAdapter;
