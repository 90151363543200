import React from 'react';
import { ImageFile } from '~/data/types/images';

const yellowLightCampaignBannerIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 222 117"
    >
      <path
        d="M-161.089 -174.257C-198.205 -25.681 46.4607 268.93 209.596 -22.3805"
        stroke="#FFE500"
        strokeWidth={24.2347}
        strokeLinecap="round"
      />
      <rect
        x={3.45254}
        y={-0.156721}
        width={47.2127}
        height={114.035}
        rx={7.06682}
        transform="matrix(0.664387 -0.738253 0.747722 0.674154 -3.04441 42.8493)"
        fill="#FFF9CC"
        stroke="#262626"
        strokeWidth={4.8899}
      />
      <path
        d="M53.9395 45.2109L60.645 77.5367"
        stroke="#262626"
        strokeWidth={4.8899}
        strokeLinecap="round"
      />
      <path
        d="M30.0044 29.3508C30.1797 32.3731 27.9083 34.8776 25.0037 35.0109C22.0991 35.1442 19.5499 32.8609 19.3746 29.8387C19.1994 26.8164 21.4708 24.3119 24.3754 24.1786C27.28 24.0453 29.8292 26.3286 30.0044 29.3508Z"
        stroke="#262626"
        strokeWidth={4.8899}
      />
      <path
        d="M49.1058 61.8285C49.1972 63.4043 48.0154 64.6832 46.5389 64.751C45.0624 64.8187 43.7389 63.6548 43.6475 62.079C43.5561 60.5032 44.738 59.2244 46.2144 59.1566C47.6909 59.0889 49.0144 60.2527 49.1058 61.8285Z"
        stroke="#262626"
        strokeWidth={4.8899}
      />
      <path
        d="M72.1703 60.6254C72.2617 62.2012 71.0799 63.4801 69.6034 63.5478C68.1269 63.6156 66.8034 62.4517 66.712 60.8759C66.6206 59.3001 67.8024 58.0212 69.2789 57.9535C70.7554 57.8857 72.0789 59.0496 72.1703 60.6254Z"
        stroke="#262626"
        strokeWidth={4.8899}
      />
      <path
        d="M18.1797 30.1756C1.64831 27.0675 -31.7685 14.7422 -21.4481 -1.05268C-9.86895 -18.774 7.65988 1.55264 17.5593 19.4794"
        stroke="#262626"
        strokeWidth={4.8899}
      />
      <path
        d="M-18.1849 90.1526C-13.8434 85.3348 -5.74491 77.773 -5.01822 70.9492"
        stroke="#262626"
        strokeWidth={4.8899}
        strokeLinecap="round"
      />
      <path
        d="M154.73 69.7422C146.982 72.2803 139.51 73.234 131.767 75.0103"
        stroke="#262626"
        strokeWidth={4.8899}
        strokeLinecap="round"
      />
      <path
        d="M139.815 53.0312L122.927 61.6202"
        stroke="#262626"
        strokeWidth={4.8899}
        strokeLinecap="round"
      />
      <path
        d="M124.091 34.7105C120.362 40.017 113.235 48.5008 113.338 55.3624"
        stroke="#262626"
        strokeWidth={4.8899}
        strokeLinecap="round"
      />
      <path
        d="M93.4348 17.215C91.9669 16.6921 88.5495 9.0445 87.6329 10.0726C86.7163 11.1006 85.7048 17.7418 84.7861 17.8044C83.8674 17.867 74.9017 16.1419 75.4319 16.9156C75.962 17.6893 83.255 22.4252 83.3057 23.6365C83.3564 24.8479 80.8496 31.4975 81.7683 31.4349C82.6871 31.3723 88.421 25.3749 89.6608 25.3527C90.9006 25.3305 98.9243 30.9822 99.4776 30.2281C100.031 29.4739 95.6568 23.667 95.9778 22.773C96.2988 21.8789 104.039 14.9661 102.867 15.046C101.695 15.1259 94.9026 17.7379 93.4348 17.215Z"
        stroke="#00D6D1"
        strokeWidth={3.13463}
        strokeLinecap="round"
      />
    </Component>
  );
};

export default yellowLightCampaignBannerIcon;
