import { CAR_FLOW_TYPE } from '~/data/car/carCardData';
import { CarCardType } from '~/data/types/car/cars';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { useMemo } from 'react';
import { ROUTES } from '~/data/routes';
import useGetCarTypeParam from './useGetCarTypeParam';

export const useCarDealOptions = (
  car: CarCardType | UsedCarType | null,
  carType?: string,
) => {
  const fetchedCarTypeParam = useGetCarTypeParam();
  const carTypeParam = carType ?? fetchedCarTypeParam;

  return useMemo(() => {
    let options = [
      CAR_FLOW_TYPE.NEW_CAR,
      CAR_FLOW_TYPE.LEASING_CAR,
      CAR_FLOW_TYPE.LOAN_CAR,
      CAR_FLOW_TYPE.ONLINE_PRICE,
    ];
    // Remove leasing option
    if ((car as CarCardType)?.leaseMinPrice === 0) {
      options = options.filter((o) => o !== CAR_FLOW_TYPE.LEASING_CAR);
    }

    // remove new car deal type
    if ((car as CarCardType)?.newCarMinPrice === 0) {
      options = options.filter((o) => o !== CAR_FLOW_TYPE.NEW_CAR);
    }
    // remove loan deal car type
    if ((car as CarCardType)?.newCarLoanMinPrice === 0) {
      options = options.filter((o) => o !== CAR_FLOW_TYPE.LOAN_CAR);
    }
    if (carTypeParam === ROUTES.RESULT_PAGE) {
      options = options.filter((o) => o !== CAR_FLOW_TYPE.ONLINE_PRICE);
    }
    if (carTypeParam === ROUTES.USED_CAR || carTypeParam === 'used') {
      options = options.filter((o) => o !== CAR_FLOW_TYPE.LEASING_CAR);
      if (
        (car as UsedCarType)?.smartPrice?.webSmartPriceLoanMinMonthlyPayment ===
          0 ||
        (car as UsedCarType)?.smartPrice?.webSmartPriceLoanMinMonthlyPayment ===
          null
      ) {
        options = options.filter((o) => o !== CAR_FLOW_TYPE.LOAN_CAR);
      }
      if ((car as UsedCarType)?.websitePrice === null) {
        options = options.filter((o) => o !== CAR_FLOW_TYPE.NEW_CAR);
      }
      if ((car as UsedCarType)?.websiteSmartPrice === 0) {
        options = options.filter((o) => o !== CAR_FLOW_TYPE.ONLINE_PRICE);
      }
    }
    return options;
  }, [car, carTypeParam]);
};

export const useCarDealDisablesOptions = (
  car: CarCardType | UsedCarType | null,
  carType?: string,
) => {
  const options = useCarDealOptions(car, carType);
  return useMemo(() => {
    return [
      CAR_FLOW_TYPE.NEW_CAR,
      CAR_FLOW_TYPE.LOAN_CAR,
      CAR_FLOW_TYPE.LEASING_CAR,
      CAR_FLOW_TYPE.ONLINE_PRICE,
    ].filter((o) => !options.includes(o));
  }, [options]);
};
