import React from 'react';
import { ImageFile } from '~/data/types/images';

const twoStarsSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="22"
      viewBox="0 0 77 22"
    >
      <path
        d="M40.2155 9.93553C39.816 9.6772 39.9818 9.05851 40.4569 9.03452L44.6549 8.82257C45.318 8.78909 45.8839 8.33175 46.0558 7.69038L47.1409 3.64072C47.2635 3.18288 47.9 3.14853 48.0713 3.59051L49.5944 7.52183C49.8329 8.13733 50.4391 8.53054 51.0984 8.49725L55.3157 8.28433C55.7902 8.26038 56.0178 8.85782 55.6477 9.15563L52.3874 11.779C51.8682 12.1968 51.6791 12.9026 51.9198 13.524L53.4316 17.426C53.6033 17.869 53.1074 18.2726 52.7085 18.0146L49.1626 15.7216C48.6084 15.3631 47.8867 15.4005 47.3724 15.8143L44.0877 18.4574C43.7184 18.7545 43.1844 18.4065 43.3071 17.9487L44.3922 13.899C44.564 13.2577 44.3026 12.5786 43.7451 12.2181L40.2155 9.93553Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M21.358 9.93504C20.9586 9.67671 21.1244 9.05802 21.5995 9.03403L25.7975 8.82209C26.4606 8.7886 27.0265 8.33126 27.1983 7.68989L28.2834 3.64023C28.4061 3.18239 29.0426 3.14804 29.2138 3.59002L30.737 7.52134C30.9754 8.13684 31.5817 8.53005 32.241 8.49677L36.4583 8.28384C36.9328 8.25989 37.1604 8.85733 36.7903 9.15515L33.53 11.7785C33.0108 12.1963 32.8217 12.9021 33.0624 13.5235L34.5742 17.4256C34.7458 17.8685 34.25 18.2721 33.8511 18.0142L30.3052 15.7211C29.7509 15.3626 29.0293 15.4 28.515 15.8138L25.2303 18.4569C24.861 18.754 24.327 18.406 24.4496 17.9482L25.5347 13.8985C25.7066 13.2572 25.4452 12.5782 24.8876 12.2176L21.358 9.93504Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
    </Component>
  );
};

export default twoStarsSelectedIcon;
