import {
  ESourceEventByRoutes,
  ESourceEventOptions,
  getCategoryFromSourceLocation,
} from '~/data/lead/enums';
import { useRouter } from 'next/router';
import { ECategoryEvent } from './useEvents';
import { ROUTES } from '~/data/routes';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';

export const useLeadLocation = () => {
  const router = useRouter();
  const { stepperActiveStep } = useAppSelector(
    (state: RootState) => state.carFlow,
  );

  const { step } = useAppSelector(
    (state: RootState) => state.searchFlow.budgetFlow,
  );

  let locationKey =
    Object.keys(ESourceEventByRoutes).find(
      (source) => router.asPath === source,
    ) ||
    Object.keys(ESourceEventByRoutes)
      .reverse()
      .find((url) => router.asPath.includes(url)) ||
    Object.keys(ESourceEventByRoutes).find(
      (source) => router.pathname === source,
    );

  // if couldn't find any location key, use manually
  // this fix because of new url type, we need to check asPath to get leasing
  if (!locationKey || router.asPath.split('/').length > 3) {
    if (router.asPath.includes('configurator')) {
      if (router.asPath.includes(ROUTES.CAR_PAGE)) {
        locationKey = ESourceEventByRoutes.NewCarConfigurator;
      } else if (router.asPath.includes(ROUTES.PRIVATE_LEASING)) {
        locationKey = ESourceEventByRoutes.LeaseCarConfigurator;
      }
    } else {
      if (router.asPath.includes(ROUTES.CAR_PAGE)) {
        if (router.asPath.includes(ROUTES.CAR_PAGE)) {
          locationKey = ESourceEventByRoutes.NewCarPage;
        } else if (router.asPath.includes(ROUTES.PRIVATE_LEASING)) {
          locationKey = ESourceEventByRoutes.LeaseCarPage;
        } else if (router.asPath.includes(ROUTES.USED_CAR)) {
          locationKey = ESourceEventByRoutes.UsedCarPage;
        }
      }
    }
  }

  let sourceLocation = locationKey ? ESourceEventByRoutes[locationKey] : '';

  if (stepperActiveStep === 4 && locationKey?.includes('carFlowId')) {
    sourceLocation =
      {
        NewCarConfigurator: 'NewCarSummary',
        CarConfigurator: 'LeaseCarSummary',
      }[sourceLocation] ?? sourceLocation;
  }

  if (sourceLocation === 'SearchFlowBudget') {
    if (step === 1)
      sourceLocation = `${ESourceEventOptions.SEARCH_FLOW_BUDGET_P1}`;
    else if (step === 2)
      sourceLocation = `${ESourceEventOptions.SEARCH_FLOW_BUDGET_P2}`;
    else if (step === 3)
      sourceLocation = `${ESourceEventOptions.SEARCH_FLOW_BUDGET_P3}`;
  }

  const category =
    getCategoryFromSourceLocation[
      sourceLocation as keyof typeof getCategoryFromSourceLocation
    ] || ECategoryEvent.HOMEPAGE;

  return {
    sourceLocation,
    category,
  };
};
