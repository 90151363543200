import React from 'react';
import { ImageFile } from '~/data/types/images';

const engine1Icon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="85"
      viewBox="0 0 84 85"
    >
      <path
        d="M40.9828 35.5072C41.2972 33.7447 43.8254 33.7447 44.1398 35.5072C44.5584 37.8537 47.3562 38.872 49.1851 37.3435C50.5588 36.1955 52.4956 37.8206 51.6035 39.3728C50.4158 41.4394 51.9045 44.0178 54.288 44.0226C56.0783 44.0261 56.5173 46.5159 54.8362 47.1316C52.5981 47.9513 52.0811 50.8834 53.9039 52.4191C55.2731 53.5726 54.0089 55.7621 52.3254 55.1531C50.084 54.3424 47.8032 56.2562 48.2125 58.6043C48.5198 60.368 46.1441 61.2327 45.2459 59.6841C44.05 57.6222 41.0726 57.6222 39.8768 59.6841C38.9786 61.2327 36.6028 60.368 36.9102 58.6043C37.3194 56.2562 35.0386 54.3424 32.7972 55.1531C31.1137 55.7621 29.8496 53.5726 31.2187 52.4191C33.0416 50.8834 32.5246 47.9512 30.2864 47.1316C28.6053 46.5159 29.0443 44.0261 30.8346 44.0226C33.2181 44.0178 34.7068 41.4394 33.5191 39.3728C32.6271 37.8206 34.5638 36.1955 35.9375 37.3435C37.7665 38.872 40.5643 37.8537 40.9828 35.5072Z"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M54.5991 31.397C55.0833 30.7858 55.9712 30.6829 56.5824 31.167C57.1936 31.6512 57.2965 32.5391 56.8124 33.1503L47.5681 44.8192C46.7387 45.8662 46.4788 47.1896 46.5416 48.4106C46.5932 49.4145 46.502 50.4869 46.0931 51.1683C45.4006 52.3223 44.3974 52.9853 43.3347 53.1722C42.2713 53.3592 41.0834 53.0807 40.004 52.2255C38.0778 50.6996 37.784 47.9319 39.663 46.0637C40.2266 45.5033 41.2487 45.1421 42.2195 44.9332C43.3574 44.6885 44.508 44.1349 45.2958 43.1405L54.5991 31.397Z"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M59.518 13.1402C59.9798 12.5573 60.8266 12.4592 61.4094 12.9209L73.1103 22.1904C73.6932 22.6522 73.7913 23.499 73.3296 24.0818L61.2096 39.3808C60.6115 40.1358 59.5147 40.2629 58.7597 39.6648L47.6821 30.8891C46.9271 30.291 46.8 29.1941 47.3981 28.4392L59.518 13.1402Z"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M71.4139 26.4441L56.9653 15.044"
        stroke="black"
        strokeWidth="1.5"
      />
      <circle
        cx="42.7021"
        cy="48.9597"
        r="1.08433"
        transform="rotate(38.3866 42.7021 48.9597)"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M27.6374 33.8794C27.1332 33.2846 27.2067 32.3938 27.8015 31.8896C28.3963 31.3855 29.2872 31.459 29.7913 32.0537L39.417 43.41C40.2807 44.429 41.5273 44.9437 42.7368 45.122C43.7312 45.2686 44.7649 45.5687 45.3527 46.1035C46.3482 47.0092 46.8012 48.1231 46.7757 49.2017C46.7502 50.2812 46.2437 51.3912 45.1932 52.2816C43.3187 53.8705 40.5472 53.615 39.0845 51.4056C38.6457 50.7429 38.4923 49.6698 38.4782 48.6768C38.4618 47.513 38.145 46.2761 37.3246 45.3083L27.6374 33.8794Z"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M10.7079 25.4736C10.2271 24.9064 10.2972 24.0568 10.8644 23.576L22.2518 13.9239C22.819 13.4431 23.6686 13.5132 24.1494 14.0804L36.7696 28.9695C37.3923 29.7042 37.3016 30.8047 36.5668 31.4275L25.786 40.5654C25.0513 41.1882 23.9508 41.0974 23.3281 40.3627L10.7079 25.4736Z"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M10.7079 25.4736C10.2271 24.9064 10.2972 24.0568 10.8644 23.576L22.2518 13.9239C22.819 13.4431 23.6686 13.5132 24.1494 14.0804L36.7696 28.9695C37.3923 29.7042 37.3016 30.8047 36.5668 31.4275L25.786 40.5654C25.0513 41.1882 23.9508 41.0974 23.3281 40.3627L10.7079 25.4736Z"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M26.4121 15.7404L12.1235 27.8516"
        stroke="black"
        strokeWidth="1.5"
      />
      <circle
        cx="42.5292"
        cy="49.0023"
        r="1.08433"
        transform="rotate(-40.285 42.5292 49.0023)"
        fill="#FFFEFE"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M42.3618 59.0027C42.3618 67.2927 35.6413 74.0131 27.3513 74.0131C19.0612 74.0131 12.3408 67.2927 12.3408 59.0027C12.3408 50.7126 19.0612 43.9922 27.3513 43.9922C35.6413 43.9922 42.3618 50.7126 42.3618 59.0027Z"
        fill="#FFFEFE"
      />
      <path
        d="M35.4996 71.5001C21.9996 78.0001 12.3408 67.2927 12.3408 59.0027C12.3408 50.7126 19.0612 43.9922 27.3513 43.9922C35.6413 43.9922 42.3618 50.7126 42.3618 59.0027C42.3618 60.9431 41.9935 62.7976 41.3232 64.5001"
        stroke="black"
        strokeWidth="1.4178"
        strokeLinecap="round"
      />
      <path
        d="M26.618 64.9922V54.8162L24.29 56.7602L23.69 54.5762L27.266 51.4082H28.874V64.9922H26.618Z"
        fill="#121111"
      />
    </Component>
  );
};

export default engine1Icon;
