import { createSlice } from '@reduxjs/toolkit';
import {
  BrandAndModel,
  categoryType,
  IAllColors,
  IColorOptionData,
  OptionType,
} from '~/data/types/resultPageType';
import {
  getBannerPromotion,
  getBannersPromotion,
  getCardsPromotion,
} from '~/redux-toolkit/thunks/resultPageThunks';
import { optionsMockRP } from '~/data/resultsCarsDefaultData';
import { CAR_COLOR_HEX, CAR_COLOR_TEXT } from '~/data/car/carColors';
import { useSortObjectArr as sortObjectArr } from '~/hooks/useSortArr';
import {
  sortOptions,
  USED_CAR_PAYMENT_METHOD,
  UsedCarFilerState,
  getSortByKey,
} from '~/data/types/usedCar/usedCarData';
import {
  getUsedCarFiltersOptions,
  getUsedCarHighlightsFilterOptions,
  getUsedCarBodyTypeFilterOptions,
} from '~/redux-toolkit/thunks/usedCarThunks';
import { removeDuplicatesOfBodyType } from '~/redux-toolkit/utils/filtersUtils';
import { HYDRATE } from 'next-redux-wrapper';
import { brandNamesFilterParams } from '~/utils/populateFiltersFromQuery';

const initialState: UsedCarFilerState = {
  usedCarQuery: {
    filters: {
      budget: {
        paymentMethod: USED_CAR_PAYMENT_METHOD.oneTime,
        priceRange: {
          minPrice: null,
          maxPrice: null,
        },
      },
      colors: [],
      categories: [],
      models: [],
      brands: [],
      carHighlights: [],
      carBodyType: [],
      yearsRange: { minYear: null, maxYear: null },
      kmRange: {
        minPrice: null,
        maxPrice: null,
      },
      gearType: [],
      engineType: [],
      carHand: [],
      originOwnership: [],
      imagesLength: null,
    },
    isAdditionFiltersSelected: false,
    pagination: {
      page: 1,
      pageSize: 24,
      pageCount: 0,
      total: 100,
    },
    sort: { [sortOptions[0].value]: sortOptions[0].type },
  },
  yearsOptionInput: [],
  isMoreFiltersOpen: false,
  brandsAndModels: [],
  allModels: [],
  isOptionsFetched: false,
  options: optionsMockRP,
  filterPagination: {
    page: 1,
    pageSize: 20,
    pageCount: 0,
    total: 0,
  },
  prevPage: 0,
  carsCurrentPage: [],
  isFetch: false,
  cars: [],
  allColors: {},
  bannerPromotion: {
    isBannerAppears: false,
    bannerTitle: '',
    bannerDescription: '',
    bannerOption: '',
    bannerButton: '',
    campaign: '',
    image: '',
    legalContent: '',
  },
  bannersPromotion: null,
  promotionCards: [],
  manufacturerEnum: {},
};

const usedCarFiltersSlice = createSlice({
  name: 'useCarFiltersData',
  initialState,
  reducers: {
    getUsedCarQuery() {
      return initialState;
    },
    setCars(state, action) {
      state.cars = action.payload;
    },
    setFilterOptionsModels(state, action) {
      const modelOptions = sortObjectArr([...action.payload], 'title');
      state.options.models.values = modelOptions;
      state.usedCarQuery.filters.models =
        state.usedCarQuery.filters.models.filter((model) => {
          return action.payload.some(
            (option: OptionType) => option.title === model,
          );
        });
    },
    setFiltersBudget(state, action) {
      state.usedCarQuery.filters.budget = action.payload;
    },
    setFiltersPriceRange(state, action) {
      state.usedCarQuery.filters.budget.priceRange = action.payload;
    },
    setFiltersColors(state, action) {
      state.usedCarQuery.filters.colors = action.payload;
    },
    setFiltersBodyTypes(state, action) {
      state.usedCarQuery.filters.carBodyType = action.payload;
    },
    setFiltersHighlights(state, action) {
      state.usedCarQuery.filters.carHighlights = action.payload;
    },
    setOptionsColorsValues(state, action) {
      state.options.colors.values = action.payload;
    },
    setFiltersBrands(state, action) {
      state.usedCarQuery.filters.brands = action.payload;
    },
    setFiltersModels(state, action) {
      state.usedCarQuery.filters.models = action.payload;
    },
    setKm(state, action) {
      state.usedCarQuery.filters.kmRange = action.payload;
    },
    setGearType(state, action) {
      state.usedCarQuery.filters.gearType = action.payload;
    },
    setEngineType(state, action) {
      state.usedCarQuery.filters.engineType = action.payload;
    },
    setManufactureYear(state, action) {
      state.usedCarQuery.filters.yearsRange = action.payload;
    },
    setIsFetch(state, action) {
      state.isFetch = action.payload;
    },
    setFiltersSort(state, action) {
      state.usedCarQuery = {
        ...state.usedCarQuery,
        sort: action.payload,
      };
    },
    setUsedCarFiltersCategories(state, action) {
      state.usedCarQuery.filters.categories = action.payload;
    },
    setCarHand(state, action) {
      state.usedCarQuery.filters.carHand = action.payload;
    },
    setOriginOwnership(state, action) {
      state.usedCarQuery.filters.originOwnership = action.payload;
    },
    setIsCarsOnlyWithImages(state, action) {
      state.usedCarQuery.filters.imagesLength = action.payload;
    },
    setIsMoreFiltersOpen(state, action) {
      state.isMoreFiltersOpen = action.payload;
    },
    setUsedCarQuery(state, action) {
      state.usedCarQuery = action.payload;
    },
    cleanBudget(state) {
      state.usedCarQuery.filters.budget.priceRange.maxPrice = null;
      state.usedCarQuery.filters.budget.priceRange.minPrice = null;
    },
    setUsedCarPaginationPage(state, action) {
      state.filterPagination = action.payload.pagination;
    },
    cleanKm(state) {
      state.usedCarQuery.filters.kmRange.maxPrice = null;
      state.usedCarQuery.filters.kmRange.minPrice = null;
    },
    cleanManufactureYear(state) {
      state.usedCarQuery.filters.yearsRange.minYear = null;
      state.usedCarQuery.filters.yearsRange.maxYear = null;
    },
    setUsedCarIsAdditionFiltersSelected(state) {
      state.usedCarQuery.isAdditionFiltersSelected =
        state.usedCarQuery.filters.carHand.length > 0 ||
        state.usedCarQuery.filters.kmRange.maxPrice !== null ||
        state.usedCarQuery.filters.kmRange.minPrice !== null ||
        state.usedCarQuery.filters.engineType.length > 0 ||
        state.usedCarQuery.filters.gearType.length > 0 ||
        state.usedCarQuery.filters.carBodyType.length > 0 ||
        state.usedCarQuery.filters.categories.length > 0 ||
        state.usedCarQuery.filters.colors.length > 0 ||
        state.usedCarQuery.filters.originOwnership.length > 0 ||
        state.usedCarQuery.filters.carHighlights.length > 0 ||
        Boolean(state.usedCarQuery.filters.imagesLength);
    },
    cleanAllFilters(state) {
      state.usedCarQuery.sort = getSortByKey(state.usedCarQuery.sort);
      state.usedCarQuery.filters.colors = [];
      state.usedCarQuery.filters.categories = [];
      state.usedCarQuery.filters.models = [];
      state.usedCarQuery.filters.brands = [];
      state.usedCarQuery.filters.engineType = [];
      state.usedCarQuery.filters.gearType = [];
      state.usedCarQuery.filters.carBodyType = [];
      state.usedCarQuery.filters.carHighlights = [];
      state.usedCarQuery.filters.carHand = [];
      state.usedCarQuery.filters.originOwnership = [];
      state.usedCarQuery.filters.budget = {
        paymentMethod: USED_CAR_PAYMENT_METHOD.oneTime,
        priceRange: {
          minPrice: null,
          maxPrice: null,
        },
      };
      state.usedCarQuery.filters.yearsRange.minYear = null;
      state.usedCarQuery.filters.yearsRange.maxYear = null;
      state.usedCarQuery.filters.kmRange.maxPrice = null;
      state.usedCarQuery.filters.kmRange.minPrice = null;
      state.usedCarQuery.filters.budget.priceRange.maxPrice = null;
      state.usedCarQuery.filters.budget.priceRange.minPrice = null;
      state.usedCarQuery.filters.imagesLength = false;
      state.usedCarQuery.isAdditionFiltersSelected = false;
    },
    setUsedCarsAllFiltersOptions(state, action) {
      try {
        const { manufactureWithModels, categories, colors, filtersOptions } =
          action.payload;
        state.options.brands.values = sortObjectArr(
          manufactureWithModels.map((car: BrandAndModel) => {
            return { title: car.ManufacturerName };
          }),
          'title',
        );
        const modelOptions = manufactureWithModels.flatMap(
          (car: BrandAndModel) => {
            return car.models.map((model: string) => {
              return { title: model };
            });
          },
        );

        state.options.models.values = sortObjectArr(modelOptions, 'title');
        state.options.carHand.values = filtersOptions.carHandArray;
        state.options.originOwnership.values = filtersOptions.originOwnerArray;
        state.options.gearType.values = filtersOptions.girArray;
        state.options.engineType.values = filtersOptions.engineArray;
        state.yearsOptionInput = filtersOptions.yearsArray;
        state.allModels = modelOptions;
        state.isOptionsFetched = true;
        state.brandsAndModels = manufactureWithModels;
        state.options.categories.values = sortObjectArr(
          categories.map((cat: categoryType) => {
            return {
              title: cat.name,
              icon: cat.icon.url,
              nameEn: cat.nameEn,
              url: cat?.url || '',
            };
          }),
          'title',
        );

        const allColors: IAllColors = {};

        colors?.forEach((color: IColorOptionData) => {
          const title = CAR_COLOR_TEXT[color.mainColor];
          allColors[title] = {
            title: title,
            hex: CAR_COLOR_HEX[title],
            allColorsFamily: color.value.map((val) => val.colorTitle),
          };
        });
        state.allColors = allColors;

        state.options.colors.values = sortObjectArr(
          Object.keys(allColors).map((color) => {
            return {
              title: allColors[color].title,
              icon: allColors[color].hex,
            };
          }),
          'title',
        );

        if (state.usedCarQuery.filters.brands.length > 0) {
          const filteredBrandsAndModels: BrandAndModel[] =
            manufactureWithModels.filter((brandAndModel: BrandAndModel) =>
              state.usedCarQuery.filters.brands.includes(
                brandAndModel.ManufacturerName,
              ),
            );

          const options: OptionType[] = filteredBrandsAndModels.flatMap(
            (brandsAndModel) =>
              brandsAndModel.models.map((model) => {
                return { title: model };
              }),
          );
          const modelsOptions = sortObjectArr([...options], 'title');
          state.options.models.values = modelsOptions;
          state.usedCarQuery.filters.models =
            state.usedCarQuery.filters.models.filter((model) => {
              return options.some(
                (option: OptionType) => option.title === model,
              );
            });
        }
      } catch (e) {
        console.log('Error', e);
      }
    },
    setUsedCarsHighlightsOptions(state, action) {
      const carHighlights = action.payload;
      state.options.carHighlights.values = sortObjectArr(
        carHighlights.map((highlight: any) => {
          return {
            title: highlight.attributes.title,
            icon: highlight.attributes.icon?.data?.attributes.url || null,
          };
        }),
        'title',
      );
    },
    setUsedCarsAllBodyTypes(state, action) {
      const carBodyTypes = action.payload;
      const filteredBodyTypes = carBodyTypes
        .map((option: any) => {
          return {
            title: option.attributes.title,
            displayTitleName: option.attributes.displayTitleName,
            icon: option.attributes.icon?.data?.attributes.url,
            isNewCar: option.attributes.isNewCar || false,
            isUsedCar: option.attributes.isUsedCar || false,
          };
        })
        .filter((op: { isUsedCar: any }) => op.isUsedCar);

      const titles = filteredBodyTypes.map((bt: { title: any }) => bt.title);
      const bodyTypeWithoutDuplicate = removeDuplicatesOfBodyType(
        filteredBodyTypes.filter(
          (bt: { icon: any; displayTitleName: any }) =>
            bt.icon && !titles.includes(bt.displayTitleName),
        ),
      );

      state.options.carBodyType.values = bodyTypeWithoutDuplicate.map(
        (bodyType) => {
          return {
            title: bodyType.displayTitleName || bodyType.title,
            icon: bodyType.icon,
          };
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsedCarFiltersOptions.fulfilled, (state, action) => {
      try {
        const { manufactureWithModels, categories, colors, filtersOptions } =
          action.payload;
        state.options.brands.values = sortObjectArr(
          manufactureWithModels.map((car: BrandAndModel) => {
            return { title: car.ManufacturerName };
          }),
          'title',
        );

        const modelOptions = manufactureWithModels.flatMap(
          (car: BrandAndModel) => {
            return car.models.map((model: string) => {
              return { title: model };
            });
          },
        );

        const manufacturerEnumObj = brandNamesFilterParams(
          manufactureWithModels,
        );

        state.manufacturerEnum = manufacturerEnumObj;
        state.options.models.values = sortObjectArr(modelOptions, 'title');
        state.options.carHand.values = filtersOptions.carHandArray;
        state.options.originOwnership.values = filtersOptions.originOwnerArray;
        state.options.gearType.values = filtersOptions.girArray;
        state.options.engineType.values = filtersOptions.engineArray;
        state.yearsOptionInput = filtersOptions.yearsArray;
        state.allModels = modelOptions;
        state.isOptionsFetched = true;
        state.brandsAndModels = manufactureWithModels;
        state.options.categories.values = sortObjectArr(
          categories.map((cat: categoryType) => {
            return {
              title: cat.name,
              icon: cat.icon.url,
              nameEn: cat.nameEn,
              seo: cat.seo ?? null,
              url: cat?.url || '',
            };
          }),
          'title',
        );

        const allColors: IAllColors = {};

        colors?.forEach((color: IColorOptionData) => {
          const title = CAR_COLOR_TEXT[color.mainColor];
          allColors[title] = {
            title: title,
            hex: CAR_COLOR_HEX[title],
            allColorsFamily: color.value.map((val) => val.colorTitle),
          };
        });
        state.allColors = allColors;

        state.options.colors.values = sortObjectArr(
          Object.keys(allColors).map((color) => {
            return {
              title: allColors[color].title,
              icon: allColors[color].hex,
            };
          }),
          'title',
        );

        if (state.usedCarQuery.filters.brands.length > 0) {
          const filteredBrandsAndModels: BrandAndModel[] =
            manufactureWithModels.filter((brandAndModel: BrandAndModel) =>
              state.usedCarQuery.filters.brands.includes(
                brandAndModel.ManufacturerName,
              ),
            );

          const options: OptionType[] = filteredBrandsAndModels.flatMap(
            (brandsAndModel) =>
              brandsAndModel.models.map((model) => {
                return { title: model };
              }),
          );
          const modelsOptions = sortObjectArr([...options], 'title');
          state.options.models.values = modelsOptions;
          state.usedCarQuery.filters.models =
            state.usedCarQuery.filters.models.filter((model) => {
              return options.some(
                (option: OptionType) => option.title === model,
              );
            });
        }
      } catch (e) {
        console.log('Error', e);
      }
    });
    builder.addCase(getBannersPromotion.fulfilled, (state, action) => {
      if (!action.payload) return;

      const attributesArray = action.payload.map((item) => item.attributes);

      state.bannersPromotion = attributesArray.map((attributes) => ({
        ...attributes,
        image: attributes?.bannerIcon?.data?.attributes?.url || '',
        regularLeadDetails: attributes?.regularLeadDetails,
      }));
    });

    builder.addCase(getBannerPromotion?.fulfilled, (state, action) => {
      if (!action.payload) return;

      const { attributes } = action.payload;

      state.bannerPromotion = {
        ...attributes,
        image: attributes?.bannerIcon?.data?.attributes?.url || '',
        regularLeadDetails: attributes?.regularLeadDetails,
      };
    });

    builder.addCase(getCardsPromotion.fulfilled, (state, action) => {
      state.promotionCards =
        action.payload.map((promotionCards) => promotionCards.attributes) || [];
    });
    builder.addCase(
      getUsedCarHighlightsFilterOptions.fulfilled,
      (state, action) => {
        const carHighlights = action.payload;
        state.options.carHighlights.values = sortObjectArr(
          carHighlights.map((highlight) => {
            return {
              title: highlight.attributes.title,
              icon: highlight.attributes.icon?.data?.attributes.url || null,
            };
          }),
          'title',
        );
      },
    );
    builder.addCase(
      getUsedCarBodyTypeFilterOptions.fulfilled,
      (state, action) => {
        const carBodyTypes = action.payload;
        const filteredBodyTypes = carBodyTypes
          .map((option) => {
            return {
              title: option.attributes.title,
              displayTitleName: option.attributes.displayTitleName,
              icon: option.attributes.icon?.data?.attributes.url,
              isNewCar: option.attributes.isNewCar || false,
              isUsedCar: option.attributes.isUsedCar || false,
            };
          })
          .filter((op) => op.isUsedCar);
        const titles = filteredBodyTypes.map((bt) => bt.title);
        const bodyTypeWithoutDuplicate = removeDuplicatesOfBodyType(
          filteredBodyTypes.filter(
            (bt) => bt.icon && !titles.includes(bt.displayTitleName),
          ),
        );

        state.options.carBodyType.values = bodyTypeWithoutDuplicate.map(
          (bodyType) => {
            return {
              title: bodyType.displayTitleName || bodyType.title,
              icon: bodyType.icon,
            };
          },
        );
      },
    );
    builder.addCase(HYDRATE, (state, action: any) => {
      const { usedCarFilters } = action.payload;

      return {
        ...state,
        ...usedCarFilters,
      };
    });
  },
});

export const {
  getUsedCarQuery,
  setCars,
  setFilterOptionsModels,
  setFiltersBudget,
  setFiltersPriceRange,
  setFiltersColors,
  setFiltersSort,
  setUsedCarFiltersCategories,
  setFiltersModels,
  setFiltersBrands,
  setManufactureYear,
  setKm,
  setEngineType,
  setGearType,
  cleanAllFilters,
  cleanKm,
  cleanBudget,
  setIsFetch,
  cleanManufactureYear,
  setOptionsColorsValues,
  setUsedCarPaginationPage,
  setFiltersBodyTypes,
  setFiltersHighlights,
  setCarHand,
  setOriginOwnership,
  setIsCarsOnlyWithImages,
  setIsMoreFiltersOpen,
  setUsedCarIsAdditionFiltersSelected,
  setUsedCarsAllFiltersOptions,
  setUsedCarsAllBodyTypes,
  setUsedCarsHighlightsOptions,
  setUsedCarQuery,
} = usedCarFiltersSlice.actions;

export default usedCarFiltersSlice.reducer;
