/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Box, Typography } from '@mui/material';
import PhoneIcon from '~/assets/icons/ContactUsPhoneIcon.svg';
import DownIcon from '~/assets/icons/jsx/arrowDownIcon';
import LeftIcon from '~/assets/icons/jsx/LeftIconBlack';
import {
  CSSProperties,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EActionEvent, useEvents } from '~/hooks/useEvents';
import { useIsMobile } from '~/hooks/useIsMobile';
import RESOURCES from '~/resources';
import { KEYBOARD_TYPES } from '~/data/constants';
import classes, {
  PhoneDropDownArrowStyled,
  contactUsItemStyle,
  phoneIconStyle,
  phoneNumberStyle,
} from './useStyles';
import { useLeadLocation } from '~/hooks/useLeadLocation';
import useLOBContacData from '~/hooks/useLOBContacData';
import useLineOfBusinessTarget from '~/hooks/useLineOfBusinessTarget';
import { ESourceEventOptions } from '~/data/lead/enums';
import { useGetPhoneNumberBySubMedia } from '~/hooks/useGetPhoneNumberBySubmedia';
import { ROUTES } from '~/data/routes';
import { CONTACT_US_TEXT } from '~/resources/resources';
import { useCurrentCar } from '~/hooks/useCurrentCar';
type PhoneDropDownProp = {
  phoneIconCustomStyle?: CSSProperties;
  optionContainerCustom?: CSSProperties;
  phoneNumberStyleCustom?: CSSProperties;
  contactUsItemStyleCustom?: CSSProperties;
  customStyle?: CSSProperties;
  showNumber?: boolean;
  isNavBar?: boolean;
};

const PhoneDropDown = ({
  phoneIconCustomStyle,
  optionContainerCustom,
  phoneNumberStyleCustom,
  contactUsItemStyleCustom,
  customStyle,
  showNumber = false,
  isNavBar,
}: PhoneDropDownProp) => {
  const events = useEvents();
  const isMobile = useIsMobile();
  const [isPhoneHidden, setIsPhoneHidden] = useState(true);
  const callLinkRef = useRef<HTMLAnchorElement>(null);
  const talkWithUsRef = useRef<HTMLAnchorElement>(null);
  const { category, sourceLocation } = useLeadLocation();
  const phoneIconCustomStyles = phoneIconCustomStyle ?? phoneIconStyle;
  const phoneNumberStyles = phoneNumberStyleCustom ?? phoneNumberStyle;
  const contactUsItemStyles = contactUsItemStyleCustom ?? contactUsItemStyle;
  const lineOfBusinessTarget = useLineOfBusinessTarget();
  const car = useCurrentCar();
  const { phoneNumber } = useLOBContacData();

  const sendEvent = useCallback(
    (action: string) => {
      const leadLocation = isNavBar
        ? ESourceEventOptions.NAV_BAR
        : sourceLocation;
      events.sendEvent(
        {
          action,
          category: category,
        },
        {
          leadLocation,
          businessLine: lineOfBusinessTarget,
          carModel: car?.modelNameDisplayName ?? car?.dmvModelName,
          carBrand: car?.manufacturerDisplayName ?? car?.dmvManufacturerName,
        },
      );
    },
    [sourceLocation, lineOfBusinessTarget, category, events, isNavBar, car],
  );

  const handleShowNumber = useCallback(() => {
    if (isPhoneHidden) {
      sendEvent(EActionEvent.EXPOSE_PHONE);
    }
    setIsPhoneHidden(!isPhoneHidden);
  }, [isPhoneHidden, sendEvent]);

  const onCallUs = useCallback(() => {
    talkWithUsRef.current &&
    !isMobile &&
    talkWithUsRef.current.innerText !== phoneNumber
      ? sendEvent(EActionEvent.EXPOSE_PHONE)
      : sendEvent(EActionEvent.CONTACT_US_MODAL_CALL_US);

    if (callLinkRef.current) {
      callLinkRef.current.click();
    }

    if (talkWithUsRef.current && !isMobile) {
      talkWithUsRef.current.innerText = phoneNumber;
    }
  }, [isMobile, phoneNumber, sendEvent]);

  const handleKeyDown = useCallback(
    (
      event: KeyboardEvent<HTMLDivElement> | KeyboardEvent<HTMLSpanElement>,
      onEnterFunction?: () => void,
    ) => {
      if (event.key === KEYBOARD_TYPES.ENTER && onEnterFunction) {
        onEnterFunction();
      }
    },
    [],
  );

  const phoneDropDownIcon = useMemo(() => {
    if (!isPhoneHidden) {
      return <DownIcon />;
    }
    return <LeftIcon Component={PhoneDropDownArrowStyled} />;
  }, [isPhoneHidden]);

  const phoneNumberMapping = {
    [ROUTES.CAR_PAGE]: CONTACT_US_TEXT.newCarPhone,
    [ROUTES.USED_CAR]: CONTACT_US_TEXT.usedCarPhone,
    [ROUTES.PRIVATE_LEASING]: CONTACT_US_TEXT.privateleasingPhone,
  };
  const phoneNumberBySubMedia = useGetPhoneNumberBySubMedia(
    CONTACT_US_TEXT.phone,
    phoneNumberMapping,
  );
  return (
    <>
      <a
        href={`tel:${phoneNumber}`}
        ref={callLinkRef}
        style={{ display: 'none' }}
      >
        Call
      </a>

      <Box
        sx={{ ...classes.optionContainer, ...optionContainerCustom }}
        onClick={isMobile || showNumber ? onCallUs : handleShowNumber}
        tabIndex={0}
        aria-label={`${RESOURCES.TALK_WITH_US}`}
        onKeyDown={(event) => handleKeyDown(event, handleShowNumber)}
        aria-expanded={!isPhoneHidden}
        style={customStyle}
      >
        <Box
          component={'img'}
          src={PhoneIcon.src}
          style={phoneIconCustomStyles}
          alt=""
        />
        {isMobile || showNumber ? (
          <Typography sx={contactUsItemStyles} ref={talkWithUsRef}>
            {RESOURCES.TALK_WITH_US}
          </Typography>
        ) : (
          <div>
            <Typography sx={contactUsItemStyles}>
              {phoneDropDownIcon}
              &nbsp;{RESOURCES.TALK_WITH_US}
            </Typography>
            {!isPhoneHidden && (
              <Typography
                sx={phoneNumberStyles}
                onClick={onCallUs}
                tabIndex={0}
                role="link"
                onKeyDown={(event) => handleKeyDown(event, onCallUs)}
              >
                {phoneNumberBySubMedia}
              </Typography>
            )}
          </div>
        )}
      </Box>
    </>
  );
};

export default PhoneDropDown;
