import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { getMappedBanners } from '../utils/bannersUtils';
import {
  BannerCardType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';
import {
  getLobbyCategories,
  getLobbyContent,
  getLobbyNewCarContent,
  getLobbyNewCarPageBannerGallery,
  getLobbyPageBannerGallery,
  getLobbyPageBudgetCards,
  getLobbyPageNewCarQuickSearch,
  getLobbyPageQuickSearch,
  getLobbyPageStaticBanners,
  getLobbyPageUsedCarQuickSearch,
  getLobbySearchByNeeds,
  getLobbySupportedBrands,
  getLobbyUsedCarContent,
  getLobbyUsedCarPageBannerGallery,
  getLobbyVideo,
  getSeoContent,
} from '../thunks/lobbyPageThunks';
import { SupportedBrand } from '~/data/types/brands';
import { CategoryType } from '~/data/types/categories/categories';
import { homePageContentType } from '~/data/types/homePageContentType';
import { QuickSearchContentType } from '~/data/QuickSearch/quickSearchType';
import { VideoType } from '~/data/types/video';
import { ISeoResutlsPage } from '~/data/types/seo/seoResutlsPages';
import { BudgetCardsResponseType } from '~/data/types/budgetCardTypes';

interface LobbyPageState {
  content: homePageContentType | null;
  contentNewCar: homePageContentType | null;
  contentUsedCar: homePageContentType | null;
  quickSearchContent: QuickSearchContentType | null;
  quickSearchContentNewCar: QuickSearchContentType | null;
  quickSearchContentUsedCar: QuickSearchContentType | null;
  galleryBanners: BannerCardType[];
  galleryBannersNewCar: BannerCardType[];
  galleryBannersUsedCar: BannerCardType[];
  categories: CategoryType[];
  supportedBrands: SupportedBrand[];
  video: VideoType;
  seoPageData: ISeoResutlsPage | null;
  staticBanners: BannerCardType[];
  searchByNeed: SearchByNeedResponseType | null;
  budgetData: BudgetCardsResponseType | null;
}

const initialState: LobbyPageState = {
  content: null,
  contentNewCar: null,
  contentUsedCar: null,
  quickSearchContent: null,
  quickSearchContentNewCar: null,
  quickSearchContentUsedCar: null,
  galleryBanners: [],
  galleryBannersUsedCar: [],
  galleryBannersNewCar: [],
  categories: [],
  supportedBrands: [],
  video: {} as VideoType,
  seoPageData: null,
  staticBanners: [],
  searchByNeed: null,
  budgetData: null,
};

const lobbyPageSlice = createSlice({
  name: 'homePageData',
  initialState,
  reducers: {
    setGalleryBanners(state, action) {
      state.galleryBanners = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLobbyContent.fulfilled, (state, action) => {
      state.content = action.payload?.[0] || '';
    });

    builder.addCase(getLobbyNewCarContent.fulfilled, (state, action) => {
      const filteredContent = action.payload?.filter(
        (item) => item.page.name === 'new-car-for-sale',
      );

      state.contentNewCar = filteredContent?.[0] || '';
    });

    builder.addCase(getLobbyUsedCarContent.fulfilled, (state, action) => {
      const filteredContent = action.payload?.filter(
        (item) => item.page.name === 'used-car-for-sale',
      );

      state.contentUsedCar = filteredContent?.[0] || '';
    });
    builder.addCase(getLobbyPageQuickSearch.fulfilled, (state, action) => {
      state.quickSearchContent = action.payload?.[0] || '';
    });
    builder.addCase(
      getLobbyPageNewCarQuickSearch.fulfilled,
      (state, action) => {
        const filteredContent = action.payload?.filter(
          (item) => item.page.name === 'new-car-for-sale',
        );
        state.quickSearchContentNewCar = filteredContent?.[0] || '';
      },
    );
    builder.addCase(
      getLobbyPageUsedCarQuickSearch.fulfilled,
      (state, action) => {
        const filteredContent = action.payload?.filter(
          (item) => item.page.name === 'used-car-for-sale',
        );
        state.quickSearchContentUsedCar = filteredContent?.[0] || '';
      },
    );
    builder.addCase(getLobbyPageBannerGallery.fulfilled, (state, action) => {
      const banners = action.payload;
      state.galleryBanners = getMappedBanners(banners);
    });
    builder.addCase(
      getLobbyNewCarPageBannerGallery.fulfilled,
      (state, action) => {
        const filteredContent = action.payload?.filter((item) => {
          return (
            item.attributes.page.data.attributes.name === 'new-car-for-sale'
          );
        });
        state.galleryBannersNewCar = getMappedBanners(filteredContent);
      },
    );
    builder.addCase(
      getLobbyUsedCarPageBannerGallery.fulfilled,
      (state, action) => {
        const filteredContent = action.payload?.filter((item) => {
          return (
            item.attributes.page.data.attributes.name === 'used-car-for-sale'
          );
        });

        state.galleryBannersUsedCar = getMappedBanners(filteredContent);
      },
    );
    builder.addCase(getLobbyCategories.fulfilled, (state, action) => {
      state.categories = action.payload.map((category) => ({
        id: category.id,
        icon: category.attributes.icon?.data?.attributes.url || '',
        selectedIcon:
          category.attributes.selectedIcon?.data?.attributes.url || '',
        name: category.attributes.name,
        displayName: category.attributes.displayName || '',
        carImage: category.attributes.carImage.data?.attributes.url || '',
        galleryIcon:
          category.attributes.galleryIcon?.data?.attributes.url || '',
        rank: category.attributes.rank ?? 0,
      }));
    });
    builder.addCase(getLobbySupportedBrands.fulfilled, (state, action) => {
      state.supportedBrands = action.payload.map((s) => ({
        hebrewName: s.attributes?.hebrewName,
        englishName: s.attributes?.englishName,
        icon: s.attributes.icon?.data?.attributes?.url ?? '',
        rank: s.attributes?.serviceRank,
        leadDetails: s.attributes?.leadDetails,
      }));
    });
    builder.addCase(getLobbyVideo.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        const video = action.payload[0].attributes;
        state.video = {
          name: video.name,
          uploadedVideoUrl: video.uploadedVideo?.data?.attributes?.url ?? '',
          videoUrl: video.videoUrl ?? '',
          altText: video.altText ?? '',
          startButtonLabel: video.startButtonLabel ?? '',
        };
      } else {
        state.video = initialState.video;
      }
    });
    builder.addCase(getSeoContent.fulfilled, (state, action) => {
      if (!action.payload) return;

      const { attributes } = action.payload;

      state.seoPageData = {
        ...attributes,
        advantages: attributes.advantages?.map((advantage) => {
          return {
            title: advantage.Title,
            paragraph: advantage.Paragraph,
            id: advantage.id,
            icon: advantage.Icon.data?.attributes?.url ?? '',
          };
        }),
        faq: attributes.faq?.map((fa) => {
          return {
            title: fa.Title,
            paragraph: fa.Paragraph,
            id: fa.id,
            icon: fa.Icon.data?.attributes?.url ?? '',
          };
        }),
      };
    });
    builder.addCase(getLobbyPageStaticBanners.fulfilled, (state, action) => {
      const banners = action.payload;
      state.staticBanners = banners ? getMappedBanners(banners) : [];
    });
    builder.addCase(getLobbySearchByNeeds.fulfilled, (state, action) => {
      state.searchByNeed = action.payload?.[0] ?? null;
    });
    builder.addCase(getLobbyPageBudgetCards.fulfilled, (state, action) => {
      state.budgetData = action.payload?.[0] ?? null;
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { lobbyPage } = action.payload;

      return {
        ...state,
        ...lobbyPage,
      };
    });
  },
});

export const { setGalleryBanners } = lobbyPageSlice.actions;

export default lobbyPageSlice.reducer;
