import { styled } from '@mui/system';
import theme from '~/theme';
import { Box } from '@mui/material';

const classes = {
  linkIcon: {
    // width: 'calc(100vw - 100px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      justifyContent: 'space-between',
      // maxWidth: '160px',
      whiteSpace: 'nowrap',
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

export default classes;

export const ItemContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0 8px 0',
  [theme.breakpoints.down('md')]: {
    width: 'calc(100vw - 100px)',
  },
  [theme.breakpoints.up('md')]: {
    height: '37px',
    borderBottom: 'none',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
  },
});

export const titleMenuItem = {
  fontWeight: '400',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  textDecoration: 'none',
};
