'use client';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import counterReducer from '../slices/counter';
import carSlice from '../slices/carSlice';
import usedCarSlice from '../slices/usedCarSlice';
import approvalFlowSlice from '../slices/approvalFlowSlice';
import searchFlowSlice from '../slices/searchFlowSlice';
import homePageSlice from '../slices/homePageSlice';
import homePageContentSlice from '../slices/homePageContentSlice';
import fundingSlice from '../slices/fundingSlice';
import carPageSlice from '../slices/carPageSlice';
import contactUsSlice from '../slices/contactUsSlice';
import wishlistSlice from '../slices/wishlistSlice';
import carFlowSlice from '../slices/carFlowSlice';
import usedCarFiltersSlice from '../slices/usedCarFiltersSlice';
import resultPageSlice from '../slices/resultPageSlice';
import tooltipSlice from '../slices/tooltipSlice';
import colorsSlice from '../slices/colorsSlice';
import ourBranchesSlice from '../slices/ourBranchesSlice';
import blogSlice from '../slices/blogSlice';
import rentalBranchesSlice from '../slices/rentalBranchesSlice';
import searchFiltersSlice from '../slices/searchFiltersSlice';
import subscriptionCarSlice from '../slices/subscriptionCarSlice';
import marketingPopupSlice from '../slices/marketingPopupSlice';
import genericPageSlice from '../slices/genericPageSlice';
import communityPageSlice from '../slices/communityPageSlice';
import lobbyPageSlice from '../slices/lobbyPageSlice';
import resultPageLeaseSlice from '../slices/resultPageLeaseSlice';
import maskyooSlice from '../slices/MaskyooSlice';
import compareSlice from '../slices/compareSlice';
import newCarForSalePageContentSlice from '../slices/newCarForSalePageContentSlice';
import privateLeasingPageContentSlice from '../slices/privateLeasingPageContentSlice';
import carFleetPageContentSlice from '../slices/carFleetPageContentSlice';
import usedCarForSalePageContentSlice from '../slices/usedCarForSalePageContentSlice';
import foundingPageSlice from '../slices/foundingPageSlice';
const rootReducer = combineReducers({
  counter: counterReducer,
  car: carSlice,
  usedCarSlice,
  approvalFlow: approvalFlowSlice,
  searchFlow: searchFlowSlice,
  homePage: homePageSlice,
  homePageContent: homePageContentSlice,
  newCarForSalePageContent: newCarForSalePageContentSlice,
  privateLeasingPageContent: privateLeasingPageContentSlice,
  carFleetPageContent: carFleetPageContentSlice,
  usedCarForSalePageContent: usedCarForSalePageContentSlice,
  blog: blogSlice,
  resultPage: resultPageSlice,
  resultPageLease: resultPageLeaseSlice,
  carPage: carPageSlice,
  // plans: plansSlice,
  tooltip: tooltipSlice,
  searchFilters: searchFiltersSlice,
  carFlow: carFlowSlice,
  wishlist: wishlistSlice,
  colors: colorsSlice,
  contactUsSlice: contactUsSlice,
  funding: fundingSlice,
  genericPage: genericPageSlice,
  marketingSlice: marketingPopupSlice,
  subscriptionCarSlice: subscriptionCarSlice,
  rentalBranches: rentalBranchesSlice,
  usedCarFilters: usedCarFiltersSlice,
  ourBranches: ourBranchesSlice,
  communityPage: communityPageSlice,
  lobbyPage: lobbyPageSlice,
  maskyoo: maskyooSlice,
  compare: compareSlice,
  foundingPage: foundingPageSlice,
  //add all your reducers here
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const ssrWrapper = createWrapper(() => store);
