import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '~/data/routes';
import { CONTACT_US_TEXT } from '~/resources/resources';
import { useGetPhoneNumberBySubMedia } from './useGetPhoneNumberBySubmedia';

const useLOBContacData = () => {
  const router = useRouter();

  const whatsappLink = useMemo(() => {
    const path = router.asPath;
    if (path.includes(ROUTES.CAR_PAGE)) {
      return CONTACT_US_TEXT.whatsappLinkNewCar;
    } else if (path.includes(ROUTES.USED_CAR)) {
      return CONTACT_US_TEXT.whatsappLinkUsedCar;
    } else if (path.includes(ROUTES.PRIVATE_LEASING)) {
      return CONTACT_US_TEXT.whatsappLinkPrivateLeasing;
    }
    return CONTACT_US_TEXT.whatsappLink;
  }, [router.asPath]);

  const phoneNumberMapping = {
    [ROUTES.CAR_PAGE]: CONTACT_US_TEXT.newCarPhone,
    [ROUTES.USED_CAR]: CONTACT_US_TEXT.usedCarPhone,
    [ROUTES.PRIVATE_LEASING]: CONTACT_US_TEXT.privateleasingPhone,
  };
  const phoneNumber = useGetPhoneNumberBySubMedia(
    CONTACT_US_TEXT.phone,
    phoneNumberMapping,
  );

  return { whatsappLink, phoneNumber };
};

export default useLOBContacData;
