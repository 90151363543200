import { useMemo } from 'react';
import { CAR_TARGET } from '~/data/consts';
import { getLineOfBusinessByCampaignNumber } from '~/data/lead/lineOfBusiness';
import { RootState } from '~/redux-toolkit/store/store';
import { getFindingCodeFromLocalStorage } from '~/utils/ApprovalFlow';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { useCampaignNumber } from './useCampaignNumber';
import { useIsNewCar } from './useIsNewCar';
import { useIsUsedCar } from './useIsUsedCar';
import { useCarsPagesLeadLocation } from './useCarsPagesLeadLocation';
import useCarEventDetails from './useCarEventDetails';
import { CarCardType } from '~/data/types/car/cars';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { useIsLeaseCar } from './useIsLeaseCar';

export function useLeadEventDetails(
  leadLocation?: string | null,
  carEvent?: CarCardType | UsedCarType,
) {
  const isUsedCar = useIsUsedCar();
  const isNewCar = useIsNewCar();
  const isLeaseCar = useIsLeaseCar();
  const campaign = useCampaignNumber();

  const { leadLocationOption } = useCarsPagesLeadLocation();

  const { dealType, car } = useAppSelector((state: RootState) => state.car);

  const { familyCarPromo } = useAppSelector(
    (state: RootState) => state.resultPage,
  );

  const { source, leadDetails, usedCarContactUs } = useAppSelector(
    (state: RootState) => state.contactUsSlice,
  );
  const { isExistOpenLeadUsedCar, usedCar } = useAppSelector(
    (state: RootState) => state.usedCarSlice,
  );
  const CarFromCarState = useAppSelector((state: RootState) => state.car.car);

  const approvalCode = getFindingCodeFromLocalStorage();

  const { GetUsedCarDetails, GetNewCarDetails } = useCarEventDetails();

  const isNotCar = useMemo(
    () => !isNewCar && !isUsedCar && !isLeaseCar,
    [isLeaseCar, isNewCar, isUsedCar],
  );
  const businessLine = useMemo(
    () =>
      !isNotCar
        ? isUsedCar
          ? CAR_TARGET.USED_CAR
          : isLeaseCar
          ? CAR_TARGET.LEASING
          : CAR_TARGET.NEW_CAR
        : CAR_TARGET.ALL,
    [isLeaseCar, isNotCar, isUsedCar],
  );

  const carDetails = useMemo(() => {
    if (familyCarPromo) {
      return {
        carModel: familyCarPromo?.modelName,
        carMake: familyCarPromo?.manufacturerName,
      };
    }
    if (isUsedCar) {
      return GetUsedCarDetails(
        (carEvent as UsedCarType) || usedCar || usedCarContactUs,
      );
    } else if (isNewCar) {
      return GetNewCarDetails(
        (carEvent as CarCardType) ||
          (car as CarCardType) ||
          (CarFromCarState as CarCardType),
        businessLine,
      );
    } else if (isLeaseCar) {
      return GetNewCarDetails(
        (carEvent as CarCardType) ||
          (car as CarCardType) ||
          (CarFromCarState as CarCardType),
        businessLine,
      );
    } else {
      return {};
    }
  }, [
    isUsedCar,
    isNewCar,
    isLeaseCar,
    GetUsedCarDetails,
    carEvent,
    usedCar,
    usedCarContactUs,
    GetNewCarDetails,
    car,
    CarFromCarState,
    businessLine,
    familyCarPromo,
  ]);

  const eventDetails = useMemo(() => {
    const eventDetails = {
      ...carDetails,
      approve_emails: leadDetails.mailsApproved ?? null,
      businessLine: isNotCar
        ? getLineOfBusinessByCampaignNumber(campaign) ?? null
        : businessLine,
      leadLocation: leadLocation || leadLocationOption || source,
      dealType: familyCarPromo ? 'familyCarPromo' : isNotCar ? null : dealType,
      fundingApproval: !!approvalCode,
      firstTimeLead: isUsedCar ? !isExistOpenLeadUsedCar : false,
    };

    Object.keys(eventDetails).forEach((key) => {
      if (eventDetails[key as keyof typeof eventDetails] === undefined) {
        delete eventDetails[key as keyof typeof eventDetails];
      }
    });

    return eventDetails;
  }, [
    carDetails,
    leadDetails.mailsApproved,
    isNotCar,
    campaign,
    businessLine,
    leadLocation,
    leadLocationOption,
    source,
    dealType,
    approvalCode,
    isUsedCar,
    isExistOpenLeadUsedCar,
    familyCarPromo,
  ]);

  return eventDetails;
}
