import React from 'react';
import { ImageFile } from '~/data/types/images';

const tradeInSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
    >
      <g clipPath="url(#clip0_8281_217480)">
        <path
          d="M45 62.322V87.9999H40V62.3218L45 62.322Z"
          fill="#FFFEFE"
          stroke="#262626"
          strokeWidth="2"
        />
        <path
          d="M39.3924 22.5827C40.5428 20.5608 43.457 20.5608 44.6074 22.5827L62.9193 54.7664C64.0573 56.7663 62.6129 59.25 60.3119 59.25H23.6879C21.3869 59.25 19.9425 56.7663 21.0804 54.7664L39.3924 22.5827Z"
          fill="#00D6D1"
        />
        <path
          d="M66.3236 54.6154C68.2455 57.8974 65.8431 62 61.9994 62H22.0006C18.1569 62 15.7545 57.8974 17.6764 54.6154L37.6758 20.4615C39.5977 17.1795 44.4023 17.1795 46.3242 20.4615L59.1656 42.3913"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M38.1668 35.8115C30.2808 39.144 30.0956 53.8499 41.9542 54.2927"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35 34.5146L39.9106 34.8832L37.6247 38.8863"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.0107 55.6572L45.0915 55.4317L47.2599 51.3638"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.5877 54.7148C54.4736 51.3823 54.6588 36.6765 42.8002 36.2337"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8281_217480">
          <rect width="84" height="84" fill="white" />
        </clipPath>
      </defs>
    </Component>
  );
};

export default tradeInSelectedIcon;
