/* eslint-disable class-methods-use-this */
import { HttpClientService } from '~/services/HttpsClientService';
import { AxiosResponse } from 'axios';
import { MetaType } from '~/data/types/paginationTypes';
import {
  EngineType,
  IOrderDetails,
  LeaseCarType,
  AccessoryDataType,
  AdditionType,
} from '~/data/types/carFlow/carFlowTypes';

class CarFlowAdapter {
  static readonly endpoint = {
    getCarFlowContent: `/api/car-configurator-flow`,
    getCarFlowAdditions: `/api/car-additions`,
    getCarAccessories: '/api/car-accessories',
    getLeasingAccessories: '/api/lease-cars-accessories',
    getCar: '/api/cars',
    getLeasingCar: '/api/lease-car-configurator',
    createNewCarOrder: '/api/order/new-car',
    createLeaseCarOrder: '/api/order/lease-car',
    createLeaseCustomer: '/api/order/lease-customer',
  };

  async getCarFlowContent(model: string, dealType: string, _carId: number) {
    const res = await HttpClientService.get<EngineType[]>(
      // If you need to filter the configurator to show only models that match a specific engine type,
      // simply append &carId=<desired_car_id> to the query URL when making the API request.
      // This ensures that only cars with the same engine type as the given car ID are returned.
      // `${CarFlowAdapter.endpoint.getCarFlowContent}?dmvModelName=${model}&dealType=${dealType}&carId=${_carId}`,
      `${CarFlowAdapter.endpoint.getCarFlowContent}?dmvModelName=${model}&dealType=${dealType}`,
    );

    return res;
  }

  async getLeaseCarData(model: string) {
    const res = await HttpClientService.get<LeaseCarType[]>(
      `${CarFlowAdapter.endpoint.getLeasingCar}/${model}`,
    );
    return res;
  }

  async getCarFlowAdditions(
    modelVehicle: string,
    colorId: string,
  ): Promise<AxiosResponse> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: AdditionType;
    }>(
      `${CarFlowAdapter.endpoint.getCarFlowAdditions}?filters[carModelCode][$eq]=${modelVehicle}&filters[carColorId][$eq]=${colorId}`,
    );
    return res;
  }

  async getCarFlowAccessories(modelVehicle: string): Promise<AxiosResponse> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: AccessoryDataType[];
    }>(
      `${CarFlowAdapter.endpoint.getCarAccessories}?filters[newCarId][modelVehicle][$eq]=${modelVehicle}&pagination[pageSize]=100`,
    );
    return res;
  }

  async getLeasingAccessories(
    modelVehicle: string,
    productCode: string,
  ): Promise<AxiosResponse> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: AdditionType;
    }>(
      `${CarFlowAdapter.endpoint.getLeasingAccessories}?filters[modelCode][$eq]=${modelVehicle}&filters[productCode][$eq]=${productCode}&filters[type][$ne]=חובה`,
    );
    return res;
  }

  async getLeasingAdditions(
    modelVehicle: string,
    productCode: string,
  ): Promise<AxiosResponse> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: AdditionType;
    }>(
      `${CarFlowAdapter.endpoint.getLeasingAccessories}?filters[modelCode][$eq]=${modelVehicle}&filters[productCode][$eq]=${productCode}&filters[type][$eq]=חובה`,
    );
    return res;
  }

  async getCar(modelVehicle: string): Promise<AxiosResponse> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: AdditionType;
    }>(
      `${CarFlowAdapter.endpoint.getCar}?filters[modelVehicle][$eq]=${modelVehicle}`,
    );
    return res;
  }

  async createNewCarOrder(data: IOrderDetails): Promise<AxiosResponse<string>> {
    const res = await HttpClientService.post<string>(
      `${CarFlowAdapter.endpoint.createNewCarOrder}`,
      data,
    );
    return res;
  }

  async createLeaseCarOrder(
    data: IOrderDetails,
  ): Promise<AxiosResponse<string>> {
    const res = await HttpClientService.post<string>(
      `${CarFlowAdapter.endpoint.createLeaseCarOrder}`,
      data,
    );
    return res;
  }

  async createLeaseCustomer(
    data: IOrderDetails,
  ): Promise<AxiosResponse<string>> {
    const res = await HttpClientService.post<string>(
      `${CarFlowAdapter.endpoint.createLeaseCustomer}`,
      data,
    );
    return res;
  }
}

const carFlowAdapter = new CarFlowAdapter();
export default carFlowAdapter;
