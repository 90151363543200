import React from 'react';
import { ImageFile } from '~/data/types/images';

const summaryStepImageIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="690"
      height="185"
      viewBox="0 0 690 185"
    >
      <g clipPath="url(#clip0_7387_183877)">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H682C686.418 0 690 3.58172 690 8V185H0V8Z"
          fill="#00D6D1"
        />
        <g clipPath="url(#clip1_7387_183877)">
          <path
            d="M443.012 69.8223C435.416 74.7809 427.661 77.9572 419.852 82.0955"
            stroke="#FFE500"
            strokeWidth="6.02339"
            strokeLinecap="round"
          />
          <path
            d="M422.179 56.1582L406.504 70.2508"
            stroke="#FFE500"
            strokeWidth="6.02339"
            strokeLinecap="round"
          />
          <path
            d="M400.003 41.0008C397.525 47.7768 392.309 58.9457 394.394 66.2899"
            stroke="#FFE500"
            strokeWidth="6.02339"
            strokeLinecap="round"
          />
          <path
            d="M137.411 121.494C146.468 120.998 154.772 122.124 163.604 122.445"
            stroke="#FFE500"
            strokeWidth="6.02339"
            strokeLinecap="round"
          />
          <path
            d="M148.621 143.744L169.242 139.377"
            stroke="#FFE500"
            strokeWidth="6.02339"
            strokeLinecap="round"
          />
          <path
            d="M160.247 167.958C165.781 163.329 175.883 156.264 177.749 148.861"
            stroke="#FFE500"
            strokeWidth="6.02339"
            strokeLinecap="round"
          />
          <path
            d="M202.595 146.295C231.342 145.31 238.45 131.146 238.45 127.205C238.45 123.263 237.236 114.789 229.963 114.789C224.01 114.789 221.653 119.41 220.329 122.817C219.812 124.148 219.086 125.436 218.012 126.377C207.835 135.293 213.062 107.098 227.887 108.087C251.115 108.072 252.636 143.13 229.271 144.156C217.604 144.156 214.042 137.447 211.622 135.329C210.891 134.69 209.864 134.555 208.91 134.374C201.915 133.046 196.826 135.769 191.699 130.801C186.728 125.984 189.414 114.962 190.13 103.14C191.675 77.6223 282.535 102.911 264.906 86.3417C264.348 85.8177 263.654 85.2845 262.915 85.0883C255.138 83.0259 249.334 105.093 256.346 113.7C256.719 114.159 257.169 114.545 257.666 114.868C268.228 121.741 291.67 118.532 302.401 117.98C302.98 117.95 303.549 117.883 304.125 117.812C309.592 117.138 329.202 116.336 330.795 105.11C333.715 84.5306 335.668 81.1898 344.232 80.8871C346.209 80.8172 348.63 81.1184 349.458 82.9149C353.979 92.7231 258.316 92.4039 270.79 79.5678C291.277 61.7478 327.808 55.6292 355.177 66.1744C367.777 70.2296 374.591 81.5885 387.749 83.6175C392.584 84.3486 404.73 84.645 403.416 96.6105C403.416 107.25 403.819 112.7 401.769 120.115C399.934 131.188 385.064 141.933 377.912 135.088C375.941 132.844 377.912 132.406 377.912 126.492C377.912 107.778 354.751 105.11 356.957 130.159C359.901 142.775 263.985 132.3 263.985 141.657C263.985 152.715 342.243 148.462 369.596 140.13C397.815 135.088 388.709 107.623 374.568 103.525C356.957 98.4211 347.254 107.35 347.254 123.94C349.049 166.894 407.161 154.173 443.469 142.778C469.215 134.697 574.5 90.5 587.5 146.5"
            stroke="#262626"
            strokeWidth="3.54201"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <rect
          x="586.074"
          y="140.469"
          width="111.383"
          height="99.6988"
          transform="rotate(-15 586.074 140.469)"
          stroke="#262626"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M649.439 125.004L632.281 129.602L640.318 159.597L657.567 223.969L674.725 219.372L659.25 161.619L649.439 125.004Z"
          stroke="#262626"
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M672.944 106.987L677.265 123.758C679.285 131.594 671.889 138.588 664.178 136.135L650.908 131.913C645.683 130.25 639.967 131.723 636.197 135.705L626.648 145.788C621.083 151.664 611.227 149.116 609.207 141.279L604.891 124.532C602.767 116.289 610.997 109.177 618.845 112.473L630.376 117.318C636.292 119.803 643.139 118.04 647.12 113.008L654.904 103.17C660.184 96.4966 670.82 98.7476 672.944 106.987Z"
          fill="#00D6D1"
          stroke="#262626"
          strokeWidth="4"
        />
        <rect
          x="627.287"
          y="119.156"
          width="23.9748"
          height="20.8665"
          rx="7.24959"
          transform="rotate(-14.4511 627.287 119.156)"
          fill="#00D6D1"
          stroke="#262626"
          strokeWidth="4"
        />
      </g>
      <defs>
        <clipPath id="clip0_7387_183877">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H682C686.418 0 690 3.58172 690 8V185H0V8Z"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_7387_183877">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H682C686.418 0 690 3.58172 690 8V185H0V8Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </Component>
  );
};

export default summaryStepImageIcon;
