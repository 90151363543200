import {
  BranchesPageDataType,
  IRentalLocationAttributes,
  RentalLocationDataType,
} from '~/data/rentalLocation.data';
import { MetaType } from '~/data/types/paginationTypes';
import { HttpClientService } from '~/services/HttpsClientService';

class OurBranchesAdapter {
  static readonly endpointOurBranches = `/api/our-branches?pagination[pageSize]=200&populate=*`;

  static readonly endpointOurBranchesV2 = `/api/our-branch-api`;

  static readonly endpointBranchTypes = `/api/branches-types?populate=*`;

  static readonly endpointSecondHandBranches = `/api/our-branches?filters[name][$notContains]=השכרה&populate=*`;

  static readonly endpointOurBranchesForGenericPages = `/api/our-branches?populate=*&pagination[limit]=-1`;

  static readonly endpointBranchesPageData = `/api/branches-page?populate=deep,4`;

  // eslint-disable-next-line class-methods-use-this
  async getOurBranches(): Promise<RentalLocationDataType[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: RentalLocationDataType[];
    }>(`${OurBranchesAdapter.endpointOurBranches}`);

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getOurBranchesV2(): Promise<IRentalLocationAttributes[]> {
    const res = await HttpClientService.get<IRentalLocationAttributes[]>(
      `${OurBranchesAdapter.endpointOurBranchesV2}`,
    );

    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getBranchTypes(): Promise<{ name: string }[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: { name: string }[];
    }>(`${OurBranchesAdapter.endpointBranchTypes}`);

    return res.data.data;
  }

  async getFilteredBranches(): Promise<RentalLocationDataType[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: RentalLocationDataType[];
    }>(`${OurBranchesAdapter.endpointSecondHandBranches}`);

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getOurBranchesForGenericPages(): Promise<RentalLocationDataType[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: RentalLocationDataType[];
    }>(`${OurBranchesAdapter.endpointOurBranchesForGenericPages}`);

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getBranchesPageData(): Promise<BranchesPageDataType> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: BranchesPageDataType;
    }>(`${OurBranchesAdapter.endpointBranchesPageData}`);

    return res.data.data;
  }
}

const ourBranchesAdapter = new OurBranchesAdapter();

export default ourBranchesAdapter;
