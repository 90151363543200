import React from 'react';
import { ImageFile } from '~/data/types/images';

const downArrow: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
    >
      <path
        d="M16.5456 7.08398L10.7122 12.9173L4.87891 7.08398"
        stroke="black"
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default downArrow;
