import theme from '~/theme';

const useStyles = () => ({
  icon: {
    marginTop: 5,
    marginRight: 2,
    right: '80% !important',
    [theme.breakpoints.up('md')]: {
      right: '90% !important',
    },
  },
});

export default useStyles;

export const inputStyle = {
  '& .MuiSelect-select': {
    paddingRight: '12px !important',
  },

  '& .MuiSelect-icon': {
    marginTop: '5px',
    marginRight: '2px',
    right: '80% !important',
    [theme.breakpoints.up('md')]: {
      right: '90% !important',
    },
  },
};
