import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const useStyles = () => ({
  root: {
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    marginLeft: '5px',
    border: `0.5px solid ${COLORS.gray.main}`,
    [theme.breakpoints.down('md')]: {
      marginRight: '1px',
      border: `0.5px solid ${COLORS.gray.main}`,
    },
  },
});
