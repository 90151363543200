import React from 'react';
import { ImageFile } from '~/data/types/images';

const extendedRoadSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
    >
      <path
        d="M56.1183 23.0883C52.0115 21.5267 42.13 20.0076 47.9381 12.5593H43.6817C38.3136 19.2868 47.4365 24.4521 51.4846 25.7133C64.3329 29.7165 70.0823 41.4493 62.7488 72.5H68.1463C70.0041 61.0684 76.0654 30.2956 56.1183 23.0883Z"
        stroke="#262626"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M37.2283 39.0468C36.1136 47.2158 22.6786 63.591 13.5264 72.5H20.5079C51.2499 37.7248 41.0417 34.0625 31.5375 27.6953C25.0253 23.3327 26.316 15.242 30.4814 12.5593H24.1146C13.5544 30.338 37.2283 31.6594 37.2283 39.0468Z"
        stroke="#262626"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M36.8177 12.5C34.9501 13.7615 33.7085 19.1067 35.351 21.8695C39.3052 28.5204 54.8288 30.338 53.3034 43.8517C52.0831 54.6627 45.813 66.0337 42.7427 72.5"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <circle
        cx="55.375"
        cy="66.25"
        r="16"
        fill="#00D6D1"
        stroke="#262626"
        strokeWidth="1.5"
      />
      <path
        d="M57.1097 74.5205C56.5146 74.6571 56.382 74.5546 57.1097 74.5205C58.5529 74.1891 62.7166 72.451 63.9275 64.9196C64.1094 63.7935 64.2582 61.9288 64.3446 60.6832C64.3923 59.998 63.8908 59.3935 63.2074 59.3219C59.309 58.914 57.2771 56.4137 56.4669 55.53C56.2208 55.26 55.8019 55.2379 55.5355 55.4896C53.2299 57.6703 51.3707 58.9636 48.1521 59.2172C45.8887 59.3954 46.9377 66.7402 48.0308 68.864C48.1057 69.0097 48.1811 69.1523 48.2568 69.2919C49.5643 68.1701 50.8849 67.0697 51.4846 66.7384C51.6573 66.6429 51.8722 66.6778 52.0082 66.8211L55.3281 69.1992L56.1271 69.7715C56.4412 69.9956 56.6341 70.3667 56.5937 70.7507C56.4651 72.0073 53.9886 71.8365 54.4736 70.3796L55.3281 69.1992L52.0082 66.8211C51.8722 66.6778 51.6573 66.6429 51.4846 66.7384C50.8849 67.0697 49.5643 68.1701 48.2568 69.2919C50.158 72.7941 52.3087 74.363 53.7645 74.5132C54.0786 74.5462 46.8109 74.5132 46.5335 74.5132C42.5855 74.5132 43.6529 73.0692 45.0877 71.9889C45.622 71.5859 46.9326 70.4279 48.2568 69.2919C48.1811 69.1523 48.1057 69.0097 48.0308 68.864C46.9377 66.7402 45.8887 59.3954 48.1521 59.2172C51.3707 58.9636 53.2299 57.6703 55.5355 55.4896C55.8019 55.2379 56.2208 55.26 56.4669 55.53C57.2771 56.4137 59.309 58.914 63.2074 59.3219C63.8908 59.3935 64.3923 59.998 64.3446 60.6832C64.2582 61.9288 64.1094 63.7935 63.9275 64.9196C62.7166 72.451 58.5529 74.1891 57.1097 74.5205Z"
        fill="#00D6D1"
      />
      <path
        d="M61.271 60.99L54.4736 70.3796C53.9886 71.8365 56.4651 72.0073 56.5937 70.7507C56.6341 70.3667 56.4412 69.9956 56.1271 69.7715L52.0082 66.8211C51.8723 66.6778 51.6573 66.6429 51.4846 66.7384C50.2776 67.4053 46.1496 71.1879 45.0877 71.9889C43.6529 73.0692 42.5855 74.5132 46.5335 74.5132C46.8109 74.5132 54.0786 74.5462 53.7645 74.5132C52.2507 74.357 49.9855 72.6668 48.0308 68.864C46.9377 66.7402 45.8887 59.3954 48.1521 59.2172C51.3707 58.9636 53.2299 57.6703 55.5355 55.4896C55.8019 55.2379 56.2208 55.26 56.4669 55.53C57.2771 56.4137 59.309 58.914 63.2074 59.3219C63.8908 59.3935 64.3923 59.998 64.3446 60.6832C64.2582 61.9288 64.1094 63.7935 63.9275 64.9196C62.1529 75.9572 54.0364 74.5517 57.4351 74.5113C60.648 74.5132 69.3332 75.2459 70.5739 71.5237"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="27.9609"
        cy="66.25"
        r="16"
        fill="#00D6D1"
        stroke="#262626"
        strokeWidth="1.5"
      />
      <path
        d="M23.0188 69.487C23.065 68.9982 22.988 68.6278 22.8335 68.2706C25.972 68.1337 28.5442 68.1996 29.3777 68.8869C30.4192 69.7458 35.5991 75.4881 35.0793 74.988C34.3515 74.2879 29.5689 72.3876 28.3212 72.3876C27.065 72.3876 21.9791 76.0882 21.2513 75.6882C20.9805 75.5393 22.9148 70.5872 23.0188 69.487Z"
        fill="#00D6D1"
      />
      <path
        d="M40.9877 75.6882C36.4701 70.4787 34.0396 68.6869 32.3761 68.8869C30.7126 69.087 37.3667 65.3863 38.5103 64.8862C39.654 64.3861 39.7579 63.486 37.7825 63.586C35.8071 63.686 31.3364 63.2859 30.6086 61.7857C29.8808 60.2854 28.6751 55.0845 28.0093 55.0845C27.3436 55.0845 27.1776 60.0854 25.826 61.1856C24.4744 62.2858 24.1625 62.5858 21.9791 62.7858C19.7958 62.9859 16.7806 62.5858 16.6767 63.0859C16.5727 63.586 21.3553 65.6864 21.9791 66.7866C22.6029 67.8868 23.1227 68.3869 23.0188 69.487C22.9148 70.5872 20.9805 75.5393 21.2513 75.6882C21.9791 76.0882 27.065 72.3876 28.3212 72.3876C29.5689 72.3876 34.3515 74.2879 35.0793 74.988C35.5991 75.4881 30.4192 69.7458 29.3777 68.8869C27.6708 67.4794 18.6712 68.678 12.4507 68.8869"
        stroke="#262626"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default extendedRoadSelectedIcon;
