import React from 'react';
import { ImageFile } from '~/data/types/images';


const AccessoryIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="83"
      viewBox="0 0 80 83"
    >
      <rect width="80" height="83" rx="6" fill="#E5F6F8" />
      <g clipPath="url(#clip0_10580_219793)">
        <path
          d="M41.195 34.2479C40.47 37.3256 38.891 44.0286 38.8265 44.3023C36.0453 45.0967 33.264 45.8912 30.4828 46.6855C29.5916 46.94 29.5906 48.2386 30.4828 48.4935C33.2662 49.2885 36.0495 50.0835 38.8328 50.8785C39.6202 54.0616 40.4076 57.2447 41.1949 60.4278C41.4158 61.3205 42.7819 61.3214 43.0029 60.4278C43.8318 57.0769 44.6607 53.7261 45.4895 50.3752C45.7793 49.2039 43.9715 48.7043 43.6815 49.8768C43.1541 52.0095 42.6264 54.1421 42.0989 56.2748C41.5714 54.1421 41.0438 52.0095 40.5163 49.8767C40.4378 49.5596 40.1703 49.3101 39.8616 49.222C37.9565 48.6779 36.0514 48.1336 34.1463 47.5895L39.8616 45.957C40.1676 45.8697 40.4417 45.6192 40.5163 45.3023C41.0438 43.0628 41.5714 40.8233 42.0989 38.5839C42.5226 40.3823 43.5777 44.8613 43.6815 45.3023C43.7562 45.6192 44.0303 45.8697 44.3363 45.957C46.2498 46.5037 48.1634 47.0502 50.0769 47.5968C49.5902 47.738 49.1036 47.8793 48.617 48.0205C47.4605 48.3561 47.9524 50.166 49.1155 49.8285C50.6487 49.3835 52.1818 48.9385 53.7151 48.4936C54.6034 48.2358 54.6099 46.9413 53.7151 46.6857C50.9339 45.8913 48.1526 45.0968 45.3714 44.3024C44.6858 41.392 43.1068 34.6889 43.0029 34.248C42.7926 33.3547 41.4056 33.3539 41.195 34.2479Z"
          fill="black"
        />
        <path
          d="M45.64 67.5056C44.5589 68.1216 43.4601 68.7035 42.3374 69.24C41.2491 69.7601 42.1992 71.3772 43.2837 70.859C44.4065 70.3223 45.5052 69.7405 46.5864 69.1246C47.6346 68.5273 46.691 66.9068 45.64 67.5056Z"
          fill="black"
        />
        <path
          d="M73.3205 32.0183C70.1183 23.1791 58.121 23.4545 50.564 24.5376C38.6587 26.2439 27.3177 32.2419 18.6714 40.5293C3.70807 54.3259 8.71639 67.5033 29.0268 58.5094C30.3922 57.8989 31.7189 57.2059 32.9998 56.4337C34.0319 55.8113 33.0904 54.1894 32.0534 54.8147C29.5041 56.3518 26.7708 57.579 23.9172 58.4305C19.3209 59.95 10.4329 60.8686 12.0011 53.5108C15.9822 40.9257 34.6133 29.5562 47.0928 27.0549C54.9541 25.3245 71.1703 23.8391 71.9595 35.6345C72.5838 46.3349 57.9985 59.7902 49.654 65.2958C48.6411 65.9448 49.5804 67.5684 50.6004 66.9148C59.984 60.5292 77.6815 44.7134 73.3205 32.0183Z"
          fill="black"
        />
        <path
          d="M44.9865 22.7298C51.8981 20.6136 59.5247 20.1831 66.4754 22.3527C67.6285 22.7176 68.122 20.9081 66.9739 20.5449C60.2586 18.4201 52.8259 18.6836 46.0572 20.4627C45.531 20.6011 45.0031 20.7449 44.4881 20.9218C43.3536 21.3117 43.841 23.1235 44.9865 22.7298Z"
          fill="black"
        />
        <path
          d="M61.8736 48.0654C61.087 48.9736 62.4076 50.3053 63.1995 49.3913C71.0174 40.8836 74.5944 27.7001 58.7358 27.4219C57.5292 27.415 57.5278 29.29 58.7359 29.2969C72.7926 29.4191 68.2253 40.94 61.8736 48.0654Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_10580_219793">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(10 13)"
          />
        </clipPath>
      </defs>
    </Component>
  );
};

export default AccessoryIconIcon;
