import { createSlice } from '@reduxjs/toolkit';
import { IFoundingPage } from '~/data/types/foundingPage/foundingPage';
import { getFoundingPage } from '../thunks/foundingPageThunk';
import { HYDRATE } from 'next-redux-wrapper';

interface foundingPageSliceState {
  foundingPage: IFoundingPage | null;
}

const initialState: foundingPageSliceState = {
  foundingPage: null,
};

const foundingPageSlice = createSlice({
  name: 'foundingPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFoundingPage.fulfilled, (state, action) => {
      if (action.payload) {
        state.foundingPage = action.payload;
      }
    });
     builder.addCase(HYDRATE, (state, action: any) => {
       const { foundingPage } = action.payload;

       return {
         ...state,
         ...foundingPage,
       };
     });
  },
});

export const {} = foundingPageSlice.actions;

export default foundingPageSlice.reducer;
