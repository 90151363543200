import React from 'react';
import { ImageFile } from '~/data/types/images';

const LeftIconBlackIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="8"
      height="14"
      viewBox="0 0 8 14"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91699 12.4303L1.08366 6.59701L6.91699 0.763672"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default LeftIconBlackIcon;
