import { carDealRes } from '~/data/types/car/carDeals';
import { MetaType } from '~/data/types/paginationTypes';
import { HttpClientService } from '~/services/HttpsClientService';

class SearchFlowAdapter {
  static readonly endpoint = `/api/search-flow-contents`;

  // eslint-disable-next-line class-methods-use-this
  async getCarDealsBullets() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: carDealRes[];
    }>(`${SearchFlowAdapter.endpoint}?populate=*`);

    return res;
  }
}

const searchFlowAdapter = new SearchFlowAdapter();
export default searchFlowAdapter;
