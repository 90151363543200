export const scrollUpConstants = {
  takeMeToTheTop: 'חזרה למעלה',
};

export const SocialMediaSectionText = {
  followUs: 'עקבו אחרינו',
  termsOfUse: 'תנאי השימוש',
};

export const BUTTON_TYPES = {
  MAIN: 'main',
  SECONDARY: 'secondary',
  SECONDARY_V2: 'secondaryV2',
  THIRD: 'third',
  MAIN_YELLOW: 'mainYellow',
  SECONDARY_YELLOW: 'secondaryYellow',
  TRANSPARENT: 'transparent',
  MAIN_DISABLED: 'mainDisabled',
  MAIN_YELLOW_DISABLED: 'mainYellowDisabled',
  SECONDARY_DISABLED: 'secondaryDisabled',
  SECONDARY_V2_DISABLED: 'secondaryDisabledV2',
  THIRD_DISABLED: 'thirdDisabled',
  BORDERED: 'bordered',
  BLACK: 'black',
  NO_RESULT: 'noResult',
};

export const BUTTON_SIZES = {
  FIT: 'fit',
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const KEYBOARD_TYPES = {
  ENTER: 'Enter',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
  ESCAPE: 'Escape',
  SPACE: ' ',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
};
