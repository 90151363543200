import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  PageContent,
  SectionComponent,
} from '~/data/types/GenericPage/pageType';
import { getGenericPageContent } from '~/redux-toolkit/thunks/genericPageThunk';

interface GenericPageState {
  genericPages: PageContent | null;
  isLoading: boolean;
  isError: boolean;
}

const initialState: GenericPageState = {
  genericPages: null,
  isLoading: false,
  isError: false,
};

const genericPageSlice = createSlice({
  name: 'genericPageData',
  initialState,
  reducers: {
    setGenericPageContent(state, action) {
      state.genericPages = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGenericPageContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGenericPageContent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(getGenericPageContent.fulfilled, (state, action) => {
      try {
        state.isLoading = false;
        const { attributes } = action.payload;

        const sectionComponent: SectionComponent[] =
          attributes.sectionComponent?.map((section) => {
            const csvTableName = section?.csvTable?.data?.attributes.name || '';
            const csvTableUrl = section?.csvTable?.data?.attributes.url || '';
            return {
              ...section,
              csvTableName: csvTableName,
              csvTableUrl: csvTableUrl,
            };
          });
        state.genericPages = {
          ...attributes,
          sectionComponent: sectionComponent,
        };
      } catch (e) {
        console.log('Error', e);
        state.isError = true;
      }
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { genericPage } = action.payload;

      return {
        ...state,
        ...genericPage,
      };
    });
  },
});
export const { setGenericPageContent } = genericPageSlice.actions;
export default genericPageSlice.reducer;
