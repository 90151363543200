import { Box, Typography } from '@mui/material';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import Button from '~/components/Button';
import RESOURCES from '~/resources';
import {
  EActionEvent,
  EBusinessLineEvent,
  ECategoryEvent,
  useEvents,
} from '~/hooks/useEvents';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { setApprovalActiveStep } from '~/redux-toolkit/slices/approvalFlowSlice';
import { useLeadActions } from '~/hooks/useLeadActions';
import WarningBanner from '~/components/WarningBanner';
import FormValidation from '~/Validators/FormValidation';
import { ARIA_LABELS_RESOURCES } from '~/resources/resources';
import useStyles, {
  YellowCircleTitleStyled,
  buttonCustomStyle,
  offerDescriptionStyle,
  offerTitleStyle,
  root,
} from './useStyles';
import ContactUsStepForm from './ContactUsStepForm';
import YellowCircleMediumIcon from '~/assets/icons/jsx/yellowCircleMedium';

const ContactUsStep = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const events = useEvents();

  const { approvalActiveStep, source } = useSelector(
    (state: RootState) => state.approvalFlow,
  );
  const { firstName, lastName, phone, email, mailsApproved } = useSelector(
    (state: RootState) => state.approvalFlow.details,
  );
  const { details } = useSelector((state: RootState) => state.approvalFlow);

  const {
    isProcessingLead,
    createLeadRequest,
    createLeadError,
    createLeadFinished,
  } = useLeadActions();

  const isDisabled = useMemo(
    () =>
      !FormValidation.validateText(lastName) ||
      !FormValidation.validateText(firstName) ||
      (email && !FormValidation.validateEmail(email)) ||
      !FormValidation.validatePhone(phone),
    [email, firstName, lastName, phone],
  );

  useEffect(() => {
    if (createLeadFinished) {
      dispatch(setApprovalActiveStep(approvalActiveStep + 1));
    }
  }, [approvalActiveStep, createLeadFinished, dispatch]);

  const handleSubmit = useCallback(async () => {
    events.sendEvent(
      {
        action: EActionEvent.APPROVAL_FLOW_LEAD,
        category: ECategoryEvent.FUNDING_APPROVAL,
      },
      {
        didApproveEmails: mailsApproved,
        approvalLocation: source,
        businessLine: EBusinessLineEvent.FUNDING,
      },
    );
    createLeadRequest(details);
  }, [createLeadRequest, details, events, mailsApproved, source]);

  return (
    <Box
      sx={root}
      aria-label={ARIA_LABELS_RESOURCES.FUNDING_FLOW_USER_DETAILS}
      aria-live="polite"
    >
      {createLeadError && <WarningBanner text={createLeadError} />}
      <Box sx={classes.boxStyle}>
        <Typography variant="h3" sx={offerTitleStyle}>
          {RESOURCES.APPROVAL_CONTACT_US_TITLE}
        </Typography>
        <YellowCircleMediumIcon Component={YellowCircleTitleStyled} />
        <Typography sx={offerDescriptionStyle}>
          {RESOURCES.APPROVAL_CONTACT_US_DESCRIPTION}
        </Typography>
        <ContactUsStepForm />
      </Box>
      <Button
        loading={isProcessingLead}
        label={RESOURCES.APPROVAL_CONTACT_US_CONTACT_ME}
        type={
          isDisabled || isProcessingLead
            ? BUTTON_TYPES.MAIN_DISABLED
            : BUTTON_TYPES.MAIN
        }
        size={BUTTON_SIZES.LARGE}
        customStyle={buttonCustomStyle}
        isDisabled={isDisabled}
        onClickFunc={handleSubmit}
      />
    </Box>
  );
};

export default ContactUsStep;
