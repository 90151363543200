import { FC } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import DownIcon from '~/assets/icons/jsx/DownIcon';
import { selectInputStyle } from '../CheckBoxDropdown/useStyles';
import useStyles, { inputStyle } from './useStyles';

interface CheckboxProps {
  values: { title: string; options: string[] };
  // eslint-disable-next-line no-unused-vars
  onChange: (e: SelectChangeEvent<unknown>) => void;
}

const Checkbox: FC<CheckboxProps> = ({ values, onChange }) => {
  const classes = useStyles();
  return (
    <Box sx={{ zIndex: '3', flex: 1 }} aria-label={values.title}>
      <InputLabel variant="standard" sx={{ fontSize: '14px' }}>
        {values.title}
      </InputLabel>
      <FormControl fullWidth>
        <Select
          IconComponent={DownIcon}
          sx={{
            icon: classes.icon,
            ...inputStyle,
          }}
          style={{
            ...selectInputStyle,
            color: 'black',
          }}
          onChange={onChange}
        >
          {values.options.map((item) => (
            <MenuItem dir="rtl" aria-label={item} key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Checkbox;
