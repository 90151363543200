/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HomePageMarketingBannerDataType } from '~/data/types/homePageMarketingBannerType';
import { ISuggestionData } from '~/data/suggestionData';
import { QuickSearchType } from '~/data/QuickSearch/quickSearchType';
import { SearchPlanType } from '~/data/types/searchPlanType';
import homePageAdapter from '~/redux-toolkit/adapters/homePageAdapter';
import { GetOurServicesResponseType } from '~/data/types/ourServices/ourServices.types';
import {
  GetBannerCardsResponseType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';

export const getHomePageContent = createAsyncThunk(
  '/api/home-page-contents?populate=*',
  async (): Promise<any> => {
    try {
      const response = await homePageAdapter.getHomePageContent();
      const { data } = response.data;

      return data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getHomePageQuickSearch = createAsyncThunk(
  '/api/home-page-quick-searches?populate=*',
  async (): Promise<QuickSearchType[]> => {
    try {
      const response = await homePageAdapter.getHomePageQuickSearch();
      const { data } = response;

      return data.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getSearchPlansSearch = createAsyncThunk(
  '/api/home-page-search-plans?populate=*',
  async (): Promise<SearchPlanType> => {
    try {
      const response = await homePageAdapter.getSearchPlansSearch();
      const { data } = response;

      return data.data[0];
    } catch (error: any) {
      console.log(error);
      return error;
    }
  },
);

export const getHomePageMarketingBanners = createAsyncThunk(
  '/api/home-page-marketing-banner',
  async (): Promise<HomePageMarketingBannerDataType[]> => {
    try {
      const response = await homePageAdapter.getHomePageMarketingBanners();
      const { data } = response;
      return data.data;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  },
);
export const getSuggestionsContent = createAsyncThunk(
  '/get-suggestions-search-carousel',
  async (): Promise<ISuggestionData[]> => {
    try {
      const suggestionContent = await homePageAdapter.getSuggestionsContent();
      return suggestionContent.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getHomePageBannerGallery = createAsyncThunk(
  '/gallery-card-banners',
  async (): Promise<GetBannerCardsResponseType[]> => {
    try {
      const response = await homePageAdapter.getHomePageBannerGallery();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getHomePageOurServices = createAsyncThunk(
  '/our-services',
  async (): Promise<GetOurServicesResponseType[]> => {
    try {
      const response = await homePageAdapter.getHomePageOurServices();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getHomePageStaticBanners = createAsyncThunk(
  '/static-card-banners',
  async (): Promise<GetBannerCardsResponseType[]> => {
    try {
      const response = await homePageAdapter.getHomePageStaticBanners();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getSearchByNeeds = createAsyncThunk(
  '/search-by-need',
  async (): Promise<SearchByNeedResponseType[]> => {
    try {
      const response = await homePageAdapter.getSearchByNeeds();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);
