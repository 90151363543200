import { Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  setFlowIsCompleted,
  setIsApprovalFlowOpen,
} from '~/redux-toolkit/slices/approvalFlowSlice';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { useRouter } from 'next/router';
import { RootState } from '~/redux-toolkit/store/store';
import { ROUTES } from '~/data/routes';
import RESOURCES from '~/resources';
import Button from '~/components/Button';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import { FUNDING_ROUTE } from '~/data/useServices';
import {
  EActionEvent,
  EBusinessLineEvent,
  ECategoryEvent,
  useEvents,
} from '~/hooks/useEvents';
import { ARIA_LABELS_RESOURCES } from '~/resources/resources';
import { titleStyle } from '../ApprovalQueryStep/useStyles';
import useStyles, {
  buttonStyle,
  codeStyle,
  descriptionCodeStyle,
  expiredTextStyle,
  expiredTitle,
  root,
} from './useStyles';
import { checkFindingCodeFromLocalStorage } from '~/utils/ApprovalFlow';

const GetApprovalCodeStep = () => {
  const { approvalCode, flowIsCompleted, isApprovalFlowOpen, source } =
    useSelector((state: RootState) => state.approvalFlow);

  const dispatch = useAppDispatch();
  const router = useRouter();
  // TODO: need to understand and fix the code
  // const currentParams = useCurrentUrlParams({ cleanAppParams: true });

  const handleNext = useCallback(() => {
    dispatch(setIsApprovalFlowOpen(!isApprovalFlowOpen));
    dispatch(setFlowIsCompleted(!flowIsCompleted));

    if (
      typeof window !== 'undefined' &&
      decodeURIComponent(window.location.href).includes(FUNDING_ROUTE)
    ) {
      const currentParams = router.query;
      router.push({
        pathname: `${ROUTES.RESULT_PAGE}`,
        query: currentParams,
      });
    }
  }, [dispatch, isApprovalFlowOpen, flowIsCompleted, router]);

  const events = useEvents();

  // useEffect for handling updates
  const userViewed = checkFindingCodeFromLocalStorage(approvalCode, 'get');

  useEffect(() => {
    if (!userViewed) {
      checkFindingCodeFromLocalStorage(approvalCode, 'set', 'yes');
      events.sendEvent(
        {
          action: EActionEvent.APPROVAL_FLOW_GENERATE_CODE,
          category: ECategoryEvent.FUNDING_APPROVAL,
        },
        {
          approvalLocation: source,
          businessLine: EBusinessLineEvent.FUNDING,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, source, approvalCode]);

  useEffect(() => {
    if (userViewed) {
      events.sendEvent(
        {
          action: EActionEvent.APPROVAL_FLOW_VIEW_CODE,
          category: ECategoryEvent.FUNDING_APPROVAL,
        },
        {
          approvalLocation: source,
          businessLine: EBusinessLineEvent.FUNDING,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  return (
    <Box
      sx={root}
      aria-label={ARIA_LABELS_RESOURCES.FUNDING_FLOW_GET_CODE}
      aria-live="polite"
    >
      <Typography variant="h2" sx={titleStyle}>
        {RESOURCES.GET_YOUR_CODE_TITLE}
      </Typography>
      <Typography sx={descriptionCodeStyle}>
        {RESOURCES.GET_YOUR_CODE_DESCRIPTION}
      </Typography>
      <Box sx={classes.codeContainer}>
        <Box sx={classes.codeBlock}>
          <Typography
            sx={codeStyle}
            aria-label={`${ARIA_LABELS_RESOURCES.FUNDING_FLOW_THE_CODE} ${approvalCode}`}
          >
            {approvalCode}
          </Typography>
        </Box>
        <Typography variant="h3" sx={expiredTitle}>
          {RESOURCES.GET_YOUR_CARD_GOOD_TO_KNOW}
        </Typography>
        <Typography sx={expiredTextStyle}>
          {RESOURCES.GET_YOUR_CARD_GOOD_TO_KNOW_DESCRIPTION}
        </Typography>
      </Box>
      <Button
        label={RESOURCES.GET_YOU_CODE_BUTTON_LABEL}
        type={BUTTON_TYPES.MAIN}
        size={BUTTON_SIZES.LARGE}
        customStyle={buttonStyle}
        onClickFunc={handleNext}
      />
    </Box>
  );
};

export default GetApprovalCodeStep;
