import {
  CarBodyType,
  CarBodyTypeData,
  carDescriptionHighlights,
} from '../../data/types/car/cars';
import {
  filterCategoriesType,
  ICategoriesType,
} from '../../data/types/filters/filterCategoriesType';

export const getMappedFiltersOptions = (
  filtersOptions: carDescriptionHighlights[] | CarBodyTypeData[],
) =>
  filtersOptions.map((option) => {
    return {
      title: option.attributes.title,
      icon: option.attributes.icon?.data?.attributes.url,
    };
  });

export const getMappedCategoriesOptions = (categories: ICategoriesType[]) =>
  categories.map((category: ICategoriesType) => {
    return {
      name: category.name,
      id: category.id,
      icon: category.icon?.url,
      selectedIcon: category.selectedIcon?.url,
      searchFlowIcon: category.searchFlowIcon?.url,
      rank: category.rank,
    } as filterCategoriesType;
  });
export const removeDuplicatesOfBodyType = (objArr: CarBodyType[]) => {
  const uniqueNames = new Set();

  return objArr.filter((item: CarBodyType) => {
    if (!item.displayTitleName) {
      return true;
    }
    if (!uniqueNames.has(item.displayTitleName)) {
      uniqueNames.add(item.displayTitleName);
      return true;
    }
    return false;
  });
};
