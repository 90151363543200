import { CSSProperties, FC, useMemo } from 'react';
import Link from 'next/link';
import RESOURCES from '~/resources';
import { ROUTES } from '~/data/routes';
import FreesbeLogo from '~/assets/images/freesbeLogoBTH.svg';
import BlackFreesbeLogo from '~/assets/images/blackFreesbeLogo.svg';
import { useStyles } from './useStyles';
import { useRouter } from 'next/router';
import Image from 'next/image';

interface AppLogoProps {
  customStyle?: CSSProperties;
  isBlackLogo?: boolean;
}
const AppLogo: FC<AppLogoProps> = ({ customStyle, isBlackLogo = false }) => {
  const classes = useStyles;
  const router = useRouter();

  const href = useMemo(
    () =>
      ({
        [ROUTES.CAR_FLEET]: ROUTES.CAR_FLEET,
        [ROUTES.CAR_FLEET_RESULT]: ROUTES.CAR_FLEET,
      })[router.pathname] ?? ROUTES.HOMEPAGE,
    [router.pathname],
  );

  return (
    <Link
      href={href}
      passHref
      aria-label={RESOURCES.FREESBE_LOGO_ALT_TEXT}
      css={classes.root}
      target="_self"
      id="freesbe-logo"
    >
      <picture>
        <Image
          src={isBlackLogo ? BlackFreesbeLogo.src : FreesbeLogo.src}
          css={[useStyles.image]}
          alt={RESOURCES.FREESBE_LOGO_ALT_TEXT}
          style={customStyle}
          width={143}
          height={45}
          priority
          loading="eager"
        />
      </picture>
    </Link>
  );
};

export default AppLogo;
