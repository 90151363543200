import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '~/data/ui/colors';

export const CustomTextFieldContainer = styled(Box)({
  direction: 'rtl',
  maxWidth: '100%',
  '& .MuiInputBase-input': {
    border: 'unset',
    color: COLORS.black.main,

    flex: '1',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: COLORS.primary.main,
      borderWidth: '1px',
    },
  } as const,

  '& svg': {
    left: '16px !important',
  },
});
export const textFieldSx = {
  right: '22px',
  top: 0,
  left: 'unset',
  textAlign: 'right',
  direction: 'rtl',
  color: COLORS.black.main,
  fontSize: '13px',
  '& .MuiFormLabel-root': {
    right: '22px',
    top: 0,
  },
  '& .MuiInputLabel-root': {
    right: '22px',
    top: 0,
  },
  '& .MuiTextField-root': {
    right: '22px',
    top: 0,
  },
  '&.Mui-focused': {
    color: COLORS.black.main,
  },
  '&.Mui-error': {
    color: (theme: { palette: { error: { main: any } } }) =>
      theme.palette.error.main,
  },
};

export const TextFieldStyled = styled(TextField)({
  marginBottom: '2px',

  '& .MuiInputBase-input': {
    height: '1.8em',
    backgroundColor: COLORS.white.main,
    borderRadius: '8px',
    paddingRight: '20px',
    paddingBottom: '0px',
    paddingTop: '15px',
    textAlign: 'right',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '& .MuiFilledInput-root': {
    border: `1px solid ${COLORS.gray.main}`,
    borderRadius: '10px',
    transition:
      'border-color 0.3s ease-in-out, background-color 0.3s ease-in-out',
    backgroundColor: 'transparent',
    paddingRight: 0,
    color: COLORS.black.main,

    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },

  '& .MuiFilledInput-root.Mui-focused': {
    border: `1.5px solid`,
    borderColor: COLORS.primary.main,
    backgroundColor: 'transparent',
    borderRadius: '8px',
  },

  '& .MuiFilledInput-root.Mui-error': {
    border: `1.5px solid`,
    borderColor: COLORS.red.main,
    borderRadius: '8px',
  },

  '& .MuiInputBase-input::placeholder': {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: '1.5em',
    position: 'absolute',
    bottom: '12px',
  },

  '& .MuiInputLabel-root': {
    right: '22px',
    top: '25%',
    transform: 'translateY(-50%)',
    left: 'unset',
    textAlign: 'right',
    direction: 'rtl',
    color: COLORS.black.main,
    transition: 'all 0.3s ease-in-out',
  },

  '& .MuiInputLabel-root.Mui-focused': {
    color: COLORS.black.main,
    top: '10%',
    transform: 'translateY(0)',
  },

  '& .MuiInputLabel-root.Mui-error': {
    color: COLORS.red.main,
  },
});

export const title = {
  fontSize: '12px',
  color: '#000',
};

export const portalTitle = {
  fontSize: '14px !important',
  color: COLORS.gray.dark,
};
