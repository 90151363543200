/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
import { CSSProperties } from 'react';
import Link from 'next/link';
import { Box, Typography } from '@mui/material';
import classes, { titleMenuItem, ItemContainer } from './useStyles';

interface FooterMenuItemProps {
  title: string;
  route: string;
  sxName?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  routeType?: string;
  onClick?: () => void;
}

const FooterMenuItem = ({
  title,
  route,
  sxName,
  routeType,
  onClick,
}: FooterMenuItemProps) => {
  return (
    <ItemContainer onClick={onClick}>
      <Box
        component={Link}
        href={route}
        sx={{ ...classes.link, ...sxName }}
        target={routeType === 'link' ? '_blank' : '_self'}
      >
        <Box sx={classes.linkIcon}>
          <Typography sx={titleMenuItem}>{title}</Typography>
        </Box>
      </Box>
    </ItemContainer>
  );
};

export default FooterMenuItem;
