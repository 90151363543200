import { Avatar, Box, SvgIcon, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { SocialMediaSectionText } from '~/data/constants';
import RESOURCES from '~/resources';
import classes, { avatarIcon, followUsHeader } from './useStyles';
import FaceBookIcon from '~/assets/icons/jsx/facebook';
import LinkedInIcon from '~/assets/icons/jsx/linkedinIcon';
import YoutubeIcon from '~/assets/icons/jsx/youtubeIcon';

const SocialMediaSection = () => {
  return (
    <Box sx={classes.root}>
      <Box sx={classes.socialMediaDiv} role="navigation">
        <Typography variant="h5" sx={followUsHeader}>
          {SocialMediaSectionText.followUs}
        </Typography>
        <Box sx={classes.socialMediaAvatarsDiv}>
          <a
            href="https://facebook.com/Freesbe"
            target="_blank"
            rel="noreferrer"
            aria-label={RESOURCES.FOLLOW_US_ON_FB}
          >
            <Avatar sx={[avatarIcon]}>
              <SvgIcon
                component={FaceBookIcon}
                style={{ transform: 'scale(0.75)' }}
              />
            </Avatar>
          </a>
          <a
            href="https://www.instagram.com/freesbe.israel/"
            target="_blank"
            rel="noreferrer"
            aria-label={RESOURCES.FOLLOW_US_ON_ING}
          >
            <Avatar sx={[avatarIcon]}>
              <InstagramIcon style={{ transform: 'scale(0.8)' }} />
            </Avatar>
          </a>
          <a
            href="https://www.linkedin.com/company/freesbeisrael/"
            target="_blank"
            rel="noreferrer"
            aria-label={RESOURCES.FOLLOW_US_IN}
          >
            <Avatar sx={[avatarIcon]}>
              <LinkedInIcon
                style={{
                  transform: 'scale(0.95)',
                  position: 'relative',
                  top: '-1px',
                }}
              />
            </Avatar>
          </a>
          <a
            href="https://www.youtube.com/channel/UC_zP6j7zxb0keGLyC1aVsKQ"
            target="_blank"
            rel="noreferrer"
            aria-label={RESOURCES.FOLLOW_US_YT}
          >
            <Avatar sx={[avatarIcon]}>
              <YoutubeIcon style={{ transform: 'scale(0.95)' }} />
            </Avatar>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default SocialMediaSection;
