import {
  buttonSizes,
  buttonTypes,
} from '~/components/Categories/CategoryCard/types';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import { sizes, useStyles } from './useStyles';

export const buttonTypeFormatter = (
  type: buttonTypes,
  classes: typeof useStyles,
) => {
  switch (type) {
    case BUTTON_TYPES.MAIN:
      return classes.main;
    case BUTTON_TYPES.SECONDARY:
      return classes.secondary;
    case BUTTON_TYPES.SECONDARY_V2:
      return classes.secondaryV2;
    case BUTTON_TYPES.THIRD:
      return classes.third;
    case BUTTON_TYPES.MAIN_YELLOW:
      return classes.mainYellow;
    case BUTTON_TYPES.SECONDARY_YELLOW:
      return classes.secondaryYellow;
    case BUTTON_TYPES.TRANSPARENT:
      return classes.transparent;
    case BUTTON_TYPES.MAIN_DISABLED:
      return classes.mainDisabled;
    case BUTTON_TYPES.MAIN_YELLOW_DISABLED:
      return classes.mainYellowDisabled;
    case BUTTON_TYPES.SECONDARY_DISABLED:
      return classes.secondaryDisabled;
    case BUTTON_TYPES.SECONDARY_V2_DISABLED:
      return classes.secondaryDisabledV2;
    case BUTTON_TYPES.THIRD_DISABLED:
      return classes.thirdDisabled;
    case BUTTON_TYPES.BORDERED:
      return classes.bordered;
    case BUTTON_TYPES.BLACK:
      return classes.black;
    case BUTTON_TYPES.NO_RESULT:
      return classes.noResult;
    default:
      return classes.main;
  }
};

export const buttonSizeFormatter = (size: buttonSizes) => {
  switch (size) {
    case BUTTON_SIZES.LARGE:
      return sizes.large;
    case BUTTON_SIZES.MEDIUM:
      return sizes.medium;
    case BUTTON_SIZES.SMALL:
      return sizes.small;
    case BUTTON_SIZES.FIT:
      return sizes.fit;
    default:
      return sizes.large;
  }
};
