/* eslint-disable no-unused-vars */

/* eslint-disable no-shadow */
import { EReferralReason } from '~/data/contactUsData';

export class CampaignNumber {
  static CAR_FLEET = process.env.NEXT_PUBLIC_CAR_FLEET || 'CAM-01629';

  static GENERAL = process.env.NEXT_PUBLIC_CAMPAIGN_GENERAL || 'CAM-01074';

  static RENAULT = process.env.NEXT_PUBLIC_CAMPAIGN_RENAULT || 'CAM-01067';

  static EVEASY = process.env.NEXT_PUBLIC_CAMPAIGN_EVEASY || 'CAM-01085';

  static NISSAN = process.env.NEXT_PUBLIC_CAMPAIGN_NISSAN || 'CAM-01068';

  static DUCIA = process.env.NEXT_PUBLIC_CAMPAIGN_DUCIA || 'CAM-01069';

  static INFINITY = process.env.NEXT_PUBLIC_CAMPAIGN_INFINITY || 'CAM-01071';

  static LEASING = process.env.NEXT_PUBLIC_CAMPAIGN_LEASING || 'CAM-01072';

  static BUSINESS_LEASING =
    process.env.NEXT_PUBLIC_BUSINESS_LEASING || 'CAM-01501';

  static RENTAL = process.env.NEXT_PUBLIC_CAMPAIGN_RENTAL || 'CAM-01073';

  static CHERRY = process.env.NEXT_PUBLIC_CAMPAIGN_CHERRY || 'CAM-01070';

  static XPENG = process.env.NEXT_PUBLIC_CAMPAIGN_XPENG || 'CAM-01447';

  static FUNDING = process.env.NEXT_PUBLIC_CAMPAIGN_FUNDING || 'CAM-01246';

  static INSURANCE = process.env.NEXT_PUBLIC_CAMPAIGN_INSURANCE || 'CAM-01082';

  static TRUCK = process.env.NEXT_PUBLIC_CAMPAIGN_TRUCK || 'CAM-01234';

  static USED_CAR = process.env.NEXT_PUBLIC_CAMPAIGN_USED_CAR || 'CAM-00233';

  static CONTACT_US_PAGE =
    process.env.NEXT_PUBLIC_CAMPAIGN_CONTACT_US_PAGE || 'CAM-01032';

  static USED_CAR_RESULT_PAGE =
    process.env.NEXT_PUBLIC_CAMPAIGN_USED_CAR || 'CAM-01032';

  static USED_CAR_SHIRYUN =
    process.env.NEXT_PUBLIC_CAMPAIGN_USED_CAR_SHIRYUN || 'CAM-00232';

  static SOLUTIONS =
    process.env.NEXT_PUBLIC_CAMPAIGN_CHARGING_SOLUTIONS || 'CAM-01266';

  static FAILED_CHERRY =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_CHERRY || 'CAM-01080';

  static FAILED_RENAULT =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_RENAULT || 'CAM-01076';

  static FAILED_EVEASY =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_EVEASY || 'CAM-01085';

  static FAILED_NISSAN =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_NISSAN || 'CAM-01077';

  static FAILED_DUCIA =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_DUCIA || 'CAM-01078';

  static FAILED_INFINITY =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_INFINITY || 'CAM-01079';

  static FAILED_LEASING =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_LEASING || 'CAM-01075';

  static FAILED_TRUCK =
    process.env.NEXT_PUBLIC_FAILED_CAMPAIGN_TRUCK || 'CAM-01235';

  static GENERAL_SEARCH = 'CAM-01599';

  static CONTACT_US = {
    // [EReferralReason.SERVICE]:
    //   process.env.NEXT_PUBLIC_CONTACT_US_SERVICE || 'CAM-01079',
    [EReferralReason.NEW_CAR]:
      process.env.NEXT_PUBLIC_CONTACT_US_NEW_CAR || 'CAM-01355',
    [EReferralReason.USED_CAR]:
      process.env.NEXT_PUBLIC_CONTACT_US_USED_CAR || 'CAM-01032',
    [EReferralReason.LEASE]:
      process.env.NEXT_PUBLIC_CONTACT_US_LEASE || 'CAM-01077',
    [EReferralReason.TRADE]:
      process.env.NEXT_PUBLIC_CONTACT_US_TRADE || 'CAM-01032',
    // [EReferralReason.INSURANCE]:
    //   process.env.NEXT_PUBLIC_CONTACT_US_INSURANCE || 'CAM-01094',
    [EReferralReason.FUNDING]:
      process.env.NEXT_PUBLIC_CONTACT_US_FUNDING || 'CAM-01246',
    [EReferralReason.CHARGING_SOLUTIONS]:
      process.env.NEXT_PUBLIC_CONTACT_US_CHARGING_SOLUTIONS || 'CAM-01266',
    [EReferralReason.OTHER]:
      process.env.NEXT_PUBLIC_CONTACT_US_OTHER || 'CAM-01079',
  };
}

export enum ERequestType {
  UPSERT_LEAD = 'upsertLead',
  GET_TIME_SLOTS = 'getTimeSlots',
  BOOK_SLOT = 'bookSlot',
  IsExistOpenLead = 'isExistOpenLead',
  GetChildTerritoryList = 'getChildTerritoryList',
  GetSlotsRestSingleTerritory = 'getSlotsRestSingleTerritory',
  queryLead = 'queryLead',
  GetChildTerritoryListTestDrive = 'getChildTerritoryListTestDrive'
}
export enum EGenericPageInfoType {
  PRIVACY_POLICY = 'privacyPolicy',
  TERM_OF_USE = 'termsOfUse',
  TERM_AND_CONDITIONS = 'termsAndConditions',
  ACCESSIBILITY = 'accessibility',
  REFUNDS_POLICY = 'refundsPolicy',
  EVEASY_PACKAGES = 'eveasy-packages',
  SERVICE_AGREEMENT = 'serviceAgreement',
}

export enum ECarCommercial {
  TRUCK = 'Trucks',
}
