import { HttpClientService } from '~/services/HttpsClientService';
import { IUsedCarForSalePageData } from '~/data/types/usedCarForSalePageContent/usedCarForSalePage.types';

class UsedCarForSalePageContentAdapter {
  static readonly endpointGetUsedCarForSalePage = `/api/used-car-for-sale-page/content`;

  // eslint-disable-next-line class-methods-use-this
  async getUsedCarForSalePageContent() {
    const res = await HttpClientService.get<IUsedCarForSalePageData | null>(
      `${UsedCarForSalePageContentAdapter.endpointGetUsedCarForSalePage}?populate=*`,
    );
    return res;
  }
}

const usedCarForSalePageContentAdapter = new UsedCarForSalePageContentAdapter();
export default usedCarForSalePageContentAdapter;
