import React from 'react';
import { ImageFile } from '~/data/types/images';

const linkedinIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M18.5533 17.565V11.705C18.5533 8.825 17.9333 6.625 14.5733 6.625C12.9533 6.625 11.8733 7.505 11.4333 8.345H11.3933V6.885H8.21332V17.565H11.5333V12.265C11.5333 10.865 11.7933 9.525 13.5133 9.525C15.2133 9.525 15.2333 11.105 15.2333 12.345V17.545H18.5533V17.565Z"
        fill="#262626"
      />
      <path
        d="M2.81293 6.88281H6.13293V17.5628H2.81293V6.88281Z"
        fill="#262626"
      />
      <path
        d="M4.47316 1.5625C3.41316 1.5625 2.55316 2.4225 2.55316 3.4825C2.55316 4.5425 3.41316 5.4225 4.47316 5.4225C5.53316 5.4225 6.39316 4.5425 6.39316 3.4825C6.39316 2.4225 5.53316 1.5625 4.47316 1.5625V1.5625Z"
        fill="#262626"
      />
    </Component>
  );
};

export default linkedinIconIcon;
