/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import tooltipAdapter from '~/redux-toolkit/adapters/tooltipAdapter';

export const getTooltipContent = createAsyncThunk(
  '/api/tips-and-help-drawers?populate=transactionTypes.transactionTypeTips.tipIcon',
  async (): Promise<any> => {
    try {
      const response = await tooltipAdapter.getTooltipContent();
      const { data } = response.data;
      return data[0];
    } catch (error: any) {
      return console.log(error);
    }
  },
);
