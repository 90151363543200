import { styled } from '@mui/system';
import { COLORS } from '~/data/ui/colors';

const useStyles = () => ({
  titleWrapper: {
    position: 'relative',
  },
  animationStyle: {
    position: 'absolute',
    top: '25px',
    left: '35px',
    zIndex: -1,
  },

  dropsDownsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'rtl',
    margin: '16px 0',
    gap: '20px',
    borderBottom: `1px solid ${COLORS.white.dark}`,
    padding: '16px 0',
  },

  radioWrapper: {
    margin: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${COLORS.white.dark}`,
    paddingBottom: '24px',
    gap: '16px',
  },
});

export default useStyles;

export const animationStyle = styled('svg')(() => ({
  position: 'absolute',
  top: '25px',
  left: '35px',
  zIndex: -1,
}));

export const headerContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

export const titleStyle = {
  fontSize: '24px',
  marginBottom: '16px',
  fontWeight: '600',
};

export const descriptionStyle = {
  marginBottom: '16px',
  lineHight: '25px',
  color: `${COLORS.gray.dark}`,
};

export const BottomTextStyle = {
  marginBottom: '16px',
  lineHight: '25px',
  fontSize: '14px',
  color: `${COLORS.gray.dark}`,
};

export const submitButtonStyle = {
  width: '100%',
};
