import { Box, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setApprovalActiveStep } from '~/redux-toolkit/slices/approvalFlowSlice';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';
import RESOURCES from '~/resources';
import Loader from '~/components/Loader';
import { LOADER_SIZE } from '~/components/Loader/useStyles';
import { loaderDescription, loaderTitle, root } from './useStyles';

const ApprovalLoader = () => {
  const { approvalActiveStep } = useSelector(
    (state: RootState) => state.approvalFlow,
  );
  const dispatch = useAppDispatch();

  const handleNext = useCallback(() => {
    dispatch(setApprovalActiveStep(approvalActiveStep + 1));
  }, [approvalActiveStep, dispatch]);

  useEffect(() => {
    setTimeout(handleNext, 5000);
  }, [handleNext]);

  return (
    <Box sx={root}>
      <Loader size={LOADER_SIZE.md} />
      <Typography
        variant="h3"
        sx={loaderTitle}
        aria-live="polite"
        aria-label={`${RESOURCES.LOADER_TITLE} ${RESOURCES.LOADER_DESCRIPTION}`}
      >
        {RESOURCES.LOADER_TITLE}
      </Typography>
      <Typography sx={loaderDescription}>
        {RESOURCES.LOADER_DESCRIPTION}
      </Typography>
    </Box>
  );
};

export default ApprovalLoader;
