import { styled } from '@mui/system';
import { Box } from '@mui/material';
import theme from '~/theme';
import { CSSProperties } from 'react';

export const DesktopFooterContainer = styled('div')({
  marginTop: '19px',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    width: 'max-content',
    marginTop: '0px',
  },
});

export const MenuList = styled(Box)({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(min-content, 160px))',
    maxHeight: '230px',
    marginBottom: '40px',
    gap: '32px',
  },
});

export const listItemStyle: CSSProperties = {
  width: 'auto',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
};

export const liStyle: CSSProperties = {
  listStyleType: 'none',
};

export const linksTitle = {
  fontFamily: 'EricaSansFOT,GillSans',
  fontWeight: '800',
  fontSize: '16px',
  lineHeight: '14px',
  paddingBottom: '8px',
};

export const sectionTitle = {
  padding: '7px 0px !important',
};
