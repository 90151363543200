import { createSlice } from '@reduxjs/toolkit';
import { getMaskyoo } from '../thunks/maskyooThunks';
import { IMaskyoo } from '../adapters/maskyooAdaptor';

const initialState: IMaskyoo = {
  globalPhone: '077-8040834',
  newCarPhone: '077-8048850',
  usedCarPhone: '077-8037360',
  privateLeasingPhone: '077-2308096',
  maskyooPhones: [
    {
      submedia: '',
      globalPhone: '077-8040834',
      newCarPhone: '077-8048850',
      usedCarPhone: '077-8037360',
      privateLeasingPhone: '077-2308096',
    },
  ],
};

const maskyoo = createSlice({
  name: 'maskyoo',
  initialState,
  reducers: {
    initState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMaskyoo.fulfilled, (state, action) => {
      if (action.payload) {
        const maskyooData = action.payload.data.attributes;

        // Update the state with the fetched data or fall back to defaults
        state.globalPhone = maskyooData.globalPhone || initialState.globalPhone;
        state.newCarPhone = maskyooData.newCarPhone || initialState.newCarPhone;
        state.usedCarPhone =
          maskyooData.usedCarPhone || initialState.usedCarPhone;
        state.privateLeasingPhone =
          maskyooData.privateLeasingPhone || initialState.privateLeasingPhone;

        // Handle maskyooPhones, use initialState if it's missing or empty
        state.maskyooPhones =
          maskyooData.maskyooPhones && maskyooData.maskyooPhones.length > 0
            ? maskyooData.maskyooPhones
            : initialState.maskyooPhones;
      }
    });
    //  DO NOT USE HYDRATE HERE
  },
});
export const { initState } = maskyoo.actions;
export default maskyoo.reducer;
