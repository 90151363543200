/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FILTER_OPTION_TYPE_PARAMS } from '~/data/filtersParams';
import {
  BannerPromotionData,
  CarBodyTypeData,
  carDescriptionHighlights,
  IPromotionCardData,
  ISearchCar,
} from '~/data/types/car/cars';
import resultPageLeaseAdapter from '~/redux-toolkit/adapters/resultPageLeaseAdapter';
import { ISearchCarsQuery } from '~/data/types/resultPageType';
import { seoResultPageDataEntity } from '~/data/types/seo/seoResutlsPages';

export const getResultPageLeaseCarsContent = createAsyncThunk(
  '/searchCars/getResultPageLeaseCarsContent',
  async (
    query: ISearchCarsQuery,
  ): Promise<{
    data: ISearchCar[];
  }> => {
    try {
      const response =
        await resultPageLeaseAdapter.getResultPageLeaseCarsContent(query);

      return response;
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
      };
    }
  },
);

export const getLeaseFiltersOptions = createAsyncThunk(
  '/filters/lease',
  async (): Promise<any> => {
    try {
      const [manufactureWithModelsRes, categoriesRes, colorsRes] =
        await Promise.all(
          Object.values(FILTER_OPTION_TYPE_PARAMS).map((optionType) =>
            resultPageLeaseAdapter.getFiltersOptions(optionType),
          ),
        );
      return {
        manufactureWithModels: manufactureWithModelsRes.data,
        categories: categoriesRes.data,
        colors: colorsRes.data,
      };
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);
export const getBannersPromotion = createAsyncThunk(
  '/getBannersPromotion',
  async (target: string): Promise<BannerPromotionData[]> => {
    try {
      const response = await resultPageLeaseAdapter.getBannerPromotion(target);

      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
export const getBannerPromotion = createAsyncThunk(
  '/getBannerPromotion',
  async (target: string): Promise<BannerPromotionData> => {
    try {
      const response = await resultPageLeaseAdapter.getBannerPromotion(target);

      return response[0];
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
export const getCardsPromotion = createAsyncThunk(
  '/getCardsPromotion',
  async (target: string): Promise<IPromotionCardData[]> => {
    try {
      const response = await resultPageLeaseAdapter.getCardsPromotion(target);

      return response;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLeaseHighlightsFilterOptions = createAsyncThunk(
  'highlights-filter/lease',
  async (): Promise<carDescriptionHighlights[]> => {
    try {
      const data = await resultPageLeaseAdapter.getHighlightsFilterOptions();

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getLeaseCarBodyTypeFilterOptions = createAsyncThunk(
  'car-body-types-filter/lease',
  async (): Promise<CarBodyTypeData[]> => {
    try {
      const data = await resultPageLeaseAdapter.getCarBodyTypeFilterOptions();

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getSeoContent = createAsyncThunk(
  '/seo-results-page',
  async (target: string): Promise<seoResultPageDataEntity> => {
    try {
      const response = await resultPageLeaseAdapter.getSeoContent(target);

      return response[0];
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
