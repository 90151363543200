import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BranchesPageDataType,
  IRentalLocationAttributes,
  RentalLocationDataType,
} from '~/data/rentalLocation.data';
import ourBranchesAdapter from '~/redux-toolkit/adapters/ourBranchesAdapter';

export const getOurBranches = createAsyncThunk(
  '/our-branches',
  async (): Promise<RentalLocationDataType[]> => {
    try {
      const locations = await ourBranchesAdapter.getOurBranches();
      return locations;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getOurBranchesV2 = createAsyncThunk(
  '/our-branches',
  async (): Promise<IRentalLocationAttributes[]> => {
    try {
      const locations = await ourBranchesAdapter.getOurBranchesV2();
      return locations;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getBranchTypes = createAsyncThunk(
  '/branch-types',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (): Promise<any> => {
    try {
      const res = await ourBranchesAdapter.getBranchTypes();
      return res;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getFilteredBranches = createAsyncThunk(
  '/our-branches-filtered',
  async (): Promise<RentalLocationDataType[]> => {
    try {
      const locations = await ourBranchesAdapter.getFilteredBranches();

      return locations;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getOurBranchesForGenericPages = createAsyncThunk(
  '/our-branches-generic-pages',
  async (): Promise<RentalLocationDataType[]> => {
    try {
      const locations =
        await ourBranchesAdapter.getOurBranchesForGenericPages();
      return locations;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getBranchesPageData = createAsyncThunk(
  '/branches-page-data',
  async (): Promise<BranchesPageDataType | null> => {
    try {
      const data = await ourBranchesAdapter.getBranchesPageData();
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);
