/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IOrderDetails,
  IUsedCarOrderDetails,
} from '~/data/types/carFlow/carFlowTypes';
import paymentAdapter from '~/redux-toolkit/adapters/paymentAdapter';

export const createTransaction = createAsyncThunk(
  'createTransaction',
  async (orderDetails: IOrderDetails | IUsedCarOrderDetails) => {
    try {
      const response = await paymentAdapter.createTransaction(orderDetails);

      return response ?? null;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);
