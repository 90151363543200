import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICarSafetyAndPollution } from '~/data/safetyAndPollution/safetyAndPollution.interfaces';
import safetyAndPollutionAdapter from '~/redux-toolkit/adapters/safetyAndPollutionAdapter';

export interface IGetSafetyAndPollutionProps {
  model: string;
  isCarZeroKm?: boolean;
  isLeasing?: boolean;
}

export const getSafetyAndPollution = createAsyncThunk(
  'api/new-and-lease-cars',
  async ({
    model,
    isCarZeroKm,
    isLeasing,
  }: IGetSafetyAndPollutionProps): Promise<ICarSafetyAndPollution> => {
    try {
      const response = await safetyAndPollutionAdapter.getCarSafetyAndPollution(
        { model, isCarZeroKm, isLeasing },
      );
      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      throw new Error('ERROR');
    }
  },
);
