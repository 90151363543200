import { useRouter } from 'next/router';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';
import { ROUTES } from '~/data/routes';
import {
  ECarModel,
  carManufacturerToPhoneMap,
} from '~/data/lead/campaignNumber';
import { useLeadEventDetails } from './useLeadEventDetails';

interface PhoneNumberMapping {
  [key: string]: string; // The key will be the route, and the value will be the default phone number.
}

export const useGetPhoneNumberBySubMedia = (
  defaultPhoneNumber: string,
  phoneNumberMapping?: PhoneNumberMapping,
): string => {
  const router = useRouter();
  const params = router.query;
  const paramsSubMedia = params.submedia ? String(params.submedia) : null;
  const leadEventDetails = useLeadEventDetails();

  const maskyoo = useAppSelector((state: RootState) => state.maskyoo);

  // Initialize the phone number with the default passed to the hook
  let phoneNumber = defaultPhoneNumber;

  // Check if there's a matching submedia in maskyooPhones
  let matchedPhoneData;
  if (paramsSubMedia && maskyoo.maskyooPhones) {
    matchedPhoneData = maskyoo.maskyooPhones.find(
      (phone) => phone.submedia === paramsSubMedia,
    );
    if (matchedPhoneData) {
      phoneNumber = matchedPhoneData.globalPhone;
    }
  }

  // If phoneNumberMapping is provided, check the path and override the phone number if needed
  if (phoneNumberMapping) {
    const currentPath = router.asPath;

    for (const path in phoneNumberMapping) {
      if (
        currentPath.includes(path) ||
        currentPath.includes(path.replace('/', ''))
      ) {
        const phoneKey =
          path === ROUTES.CAR_PAGE
            ? 'newCarPhone'
            : path === ROUTES.USED_CAR
            ? 'usedCarPhone'
            : path === ROUTES.PRIVATE_LEASING
            ? 'privateLeasingPhone'
            : null;

        if (path === ROUTES.CAR_PAGE && leadEventDetails.carMake) {
          phoneNumber =
            carManufacturerToPhoneMap[leadEventDetails.carMake as ECarModel];
        } else if (phoneKey && matchedPhoneData && matchedPhoneData[phoneKey]) {
          phoneNumber = matchedPhoneData[phoneKey];
        } else if (phoneKey && maskyoo[phoneKey]) {
          phoneNumber = maskyoo[phoneKey];
        } else {
          phoneNumber = phoneNumberMapping[path]; // Fallback to the mapping's default number
        }
        break; // Stop checking after the first match
      }
    }
  }

  return phoneNumber;
};
