/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum EContactUsFlow {
  LEAVE_DETAILS = 'leave_details',
  CREATE_MEETING = 'create_meeting',
  EXPOSE_ONLINE_PRICE = 'expose_online_price',
  CONTACT_US_NEED_HELP = 'contact_us_need_help',
  CREATE_TEST_DRIVE = 'create_test_drive',
  NONE = 'none',
}

export const ContactUsFlowAriaLabel = {
  leave_details: 'טופס השאר פרטים',
  create_meeting: 'טופס תיאום פגישה',
  expose_online_price: 'חשיפת מחיר אונליין',
  none: '',
};

export const UpsertCaseTypes = {
  Insurance: 'insurance',
  Funding: 'funding',
  ContactUsPage: 'contactUsPage',
};
