import React from 'react';
import { ImageFile } from '~/data/types/images';

const fourStarsSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="22"
      viewBox="0 0 77 22"
    >
      <path
        d="M58.9293 10.6025C58.5299 10.3442 58.6956 9.7255 59.1708 9.70151L63.3687 9.48957C64.0319 9.45608 64.5978 8.99874 64.7696 8.35737L65.8547 4.30771C65.9774 3.84987 66.6139 3.81552 66.7851 4.2575L68.3083 8.18882C68.5467 8.80432 69.153 9.19753 69.8122 9.16425L74.0296 8.95132C74.5041 8.92737 74.7317 9.52481 74.3616 9.82263L71.1013 12.446C70.5821 12.8638 70.393 13.5696 70.6337 14.191L72.1455 18.093C72.3171 18.536 71.8213 18.9396 71.4224 18.6816L67.8765 16.3886C67.3222 16.0301 66.6006 16.0675 66.0863 16.4813L62.8016 19.1244C62.4323 19.4215 61.8982 19.0735 62.0209 18.6157L63.106 14.566C63.2779 13.9247 63.0165 13.2456 62.4589 12.8851L58.9293 10.6025Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M40.0729 10.6025C39.6734 10.3442 39.8392 9.7255 40.3143 9.70151L44.5123 9.48957C45.1754 9.45608 45.7413 8.99874 45.9132 8.35737L46.9983 4.30771C47.121 3.84987 47.7574 3.81552 47.9287 4.2575L49.4518 8.18882C49.6903 8.80432 50.2966 9.19753 50.9558 9.16425L55.1732 8.95132C55.6476 8.92737 55.8752 9.52481 55.5051 9.82263L52.2448 12.446C51.7256 12.8638 51.5365 13.5696 51.7773 14.191L53.2891 18.093C53.4607 18.536 52.9648 18.9396 52.5659 18.6816L49.0201 16.3886C48.4658 16.0301 47.7441 16.0675 47.2298 16.4813L43.9451 19.1244C43.5758 19.4215 43.0418 19.0735 43.1645 18.6157L44.2496 14.566C44.4214 13.9247 44.16 13.2456 43.6025 12.8851L40.0729 10.6025Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M21.2155 10.603C20.816 10.3447 20.9818 9.72599 21.4569 9.702L25.6549 9.49005C26.318 9.45657 26.8839 8.99923 27.0558 8.35786L28.1409 4.3082C28.2635 3.85036 28.9 3.81601 29.0713 4.25799L30.5944 8.18931C30.8329 8.80481 31.4391 9.19802 32.0984 9.16474L36.3157 8.95181C36.7902 8.92786 37.0178 9.5253 36.6477 9.82311L33.3874 12.4465C32.8682 12.8643 32.6791 13.5701 32.9198 14.1915L34.4316 18.0935C34.6033 18.5365 34.1074 18.9401 33.7085 18.6821L30.1626 16.389C29.6084 16.0306 28.8867 16.068 28.3724 16.4818L25.0877 19.1248C24.7184 19.422 24.1844 19.074 24.3071 18.6162L25.3922 14.5665C25.564 13.9251 25.3026 13.2461 24.7451 12.8856L21.2155 10.603Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M2.35804 10.6025C1.95857 10.3442 2.12435 9.7255 2.59947 9.70151L6.79745 9.48957C7.4606 9.45608 8.02648 8.99874 8.19834 8.35737L9.28344 4.30771C9.40612 3.84987 10.0426 3.81552 10.2138 4.2575L11.737 8.18882C11.9754 8.80432 12.5817 9.19753 13.241 9.16425L17.4583 8.95132C17.9328 8.92737 18.1604 9.52481 17.7903 9.82263L14.53 12.446C14.0108 12.8638 13.8217 13.5696 14.0624 14.191L15.5742 18.093C15.7458 18.536 15.25 18.9396 14.8511 18.6816L11.3052 16.3886C10.7509 16.0301 10.0293 16.0675 9.515 16.4813L6.23027 19.1244C5.86098 19.4215 5.32695 19.0735 5.44963 18.6157L6.53474 14.566C6.70659 13.9247 6.4452 13.2456 5.88763 12.8851L2.35804 10.6025Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
    </Component>
  );
};

export default fourStarsSelectedIcon;
