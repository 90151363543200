import axios, { AxiosResponse } from 'axios';
import { BookSlotRequest, BookSlotResponse } from './requests/BookSlotRequest';
import {
  ICreateMeetingOrTestDriveRequest,
  IsExistOpenLead,
  IsExistOpenLeadById,
  LeadProductItem,
} from './interfaces';
import { LeadRequest } from './requests/LeadRequest';
import {
  UpsertLeadRequest,
  UpsertLeadResponse,
} from './requests/UpsertLeadRequest';
import {
  IsExistOpenLeadRequest,
  IsExistOpenLeadResponse,
} from './requests/IsExistOpenLeadRequest';
import { CampaignNumber } from './enums';
import { UpsertLead } from './UpsertData';
import {
  GetChildTerritoryListRequest,
  GetChildTerritoryListResponse,
} from './requests/GetChildTerritoryListRequest';
import {
  GetSlotsRestSingleTerritoryRequest,
  GetSlotsRestSingleTerritoryResponse,
} from './requests/GetSlotsRestSingleTerritoryRequest';
import { IsExistOpenLeadByIdRequest } from './requests/IsExistOpenLeadByIdRequest';
import { GetChildTerritoryListTestDriveRequest, GetChildTerritoryListTestDriveResponse } from './requests/GetChildTerritoryListTestDriveRequest';

export class LeadService {
  static baseUrl = process.env.NEXT_PUBLIC_SERVER_BASE_URL || '';

  static defaultMeetingType = 'Sales Meeting in a Showroom';

  static leasingMeetingType = 'Face to Face Meeting';

  static leasingPhoneMeetingType = 'Phone Meeting';

  static eveasyMeetingType = 'Sales Meeting in a Showroom Eveasy';

  static usedCarMeetingType = 'Sale of Used Car - Branch';

  static defaultWebsiteMeeting = 'Create Website Meeting';

  static TestDriveMeetingType = 'Test Drive in a Showroom'

  static async createNewLeadRequest(upsertLead: UpsertLead) {
    const request = new UpsertLeadRequest(upsertLead);

    return LeadService.send<UpsertLeadResponse>(request);
  }

  static async addLeadProductsRequest(upsertLead: UpsertLead) {
    const request = new UpsertLeadRequest(upsertLead);

    // First we need to create lead withput the products
    const products: LeadProductItem[] = [
      ...request.upsertLead.creatLeadProductList,
    ];
    request.upsertLead.removeLeadProducts();
    request.upsertLead.addLeadProducts(...products);
    request.upsertLead.isUpsertLeadNeeded = true;
    const res = await LeadService.send<UpsertLeadResponse>(request);

    return res;
  }

  static async createNewMeetingRequest(
    data: ICreateMeetingOrTestDriveRequest
  ) {
    const request = new BookSlotRequest({
      leadId: data.leadId,
      selectedTerritoryId: data.selectedTerritoryId,
      scheduledTime: data.scheduledTime,
      meetingType: data.modelSkill ? LeadService.TestDriveMeetingType : LeadService.getMeetingType(data.campaignNumber || ''),
      websiteMeeting: LeadService.defaultWebsiteMeeting,
      ...(data.modelSkill && { modelSkill: data.modelSkill })
    });
    return LeadService.send<BookSlotResponse>(request);
  }

  /**
   *
   * @param params = {
        firstName: name,
        lastName: name,
        mobilePhone: phone,
        email,
        campaignNumber: CampaignNumber.CHERRY,
      }
   * @param city = 'חיפה'
   * @param leadId = '00Q3H000006lU4xUAE'
   * @returns GetChildTerritoryListResponse
   */
  static async getCityTerritories(upsertLead: UpsertLead) {
    try {
      const meetingType = LeadService.getMeetingType(upsertLead.campaignNumber);
      // Check if lead exist for mobile phone and lineOfBusiness
      const leadId = await LeadService.getExistOpenLeadId({
        lineOfBusiness: upsertLead.lineOfBusiness,
        mobilePhone: upsertLead.mobilePhone,
      });

      // 1. Creating / updating lead
      const leadRequest = new UpsertLeadRequest(upsertLead);
      if (leadId) {
        const creatLeadProductList =
          leadRequest.upsertLead.creatLeadProductList.map((p) => {
            return leadId ? { ...p, leadId } : p;
          });

        leadRequest.upsertLead = {
          isUpsertLeadNeeded: true,
          creatLeadProductList,
          leadId,
          meetingCity: leadRequest.upsertLead.meetingCity,
        } as UpsertLead;
      }

      const leadRes = await LeadService.send<UpsertLeadResponse>(leadRequest);

      // 2. Fetching territories with the  new lead id
      return {
        leadId: leadId || leadRes.data.upsertLead.leadSfid,
        territories: await LeadService.fetchChildTerritories(
          leadId || leadRes.data.upsertLead.leadSfid,
          meetingType,
        ),
      };
    } catch (e) {
      return null;
    }
  }

  /**
  *
  * @param params = {
       firstName: name,
       lastName: name,
       mobilePhone: phone,
       email,
       campaignNumber: CampaignNumber.CHERRY,
     }
  * @param city = 'חיפה'
  * @param familyId = 'a1E5p00000EinP4EAJ'
  * @param leadId = '00Q3H000006lU4xUAE'
  * @returns GetChildTerritoryListResponse
  */
  static async getTestDriveTerritories(upsertLead: UpsertLead, familyId: string,) {
    try {

      // Check if lead exist for mobile phone and lineOfBusiness
      const leadId = await LeadService.getExistOpenLeadId({
        lineOfBusiness: upsertLead.lineOfBusiness,
        mobilePhone: upsertLead.mobilePhone,
      });

      // 1. Creating / updating lead
      const leadRequest = new UpsertLeadRequest(upsertLead);
      if (leadId) {
        const creatLeadProductList =
          leadRequest.upsertLead.creatLeadProductList.map((p) => {
            return leadId ? { ...p, leadId } : p;
          });

        leadRequest.upsertLead = {
          isUpsertLeadNeeded: true,
          creatLeadProductList,
          leadId,
          meetingCity: leadRequest.upsertLead.meetingCity,
        } as UpsertLead;
      }

      const leadRes = await LeadService.send<UpsertLeadResponse>(leadRequest);

      // 2. Fetching territories with the  new lead id
      return {
        leadId: leadId || leadRes.data.upsertLead.leadSfid,
        territories: await LeadService.fetchChildTerritoriesTestDrive(
          leadId || leadRes.data.upsertLead.leadSfid,
          LeadService.TestDriveMeetingType,
          familyId
        ),
      };
    } catch (e) {
      return null;
    }
  }

  static async getTerritorySlots(
    leadId: string,
    campaignNumber: CampaignNumber,
    territoryId: string,
    modelSkill?: string,
  ) {
    try {
      const reqData = {
        leadId,
        meetingType: modelSkill ? LeadService.TestDriveMeetingType : LeadService.getMeetingType(campaignNumber),
        relevantServiceTerritory: territoryId,
        ...(modelSkill && { modelSkill }),
      }
      const request = new GetSlotsRestSingleTerritoryRequest(reqData);
      return LeadService.send<GetSlotsRestSingleTerritoryResponse>(request);
    } catch (e) {
      return null;
    }
  }

  private static getMeetingType(campaignNumber: CampaignNumber) {
    switch (campaignNumber) {
      case CampaignNumber.LEASING:
        return LeadService.leasingPhoneMeetingType;
      case CampaignNumber.EVEASY:
        return LeadService.eveasyMeetingType;
      case CampaignNumber.USED_CAR:
      case CampaignNumber.USED_CAR_SHIRYUN:
        return LeadService.usedCarMeetingType;

      default:
        return LeadService.defaultMeetingType;
    }
  }

  private static async getExistOpenLeadId(data: IsExistOpenLead) {
    const request = new IsExistOpenLeadRequest(data);
    const res = await LeadService.send<IsExistOpenLeadResponse>(request);
    const { leadId, isExistOpenLead } = res.data.isExistOpenLead;
    return isExistOpenLead === 'true' ? leadId : undefined;
  }

  static async getExistOpenLeadByLeadId(data: IsExistOpenLeadById) {
    const request = new IsExistOpenLeadByIdRequest(data);
    const res = await LeadService.send<IsExistOpenLeadResponse>(request);
    const { leadId, isExistOpenLead } = res.data.isExistOpenLead;
    return isExistOpenLead === 'true' ? leadId : undefined;
  }

  private static async fetchChildTerritories(
    leadId: string,
    meetingType: string,
  ) {
    const getChildTerritoryRequest = new GetChildTerritoryListRequest({
      leadId,
      meetingType,
    });
    return LeadService.send<GetChildTerritoryListResponse>(
      getChildTerritoryRequest,
    );
  }

  private static async fetchChildTerritoriesTestDrive(
    leadId: string,
    meetingType: string,
    familyId: string,
  ) {
    const getChildTerritoryRequest = new GetChildTerritoryListTestDriveRequest({
      leadId,
      meetingType,
      familyId,
    });
    return LeadService.send<GetChildTerritoryListTestDriveResponse>(
      getChildTerritoryRequest,
    );
  }

  private static async send<T>(
    request: LeadRequest,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<AxiosResponse<T, any>> {
    const location = `freesbe.com${window.location.pathname}`;

    return axios({
      url: `${LeadService.baseUrl}/api/lead-api`,
      method: 'post',
      data: { data: request, source: 'freesbe', location },
    });
  }
}
