export const formLabelStyle = {
  fontSize: '16px',
  marginRight: '9px',
  marginBottom: '10px',
};

export const formControlStyle = {
  '& .MuiTypography-root': {
    fontSize: '16px',
    marginRight: '0',
  },
};

export const checkboxStyle = {
  marginRight: '0px',
};

export const radioGroupStyle = {
  borderRadius: '20px',
  height: '21px',
  width: '21px',
};

export const radioButtonContainer = {
  display: 'flex',
  flexDirection: 'column',
  direction: 'rtl',
} as const;
