import { ERequestType } from '../enums';
import { LeadRequest } from './LeadRequest';
import { UpsertLead } from '../UpsertData';

export interface UpsertLeadResponse {
  errorMessage: string;
  upsertLead: {
    leadSfid: string;
    isSuccessLead: string;
    isSuccessProcucts: string;
    isSuccessTasks: string;
    errorDescription: string;
  };
}

export class UpsertLeadRequest extends LeadRequest {
  upsertLead: UpsertLead;

  constructor(upsertLead: UpsertLead) {
    super(ERequestType.UPSERT_LEAD);
    this.upsertLead = upsertLead;
  }
}
