/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

export enum ELocation {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum ESize {
  xs = 'sm',
  small = 'sm',
  medium = 'md',
  large = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}
