import {
  BannerCardType,
  GetBannerCardsResponseType,
} from '~/data/types/bannerCard/bannerCard.types';
import { ICampaignGalleryBanner } from '~/data/types/MarketingBannerCarousels/MarketingBannerCarousels.types';

export const getMappedBanners = (
  banners: GetBannerCardsResponseType[],
): BannerCardType[] => {
  return banners.map((b) => {
    const banner = b.attributes;
    const info = banner.info;

    return {
      title: info?.title ?? '',
      description: info?.description ?? '',
      titleSize: info?.titleSize ?? null,
      descriptionSize: info?.descriptionSize ?? null,
      textAlignment: info?.textAlignment ?? null,
      textColor: info?.textColor ?? null,
      lineOfBusiness: banner.lineOfBusiness,
      bannerType: banner.bannerType,
      buttonTitle: banner.buttonTitle,
      bannerLink: banner.bannerLink,
      leadDetails: banner.leadDetails,
      image: banner.image.data?.attributes.url || '',
      imageAlt: banner.image.data?.attributes.alternativeText || '',
      imageSizes: {
        desktop: {
          w: banner.image.data?.attributes?.width || 0,
          h: banner.image.data?.attributes?.height || 0,
        },
        mobile: {
          w: banner.mobileImage.data?.attributes?.width || 0,
          h: banner.mobileImage.data?.attributes?.height || 0,
        },
      },
      mobileImage: banner.mobileImage.data?.attributes.url || '',
      rank: banner.rank ?? banners.length + 1,
      preDesignedBanner: banner.preDesignedBanner || false,
      openLinkInNewTab: banner.openLinkInNewTab || false,
      tooltipContent: banner.tooltipContent || '',
    };
  });
};

export const getCampaignMappedBanners = (
  banners: ICampaignGalleryBanner[],
): BannerCardType[] => {
  return banners.map((banner) => {
    const info = banner.info;

    return {
      title: info?.title ?? '',
      description: info?.description ?? '',
      titleSize: info?.titleSize ?? null,
      descriptionSize: info?.descriptionSize ?? null,
      textAlignment: info?.textAlignment ?? null,
      textColor: info?.textColor ?? null,
      lineOfBusiness: banner.lineOfBusiness,
      bannerType: banner.bannerType,
      buttonTitle: banner.buttonTitle,
      bannerLink: banner.bannerLink,
      leadDetails: banner.leadDetails,
      image: banner.image?.url || '',
      imageAlt: banner.image?.alternativeText || '',
      imageSizes: {
        desktop: {
          w: banner.image?.width || 0,
          h: banner.image?.height || 0,
        },
        mobile: {
          w: banner.mobileImage?.width || 0,
          h: banner.mobileImage?.height || 0,
        },
      },
      mobileImage: banner.mobileImage?.url || '',
      rank: banner.rank ?? banners.length + 1,
      preDesignedBanner: banner.preDesignedBanner || false,
      openLinkInNewTab: banner.openLinkInNewTab || false,
      tooltipContent: banner.tooltipContent || '',
    };
  });
};
