// import { styled } from '@mui/material';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.primary.main,
    position: 'relative',
    marginBottom: '20px',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
  socialMediaDiv: {
    flexDirection: 'row',
    gap: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  socialMediaAvatarsDiv: {
    display: 'flex',
    gap: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
    },
  },

  linkItem: {
    marginLeft: '24px',
  },
};

export default classes;

export const avatarIcon = {
  backgroundColor: COLORS.primary.main,
  height: '29px',
  width: '29px',
  color: COLORS.black.main,
};

export const followUsHeader = {
  fontWeight: '400',
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
};
