import { HttpsClientRentalService } from '~/services/HttpsClientRentalService';
import {
  HolidaysDatesDataType,
  RentalLocationsDataType,
} from '~/data/types/rentalLocationsType';
import { MetaType } from '~/data/types/paginationTypes';

class RentalBranchesAdapter {
  static readonly endpointRentalLocations = `/api/rental-locations?populate[rentalLocationDetails][filters][language]`;

  static readonly endpointHolidaysDates = `/api/holiday-dates`;

  // eslint-disable-next-line class-methods-use-this
  async getRentalBranches(): Promise<RentalLocationsDataType[]> {
    const res = await HttpsClientRentalService.get<{
      meta: MetaType;
      data: RentalLocationsDataType[];
    }>(
      `${RentalBranchesAdapter.endpointRentalLocations}[$eq]=he&populate[specialDates][populate]=*&populate`,
    );

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHolidaysDates(): Promise<HolidaysDatesDataType[]> {
    const res = await HttpsClientRentalService.get<{
      meta: MetaType;
      data: HolidaysDatesDataType[];
    }>(`${RentalBranchesAdapter.endpointHolidaysDates}`);

    return res.data.data;
  }
}

const rentalBranchesAdapter = new RentalBranchesAdapter();

export default rentalBranchesAdapter;
