import { IChiledTerritory } from '~/data/lead/interfaces';
import { ERequestType } from '../enums';
import { GetChildTerritoryList } from '../interfaces';
import { LeadRequest } from './LeadRequest';

export interface GetChildTerritoryListResponse {
  errorMessage?: string;
  getChildTerritoryList: {
    relevantTerritories: IChiledTerritory[];
    isActiveMeetingForLead: boolean;
    errorDescription: string;
  };
}

export class GetChildTerritoryListRequest extends LeadRequest {
  getChildTerritoryList: GetChildTerritoryList;

  constructor(getChildTerritoryList: GetChildTerritoryList) {
    super(ERequestType.GetChildTerritoryList);

    this.getChildTerritoryList = getChildTerritoryList;
  }
}
