import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ACCESSORIES,
  ADDITIONS_MOCK,
  selectedCarInitialStates,
  STEP_FLOW_OPTION,
} from '~/data/types/carFlow/carFlowData';

import { IUserDetails } from '~/data/lead/interfaces';
import {
  AdditionType,
  additionItem,
  AccessoryDataType,
  AccessoryCard,
  SelectedCar,
  WarrantyDataType,
  ICarFlowData,
  ICar,
  LeaseCarType,
  LeasingAccessoriesType,
  EngineType,
  LevelType,
  ColorType,
  SingleAccessory,
} from '~/data/types/carFlow/carFlowTypes';
import { EUserDetailsStatus } from '~/data/PaymentLayout/enums';
import { ITransactionResponse } from '~/data/PaymentLayout/interfaces';
import {
  getCarFlowAccessories,
  getCarFlowAdditions,
  getCar,
  getLeasingAccessories,
  getLeasingAdditions,
  createNewCarOrder,
  getCarConfigurator,
  getLeaseCarConfigurator,
  createLeaseCustomer,
} from '~/redux-toolkit/thunks/carFlowThunks';
import { createTransaction } from '~/redux-toolkit/thunks/paymentThunks';
import RESOURCES from '~/resources';

// Private actions
const PRIVATE_ACTIONS = {
  setSelectedEngine: (state: CarFlowState, payload: EngineType) => {
    const newEngine = payload;
    state.selectedCar.engine = newEngine;
    const [initialFinishLevel] = newEngine.finishLevels;
    PRIVATE_ACTIONS.setSelectedFinishLevel(state, initialFinishLevel);
  },
  setSelectedFinishLevel: (state: CarFlowState, payload: LevelType) => {
    const newFinishLevel = payload;
    state.selectedCar.finishLevel = newFinishLevel;
    const [initialColor] = newFinishLevel.colors;
    PRIVATE_ACTIONS.setSelectedColor(state, initialColor);
    if (newFinishLevel.timesFrames) {
      const [initialTimeFrame] = newFinishLevel.timesFrames;
      state.selectedCar.timeFrame = initialTimeFrame;
      const [initialDistancePackage] = initialTimeFrame.kmPackages;
      const plansAndManagement = initialDistancePackage.routePrice[0];
      state.selectedCar.distancePackage = initialDistancePackage;
      state.selectedCar.plansAndManagement = plansAndManagement;
    }
  },
  setSelectedColor: (state: CarFlowState, payload: ColorType) => {
    state.selectedCar.color = payload;
  },
  setPlanType: (
    state: CarFlowState,
    payload: 'basic' | 'premium' | undefined,
  ) => {
    state.selectedCar.planType = payload;
  },
};

interface CarFlowState {
  selectedCar: SelectedCar;
  carData: ICarFlowData;
  additions: AdditionType[];
  stepperActiveStep: number;
  accessories: AccessoryCard[];
  isFundingApproved: boolean;
  warrantyData: WarrantyDataType[];
  singleAccessory: SingleAccessory;
  userDetails: IUserDetails;
  transactionDetails: ITransactionResponse;
  isCarFlowOpen: boolean;
  isPaymentSuccess: boolean;
  advancePayment: number;
  agencyCode: number;
  agency: string;
  isLoading: boolean;
  extendedWarrantyAccessory?: AccessoryDataType;
  createCustomerStatus: string;
  isErrorCreateCustomer: boolean;
  pendingCreateCustomer: boolean;
  isAdditionModalOpen: boolean;
  isAdditionsOpen: boolean;
  isAccessoriesOpen: boolean;
  isAdditionLeasingOpen: boolean;
  isEmptyColor: boolean;
  paymentAttempts: number;
  carNameAndModel: string;
  familyCarZoneId: string;
}

const initialState: CarFlowState = {
  selectedCar: selectedCarInitialStates,
  additions: ADDITIONS_MOCK,
  carData: STEP_FLOW_OPTION,
  stepperActiveStep: 0,
  accessories: ACCESSORIES,
  isFundingApproved: false,
  warrantyData: [],
  singleAccessory: { title: '', description: '', imgUrl: '' },
  userDetails: {
    lastName: '',
    firstName: '',
    email: '',
    idNumber: '',
    phone: '',
    address: ' ',
    city: '',
  },
  advancePayment: 0,
  agencyCode: 100,
  agency: '',
  transactionDetails: {
    transactionId: '',
    tranzilaIframeUrl: '',
  },
  isCarFlowOpen: false,
  isPaymentSuccess: false,
  isLoading: false,
  createCustomerStatus: '',
  isErrorCreateCustomer: false,
  pendingCreateCustomer: false,
  isAdditionModalOpen: false,
  isAdditionsOpen: false,
  isAccessoriesOpen: false,
  isAdditionLeasingOpen: false,
  isEmptyColor: false,
  paymentAttempts: 0,
  carNameAndModel: '',
  familyCarZoneId: '',
};

const carFlowSlice = createSlice({
  name: 'carFlow',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAdditionModalOpen(state, action) {
      state.isAdditionModalOpen = action.payload;
    },
    setAdditionOpen(state, action) {
      state.isAdditionsOpen = action.payload;
    },
    setAccessoriesOpen(state, action) {
      state.isAccessoriesOpen = action.payload;
    },
    setAdditionLeasingOpen(state, action) {
      state.isAdditionLeasingOpen = action.payload;
    },
    saveTransaction(state, action) {
      state.transactionDetails = action.payload;
    },
    setPaymentSuccess(state, action) {
      state.isPaymentSuccess = action.payload;
    },
    setCarFlowOpen(state, action) {
      state.isCarFlowOpen = action.payload;
    },
    setCurrentStep(state, action) {
      state.stepperActiveStep = action.payload;
    },
    setCar(state, action) {
      state.selectedCar = action.payload;
    },
    setCarNameAndModel(state, action) {
      state.carNameAndModel = action.payload;
    },
    setSelectedCarReset(state) {
      state.selectedCar = selectedCarInitialStates;
    },
    setAdvancePayment(state, action) {
      state.advancePayment = action.payload;
    },
    setEngine(state, action: PayloadAction<EngineType>) {
      PRIVATE_ACTIONS.setSelectedEngine(state, action.payload);
    },
    setLeaseEngine(state, action) {
      state.selectedCar.engine = action.payload;
    },
    setCreateCustomerStatus(state, action) {
      state.createCustomerStatus = action.payload;
    },
    setFinishLevel(state, action: PayloadAction<LevelType>) {
      PRIVATE_ACTIONS.setSelectedFinishLevel(state, action.payload);
    },
    setColor(state, action) {
      PRIVATE_ACTIONS.setSelectedColor(state, action.payload);
    },
    setPlanType(state, action) {
      PRIVATE_ACTIONS.setPlanType(state, action.payload);
    },
    setAccessories(state, action) {
      state.selectedCar.accessories = action.payload;
      const { accessories } = state.selectedCar;
      const sumAccessories = accessories
        .map((a) => a.price)
        .reduce((a, b) => a + b, 0);
      state.selectedCar.accessoriesSumPrice = sumAccessories;
    },
    setTotalPrice: (state: CarFlowState, action) => {
      state.selectedCar.totalPrice = action.payload;
    },
    setWarranty(state, action) {
      state.selectedCar.warranty = action.payload;
    },
    setNeedTradeIn(state) {
      state.selectedCar.needsTrade = !state.selectedCar.needsTrade;
    },
    setNeedInsurance(state) {
      state.selectedCar.needsInsurance = !state.selectedCar.needsInsurance;
    },
    setNeedFunding(state, action) {
      state.selectedCar.needsFunding = action.payload;
    },
    setTimeFrame(state, action) {
      state.selectedCar.timeFrame = action.payload;
    },
    setDistancePackage(state, action) {
      state.selectedCar.distancePackage = action.payload;
      const plansAndManagement =
        state.selectedCar.distancePackage.routePrice[0];
      state.selectedCar.plansAndManagement = plansAndManagement;
    },
    setPlansAndManagement(state, action) {
      state.selectedCar.plansAndManagement = action.payload;
    },
    setSingleAccessory(state, action) {
      state.singleAccessory = action.payload;
    },
    setPendingButton(state, action) {
      state.pendingCreateCustomer = action.payload;
    },
    setStateUserDetails(state, action) {
      const {
        firstName,
        lastName,
        email,
        address,
        city,
        phoneNumber,
        idNumber,
        agencyCode,
        mailsApproved,
        agency,
        addressHomeNum,
      } = action.payload;
      state.userDetails.firstName = firstName;
      state.userDetails.lastName = lastName;
      state.userDetails.email = email;
      state.userDetails.phone = phoneNumber;
      state.userDetails.address = address;
      state.userDetails.city = city;
      state.userDetails.idNumber = idNumber;
      state.userDetails.mailsApproved = mailsApproved;
      state.agencyCode = agencyCode;
      state.agency = agency;
      state.userDetails.addressHomeNum = addressHomeNum;
    },
    setLeasingUpdateProperties(state) {
      const distancePackage = state.selectedCar.timeFrame.kmPackages[0];
      const plansAndManagement = distancePackage.routePrice[0];
      state.selectedCar.distancePackage = distancePackage;
      state.selectedCar.plansAndManagement = plansAndManagement;
    },
    setIsErrorCreateCustomer(state) {
      state.isErrorCreateCustomer = false;
    },
    setPaymentAttempts(state, action) {
      state.paymentAttempts = action.payload;
    },
    setFamilyCarZoneId(state, action) {
      state.familyCarZoneId = action.payload;
    },
    initState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCarFlowAccessories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCarFlowAccessories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCarFlowAccessories.fulfilled, (state, action) => {
      try {
        state.extendedWarrantyAccessory = action.payload.find(
          (a) => a.attributes.isWarranty,
        );
        state.accessories = action.payload
          .filter(
            (accessory: AccessoryDataType) =>
              !accessory.attributes.isWarranty &&
              accessory.attributes.accessory &&
              accessory.attributes.title &&
              accessory.attributes.price,
          )
          .map((item: AccessoryDataType) => {
            const { attributes } = item;
            return {
              title: attributes.title,
              price: attributes.price,
              id: attributes.accessory,
              description: attributes.secondDescription,
              imgUrl: attributes.link,
              sfCode: attributes.accessorySFID,
            } as AccessoryCard;
          });
        state.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    });
    builder.addCase(getLeasingAccessories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLeasingAccessories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getLeasingAccessories.fulfilled, (state, action) => {
      try {
        state.accessories = action.payload
          .filter(
            (accessory: LeasingAccessoriesType) =>
              accessory.attributes.accessoryDescription &&
              accessory.attributes.accessoryCode,
          )
          .map((item: LeasingAccessoriesType) => {
            const { attributes } = item;
            return {
              title: attributes.accessoryDescription,
              price: attributes.price,
              description: attributes.accessoryDescription,
              id: attributes.accessoryCode,
              essenceCode: attributes.essenceCode,
            };
          });
        state.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    });
    builder.addCase(getLeasingAdditions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLeasingAdditions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getLeasingAdditions.fulfilled, (state, action) => {
      try {
        state.additions = action.payload.map(
          (item: { attributes: { accessoryDescription: string } }) => {
            const { attributes } = item;
            return {
              accessory: attributes.accessoryDescription,
            };
          },
        );
        state.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    });
    builder.addCase(getCarFlowAdditions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCarFlowAdditions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCarFlowAdditions.fulfilled, (state, action) => {
      try {
        state.additions = action.payload.map((item: additionItem) => {
          const { attributes } = item;
          return {
            accessory: attributes.accessory,
            title: attributes.title,
          };
        });
        state.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    });
    builder.addCase(getCar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCar.fulfilled, (state, action) => {
      state.isLoading = false;
      try {
        const car = action.payload as ICar;
        const { attributes } = car;

        state.carData.warranties = [
          {
            id: 1,
            brand: attributes.brandDesc,
            years_battery: attributes.batteryWarrantyByYears,
            km_battery: attributes.batteryWarrantyByKm,
            km: attributes.warrantyByKm,
            years: attributes.warrantyByYears,
            title: '',
            description: '',
            isExtendedWarranty: false,
            price: 0,
            image: '',
            selectedImage: '',
            extendedWarrantyId: '',
            extendedWarrantySFId: '',
          },
        ];

        const [warranty] = state.carData?.warranties;
        state.selectedCar.warranty = warranty;
      } catch (e) {
        console.log(e);
      }
    });

    builder.addCase(createNewCarOrder.fulfilled, (state, action) => {
      try {
        const res = action.payload;
        console.log(res);
      } catch (e) {
        console.log(e);
      }
    });
    builder.addCase(getCarConfigurator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCarConfigurator.fulfilled, (state, action) => {
      try {
        state.isLoading = false;
        const res = action.payload;

        if (res) {
          res.engines.forEach((item) => {
            item.title = item.title.replace(/,,/g, ',');
          });
          state.carData.engines = res.engines
            .map((item) => {
              return {
                ...item,
                finishLevels: item.finishLevels.filter(
                  (item2: any) => item2.colors.length !== 0,
                ),
              };
            })
            .filter((item2) => item2.finishLevels.length > 0);

          state.carData.finishLevels = res.engines.flatMap((engine) =>
            engine.finishLevels.map((finishLevel) => ({
              ...finishLevel,
              engineTitle: engine.title,
            })),
          );

          const initialEngine = state.carData.engines[0];
          PRIVATE_ACTIONS.setSelectedEngine(state, initialEngine);

          state.carData.newCarConfPage = res.newCarConfPage;
        }
      } catch (e) {
        state.isEmptyColor = true;
        console.log(e);
      }
    });

    builder.addCase(getLeaseCarConfigurator.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createLeaseCustomer.pending, (state) => {
      state.pendingCreateCustomer = true;
    });
    builder.addCase(createLeaseCustomer.fulfilled, (state, action) => {
      state.pendingCreateCustomer = false;
      if (action.payload.isValid) {
        state.createCustomerStatus = EUserDetailsStatus.SUCCESS;
      } else {
        state.createCustomerStatus = EUserDetailsStatus.ERROR;
        state.isErrorCreateCustomer = true;
      }
    });
    builder.addCase(createLeaseCustomer.rejected, (state) => {
      state.pendingCreateCustomer = false;
      state.isErrorCreateCustomer = true;
      state.createCustomerStatus = EUserDetailsStatus.ERROR;
    });
    builder.addCase(createTransaction.pending, (state) => {
      state.pendingCreateCustomer = true;
    });
    builder.addCase(createTransaction.fulfilled, (state, action) => {
      state.pendingCreateCustomer = false;
      state.transactionDetails.transactionId = action.payload?.id || '';
      state.transactionDetails.tranzilaIframeUrl =
        action.payload?.tranzilaIframeUrl || '';
    });
    builder.addCase(createTransaction.rejected, (state) => {
      state.pendingCreateCustomer = false;
      state.isErrorCreateCustomer = true;
      state.createCustomerStatus = EUserDetailsStatus.ERROR;
    });
    builder.addCase(getLeaseCarConfigurator.fulfilled, (state, action) => {
      try {
        state.isLoading = false;
        const res = action.payload;
        if (res) {
          state.carData.engines = res.leaseEngines
            .map((item: LeaseCarType) => {
              const title = [
                item.fuel,
                item.engineCapacity && ` ${item.engineCapacity} סמ״ק`,
                // item.horsepower && ` ${item.horsepower} כ״ס`,
                item.isAuthomatic
                  ? ` ${RESOURCES.GEAR_AUTOMATIC}`
                  : ` ${RESOURCES.GEAR_MANUALLY}`,
              ]
                .filter((spec) => spec)
                .join(',');

              return {
                title,
                engineCapacity: item.engineCapacity,
                horsepower: item.horsepower,
                fuel: item.fuel,
                isAuthomatic: item.isAuthomatic,
                finishLevels: item.finishLevels
                  .filter((item2) => item2.colors.length !== 0)
                  .map((level) => {
                    return {
                      ...level,
                      leasingPrice: level.price,
                      price: null,
                      rishuiPrice: level.licensingCost,
                    };
                  }),
                description: item.description,
                motorRangeElc: item.motorRangeElc,
                isElectric: item.isElectric,
              };
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((item2) => item2.finishLevels.length > 0) as any;
          const initialEngine = state.familyCarZoneId?.includes('hybrid')
            ? state.carData?.engines.find(
                (engine) =>
                  !engine.isElectric &&
                  engine.motorRangeElc &&
                  engine.motorRangeElc > 0,
              ) || state.carData.engines[0]
            : state.carData.engines[0];
          PRIVATE_ACTIONS.setSelectedEngine(state, initialEngine);
          state.carData.newCarConfPage = res.newCarConfPage;
        }
      } catch (e) {
        state.isEmptyColor = true;
        console.log(e);
      }
    });
  },
});

export const {
  setPaymentSuccess,
  saveTransaction,
  setCurrentStep,
  setCar,
  setEngine,
  setFinishLevel,
  setColor,
  setCarNameAndModel,
  setPlanType,
  setAccessories,
  setWarranty,
  setNeedTradeIn,
  setNeedInsurance,
  setNeedFunding,
  setTimeFrame,
  setDistancePackage,
  setPlansAndManagement,
  setLeaseEngine,
  setSelectedCarReset,
  setSingleAccessory,
  setStateUserDetails,
  setAdvancePayment,
  setCarFlowOpen,
  setLeasingUpdateProperties,
  initState,
  setAdditionModalOpen,
  setAdditionOpen,
  setAccessoriesOpen,
  setAdditionLeasingOpen,
  setPendingButton,
  setCreateCustomerStatus,
  setIsLoading,
  setTotalPrice,
  setIsErrorCreateCustomer,
  setPaymentAttempts,
  setFamilyCarZoneId,
} = carFlowSlice.actions;

export default carFlowSlice.reducer;
