/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { CarCardType } from '~/data/types/car/cars';
import { CAR_FLOW_TYPE } from '~/data/car/carCardData';
import { CampaignNumber } from '~/services/LeadService/enums';
import { CONTACT_US_TEXT } from '~/resources/resources';

export enum ECarModel {
  CHERRY = "צ'רי",
  RENAULT = 'רנו',
  DUCIA = "דאצ'יה",
  DUCIA1 = 'דאציה',
  INFINITY = 'אינפיניטי',
  NISSAN = 'ניסאן',
  EVEASY = 'איויאיסי',
  XPENG = 'אקספנג',
}

export const carManufacturerToPhoneMap = {
  [ECarModel.CHERRY]: CONTACT_US_TEXT.CherryPhone,
  [ECarModel.RENAULT]: CONTACT_US_TEXT.RenaultPhone,
  [ECarModel.DUCIA]: CONTACT_US_TEXT.DaciaPhone,
  [ECarModel.DUCIA1]: CONTACT_US_TEXT.DaciaPhone,
  [ECarModel.INFINITY]: CONTACT_US_TEXT.InfinityPhone,
  [ECarModel.NISSAN]: CONTACT_US_TEXT.NissanPhone,
  [ECarModel.EVEASY]: CONTACT_US_TEXT.EveasyPhone,
  [ECarModel.XPENG]: CONTACT_US_TEXT.XpengPhone,
};
const carManufacturerToCampaignMap = {
  [ECarModel.CHERRY]: CampaignNumber.CHERRY,
  [ECarModel.RENAULT]: CampaignNumber.RENAULT,
  [ECarModel.DUCIA]: CampaignNumber.DUCIA,
  [ECarModel.DUCIA1]: CampaignNumber.DUCIA,
  [ECarModel.INFINITY]: CampaignNumber.INFINITY,
  [ECarModel.NISSAN]: CampaignNumber.NISSAN,
  [ECarModel.EVEASY]: CampaignNumber.EVEASY,
  [ECarModel.XPENG]: CampaignNumber.XPENG,
};

const carManufacturerTFailoCampaignMap = {
  [ECarModel.CHERRY]: CampaignNumber.FAILED_CHERRY,
  [ECarModel.RENAULT]: CampaignNumber.FAILED_RENAULT,
  [ECarModel.DUCIA]: CampaignNumber.FAILED_DUCIA,
  [ECarModel.DUCIA1]: CampaignNumber.FAILED_DUCIA,
  [ECarModel.INFINITY]: CampaignNumber.FAILED_INFINITY,
  [ECarModel.NISSAN]: CampaignNumber.FAILED_NISSAN,
  [ECarModel.EVEASY]: CampaignNumber.FAILED_EVEASY,
  [ECarModel.XPENG]: CampaignNumber.XPENG,
};

export const getCampaignNumberByCar = (
  car: CarCardType,
  dealType: string | null,
  failure?: boolean,
) => {
  return !car
    ? CampaignNumber.GENERAL
    : car.id && dealType === CAR_FLOW_TYPE.LEASING_CAR
    ? failure
      ? CampaignNumber.FAILED_LEASING
      : CampaignNumber.LEASING
    : failure
    ? carManufacturerTFailoCampaignMap[car.dmvManufacturerName as ECarModel]
    : carManufacturerToCampaignMap[car.dmvManufacturerName as ECarModel];
};
