import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { ESourceEventOptions } from '~/data/lead/enums';
import { useIsUsedCar } from './useIsUsedCar';
import { useIsNewCar } from './useIsNewCar';
import { useIsLeaseCar } from './useIsLeaseCar';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';

export const useCarsPagesLeadLocation = () => {
  const router = useRouter();
  const { stepperActiveStep } = useAppSelector(
    (state: RootState) => state.carFlow,
  );

  const isUsedCar = useIsUsedCar();
  const isNewCar = useIsNewCar();
  const isLeaseCar = useIsLeaseCar();

  const isResultPage = useMemo(() => {
    return router.pathname.includes('[[...params]]');
  }, [router.pathname]);

  const isConfiguratorPage = useMemo(() => {
    return router.pathname.includes('flow/[carFlowId]');
  }, [router.pathname]);

  const leadLocationOption = useMemo(() => {
    if (isUsedCar) {
      return isResultPage
        ? ESourceEventOptions.USED_CAR_RESULT_PAGE
        : ESourceEventOptions.USED_CAR_PAGE;
    } else if (isLeaseCar) {
      if (isConfiguratorPage && stepperActiveStep === 4) {
        return ESourceEventOptions.LEASE_CAR_SUMMARY;
      } else if (isConfiguratorPage) {
        return ESourceEventOptions.LEASE_CAR_CONFIGURATOR;
      } else if (isResultPage) {
        return ESourceEventOptions.LEASE_CAR_RESULT_PAGE;
      } else {
        return ESourceEventOptions.LEASE_CAR_PAGE;
      }
    } else if (isNewCar) {
      if (isConfiguratorPage && stepperActiveStep === 4) {
        return ESourceEventOptions.NEW_CAR_SUMMARY;
      } else if (isConfiguratorPage) {
        return ESourceEventOptions.NEW_CAR_CONFIGURATOR;
      } else if (isResultPage) {
        return ESourceEventOptions.NEW_CAR_RESULT_PAGE;
      } else {
        return ESourceEventOptions.CAR_PAGE;
      }
    }
    return null;
  }, [
    isUsedCar,
    isNewCar,
    isLeaseCar,
    isResultPage,
    isConfiguratorPage,
    stepperActiveStep,
  ]);

  return {
    leadLocationOption,
    isResultPage,
  };
};
