import { COLORS } from '~/data/ui/colors';
import { SCROLLBAR, SCROLLBARMINI } from '~/data/ui/scrollbar';
import theme from '~/theme';

export const scrollBar = {
  '::-webkit-scrollbar': {
    width: SCROLLBAR.width,
    height: SCROLLBAR.width,
  },
  '::-webkit-scrollbar-track': {
    background: SCROLLBAR.track.background,
    borderRadius: SCROLLBAR.track.borderRadius,
  },
  '::-webkit-scrollbar-thumb': {
    background: SCROLLBAR.thumb.background,
    borderRadius: SCROLLBAR.thumb.borderRadius,
    '&:hover': {
      background: SCROLLBAR.thumb.hover_background,
    },
  },
};

export const scrollBarMini = {
  '::-webkit-scrollbar': {
    width: SCROLLBARMINI.width,
    height: SCROLLBARMINI.width,
  },
  '::-webkit-scrollbar-track': {
    background: SCROLLBARMINI.track.background,
    borderRadius: SCROLLBARMINI.track.borderRadius,
  },
  '::-webkit-scrollbar-thumb': {
    background: SCROLLBARMINI.thumb.background,
    borderRadius: SCROLLBARMINI.thumb.borderRadius,
    '&:hover': {
      background: SCROLLBARMINI.thumb.hover_background,
    },
  },
};

export const scrollBarMui = {
  '& ::-webkit-scrollbar': {
    width: SCROLLBAR.width,
    height: SCROLLBAR.width,
  },
  '& ::-webkit-scrollbar-track': {
    background: SCROLLBAR.track.background,
    borderRadius: SCROLLBAR.track.borderRadius,
  },
  '& ::-webkit-scrollbar-thumb': {
    background: SCROLLBAR.thumb.background,
    borderRadius: SCROLLBAR.thumb.borderRadius,
    '&:hover': {
      background: SCROLLBAR.thumb.hover_background,
    },
  },
};

export const ArrowsStyle = {
  '& .swiper-button-prev, .swiper-button-next': {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    boxSizing: 'border-box',
    gap: '10px',
    width: 35,
    height: 35,
    background: 'rgba(69, 68, 68, 0.5)',
    border: `1px solid ${COLORS.gray.light8}`,
    borderRadius: 234,
    color: 'white',
  },
  '& .swiper-button-next:after, .swiper-button-prev:after': {
    fontSize: 16,
  },
  '& .swiper-button-disabled': {
    display: 'none',
  },
  '& .swiper-button-next:hover, .swiper-button-prev:hover': {
    background: 'rgba(255, 254, 254, 0.5)',
    border: `1px solid ${COLORS.primary.main}`,
    color: COLORS.black.main,
  },
  '& .swiper-button-next:active, .swiper-button-prev:active': {
    background: COLORS.primary.main,
    border: `1px solid ${COLORS.primary.main}`,
    color: COLORS.black.main,
  },
  '& .swiper-pagination-bullets': {
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: '-100px',
    },
  },
};

export const MarkdownDefaultStyle = {
  ' & p,  & h3': {
    margin: '1em 0',
  },
  ' & h2': {
    margin: '.8em 0',
  },
};
