import { ImageMultiMediaDB } from '~/data/lead/marketing';
import { ImageDB } from '../car/cars';
import { ICard, ICardRes } from '../strapiComponent/cardType';
import { ELocation, ESize } from '../strapiComponent/general';
import { ITitleSection } from '../strapiComponent/titleSectionType';

export interface ICommunityPageRes {
  id: number;
  attributes: {
    foundationHero: string;
    galleryHero: IGalleryHeroRes;
    ourActivitiesHero: string;
  };
}
export interface IGalleryHeroRes {
  id: number;
  rank: number;
  titleSection: ITitleSection;
  carouselImages: ICarouselImageRes[];
}

export interface IGalleryHero {
  id: number;
  rank: number;
  titleSection: ITitleSection;
  carouselImages: ICarouselImage[];
}

export interface ICarouselImageRes {
  id: number;
  info: string;
  image: { data: ImageDB };
  icon: { data: ImageDB };
}
export interface ICarouselImage {
  id: number;
  info: string;
  image: string;
  icon: string;
}

export const initialGalleryHero: IGalleryHero = {
  id: 0,
  rank: 0,
  titleSection: {
    id: 0,
    title: '',
    description: '',
    textAlign: ELocation.Left,
    titleSize: ESize.small,
    descriptionSize: ESize.small,
    rank: 0,
  },
  carouselImages: [],
};

export interface IFreeSbeActivityRes {
  id: number | null;
  attributes: {
    name: string;
    rank: number;
    urlTitle: string;
    card: ICardRes;
    activityPage: IGalleryHeroActivityPageRes;
    logo: ImageMultiMediaDB;
  };
}
export interface IFreeSbeActivity {
  name: string;
  rank: number;
  urlTitle: string;
  card: ICard;
  activityPage: IGalleryHeroActivityPage;
  logo: string;
}

export interface IGalleryHeroActivityPageRes {
  id: number;
  title: string;
  descriptionMd: string;
  paragraph: string;
  images: {
    data: ImageDB[];
  };
}

export interface IGalleryHeroActivityPage {
  id: number;
  title: string;
  descriptionMd: string;
  paragraph: string;
  images: string[];
}
