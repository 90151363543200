import { COLORS } from '~/data/ui/colors';
import { CSSProperties } from 'react';
import theme from '~/theme';

const useStyles = () => ({
  boxStyle: {
    border: `1px solid ${COLORS.gray.light2}`,
    borderRadius: '8px',
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '24px',
  },
  specialPriceTagStyle: {
    position: 'absolute',
    left: '0',
  },

  inputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    margin: '16px 0',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  iconStyle: {
    position: 'absolute',
    left: '10px',
  },

  input: {
    marginTop: '10px',

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
});

export default useStyles;

export const root = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const offerTitleStyle = {
  marginTop: '24px',
  marginBottom: '8px',
  fontWeight: '600',
};

export const offerDescriptionStyle = {
  marginBottom: '24px',
  lineHight: '25px',
  color: `${COLORS.gray.dark}`,
  textAlign: 'center',
  maxWidth: '408px',
};

export const buttonCustomStyle: CSSProperties = {
  width: '100%',
  flex: 1,
};

export const formGroupStyle = {
  width: '90%',
};

export const checkBoxLabelStyle = {
  marginRight: '-10px',
  fontSize: 14,
  padding: '8px',
  color: `${COLORS.gray.dark}`,
};

export const checkBoxStyle = {
  marginRight: '-10px',
};
