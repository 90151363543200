export enum EReferralReason {
  //   SERVICE = 'SERVICE',
  NEW_CAR = 'NEW_CAR',
  USED_CAR = 'USED_CAR',
  LEASE = 'LEASE',
  TRADE = 'TRADE',
  FUNDING = 'FUNDING',
  CHARGING_SOLUTIONS = 'CHARGING_SOLUTIONS',
  OTHER = 'OTHER',
}

export enum EReferralReasonContactUsPage {
  //   SERVICE = 'SERVICE',
  NEW_CAR = 'NEW_CAR',
  USED_CAR = 'USED_CAR',
  LEASE = 'LEASE',
  TRADE = 'TRADE',
  CHARGING_SOLUTIONS = 'CHARGING_SOLUTIONS',
}

enum EReferralReasonLabels {
  //   SERVICE = 'קבלת שירות',
  NEW_CAR = 'קניית מכונית חדשה',
  USED_CAR = 'קניית מכונית יד שנייה',
  LEASE = 'קניית מכונית בליסינג',
  TRADE = 'מכירת רכב וטרייד אין',
  CHARGING_SOLUTIONS = 'פתרונות טעינה',
  OTHER = 'קבלת שירות או כל נושא אחר',
}

export const CONTACT_US_TEXT = {
  title: 'רוצה לדעת עוד?',
  whatsappLink: 'https://wa.me/972502807648?text=%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A9%D7%9E%D7%95%D7%A2%20%D7%A4%D7%A8%D7%98%D7%99%D7%9D%20%D7%9E%D7%A0%D7%A6%D7%99%D7%92%20freesbe',
  subtitle: 'פשוט ליצור איתנו קשר',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const interactivity: any = {
  mode: 'scroll',
  actions: [
    {
      visibility: [0, 1],
      type: 'seek',
      frames: [0, 160],
    },
  ],
};

export const getContactUsReferralReasons = () => {
  return Object.keys(EReferralReasonContactUsPage).map((reason) => ({
    value: reason,
    label: EReferralReasonLabels[reason as keyof typeof EReferralReasonLabels],
    subSubjects: [],
  }));
};
