import { ICarSafetyAndPollution } from '~/data/safetyAndPollution/safetyAndPollution.interfaces';
import { HttpClientService } from '~/services/HttpsClientService';
import { IGetSafetyAndPollutionProps } from '../thunks/safetyAndPollutionThunks';

class SafetyAndPollutionAdapter {
  static readonly endpoint = `api/car-safety-and-pollution?dmvModelName=`;

  // eslint-disable-next-line class-methods-use-this
  async getCarSafetyAndPollution({
    model,
    isCarZeroKm,
    isLeasing,
  }: IGetSafetyAndPollutionProps) {
    const res = await HttpClientService.get<ICarSafetyAndPollution>(
      `${SafetyAndPollutionAdapter.endpoint}${model}&isZeroKm=${Boolean(
        isCarZeroKm,
      )}&isLeasing=${Boolean(isLeasing)}`,
    );
    return res;
  }
}

const safetyAndPollutionAdapter = new SafetyAndPollutionAdapter();
export default safetyAndPollutionAdapter;
