export const PARAMS_CATEGORY = {
  PAGINATION: 'pagination',
  FILTERS: 'filters',
} as const;
export const PARAMS_OPERATORS = {
  OR: '[$or]',
  AND: '[$and]',
  IN: '[$in]',
  CONTAINS: '[$contains]',
} as const;

export const PARAMS_FILTERS = {
  OR: '$or',
  AND: '$and',
  EQUAL: '$eq',
  IN: '$in',
  GREATER_EQUAL: '$gte',
  LESS_EQUAL: '$lte',
  CONTAINS: '$contains',
} as const;

export const PARAMS_TYPE = {
  BRAND: '[dmvManufacturerName]',
  BRAND_DISPLAY: '[manufacturerDisplayName]',
  MODEL: '[dmvModelName]',
  MODEL_DISPLAY: '[modelNameDisplayName]',
  CATEGORY: '[categories]',
  FREESEBE_CATEGORY: '[freesbeCategories][name]',
  COLOR: '[allColors][title]',
  BUDGET_ONE_TIME_NEW_CAR: '[newCarPrice][price]',
  BUDGET_ONE_TIME_LEASE_PRICE_LIST: '[leaseCarPriceList][price]',
  BUDGET_MONTHLY_LOAN: '[newCarLoanPrice][price]',
  BUDGET_MONTHLY_LEASING: '[leaseCarPrice][price]',
  FILTERS: 'filters',
  FILTERS_AND: 'filters[$and]',
} as const;

export const FILTER_OPTION_TYPE_PARAMS = {
  BRAND_AND_MODEL: 'manufacture-with-models',
  CATEGORY: 'categories',
  COLORS: 'colors',
} as const;

export const ACCURATE_FLOW_FILTER_OPTION_PARAMS = {
  BRAND_AND_MODEL: 'manufacture-with-models',
  CATEGORY: 'categories',
} as const;

export const MAX_OPTION_TEXT_LENGTH = 10 as const;
export const MAX_OPTION_TEXT_ICON_LENGTH = 13 as const;
interface convertObjectToUrlParamsProps {
  category: string;
  type: string;
  value: number | string;
  filter?: string;
  operatorIndexArr?: OperatorIndex[];
  filterIndex?: OperatorIndex;
}
interface OperatorIndex {
  operator: string;
  index?: number;
}

export const convertObjectToUrlParams = ({
  category,
  type,
  value,
  filter,
  operatorIndexArr,
  filterIndex,
}: convertObjectToUrlParamsProps) => {
  const operatorsIndex = operatorIndexArr?.map(
    (operatorIndex) =>
      `${operatorIndex.operator ? operatorIndex.operator : ''}${
        operatorIndex.index || operatorIndex.index === 0
          ? `[${operatorIndex.index}]`
          : ''
      }`,
  );

  return `&${category}${operatorsIndex ? operatorsIndex.join('') : ''}${type}${
    filter
      ? `[${filter}]`
      : filterIndex
      ? `${filterIndex.operator}[${filterIndex.index}]`
      : ''
  }=${value}`;
};
