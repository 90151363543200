import { IColorsRes } from '~/data/types/colors/colors';
import { MetaType } from '~/data/types/paginationTypes';
import { HttpClientService } from '~/services/HttpsClientService';

class ColorsAdapter {
  static readonly endpoint = `/api/new-and-lease-cars-colors?pagination[pageSize]=500`;

  // eslint-disable-next-line class-methods-use-this
  async getAllColors() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: IColorsRes[];
    }>(`${ColorsAdapter.endpoint}`);

    return res;
  }
}

const colorsAdapter = new ColorsAdapter();
export default colorsAdapter;
