import React from 'react';
import { ImageFile } from '~/data/types/images';

const CycleEmptyIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <circle cx="10" cy="10" r="9.25" stroke="#595959" strokeWidth="1.5" />
    </Component>
  );
};

export default CycleEmptyIcon;
