/* eslint-disable class-methods-use-this */
import { HttpClientService } from '~/services/HttpsClientService';
import { IGetUsedCarDataRes } from './usedCarAdapter';

class CompareAdapter {
  static readonly endpointGetUsedCar = `/api/search-used-cars`;

  async getUsedCars(query: any): Promise<IGetUsedCarDataRes> {
    const res = await HttpClientService.post(
      CompareAdapter.endpointGetUsedCar,
      query,
    );

    return res.data as IGetUsedCarDataRes;
  }
}

const compareAdapter = new CompareAdapter();
export default compareAdapter;
