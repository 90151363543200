import React from 'react';
import { ImageFile } from '~/data/types/images';

const PhoneIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6097 10.3963C12.9339 13.7196 13.6881 9.87487 15.8046 11.9899C17.8451 14.0299 19.0179 14.4386 16.4326 17.0232C16.1088 17.2835 14.0513 20.4145 6.82042 13.1857C-0.411313 5.95594 2.71791 3.89631 2.97823 3.57256C5.56981 0.980809 5.97147 2.16042 8.01199 4.20038C10.1286 6.31635 6.28547 7.07295 9.6097 10.3963Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default PhoneIconIcon;
