import { createSlice } from '@reduxjs/toolkit';
import { IPrivateLeasingPageData } from '~/data/types/privateLeasingPageContent/privateLeasingPageContent.types';
import { getPrivateLeasingPageData } from '~/redux-toolkit/thunks/privateLeasingPageContentThunks';

interface PrivateLeasingPageState {
  privateLeasingPage: IPrivateLeasingPageData | null;
}

const initialState: PrivateLeasingPageState = {
  privateLeasingPage: null,
};

const privateLeasingPageSlice = createSlice({
  name: 'privateLeasingPageContentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrivateLeasingPageData.fulfilled, (state, action) => {
      if (action.payload) {
        state.privateLeasingPage = action.payload;
      }
    });
  },
});

export const {} = privateLeasingPageSlice.actions;

export default privateLeasingPageSlice.reducer;
