import React from 'react';
import { ImageFile } from '~/data/types/images';

const WarningIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M11.6631 1.55567L11.6632 1.55567C11.7607 1.38672 11.901 1.24643 12.0699 1.14889C12.2389 1.05135 12.4305 1 12.6256 1C12.8207 1 13.0123 1.05135 13.1813 1.14889C13.3502 1.24643 13.4905 1.38672 13.5881 1.55567L23.851 19.3322L23.8511 19.3324C23.9487 19.5014 24.0001 19.6931 24.0001 19.8882C24.0002 20.0834 23.9488 20.2751 23.8512 20.4441L24.7173 20.9441L23.8512 20.4441C23.7536 20.6132 23.6133 20.7535 23.4443 20.8511C23.2753 20.9487 23.0836 21 22.8884 21H22.8883H2.36182H2.36174C2.16658 21 1.97486 20.9487 1.80585 20.8511C1.63684 20.7535 1.49649 20.6132 1.39891 20.4441C1.30134 20.2751 1.24998 20.0834 1.25 19.8882C1.25002 19.6931 1.30141 19.5014 1.39902 19.3324L1.39908 19.3323L11.6631 1.55567Z"
        stroke="#F99F3C"
        strokeWidth="2"
      />
      <path
        d="M12.7587 6.53906H12.4909C11.8317 6.53906 11.2974 7.07343 11.2974 7.73262V13.4376C11.2974 14.0968 11.8317 14.6312 12.4909 14.6312H12.7587C13.4178 14.6312 13.9522 14.0968 13.9522 13.4376V7.73262C13.9522 7.07343 13.4178 6.53906 12.7587 6.53906Z"
        fill="#F99F3C"
      />
      <path
        d="M12.6248 19.1549C13.3579 19.1549 13.9522 18.5605 13.9522 17.8274C13.9522 17.0943 13.3579 16.5 12.6248 16.5C11.8917 16.5 11.2974 17.0943 11.2974 17.8274C11.2974 18.5605 11.8917 19.1549 12.6248 19.1549Z"
        fill="#F99F3C"
      />
    </Component>
  );
};

export default WarningIcon;
