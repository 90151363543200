export const USED_CAR_FILTER_OPTION_TYPE_PARAMS = {
  BRAND_AND_MODEL: 'used-car-manufacture-with-models',
  CATEGORY: 'used-car-categories',
  COLORS: 'used-car-colors',
  USED_CAR_FILTERS: 'used-car-filters-options',
} as const;

export const ACCURATE_FLOW_USED_CAR_FILTER_OPTION_PARAMS = {
  BRAND_AND_MODEL: 'used-car-manufacture-with-models',
  CATEGORY: 'used-car-categories',
  COLORS: 'used-car-colors',
} as const;
