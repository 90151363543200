import { HttpClientService } from '~/services/HttpsClientService';

export interface IMaskyoo {
  globalPhone: string;
  newCarPhone: string;
  usedCarPhone: string;
  privateLeasingPhone: string;
  maskyooPhones: IMaskyooPhones[];
}
export interface IMaskyooPhones {
  submedia: string;
  globalPhone: string;
  newCarPhone: string;
  usedCarPhone: string;
  privateLeasingPhone: string;
}
export type GetMaskyooResult = {
  data: { attributes: IMaskyoo };
};

class MaskyooAdapter {
  static readonly endpoint = {
    getMaskyoo: `/api/maskyoo?populate=*`,
  };

  // eslint-disable-next-line class-methods-use-this
  async getMaskyoo() {
    const res = await HttpClientService.get<GetMaskyooResult>(
      MaskyooAdapter.endpoint.getMaskyoo,
    );

    return res;
  }
}

const maskyooAdapter = new MaskyooAdapter();
export default maskyooAdapter;
