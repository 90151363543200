import { useMemo } from 'react';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';

export const useCurrentCar = () => {
  const { car } = useAppSelector((state: RootState) => state.car);

  return useMemo(() => {
    return car;
  }, [car]);
};
