import React from 'react';
import { ImageFile } from '~/data/types/images';

const CircleIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="36"
      viewBox="0 0 105 39"
    >
      <path
        d="M28.9375 35.308C45.9873 37.8408 103.91 29.7181 101.85 17.1553C99.5517 3.14256 68.9038 2.13062 53.7839 3.06329C38.6641 3.99596 5.42088 3.5284 2.81765 18.3782C2.13507 22.2719 12.2401 30.7298 41.5907 26.4789"
        stroke="#FFE500"
        strokeWidth="5.55844"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default CircleIcon;
