/* eslint-disable @typescript-eslint/no-explicit-any */
import RESOURCES from '~/resources';
import { IMetaData } from '../seoMetaText';

export interface IObj {
  [key: string]: string;
}
export interface IObjKeyNum {
  [key: string]: any;
}

export interface IObjMeta {
  [key: string]: IMetaData;
}

export const CAR_COLOR_TEXT: IObj = {
  Red: RESOURCES.COLOR_RED,
  Gray: RESOURCES.COLOR_GRAY,
  Blue: RESOURCES.COLOR_BLUE,
  Silver: RESOURCES.COLOR_SILVER,
  Orange: RESOURCES.COLOR_ORANGE,
  White: RESOURCES.COLOR_WHITE,
  Black: RESOURCES.COLOR_BLACK,
  Gold: RESOURCES.COLOR_GOLD,
  Brown: RESOURCES.COLOR_BROWN,
  Green: RESOURCES.COLOR_GREEN,
  Yellow: RESOURCES.COLOR_YELLOW,
  Pink: RESOURCES.COLOR_PINK,
  Purple: RESOURCES.COLOR_PURPLE,
};
export const CAR_COLOR_HEX = {
  [RESOURCES.COLOR_RED]: '#e33f39',
  [RESOURCES.COLOR_GRAY]: '#6e757b',
  [RESOURCES.COLOR_BLUE]: '#0d5ec7',
  [RESOURCES.COLOR_SILVER]: '#dbdae0',
  [RESOURCES.COLOR_ORANGE]: '#DD5226',
  [RESOURCES.COLOR_WHITE]: '#fbfcfb',
  [RESOURCES.COLOR_BLACK]: '#23221E',
  [RESOURCES.COLOR_GOLD]: '#d4af37',
  [RESOURCES.COLOR_BROWN]: '#695c4c',
  [RESOURCES.COLOR_GREEN]: '#458f39',
  [RESOURCES.COLOR_YELLOW]: '#ffcd43',
  [RESOURCES.COLOR_PINK]: '#FF647F',
  [RESOURCES.COLOR_PURPLE]: '#800080',
};
