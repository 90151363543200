import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '~/data/routes';

const useGetCarTypeParam = () => {
  const router = useRouter(); // Use useRouter from next/router
  const pathSegments = router.asPath.split('/'); // Split the path into segments
  const type = pathSegments[1] ? `/${pathSegments[1]}` : ''; // Get the second path segment if it exists

  const carTypeParam = useMemo(() => {
    switch (type) {
      case ROUTES.USED_CAR:
        return ROUTES.USED_CAR;
      case ROUTES.RESULT_PAGE:
        return ROUTES.RESULT_PAGE;
      case ROUTES.RESULT_PAGE_LEASE:
        return ROUTES.RESULT_PAGE_LEASE;
      default:
        return ROUTES.RESULT_PAGE;
    }
  }, [type]);

  return carTypeParam;
};

export default useGetCarTypeParam;
