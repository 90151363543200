import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { CarAcquireType } from '~/data/car/carSubscriptionData';
import {
  CarCardType,
  SimilarFamilyCar,
  SubscriptionPackagesType,
  mapCarFamilyToCarCardType,
} from '~/data/types/car/cars';
import {
  getFamilyCarById,
  getFamilyLeaseCarById,
  getSimilarFamilyCars,
  getNewAndLeaseCarByModelAndManufacturer,
  getPopularFamilyCars,
  getPopularLeaseCars,
} from '~/redux-toolkit/thunks/carThunks';
import cleanObject from '~/utils/cleanObject';

interface CarState {
  car: CarCardType | null;
  fetchingCar: boolean;
  isErrorFetchingCar: boolean;
  dealType: string | null;
  selectedPackage: SubscriptionPackagesType | null;
  showRecommendedCars: boolean;
  similarFamilyCars: SimilarFamilyCar[] | null;
  popularFamilyCars: CarCardType[];
  popularLeaseCars: CarCardType[];
}

const initialState: CarState = {
  car: null,
  fetchingCar: false,
  dealType: null,
  isErrorFetchingCar: false,
  selectedPackage: null,
  showRecommendedCars: false,
  similarFamilyCars: null,
  popularFamilyCars: [],
  popularLeaseCars: [],
};

const carSlice = createSlice({
  name: 'carSlice',
  initialState,
  reducers: {
    setCarType(state, action) {
      state.dealType = action.payload;
    },
    setSelectedPackage(state, action) {
      state.selectedPackage = action.payload;
    },
    setCar(state, action) {
      state.car = action.payload;
    },
    setShowRecommendedCars(state, action) {
      state.showRecommendedCars = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFamilyCarById.pending, (state) => {
      state.isErrorFetchingCar = false;
      state.fetchingCar = true;
      state.car = null;
    });
    builder.addCase(getFamilyCarById.rejected, (state) => {
      state.isErrorFetchingCar = true;
      state.fetchingCar = false;
    });
    builder.addCase(getFamilyCarById.fulfilled, (state, action) => {
      state.fetchingCar = false;
      const car = cleanObject(mapCarFamilyToCarCardType(action.payload));
      state.car = {
        ...car,
        carLabels: {
          data:
            [...(car.carLabels?.data || [])].sort((a, b) => a.id - b.id) || [],
          length: car.carLabels?.length || 0,
        },
      };
      if (
        car.acquireType === CarAcquireType.SUBSCRIPTION &&
        car.subscriptionPackages
      ) {
        state.selectedPackage = car?.subscriptionPackages[0];
      }
    });
    builder.addCase(getFamilyLeaseCarById.pending, (state) => {
      state.isErrorFetchingCar = false;
      state.fetchingCar = true;
      state.car = null;
    });
    builder.addCase(getFamilyLeaseCarById.rejected, (state) => {
      state.isErrorFetchingCar = true;
      state.fetchingCar = false;
    });
    builder.addCase(getFamilyLeaseCarById.fulfilled, (state, action) => {
      state.fetchingCar = false;

      const car = cleanObject(mapCarFamilyToCarCardType(action.payload));
      state.car = {
        ...car,
        carLabels: {
          data:
            [...(car.carLabels?.data || [])].sort((a, b) => a.id - b.id) || [],
          length: car.carLabels?.length || 0,
        },
      };
      if (
        car.acquireType === CarAcquireType.SUBSCRIPTION &&
        car.subscriptionPackages
      ) {
        state.selectedPackage = car?.subscriptionPackages[0];
      }
    });
    builder.addCase(getPopularFamilyCars.fulfilled, (state, action) => {
      state.popularFamilyCars = action.payload;
    });
    builder.addCase(getPopularLeaseCars.fulfilled, (state, action) => {
      state.popularLeaseCars = action.payload;
    });
    builder.addCase(getSimilarFamilyCars.fulfilled, (state, action) => {
      let similarFamilyCars = null;
      if (action.payload) {
        similarFamilyCars = action.payload.map((car) => {
          return {
            carZoneModelNameEn: car?.attributes?.carZoneModelNameEn,
            newCarMinPrice: car?.attributes?.newCarMinPrice,
            newCarLoanMinPrice: car?.attributes?.newCarLoanMinPrice,
            carBanner: car?.attributes?.carBanner?.data?.attributes?.url || '',
            year: car?.attributes?.year,
            id: car?.id,
            carZoneMakeNameEn: car?.attributes?.carZoneMakeNameEn,
            familyCarZoneId: car?.attributes?.familyCarZoneId,
            modelNameDisplayName: car?.attributes?.modelNameDisplayName,
          };
        });
      }

      state.similarFamilyCars = similarFamilyCars;
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { car } = action.payload;

      return {
        ...state,
        ...car,
      };
    });

    builder.addCase(
      getNewAndLeaseCarByModelAndManufacturer.fulfilled,
      (state, action) => {
        state.fetchingCar = false;
        if (state.car) {
          state.car = {
            ...state.car,
            warranties: action.payload ?? [],
          };
        }
      },
    );
  },
});

export const {
  setCarType,
  setSelectedPackage,
  setCar,
  setShowRecommendedCars,
} = carSlice.actions;

export default carSlice.reducer;
