export const calculateTimeLeft = (
  endDate: Date,
  maxHoursForDays: number = 72, // Default threshold to 72 hours
): { days?: string; hours: string; minutes: string; seconds: string } => {
  const now = new Date();

  const difference = Math.abs(endDate.getTime() - now.getTime());

  const totalHours = Math.floor(difference / (1000 * 60 * 60));
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;

  const minutes = Math.floor((difference / (1000 * 60)) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  if (totalHours > maxHoursForDays) {
    return {
      days: String(days),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  } else {
    return {
      hours: String(totalHours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  }
};
