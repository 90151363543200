import ApprovalQueryStep from '~/components/ApprovalFlowPopup/ApprovalQueryStep';
import CheckResultStep from '~/components/ApprovalFlowPopup/CheckResultStep';
import ApprovalLoader from '~/components/ApprovalFlowPopup/ApprovalLoader';
import ContactUsStep from '~/components/ApprovalFlowPopup/ContactUsStep';
import ContactUsStepDenied from '~/components/ApprovalFlowPopup/ContactUsStepDenied';
import GetApprovalCodeStep from '~/components/ApprovalFlowPopup/GetApprovalCodeStep';

import checkResultStepImage from '~/assets/images/checkResultStep.svg';
import ApprovalQueryImage from '~/assets/images/ApprovalFlowImage.svg';
import getYourCode from '~/assets/images/getYourCodeImage.svg';

type ApprovalFlowType = {
  component: JSX.Element;
  imgUrl?: string | React.ElementType<any> | undefined | { src: string };
}[];

export const APPROVAL_FLOW_STEPS: ApprovalFlowType = [
  { component: <ApprovalQueryStep />, imgUrl: ApprovalQueryImage },
  { component: <ApprovalLoader /> },
  { component: <CheckResultStep />, imgUrl: checkResultStepImage },
  { component: <ContactUsStep />, imgUrl: checkResultStepImage },
  { component: <GetApprovalCodeStep />, imgUrl: getYourCode },
  { component: <ContactUsStepDenied />, imgUrl: checkResultStepImage },
];

export const DENIED_STEP_INDEX = APPROVAL_FLOW_STEPS.length - 1;
