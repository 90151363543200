import { createSlice } from '@reduxjs/toolkit';
import { IColor, IColorDictionary } from '~/data/types/colors/colors';
import { getAllColors } from '~/redux-toolkit/thunks/colorsThunks';
import { IColorsRes } from '~/data/types/colors/colors';
import { HYDRATE } from 'next-redux-wrapper';

interface ColorsState {
  colors: IColorDictionary;
}

const initialState: ColorsState = {
  colors: {},
};

const colorsSlice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    setColors: (state, action) => {
      state.colors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllColors.fulfilled, (state, action) => {
      action.payload.forEach((colorRes: IColorsRes) => {
        const { attributes } = colorRes;
        const color = {
          title: attributes.colorTitle,
          colorHex: attributes.colorHex,
          colorHexRoof: attributes.colorHexRoof,
          code: attributes.dmvColorCode,
        } as IColor;
        state.colors[color.title] = color;
      });
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { colors } = action.payload;

      return {
        ...state,
        ...colors,
      };
    });
  },
});

export const { setColors } = colorsSlice.actions;

export default colorsSlice.reducer;
