import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import RESOURCES from '~/resources';
import useStyles from './useStyles';

export interface ITimer {
  hours: string;
  minutes: string;
  seconds: string;
  days?: string;
}

const TimeBox: FC<{ digit: string }> = memo(({ digit }) => {
  const classes = useStyles();

  return (
    <Box sx={classes.timeBox}>
      <Typography sx={classes.digitText}>{digit}</Typography>
    </Box>
  );
});

const Timer: FC<ITimer> = ({ hours, minutes, seconds, days }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box sx={classes.timerContainer}>
        {/* Seconds */}
        {!days && (
          <>
            <Box sx={classes.timeSection}>
              <Box sx={classes.digitContainer}>
                <TimeBox digit={seconds[1]} />
                <TimeBox digit={seconds[0]} />
              </Box>
              <Typography sx={classes.labelText}>{RESOURCES.SEC}</Typography>
            </Box>

            <Box sx={classes.separator} />
          </>
        )}

        {/* Minutes */}
        <Box sx={classes.timeSection}>
          <Box sx={classes.digitContainer}>
            <TimeBox digit={minutes[1]} />
            <TimeBox digit={minutes[0]} />
          </Box>
          <Typography sx={classes.labelText}>{RESOURCES.MINUTES}</Typography>
        </Box>

        <Box sx={classes.separator} />

        {/* Hours */}
        <Box sx={classes.timeSection}>
          <Box sx={classes.digitContainer}>
            <TimeBox digit={hours[1]} />
            <TimeBox digit={hours[0]} />
          </Box>
          <Typography sx={classes.labelText}>{RESOURCES.HOURS}</Typography>
        </Box>

        {/* Days */}
        {days && (
          <>
            <Box sx={classes.separator} />
            <Box sx={classes.timeSection}>
              <Box
                sx={{
                  ...classes.digitContainer,
                  ...(days && !days[1] && classes.digitContainerRevert),
                }}
              >
                <TimeBox digit={days?.[1] ?? '0'} />
                <TimeBox digit={days?.[0] ?? '0'} />
              </Box>
              <Typography sx={classes.labelText}>{RESOURCES.DAYS}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Timer;
