import { ROUTES } from '~/data/routes';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

export const useIsNewCar = () => {
  const router = useRouter();

  return useMemo(
    () =>
      router.pathname.includes(ROUTES.CAR_PAGE) ||
      router.pathname.includes(ROUTES.RESULT_PAGE),

    [router.pathname],
  );
};
