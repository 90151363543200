import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SxProps,
} from '@mui/material';
import { FC, useState } from 'react';
import {
  checkboxStyle,
  formControlStyle,
  formLabelStyle,
  radioGroupStyle,
  radioButtonContainer,
} from './useStyles';
import CycleEmpty from '~/assets/icons/jsx/cycleEmpty';
import CheckedIcon from '~/assets/icons/jsx/CheckedIcon';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';

interface RadioButtonProps {
  formLabel: string;
  labels: { label: string; value: string }[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getCustomLabelContainerStyle?: (
    isSelected: boolean,
  ) => React.CSSProperties | SxProps;
  customContainerStyles?: React.CSSProperties | SxProps;
  isDisabled?: boolean;
  multiple?: boolean;
}
const RadioButton: FC<RadioButtonProps> = ({
  formLabel,
  labels,
  handleChange,
  getCustomLabelContainerStyle,
  customContainerStyles,
  isDisabled,
  multiple = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const { selectedBrands, selectedModels } = useAppSelector(
    (state: RootState) => state.searchFilters.accurateSearch,
  );

  const wrapHandleChange = (value: string) => {
    const e = {
      target: {
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleChange(e);
  };

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedValue(value);
    handleChange(e);
  };

  return (
    <Box sx={radioButtonContainer}>
      <FormLabel sx={formLabelStyle}>{formLabel}</FormLabel>
      <RadioGroup
        onChange={multiple ? () => {} : onRadioChange}
        sx={customContainerStyles}
      >
        {labels.map((item) => {
          const isSelected = multiple
            ? (() => {
                const [brand, model] = item.value
                  .split(',')
                  .map((s) => s.trim());
                return (
                  selectedBrands.includes(brand) &&
                  selectedModels.includes(model)
                );
              })()
            : selectedValue === item.value;

          const containerStyle = getCustomLabelContainerStyle 
            ? getCustomLabelContainerStyle(isSelected && !isDisabled) 
            : {};

          return (
            <div key={item.label}>
              <FormControlLabel
                sx={{ ...formControlStyle, ...containerStyle }}
                value={item.value}
                aria-label={`${formLabel} ${item.label}`}
                control={
                  multiple ? (
                    <Checkbox
                      onChange={() => {
                        wrapHandleChange(item.value);
                      }}
                      icon={<CycleEmpty style={radioGroupStyle} />}
                      checkedIcon={<CheckedIcon style={radioGroupStyle} />}
                    />
                  ) : (
                    <Radio
                      icon={<CycleEmpty style={radioGroupStyle} />}
                      checkedIcon={<CheckedIcon style={radioGroupStyle} />}
                    />
                  )
                }
                disabled={isDisabled}
                style={checkboxStyle}
                label={item.label}
              />
            </div>
          );
        })}
      </RadioGroup>
    </Box>
  );
};

export default RadioButton;
