import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { ROUTES } from '~/data/routes';

export function useIsUsedCar() {
  const router = useRouter();
  const isUsedCar = useMemo(
    () => router.pathname.includes(ROUTES.USED_CAR),
    [router.pathname],
  );
  return isUsedCar;
}
