import { createAsyncThunk } from '@reduxjs/toolkit';
import { IColorsRes } from '~/data/types/colors/colors';
import colorsAdapter from '~/redux-toolkit/adapters/colorsAdapter';

export const getAllColors = createAsyncThunk(
  'getAllColors',
  async (): Promise<IColorsRes[]> => {
    try {
      const response = await colorsAdapter.getAllColors();
      const { data } = response;

      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
