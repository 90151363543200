/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import privateLeasingPageContentAdapter from '~/redux-toolkit/adapters/privateLeasingPageContentAdapter';
import { IPrivateLeasingPageData } from '~/data/types/privateLeasingPageContent/privateLeasingPageContent.types';

export const getPrivateLeasingPageData = createAsyncThunk(
  '/api/private-leasing-page/content?populate=*',
  async (): Promise<IPrivateLeasingPageData | null> => {
    try {
      const response =
        await privateLeasingPageContentAdapter.getPrivateLeasingPageContent();
      const { data } = response;
      return data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);
