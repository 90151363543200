import { HttpClientService } from '~/services/HttpsClientService';
import { AxiosResponse } from 'axios';

class TooltipAdapter {
  static readonly endpoint = `/api/tips-and-help-drawers?populate=transactionTypes.transactionTypeTips.tipIcon`;

  // eslint-disable-next-line class-methods-use-this
  async getTooltipContent(): Promise<AxiosResponse> {
    const res = await HttpClientService.get(`${TooltipAdapter.endpoint}`);

    return res;
  }
}

const tooltipAdapter = new TooltipAdapter();
export default tooltipAdapter;
