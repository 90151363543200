/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */

import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { SESSION_STORAGE_KEY } from '~/utils/utmStorage';

// eslint-disable-next-line no-unused-vars, no-shadow
export enum EUtmParams {
  CAMPAIGN = 'utm_campaign',
  MEDIUM = 'utm_medium',
  SOURCE = 'utm_source',
  CONTENT = 'utm_content',
  TERM = 'utm_term',
  SUB_MEDIA = 'submedia',
}

type EUtm = {
  campaign?: string | undefined;
  medium?: string | undefined;
  source?: string | undefined;
  content?: string | undefined;
  term?: string | undefined;
  subMedia?: string | undefined;
};
export const useGetUtmToLead = () => {
  const router = useRouter();
  const queryParameters = new URLSearchParams(router.asPath.split('?')[1]);
  const campaign = queryParameters.get(EUtmParams.CAMPAIGN);
  const medium = queryParameters.get(EUtmParams.MEDIUM);
  const source = queryParameters.get(EUtmParams.SOURCE);
  const content = queryParameters.get(EUtmParams.CONTENT);
  const term = queryParameters.get(EUtmParams.TERM);
  const subMedia = queryParameters.get(EUtmParams.SUB_MEDIA);

  const utmData = useMemo(() => {
    const storedUtm =
      (typeof sessionStorage !== 'undefined' &&
        sessionStorage?.getItem(SESSION_STORAGE_KEY)) ||
      '{}';
    const storedUtmData: EUtm = storedUtm ? JSON.parse(storedUtm) : {};

    const utm: EUtm = {
      campaign: campaign || storedUtmData.campaign || '',
      medium: medium || storedUtmData.medium || '',
      source: source || storedUtmData.source || '',
      content: content || storedUtmData.content || '',
      term: term || storedUtmData.term || '',
      subMedia: subMedia || storedUtmData.subMedia || '',
    };

    return utm;
  }, [campaign, medium, source, content, term, subMedia]);

  return utmData;
};
