import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  HolidaysDatesDataType,
  RentalLocationsDataType,
} from '~/data/types/rentalLocationsType';
import rentalBranchesAdapter from '~/redux-toolkit/adapters/rentalBranchesAdapter';

export const getRentalBranches = createAsyncThunk(
  '/rental-locations',
  async (): Promise<RentalLocationsDataType[]> => {
    try {
      const locations = await rentalBranchesAdapter.getRentalBranches();
      return locations;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getHolidaysDates = createAsyncThunk(
  '/holidays-dates',
  async (): Promise<HolidaysDatesDataType[]> => {
    try {
      const dates = await rentalBranchesAdapter.getHolidaysDates();
      return dates;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
