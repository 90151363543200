import { createSlice } from '@reduxjs/toolkit';
import { CarCardType, FamilyCar } from '~/data/types/car/cars';
import { mappedCarsCards } from '~/data/resultPage';
import {
  getWishlistCarsDetails,
  getWishlistFamilyCarsPromoDetails,
  getWishlistLeaseCarsDetails,
  getWishlistUsedCarsDetails,
} from '~/redux-toolkit/thunks/wishlistThunk';
import {
  initiateWishlistIds,
  initiateWishlistLeaseIds,
  initiateWishlistUsedCarIds,
} from '~/utils/WishlistPage';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { setCookie } from '~/utils/cookieStorageHandlers';

export interface WishlistState {
  ids: number[];
  leaseIds: number[];
  usedCarIds: number[];
  usedCars: UsedCarType[];
  cars: CarCardType[];
  leaseCars: CarCardType[];
}

export const localStorageNewCar = 'WISHLIST_NEW_CAR_ITEMS';
export const localStorageFamilyCarPromo = 'WISHLIST_FAMILY_CAR_PROMO_ITEMS';
export const localStorageLeaseCar = 'WISHLIST_LEASE_CAR_ITEMS';
export const localStorageUsedCar = 'WISHLIST_USED_CAR_ITEMS';

const initialState: WishlistState = {
  ids: initiateWishlistIds(),
  leaseIds: initiateWishlistLeaseIds(),
  usedCarIds: initiateWishlistUsedCarIds(),
  usedCars: [],
  cars: [],
  leaseCars: [],
};

const wishlistSlice = createSlice({
  name: 'wishlistData',
  initialState,
  reducers: {
    setIds(state, action) {
      const newId = Number(action.payload.newId);

      const isIdSelected = state.ids.includes(newId);
      if (isIdSelected) {
        state.cars = state.cars.filter((car) => car.id !== newId);
      }
      const newIDsSet = isIdSelected
        ? state.ids.filter((id) => id !== newId)
        : [...state.ids, newId];
      state.ids = newIDsSet;

      const storageJSON = JSON.stringify({ ids: newIDsSet });
      setCookie(localStorageNewCar, storageJSON, 100);
    },
    setFamilyCarPromoIds(state, action) {
      const newId = Number(action.payload.newId);

      const isIdSelected = state.ids.includes(newId);
      if (isIdSelected) {
        state.cars = state.cars.filter((car) => car.id !== newId);
      }
      const newIDsSet = isIdSelected
        ? state.ids.filter((id) => id !== newId)
        : [...state.ids, newId];
      state.ids = newIDsSet;

      const storageJSON = JSON.stringify({ ids: newIDsSet });
      setCookie(localStorageFamilyCarPromo, storageJSON, 2);
    },
    setLeaseIds(state, action) {
      const newId = Number(action.payload.newId);

      const isIdSelected = state.leaseIds.includes(newId);
      if (isIdSelected) {
        state.leaseCars = state.leaseCars.filter((car) => car.id !== newId);
      }
      const newIDsSet = isIdSelected
        ? state.leaseIds.filter((id) => id !== newId)
        : [...state.leaseIds, newId];
      state.leaseIds = newIDsSet;

      const storageJSON = JSON.stringify({ leaseIds: newIDsSet });
      setCookie(localStorageLeaseCar, storageJSON, 100);
    },
    setUsedCarIds(state, action) {
      const newId = Number(action.payload.newId);
      const isIdSelected = state.usedCarIds.includes(newId);
      if (isIdSelected) {
        state.usedCars = state.usedCars.filter((car) => car.id !== newId);
      }
      const newIDsSet = isIdSelected
        ? state.usedCarIds.filter((id) => id !== newId)
        : [...state.usedCarIds, newId];
      state.usedCarIds = newIDsSet;

      const storageJSON = JSON.stringify({ usedCarIds: newIDsSet });
      setCookie(localStorageUsedCar, storageJSON, 100);
    },
    setSimpleWishId(state, action) {
      try {
        if (action.payload.data?.length !== state.ids.length) {
          const newIDsSet = action.payload.data?.map(
            (car: FamilyCar) => car.id,
          );
          state.ids = newIDsSet;
          const storageJSON = JSON.stringify({ ids: newIDsSet });
          setCookie(localStorageNewCar, storageJSON, 100);
        }
      } catch (e) {
        console.log('Error', e);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWishlistCarsDetails.fulfilled, (state, action) => {
      try {
        if (action.payload.data?.length !== state.ids.length) {
          const newIDsSet = action.payload.data?.map(
            (car: FamilyCar) => car.id,
          );
          state.ids = newIDsSet;
          const storageJSON = JSON.stringify({ ids: newIDsSet });
          setCookie(localStorageNewCar, storageJSON, 100);
        }

        state.cars = mappedCarsCards(action.payload.data, {
          priceRange: { minPrice: null, maxPrice: null },
          paymentMethod: 'null',
        });
      } catch (e) {
        console.log('Error', e);
      }
    });
    builder.addCase(
      getWishlistFamilyCarsPromoDetails.fulfilled,
      (state, action) => {
        try {
          if (action.payload.data?.length !== state.ids.length) {
            const newIDsSet = action.payload.data?.map(
              (car: FamilyCar) => car.id,
            );
            state.ids = newIDsSet;
            const storageJSON = JSON.stringify({ ids: newIDsSet });
            setCookie(localStorageNewCar, storageJSON, 100);
          }

          state.cars = mappedCarsCards(action.payload.data, {
            priceRange: { minPrice: null, maxPrice: null },
            paymentMethod: 'null',
          });
        } catch (e) {
          console.log('Error', e);
        }
      },
    );

    builder.addCase(getWishlistLeaseCarsDetails.fulfilled, (state, action) => {
      try {
        if (action.payload.data?.length !== state.leaseIds.length) {
          const newIDsSet = action.payload.data?.map(
            (car: FamilyCar) => car.id,
          );
          state.leaseIds = newIDsSet;
          const storageJSON = JSON.stringify({ leaseIds: newIDsSet });
          setCookie(localStorageLeaseCar, storageJSON, 100);
        }

        state.leaseCars = mappedCarsCards(action.payload.data, {
          priceRange: { minPrice: null, maxPrice: null },
          paymentMethod: 'null',
        });
      } catch (e) {
        console.log('Error', e);
      }
    });

    builder.addCase(getWishlistUsedCarsDetails.fulfilled, (state, action) => {
      try {
        if (action.payload.data?.length !== state.usedCarIds.length) {
          const newIDsSet = action.payload.data?.map(
            (car: UsedCarType) => car.id,
          );
          state.usedCarIds = newIDsSet;
          const storageJSON = JSON.stringify({ usedCarIds: newIDsSet });
          setCookie(localStorageUsedCar, storageJSON, 100);
        }

        state.usedCars =
          action.payload.data?.map(
            (item: { id: number; attributes: UsedCarType }) => {
              const { attributes } = item;
              const { carLabels } = attributes;

              return {
                ...attributes,
                ...{ carLabels: (carLabels as any).data },
                id: item.id,
              };
            },
          ) || [];
      } catch (e) {
        console.log('Error', e);
      }
    });
  },
});

export const { setIds, setUsedCarIds, setSimpleWishId, setLeaseIds } =
  wishlistSlice.actions;

export default wishlistSlice.reducer;
