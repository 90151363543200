import { createSlice } from '@reduxjs/toolkit';
import { MarketingLeadDetailsDataType } from '~/data/lead/marketing';

import { getMarketingData } from '~/redux-toolkit/thunks/marketingThunks';
import { CampaignNumber } from '~/services/LeadService/enums';

interface MarketingPopupState {
  marketingLead: {
    id?: number;
    campaignNumber: CampaignNumber;
    lineOfBusiness: string;
    note: string;
    subMedia: string;
    title: string;
    image: string | { src: string };
    images?: string[];
    page: string;
    dropdownTitle?: string;
    publishDropdown?: boolean;
    dropdownOption?: {
      id?: number;
      campaignNumber: CampaignNumber;
      lineOfBusiness: string;
      note: string;
      subMedia: string;
      title: string;
    }[];
  };
  isMarketingPopupOpen: boolean;
}

const initialState: MarketingPopupState = {
  isMarketingPopupOpen: false,
  marketingLead: {
    campaignNumber: '',
    lineOfBusiness: '',
    note: '',
    subMedia: '',
    title: '',
    image: '',
    images: [],
    page: '',
  },
};

const marketingPopupSlice = createSlice({
  name: 'MarketingPopupState',
  initialState,
  reducers: {
    setOpenMarketingPopup(state, action) {
      state.isMarketingPopupOpen = action.payload;
    },
    initState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketingData.rejected, (state) => {
      state.isMarketingPopupOpen = false;
    });
    builder.addCase(getMarketingData.fulfilled, (state, action) => {
      try {
        const marketingData = action.payload as MarketingLeadDetailsDataType[];
        // fetch only the first marketing lead
        const firstMarketingData = marketingData[0];

        if (firstMarketingData) {
          const { attributes } = firstMarketingData;
          state.marketingLead = {
            ...attributes,
            image: attributes.image.data.attributes.url,
            id: firstMarketingData.id,
            images: attributes.images?.data.map(
              (image) => image.attributes.url,
            ),
            dropdownOption: attributes.dropdownOptions,
            dropdownTitle: attributes.dropdownTitle,
            publishDropdown: attributes.publishDropdown,
          };
          state.isMarketingPopupOpen = true;
        }
      } catch (e) {
        console.log('error', e);
      }
    });
  },
});

export const { setOpenMarketingPopup, initState } = marketingPopupSlice.actions;

export default marketingPopupSlice.reducer;
