/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MarketingLeadDetailsDataType } from '~/data/lead/marketing';
import marketingAdapter from '~/redux-toolkit/adapters/marketingAdapter';

export const getMarketingData = createAsyncThunk(
  'getMarketingData',
  async (): Promise<MarketingLeadDetailsDataType[]> => {
    try {
      const response = await marketingAdapter.getMarketingData();

      return response.data.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);
