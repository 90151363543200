import { CSSProperties, ReactElement, useCallback, useState } from 'react';
import { Box, IconButton, SxProps } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import TooltipInfoIcon from '~/assets/icons/jsx/tooltipInfoIcon';
import { KEYBOARD_TYPES } from '~/data/constants';
import { useStyles, iconStyle, iconButtonStyles } from './useStyles';
import { useIsMobile } from '~/hooks/useIsMobile';
import useClickOutside from '~/hooks/useClickOutside';

interface ITooltipInfo {
  infoText: string | undefined;
  customStyle?: CSSProperties;
  buttonStyle?: SxProps;
  ariaLabel?: string;
  placement?: TooltipProps['placement'];
  body?: ReactElement<any, any>;
}

export const TooltipInfo = ({
  infoText,
  customStyle,
  buttonStyle,
  ariaLabel,
  placement = 'top-end',
  body,
}: ITooltipInfo) => {
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const classes = useStyles();

  const handleOnClick = useCallback(
    (event: any) => {
      event.stopPropagation();
      setOpen(!open);
    },
    [open],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYBOARD_TYPES.ENTER) {
      event.preventDefault();
      setOpen(!open);
    }
  };
  const tooltipRef = useClickOutside(() => {
    if (open) {
      setOpen(false);
    }
  });

  return (
    <>
      {infoText && (
        <Tooltip
          title={infoText}
          arrow
          placement={placement}
          disableTouchListener
          open={open}
          role="tooltip"
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onBlur={() => {
            setOpen(false);
          }}
          onClick={handleOnClick}
          disableFocusListener
          aria-label={`עזרה אודות`}
          onKeyDown={handleKeyDown}
          aria-describedby={'tooltip-info'}
          componentsProps={{
            tooltip: {
              sx: classes.root,
            },
            popper: {
              modifiers: [
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    fallbackPlacements: ['top', 'bottom'],
                  },
                },

                {
                  name: 'offset',
                  options: {
                    offset: isMobile ? [0, -15] : [0, -5],
                  },
                },
              ],
            },
          }}
        >
          {body ? (
            <div ref={tooltipRef}>{body}</div>
          ) : (
            <div ref={tooltipRef}>
              <IconButton
                sx={{ ...iconButtonStyles, ...buttonStyle }}
                role="button"
              >
                {open && (
                  <Box
                    aria-live="polite"
                    aria-atomic="true"
                    role="status"
                    id="tooltip-info"
                    sx={classes.hiddenTooltipLabel}
                    aria-label={`${ariaLabel}`}
                  >
                    {infoText}
                  </Box>
                )}
                <TooltipInfoIcon Component={iconStyle} style={customStyle} />
              </IconButton>
            </div>
          )}
        </Tooltip>
      )}
    </>
  );
};
