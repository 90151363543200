// utils/utmStorage.ts
export const SESSION_STORAGE_KEY = 'utmParams';

export const saveUtmParamsToSessionStorage = (utmParams: {
  campaign?: string | null;
  medium?: string | null;
  source?: string | null;
  content?: string | null;
  term?: string | null;
  subMedia?: string | null;
}) => {
  if (Object.values(utmParams).some((value) => value !== null)) {
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(utmParams));
    }
  }
};

export const filterArrayByUtmCampaign = (
  parent: any,
  fieldKey: string,
  utmCampaign: string,
) => {
  const items = parent?.[fieldKey];

  if (!utmCampaign || !Array.isArray(items) || items.length === 0) {
    return null;
  }

  const filteredItems = items.filter(
    (item: any) => item.utmCampaign === utmCampaign,
  );

  return filteredItems.length > 0 ? filteredItems : null;
};
