export const ROUTES = {
  HOMEPAGE: '/',
  LISTING: '/listing',
  RESULT_PAGE: '/new-car-for-sale/listings',
  RESULT_PAGE_LEASE: '/private-leasing/listings',
  RESULT_PAGE_USED_CAR: '/used-car-for-sale/listings',
  RESULT_PAGE_USED_CAR_V2: '/used-car-for-sale/list',
  SERVICE_AGREEMENT: '/service-agreement',
  FINANCE: '/finance',
  PRIVATE_LEASING: '/private-leasing',
  LEASING: '/business-leasing',
  CAR_PAGE: '/new-car-for-sale',
  SAFETY_AND_POLLUTION_PAGE: '/safety-pollution',
  WISHLIST_PAGE: '/wishlist',
  ACCESSIBILITY: '/accessibility',
  INSURANCE_PAGE: '/insurance-page',
  TERMS_OF_USE: '/terms-of-use',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  EVEASY_PACKAGES: '/eveasy-packages',
  PRIVACY: '/privacy',
  REFUNDS: '/refunds',
  REDIRECT: '*',
  ABOUT_PAGE: '/about',
  BLOG_PAGE: '/blog',
  CHARGING_SOLUTIONS: '/charging-solutions',
  USED_CAR: '/used-car-for-sale',
  SEARCH_FLOW: '/search-flow',
  LOCATION_PAGE: '/our-locations',
  BLOGS_MAIN: '/blog',
  CONTACT_US_PAGE: '/contact-us',
  VOLUNTEER_PAGE: '/together',
  COMMUNITY_PAGE: '/community',
  BUSINESS_LEASING_PAGE: '/business-leasing',
  CAR_FLEET: '/car-fleet',
  CAR_FLEET_RESULT: '/car-fleet/listings',
  COMPARE: '/used-car-for-sale/compare',
} as const;

export const PAGES = {
  HOMEPAGE: 'HOMEPAGE',
} as const;

export const DOC_TITLE_ROUTES = {
  START_NAME: 'freesbe - ',
  ALL: 'freesbe',
  CONST: {
    '/new-car-for-sale': 'freesbe - מבחר המכוניות שלנו ',
    '/finance': 'freesbe - מימון  למכונית ',
    '/wishlist': 'freesbe - מועדפים',
    '/insurance-page': 'freesbe - ביטוח למכונית',
    '/about': 'freesbe - אודות',
    '/': 'freesbe - עמוד הבית',
  },
};
