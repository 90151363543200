import { HttpClientService } from '~/services/HttpsClientService';
import { GetPrimeRateResult } from '~/data/types/approvalFlow/approvalFlowType';
import { IFoundingPage } from '~/data/types/foundingPage/foundingPage';

class FundingAdapter {
  static readonly endpoint = {
    getPrimeRate: `/api/finance-page?populate=*`,
    getFoundingPage: `/api/founding-page/content?populate=*`,
  };

  async getFoundingPage() {
    const res = await HttpClientService.get<IFoundingPage>(
      FundingAdapter.endpoint.getFoundingPage,
    );
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPrimeRate() {
    const res = await HttpClientService.get<GetPrimeRateResult>(
      FundingAdapter.endpoint.getPrimeRate,
    );

    return res;
  }
}

const fundingAdapter = new FundingAdapter();
export default fundingAdapter;
