import { createAsyncThunk } from '@reduxjs/toolkit';
import { BlogContentType } from '~/data/types/blog/blogContentType';
import blogAdapter from '~/redux-toolkit/adapters/blogAdapter';

export const getBlogContent = createAsyncThunk(
  '/api/blogs?populate=deep,4?sort=rank:asc',
  async (): Promise<BlogContentType[]> => {
    try {
      const response = await blogAdapter.getBlogContent();
      const { data } = response;
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getBlogById = createAsyncThunk(
  '/api/blogs/:id',
  async (id: string): Promise<BlogContentType> => {
    try {
      const response = await blogAdapter.getBlogById(id);
      const { data } = response;

      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);
export const getBlogByUrlName = createAsyncThunk(
  '/api/blogs/',
  async (urlName: string): Promise<BlogContentType> => {
    try {
      const response = await blogAdapter.getBlogByUrlName(urlName);
      const { data } = response;

      return data?.data[0];
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);
