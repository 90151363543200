import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getCarColorsAndImages,
  getFamilyCarById,
} from '~/redux-toolkit/thunks/carThunks';
import {
  getCarTechSpec,
  getLeaseCarTechSpec,
} from '~/redux-toolkit/thunks/carTechSpecThunks';
import { getSafetyAndPollution } from '~/redux-toolkit/thunks/safetyAndPollutionThunks';
import {
  formatAmericanCarPollution,
  formatRegularCarPollution,
} from '~/data/pollutionAndSafetyTable';
import { CarTechSpecData } from '~/data/car/carTechSpec';
import { ICarSafetyAndPollution } from '~/data/safetyAndPollution/safetyAndPollution.interfaces';
import { AnyObject, flattenArray } from '~/utils/flattenArrayOfObjects';
import _ from 'lodash';
import {
  carSpecFieldMap,
  initialAboutCarTechSpecFields,
  initialAboutElectricCarTechSpecFields,
} from '~/components/AboutCar/carSpecsMap';
import { CarColorDetails, CarColorsAndImages } from '~/data/types/car/cars';
import { HYDRATE } from 'next-redux-wrapper';
import RESOURCES from '~/resources';

interface CarPageState {
  carsSafetyAndPollution: ICarSafetyAndPollution;
  carsTechSpec: CarTechSpecData[];
  fetchingCar: boolean;
  aboutCarSpecs: AnyObject | null;
  carColorsAndImages: Record<string, Array<CarColorDetails>> | null;
}

const initialState: CarPageState = {
  carsSafetyAndPollution: {
    carSafety: [],
    safetyScores: [],
    pollutionScores: [],
    carPollution: {
      american: [],
      regular: [],
      hybrid: [],
    },
  },
  carsTechSpec: [],
  fetchingCar: false,
  aboutCarSpecs: null,
  carColorsAndImages: null,
};
const carPageSlice = createSlice({
  name: 'carPage',
  initialState,
  reducers: {
    initState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFamilyCarById.pending, (state) => {
      state.fetchingCar = true;
    });
    builder.addCase(
      getCarColorsAndImages.fulfilled,
      (state, action: PayloadAction<CarColorsAndImages[]>) => {
        const colorsData: Record<
          string, // color name
          Array<{
            carImg: string;
            carZoneFinishedLevelName: string;
          }>
        > = {};

        action.payload?.forEach((car) => {
          Object.entries(car.colors)?.forEach(([colorName, carImg]) => {
            if (!colorsData[colorName]) {
              colorsData[colorName] = [];
            }

            const alreadyExists = colorsData[colorName].some(
              (item) =>
                item.carImg === carImg &&
                item.carZoneFinishedLevelName === car.carZoneFinishedLevelName,
            );

            if (!alreadyExists) {
              colorsData[colorName].push({
                carImg,
                carZoneFinishedLevelName: car.carZoneFinishedLevelName,
              });
            }
          });
        });

        state.carColorsAndImages = colorsData;
      },
    );
    builder.addCase(getSafetyAndPollution.fulfilled, (state, action) => {
      try {
        const carsSafetyAndPollution = action.payload as ICarSafetyAndPollution;
        const regular = formatRegularCarPollution(carsSafetyAndPollution);
        const american = formatAmericanCarPollution(carsSafetyAndPollution);
        state.carsSafetyAndPollution = carsSafetyAndPollution;
        state.carsSafetyAndPollution.carPollution.regular = regular;
        state.carsSafetyAndPollution.carPollution.american = american;
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getCarTechSpec.fulfilled, (state, action) => {
      try {
        const carsTechSpec = action.payload as CarTechSpecData[];
        state.carsTechSpec = carsTechSpec;

        if (carsTechSpec) {
          const flattenedData = flattenArray(carsTechSpec);
          let aboutCarTechSpecFields: { [key: string]: any[] };
          if (
            flattenedData &&
            flattenedData[0][carSpecFieldMap.ENGINE_TYPE] === RESOURCES.ELECTRIC
          ) {
            aboutCarTechSpecFields = _.cloneDeep(
              initialAboutElectricCarTechSpecFields,
            );
          } else {
            aboutCarTechSpecFields = _.cloneDeep(initialAboutCarTechSpecFields);
          }

          // Flatten the data and collect values for the fields of interest
          flattenedData?.forEach(
            (item) =>
              Object.keys(item)?.forEach((key) => {
                if (aboutCarTechSpecFields[key]) {
                  aboutCarTechSpecFields[key].push(item[key]);
                }
              }),
          );

          // Calculate ranges or single values for fields
          const result = _.mapValues(aboutCarTechSpecFields, (values) => {
            const uniqueValues = _.uniq(values);
            if (uniqueValues.length === 1) {
              return uniqueValues[0];
            }
            return typeof values[0] === 'number'
              ? `${Math.min(...values)}-${Math.max(...values)}`
              : uniqueValues.join(', ');
          });

          state.aboutCarSpecs = result;
        }
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getLeaseCarTechSpec.fulfilled, (state, action) => {
      try {
        const carsTechSpec = action.payload as CarTechSpecData[];
        state.carsTechSpec = carsTechSpec;

        if (carsTechSpec) {
          const flattenedData = flattenArray(carsTechSpec);
          const aboutCarTechSpecFields = _.cloneDeep(
            initialAboutCarTechSpecFields,
          );

          // Flatten the data and collect values for the fields of interest
          flattenedData?.forEach(
            (item) =>
              Object.keys(item)?.forEach((key) => {
                if (aboutCarTechSpecFields[key]) {
                  aboutCarTechSpecFields[key].push(item[key]);
                }
              }),
          );

          // Calculate ranges or single values for fields
          const result = _.mapValues(aboutCarTechSpecFields, (values) => {
            const uniqueValues = _.uniq(values);
            if (uniqueValues.length === 1) {
              return uniqueValues[0];
            }
            return typeof values[0] === 'number'
              ? `${Math.min(...values)}-${Math.max(...values)}`
              : uniqueValues.join(', ');
          });

          state.aboutCarSpecs = result;
        }
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const {
        carPage: { carColorsAndImages },
      } = action.payload;

      return {
        ...state,
        carColorsAndImages,
      };
    });
  },
});
export const { initState } = carPageSlice.actions;
export default carPageSlice.reducer;
