import React from 'react';
import { ImageFile } from '~/data/types/images';

const shortPlanSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
    >
      <path
        d="M79 53.7051C71.5999 53.7051 46.2614 53.7051 12 53.7051"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M11.9798 30.7753C24.6551 30.7753 21.459 47.7574 12.3851 53.0934C11.9999 53.3199 11.5183 53.2849 11.17 53.005C3.77262 47.0605 -0.661952 30.7753 11.9798 30.7753Z"
        fill="#00D6D1"
        stroke="#262626"
        strokeWidth="2"
      />
      <circle
        cx="12.1855"
        cy="40.2204"
        r="2.1484"
        fill="#FFFEFE"
        stroke="#262626"
        strokeWidth="2"
      />
      <path
        d="M74.8675 57.7304C71.8342 57.904 69.2345 55.5856 69.0609 52.5523C68.8874 49.5189 71.2057 46.9192 74.2391 46.7456C77.2724 46.5721 79.8721 48.8904 80.0457 51.9238C80.2192 54.9571 77.9009 57.5569 74.8675 57.7304Z"
        fill="#00D6D1"
      />
      <path
        d="M68.286 53.7029C67.9862 48.8622 71.2611 47.0607 74.2897 47.3037C77.3183 47.5467 79.5765 50.1988 79.3335 53.2274C79.0905 56.256 76.4384 58.5142 73.4098 58.2712C72.7009 58.2143 72.0342 58.0255 71.4319 57.7307"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default shortPlanSelectedIcon;
