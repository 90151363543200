import { CompareCarTypeEnum } from '~/data/types/compare/compareTypes';
import { CompareState } from '../slices/compareSlice';

// Update compare IDs (add/remove)
export const toggleCompareId = (
  compareIds: number[],
  carId: number,
): number[] => {
  if (compareIds.includes(carId)) {
    return compareIds.filter((id) => id !== carId); // Remove car
  } else {
    return [...compareIds, carId]; // Add car
  }
};

// Update cars list (add/remove)
export const toggleCompareCar = (
  cars: Record<any, any>[],
  car: any,
): Record<any, any>[] => {
  const carExists = cars.some((existingCar) => existingCar.id === car.id);

  if (carExists) {
    return cars.filter((existingCar) => existingCar.id !== car.id); // Remove car
  } else {
    return [...cars, car]; // Add car
  }
};

// Get the appropriate compare and cars list based on car type
export const getCompareListsByType = (
  state: CompareState,
  type: CompareCarTypeEnum,
): { compareIds: number[]; cars: Record<any, any>[] } => {
  switch (type) {
    case CompareCarTypeEnum.USED:
      return {
        compareIds: state.usedCarComparison.compareIds,
        cars: state.usedCarComparison.cars,
      };
    case CompareCarTypeEnum.NEW:
      return {
        compareIds: state.newCarComparison.compareIds,
        cars: state.newCarComparison.cars,
      };
    case CompareCarTypeEnum.PRIVATE_LEASING:
      return {
        compareIds: state.leasingComparison.compareIds,
        cars: state.leasingComparison.cars,
      };
    default:
      throw new Error('Invalid car type');
  }
};

// Set the compare and cars list based on car type
export const setCompareListsByType = (
  state: CompareState,
  type: CompareCarTypeEnum,
  compareIds?: number[],
  cars?: Record<any, any>[],
) => {
  const comparisonMapping = {
    [CompareCarTypeEnum.USED]: state.usedCarComparison,
    [CompareCarTypeEnum.NEW]: state.newCarComparison,
    [CompareCarTypeEnum.PRIVATE_LEASING]: state.leasingComparison,
  };

  const comparison = comparisonMapping[type];

  if (compareIds !== undefined) {
    comparison.compareIds = compareIds;
  }
  if (cars !== undefined) {
    comparison.cars = cars;
  }
};
