import { CampaignNumber } from '~/services/LeadService/enums';
import { EReferralReason } from '~/data/contactUsData';

export const getLineOfBusinessByCampaignNumber = (
  campaignNumber: CampaignNumber,
) => {
  switch (campaignNumber) {
    case CampaignNumber.LEASING:
    case CampaignNumber.FAILED_LEASING:
    case CampaignNumber.CONTACT_US[EReferralReason.LEASE]:
      return 'Leasing';
    case CampaignNumber.BUSINESS_LEASING:
      return 'Business Leasing';
    case CampaignNumber.RENAULT:
    case CampaignNumber.FAILED_RENAULT:
      return 'Renault';
    case CampaignNumber.NISSAN:
    case CampaignNumber.FAILED_NISSAN:
      return 'Nissan';
    case CampaignNumber.DUCIA:
    case CampaignNumber.FAILED_DUCIA:
      return 'Dacia';
    case CampaignNumber.INFINITY:
    case CampaignNumber.FAILED_INFINITY:
      return 'Infiniti';
    case CampaignNumber.CHERRY:
    case CampaignNumber.FAILED_CHERRY:
      return 'Chery';
    case CampaignNumber.INSURANCE:
      // case CampaignNumber.CONTACT_US[EReferralReason.INSURANCE]:
      return 'Insurance';
    case CampaignNumber.SOLUTIONS:
    case CampaignNumber.CONTACT_US[EReferralReason.CHARGING_SOLUTIONS]:
      return 'Energy';
    case CampaignNumber.EVEASY:
      return 'Renault';
    case CampaignNumber.XPENG:
      return 'Xpeng';
    case CampaignNumber.TRUCK:
    case CampaignNumber.FAILED_TRUCK:
      return 'Trucks';
    case CampaignNumber.USED_CAR:
    case CampaignNumber.USED_CAR_SHIRYUN:
    case CampaignNumber.CONTACT_US[EReferralReason.USED_CAR]:
    case CampaignNumber.CONTACT_US[EReferralReason.TRADE]:
      return 'Used Car Division';
    case CampaignNumber.FUNDING:
    case CampaignNumber.CONTACT_US[EReferralReason.FUNDING]:
      return 'Autocash';
    case CampaignNumber.CONTACT_US[EReferralReason.NEW_CAR]:
      return 'Freesbe';
    default:
      return undefined;
  }
};
