import { createAsyncThunk } from '@reduxjs/toolkit';
import { GenericPageType } from '~/data/types/GenericPage/GenericPageType';
import genericPageAdapter from '~/redux-toolkit/adapters/genericPageAdapter';

export const getGenericPageContent = createAsyncThunk(
  '/api/generic-pages?populate=deep,4',
  async (pageType: string): Promise<GenericPageType> => {
    try {
      const response = await genericPageAdapter.getGenericPageContent(pageType);
      const { data } = response;
      const genericPageType: GenericPageType = data.data[0];
      return genericPageType;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);
