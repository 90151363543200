/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICarFleetPageData } from '~/data/types/CarFleetPageContent/CarFleetPageContent.types';
import carFleetPageContentAdapter from '~/redux-toolkit/adapters/carFleetPageContentAdapter';

export const getCarFleetPageData = createAsyncThunk(
  '/api/car-fleet-page/content?populate=*',
  async (): Promise<ICarFleetPageData | null> => {
    try {
      const response =
        await carFleetPageContentAdapter.getCarFleetPageContent();
      const { data } = response;
      return data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);
