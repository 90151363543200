/* eslint-disable no-unused-vars */

// eslint-disable-next-line no-shadow
export enum EUserDetails {
  firstName = 'firstName',
  lastName = 'lastName',
  address = 'address',
  email = 'email',
  phoneNumber = 'phoneNumber',
  city = 'city',
  idNumber = 'idNumber',
  branch = 'branch',
}

// eslint-disable-next-line no-shadow
export enum ECheckboxes {
  read = 'read',
  email = 'email',
}

// eslint-disable-next-line no-shadow
export enum ETransactionStatus {
  success = 'success',
  failed = 'failed',
}

export const EUserDetailsStatus = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const EUserDetailsLength = {
  START_DETAILS: 0,
  END_DETAILS: 0,
  START_ADDRESS: 5,
  END_ADDRESS_LEASING: 7,
  END_ADDRESS: 8,
};
