import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPrimeRateRange } from '~/data/types/approvalFlow/approvalFlowType';
import fundingAdaptor from '~/redux-toolkit/adapters/fundingAdaptor';

export const getPrimeRate = createAsyncThunk(
  '/api/finance-page',
  async (): Promise<IPrimeRateRange> => {
    const response = await fundingAdaptor.getPrimeRate();
    return response.data.data.attributes;
  },
);
