import { ERequestType } from '../enums';
import { IsExistOpenLeadById } from '../interfaces';
import { LeadRequest } from './LeadRequest';

export interface IsExistOpenLeadResponse {
  isExistOpenLead: {
    leadId: string;
    isExistOpenLead: string;
    errorDescription: string;
    openLeadStatus: string;
    thereIsAnEvent: string;
    dueDateOnly: string;
    maMeetingTime: string;
    location: string;
    activityId: string;
  };
}

export class IsExistOpenLeadByIdRequest extends LeadRequest {
  isExistOpenLead: IsExistOpenLeadById;

  constructor(isExistOpenLead: IsExistOpenLeadById) {
    super(ERequestType.IsExistOpenLead);

    this.isExistOpenLead = isExistOpenLead;
  }
}
