import { CampaignNumber } from '~/services/LeadService/enums';

export const getSubMediaByCampaignNumber = (campaignNumber: CampaignNumber) => {
  switch (campaignNumber) {
    case CampaignNumber.USED_CAR:
      return '1784';
    case CampaignNumber.USED_CAR_SHIRYUN:
      return '1782';
    case CampaignNumber.CAR_FLEET:
      return subMedia.fleetCar;
    default:
      return '1000';
  }
};

export const subMedia = {
  usedCar: '1784',
  usedCarPriceExposed: '1782',
  freesbe: '1000',
  contactUsCarCard: '1112',
  configurator: '1900',
  summaryPage: '1901',
  carPage: '1784',
  fleetCar: '1903',
};
