import { OptionWithSubsType } from '~/components/SubsItemsCheckBoxDropdown/types';
import {
  BranchType,
  OurBranchesLocationType,
  RentalLocationAttributesType,
} from '~/data/rentalLocation.data';

export type BranchSpecialDates = {
  id: number;
  startTime: string;
  date: string;
  endTime: string;
  name: string;
  isDateDisable: boolean;
};

export type RentalLocationsAttributesType = {
  phoneNumber: string;
  displayPhoneNumber: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  tafnitID: string;
  isOpenning24_7: boolean;
  rentalLocationDetails: [
    {
      id: number;
      language: string;
      locationName: string;
      address: string;
      countryRegion: string;
      city: string;
      urlTitle: string;
    },
  ];
  specialDates: BranchSpecialDates[];
};

export type RentalLocationsDataType = {
  id: number;
  attributes: RentalLocationsAttributesType;
};

export type RentalLocationType = {
  id: string;
  name: string;
  phoneNumber: string;
  displayPhoneNumber: string;
  address: string;
  countryRegion: string;
  urlTitle: string;
  specialDates: string[];
  tafnitName?: string;
};

export type RentalBranchesState = {
  branches: RentalLocationType[];
  fetchingBranches: boolean;
  holidaysDates: string[];
};

export type OurBranchesState = {
  ourBranches: OurBranchesLocationType[];
  usedBranches: object;
  fetchingBranches: boolean;
  branchTypes: BranchType[];
  selectedArea: string;
  selectedServiceType: OptionWithSubsType[];
  selectedBranch: OurBranchesLocationType | null;
  genericPageBranches: {
    [key: string]: { [key: string]: RentalLocationAttributesType[] };
  };
};

export const JERUSALEM = 'ירושלים';
export const CENTER_AND_SHARON = 'מרכז והשרון';
export const CENTER = 'מרכז';

export type HolidaysDatesType = {
  holidayName: string;
  date: string;
  isFullDay: boolean;
};

export type HolidaysDatesAttributesType = {
  holidayName: string;
  date: string;
  isFullDay: boolean;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
};
export type HolidaysDatesDataType = {
  id: number;
  attributes: HolidaysDatesAttributesType;
};
