/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFoundingPage } from '~/data/types/foundingPage/foundingPage';
import fundingAdapter from '../adapters/fundingAdaptor';

export const getFoundingPage = createAsyncThunk(
  '/api/founding-page/content?populate=*',
  async (): Promise<IFoundingPage | null> => {
    try {
      const response = await fundingAdapter.getFoundingPage();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);
