import React from 'react';
import { ImageFile } from '~/data/types/images';

const DoodleIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="83"
      height="12"
      viewBox="0 0 83 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M1.5 2C1.5 2 66.678 2 68.555 2C70.432 2 31.2087 4.66667 28.9434 4.78261C26.678 4.89855 67.7136 6.6087 69.6553 6.6087C71.5971 6.6087 38.0049 8.72464 39.7524 8.72464C41.5 8.72464 81.5 10 81.5 10"
        stroke="#FFE500"
        strokeWidth="2.72727"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default DoodleIcon;
