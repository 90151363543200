/* eslint-disable @typescript-eslint/no-explicit-any */

export const useSortObjectArr = (arr: any, key: string, method?: string) => {
  return arr.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
    const valueA = a[key];
    const valueB = b[key];
    if (method === 'desc') {
      if (valueA > valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }
    } else {
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    }
    return 0;
  });
};
