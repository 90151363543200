import { styled } from '@mui/system';

const Container = styled('section')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

export default Container;
