import { createAsyncThunk } from '@reduxjs/toolkit';
import { CarTechSpecData } from '~/data/car/carTechSpec';
import {
  CarBodyTypeData,
  carDescriptionHighlights,
} from '~/data/types/car/cars';
import { PaginationType } from '~/data/types/paginationTypes';
import {
  ISearchUsedCarsQuery,
  OfferData,
  UsedCarDataType,
  UsedCarType,
} from '~/data/types/usedCar/usedCarData';
import { USED_CAR_FILTER_OPTION_TYPE_PARAMS } from '~/data/utils/usedCarFiltersParams';
import usedCarAdapter, {
  IAdvantagesDataRes,
  IOtp,
  IOtpRes,
} from '~/redux-toolkit/adapters/usedCarAdapter';
import { LeadService } from '~/services/LeadService/LeadService';
import { IsExistOpenLeadById } from '~/services/LeadService/interfaces';
export interface IGetUsedCarsContent {
  query: ISearchUsedCarsQuery;
  version?: string;
}

export const getUsedCarsContent = createAsyncThunk(
  '/searchUsedCars',
  async ({
    query,
    version = 'v1',
  }: IGetUsedCarsContent): Promise<{
    data: UsedCarType[];
    pagination: PaginationType;
  }> => {
    try {
      const response = await usedCarAdapter.getUsedCarsContent({
        ...query,
        version,
      });

      return response;
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
        pagination: {} as PaginationType,
      };
    }
  },
);

export const getUsedCarsContentAdditional = createAsyncThunk(
  '/searchUsedCarsAdditional',
  async (
    query: ISearchUsedCarsQuery,
  ): Promise<{
    data: UsedCarType[];
    pagination: PaginationType;
  }> => {
    try {
      const response = await usedCarAdapter.getUsedCarsContentAdditional(query);

      return response;
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
        pagination: {} as PaginationType,
      };
    }
  },
);

export const getMoreUsedCarsContent = createAsyncThunk(
  '/searchMoreUsedCars',
  async ({
    query,
    version = 'v1',
  }: IGetUsedCarsContent): Promise<{
    data: UsedCarType[];
    pagination: PaginationType;
  }> => {
    try {
      const response = await usedCarAdapter.getUsedCarsContent({
        ...query,
        version,
      });

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
        pagination: {} as PaginationType,
      };
    }
  },
);
export const getPopularUsedCars = createAsyncThunk(
  '/getPopularUsedCars',
  async (): Promise<{
    data: UsedCarType[];
  }> => {
    try {
      const response = await usedCarAdapter.getPopularUsedCars();
      return { data: response };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
      };
    }
  },
);

export const getUsedCarFiltersOptions = createAsyncThunk(
  '/used-car-filters',
  async (isOnlyFiltersOptions: boolean): Promise<any> => {
    try {
      if (isOnlyFiltersOptions) {
        const response = await usedCarAdapter.getUsedCarFiltersOptions(
          USED_CAR_FILTER_OPTION_TYPE_PARAMS.USED_CAR_FILTERS,
        );
        return {
          manufactureWithModels: [],
          categories: [],
          colors: [],
          filtersOptions: response.data,
        };
      }
      const [
        manufactureWithModelsRes,
        categoriesRes,
        colorsRes,
        filtersOptions,
      ] = await Promise.all(
        Object.values(USED_CAR_FILTER_OPTION_TYPE_PARAMS).map((optionType) =>
          usedCarAdapter.getUsedCarFiltersOptions(optionType),
        ),
      );

      return {
        manufactureWithModels: manufactureWithModelsRes.data,
        categories: categoriesRes.data,
        colors: colorsRes.data,
        filtersOptions: filtersOptions.data,
      };
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getUsedCarById = createAsyncThunk(
  '/api/used-cars',
  async (id: string): Promise<UsedCarDataType> => {
    const response = await usedCarAdapter.getUsedCarById(id);
    const { data } = response;

    return data.data;
  },
);

export const getAdvantagesContent = createAsyncThunk(
  '/api/advantages-sections',
  async (): Promise<IAdvantagesDataRes> => {
    const response = await usedCarAdapter.getAdvantagesSectionContent();
    const { data } = response;

    return data.data[0];
  },
);

export const getUsedCarTechSpec = createAsyncThunk(
  'api/car-tech-spec',
  async (carNumber: string): Promise<CarTechSpecData[]> => {
    try {
      const response = await usedCarAdapter.getUsedCarTechSpec(carNumber);
      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getOTP = createAsyncThunk(
  'api/otp/create',
  async (otpData: IOtp): Promise<IOtpRes> => {
    const response = await usedCarAdapter.getOTP(otpData);

    return response;
  },
);

export const validateOTP = createAsyncThunk(
  'api/otp/validate',
  async (otpData: IOtpRes): Promise<any> => {
    const response = await usedCarAdapter.validateOTP(otpData);

    return response;
  },
);

export const isExistOpenLead = createAsyncThunk(
  'api/isExistOpenLead/validate',
  async ({
    leadId,
    lineOfBusiness,
    mobilePhone,
  }: IsExistOpenLeadById): Promise<string | undefined> => {
    const response = await LeadService.getExistOpenLeadByLeadId({
      leadId,
      lineOfBusiness,
      mobilePhone,
    });

    return response;
  },
);

export const getUsedCarHighlightsFilterOptions = createAsyncThunk(
  'used-car-highlights-filters',
  async (): Promise<carDescriptionHighlights[]> => {
    try {
      const data = await usedCarAdapter.getUsedCarHighlightsFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getUsedCarBodyTypeFilterOptions = createAsyncThunk(
  'used-car-body-types-filters',
  async (): Promise<CarBodyTypeData[]> => {
    try {
      const data = await usedCarAdapter.getUsedCarBodyTypeFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getUsedCarFilterOptions = createAsyncThunk(
  'used-car-years-filters',
  async (): Promise<any> => {
    try {
      const data = await usedCarAdapter.getUsedCarBodyTypeFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getUsedCarsOfferCard = createAsyncThunk(
  '/api/offer-components',
  async (): Promise<OfferData[]> => {
    const response = await usedCarAdapter.getUsedCarsOfferCard();

    return response;
  },
);
