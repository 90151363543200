import React from 'react';
import { ImageFile } from '~/data/types/images';

const youtubeIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_12308_51790)">
        <path
          d="M9.99923 17.2653H9.99389C9.3904 17.2612 4.05531 17.2126 2.54377 16.8038C1.48741 16.5198 0.653512 15.6868 0.368783 14.6303C-0.0267259 13.1455 -0.00154887 10.2867 0.00119771 10.0585C-0.00139628 9.83125 -0.0268785 6.94886 0.367562 5.45182L0.368783 5.44755C0.650308 4.40308 1.50297 3.54431 2.54118 3.25958C2.54377 3.25882 2.54652 3.25821 2.54911 3.25745C4.04356 2.86469 9.38918 2.81662 9.99389 2.8125H10.0047C10.6097 2.81662 15.959 2.8653 17.4567 3.27469C18.5103 3.55789 19.3436 4.3898 19.6291 5.44495C20.0394 6.94306 20.0014 9.83171 19.9973 10.0755C20.0002 10.3157 20.0241 13.1488 19.6309 14.6411L19.6298 14.6454C19.345 15.7019 18.5112 16.5349 17.4536 16.8192L17.4493 16.8204C15.9551 17.213 10.6093 17.2611 10.0047 17.2653H9.99923ZM1.87788 5.85251C1.53059 7.17393 1.5634 10.0195 1.5637 10.0482V10.0688C1.55332 10.858 1.58979 13.1435 1.87803 14.2259C2.0178 14.7443 2.42903 15.1549 2.95057 15.2951C4.06584 15.5968 8.41109 15.6917 9.99923 15.7028C11.5915 15.6917 15.9431 15.5994 17.0502 15.3098C17.57 15.1691 17.9799 14.7598 18.1206 14.2409C18.4091 13.1426 18.4453 10.8682 18.4348 10.0841C18.4348 10.0758 18.4348 10.0676 18.4349 10.0594C18.4493 9.26072 18.421 6.94856 18.122 5.8574L18.1212 5.85419C17.9808 5.33356 17.5694 4.92295 17.0479 4.78272C15.9435 4.48075 11.5912 4.38614 9.99923 4.375C8.40804 4.38614 4.06065 4.47831 2.95027 4.76761C2.43864 4.90952 2.01811 5.33478 1.87788 5.85251ZM8.75819 12.7687C8.42486 12.9616 8.0078 12.721 8.0078 12.3359V7.7418C8.0078 7.35669 8.42487 7.11616 8.7582 7.30901L12.7285 9.60613C13.0613 9.79868 13.0613 10.2792 12.7285 10.4717L8.75819 12.7687Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12308_51790">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Component>
  );
};

export default youtubeIconIcon;
