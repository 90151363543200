import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    dark2?: string;
    dark3?: string;
    light2?: string;
    light3?: string;
    disabled?: string;
    hover?: string;
  }
}

export const themeColor = 'light';
export const highMidWidth = 1250;
export const thinMobileWidth = 385;
export const extraThinMobileWidth = 370;

const theme = createTheme({
  // direction: 'rtl',
  typography: {
    fontFamily: 'EricaSansFOT, GillSans',
    fontWeightRegular: 'regular',
    // fontWeightBold: 'EricaSansFOTBold, GillSansBold', // Removed by https://freesbe.monday.com/boards/5546358669/views/128828113/pulses/6989892341
    fontWeightLight: 'EricaSansLight',

    h1: {
      fontSize: '42px',
      lineHeight: '44px',
      fontFamily: 'EricaSansFOT,GillSans',
      fontWeight: '600',
      color: '#000000',
    },
    h2: {
      fontSize: '32px',
      color: '#000',
    },
    h3: {
      fontSize: '26px',
      color: '#000',
    },
    h4: {
      fontSize: '18px',
      color: '#000',
      fontWeight: 400,
    },
    h5: {
      fontSize: '14px',
      color: '#000',
    },

    h6: {
      fontSize: '12px',
      color: '#000',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '20px',
      textAlign: 'right',
      color: '#757575',
    },
    body1: {
      fontSize: '18px',
      textAlign: 'right',
      fontWeight: '400',
      color: '#262626',
    },
    subtitle2: {
      fontSize: '12px',
      color: '#000',
    },
  },
  palette: {
    primary: {
      main: '#00D6D1', // #00D6D1
      dark: '#757575',
      light: '#FFFEFE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#757575',
      main: '#00D6D1',
      contrastText: '#262626',
    },
    text: {
      primary: '#595959',
    },
    grey: {
      50: '#0D4B4A',
      100: '#EDEDED',
    },
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1700,
    },
  },
});
export default theme;
