import { HttpClientService } from '~/services/HttpsClientService';
import { GenericPageType } from '~/data/types/GenericPage/GenericPageType';

class GenericPageAdapter {
  static readonly endpoint = `/api/generic-pages?populate=deep,4&filters[pageInfo][$eq]=`;

  // eslint-disable-next-line class-methods-use-this
  async getGenericPageContent(pageType: string) {
    const res = await HttpClientService.get<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      meta: any;
      data: GenericPageType[];
    }>(`${GenericPageAdapter.endpoint}${pageType}`);

    return res;
  }
}

const genericPageAdapter = new GenericPageAdapter();
export default genericPageAdapter;
