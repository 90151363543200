import { createSlice } from '@reduxjs/toolkit';
import {
  CENTER,
  CENTER_AND_SHARON,
  JERUSALEM,
  RentalBranchesState,
  RentalLocationType,
} from '~/data/types/rentalLocationsType';
import {
  getHolidaysDates,
  getRentalBranches,
} from '~/redux-toolkit/thunks/rentalBranchesThunks';

const initialState: RentalBranchesState = {
  branches: [],
  fetchingBranches: false,
  holidaysDates: [],
};

const rentalBranchesSlice = createSlice({
  name: 'rentalBranches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRentalBranches.pending, (state) => {
      state.fetchingBranches = true;
    });
    builder.addCase(getRentalBranches.rejected, (state) => {
      state.fetchingBranches = false;
    });
    builder.addCase(getRentalBranches.fulfilled, (state, action) => {
      const branchesRes = action.payload;
      const mappedBranches: RentalLocationType[] = [];
      branchesRes.forEach((branch) => {
        const rentBranchDetails = branch.attributes.rentalLocationDetails[0];
        mappedBranches.push({
          id: branch.attributes.tafnitID,
          name: rentBranchDetails.city,
          phoneNumber: branch.attributes.phoneNumber,
          displayPhoneNumber: branch.attributes.displayPhoneNumber,
          address: rentBranchDetails.address,
          countryRegion:
            rentBranchDetails.city === JERUSALEM
              ? JERUSALEM
              : rentBranchDetails.countryRegion === CENTER
              ? CENTER_AND_SHARON
              : rentBranchDetails.countryRegion,
          urlTitle: rentBranchDetails.urlTitle,
          specialDates: branch.attributes.specialDates.map((date) => date.date),
        });
      });
      state.branches = mappedBranches.sort((loc, loc1) =>
        loc.name.localeCompare(loc1.name),
      );
      state.fetchingBranches = false;
    });
    builder.addCase(getHolidaysDates.fulfilled, (state, action) => {
      const dates = action.payload;
      const mappedDates: string[] = [];
      dates.forEach((date) => {
        mappedDates.push(date.attributes.date);
      });
      state.holidaysDates = mappedDates;
    });
  },
});

export default rentalBranchesSlice.reducer;
