import RESOURCES from '~/resources';

export const CATEGORIES = 'categories' as const;

export const SEARCH_FLOW = {
  BUDGET: 'budget',
  GENERAL_SEARCH: 'generalSearch',
  ACCURATE_SEARCH: 'accurateSearch',
  FAVORITES: 'favorites',
} as const;

export const SEARCH_PLANS = {
  BUDGET: 'budget',
  GENERAL_SEARCH: 'generalSearch',
  ACCURATE_SEARCH: 'accurateSearch',
  CONTACT_US: 'contactUs',
} as const;

export const CAR_FLOW = {
  NEW_CAR: 'new_car',
  RENTAL_CAR: 'rental_car',
  USED_CAR: 'used_car',
  LEASING: 'leasing',
} as const;

export const CARS_FLOWS_LABELS = {
  [CAR_FLOW.NEW_CAR]: RESOURCES.NEW_CAR_LABEL,
  [CAR_FLOW.RENTAL_CAR]: RESOURCES.RENTAL_CAR,
  [CAR_FLOW.LEASING]: RESOURCES.PRIVATE_LEASING,
  [CAR_FLOW.USED_CAR]: RESOURCES.USED_CAR,
} as const;

export const CONTACT_US_REDIRECTION = {
  HOME_PAGE: 'homepage',
  CAR_PAGE: 'carPage',
  RESULTS_PAGE: 'resultsPage',
};

export const CAR_TARGET = {
  NEW_CAR: 'newCar',
  USED_CAR: 'usedCar',
  LEASING: 'privateLeasing',
  FLEET: 'carFleet',
  ALL: 'all',
} as const;

export const CAR_ACTION_TYPES = {
  NEW_CAR: 'חדשה',
  USED_CAR: 'יד שנייה',
  LEASING: 'בליסינג',
} as const;

export type SEARCH_FLOW_TYPES = (typeof SEARCH_FLOW)[keyof typeof SEARCH_FLOW];

export type SEARCH_PLANS_TYPES =
  (typeof SEARCH_PLANS)[keyof typeof SEARCH_PLANS];

export type CAR_FLOW_TYPES = (typeof CAR_FLOW)[keyof typeof CAR_FLOW];
export type CAR_TARGET_TYPES = (typeof CAR_TARGET)[keyof typeof CAR_TARGET];

export const FILTERS_LABELS = {
  BRAND: 'יצרן',
  MODEL: 'דגם',
  CATEGORY: 'קטגוריה',
  COLOR: 'צבע',
  OWNER: 'בעלות מקורית',
  HIGHLIGHT: 'מאפייני המכונית',
  BODYTYPE: 'מרכב',
  ENGINE: 'מנוע',
  GEAR: 'תיבת הילוכים',
  HAND: 'יד',
};

export const LEAD_LOCATION = {
  HomePageMarketingBanner: 'HomePageMarketingBanner',
  contactUsCard: 'contactUsCard',
  PromotionBanner: 'PromotionBanner',
  newCarLobby: 'NewCarLobby',
  usedCarLobby: 'UsedCarLobby',
  leasingLobby: 'LeasingLobby',
};

export const BANNER_LOCATION = {
  homePageGallery: 'homePageGallery',
  homePageStaticBanner: 'homePageStaticBanner',
  newCarLobbyGallery: 'newCarLobbyGallery',
  usedCarLobbyGallery: 'usedCarLobbyGallery',
  leasingLobbyGallery: 'privateLeasingLobbyGallery',
  newCarLobbyStaticBanner: 'newCarLobbyStaticBanner',
  usedCarLobbyStaticBanner: 'usedCarLobbyStaticBanner',
  leasingLobbyStaticBanner: 'privateLeasingLobbyStaticBanner',
} as const;

export const SEARCH_LOCATION = {
  homePage: 'homePage',
  newCarLobby: 'newCarLobby',
  usedCarLobby: 'usedCarLobby',
  leasingLobby: 'privateLeasingLobby',
} as const;

export const BANNER_TYPE = {
  LINK: 'link',
  LEAD: 'lead',
} as const;

type BannerTypeKeys = keyof typeof BANNER_TYPE;
export type BannerTypes = (typeof BANNER_TYPE)[BannerTypeKeys];

type BannerLocationKeys = keyof typeof BANNER_LOCATION;
export type BannerLocationType = (typeof BANNER_LOCATION)[BannerLocationKeys];
