import { styled } from '@mui/material';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

const useStyles = () => ({
  boxStyle: {
    border: `1px solid ${COLORS.gray.light2}`,
    borderRadius: '8px',
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '24px',
  },
  specialPriceTagStyle: {
    position: 'absolute',
    left: '0',
  },
  yellowCircleTitle: {
    top: '14px',
    position: 'absolute',
    left: '36px',
    zIndex: -1,
    [theme.breakpoints.up('md')]: {
      left: '159px',
    },
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    direction: 'ltr',
    gap: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },

  inputsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  iconStyle: {
    position: 'absolute',
    left: '10px',
  },

  visuallyHidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: -'1px',
    padding: '0',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: '0',
  },

  input: {
    marginTop: '10px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  fieldSetStyle: {
    border: 'none',
    padding: 0,
    margin: 0,
  },
});

export default useStyles;

export const root = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const offerTitleStyle = {
  marginTop: '24px',
  marginBottom: '8px',
  fontWeight: '600',
  fontSize: '18px',
};

export const offerDescriptionStyle = {
  marginBottom: '24px',
  lineHight: '25px',
  color: `${COLORS.gray.dark}`,
  textAlign: 'center',
  maxWidth: '408px',
  fontSize: '18px',
};

export const buttonCustomStyle = {
  width: '100%',
  marginTop: 8,
};

export const formGroupStyle = {
  width: '90%',
};

export const checkBoxLabelStyle = {
  marginRight: '-10px',
  fontSize: 14,
  padding: '8px',
  color: `${COLORS.gray.dark}`,
};

export const checkBoxStyle = {
  marginRight: '-10px',
};

export const iAgreeTextStyle = {
  direction: 'rtl',
  paddingRight: '33px',
  marginTop: '15px',
  color: COLORS.gray.dark,
};

export const helperTextStyle = {
  marginBottom: '22px',
  direction: 'rtl',
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
    position: 'absolute',
    bottom: -22,
    marginRight: '1px',
  },

  '& .MuiInputBase-input': {
    direction: 'rtl',
  },
};

export const YellowCircleTitleStyled = styled('svg')(() => ({
  top: '14px',
  position: 'absolute',
  left: '36px',
  zIndex: -1,
  [theme.breakpoints.up('md')]: {
    left: '159px',
  },
}));
