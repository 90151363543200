/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import homePageContentAdapter from '~/redux-toolkit/adapters/homePageContentAdapter';
import { IHomePageData } from '~/data/types/homePageContent/homePage.types';

export const getIHomePageData = createAsyncThunk(
  '/api/home-page/content?populate=*',
  async (): Promise<IHomePageData | null> => {
    try {
      const response = await homePageContentAdapter.getHomePageContent();
      const { data } = response;

      return data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);
