import { Checkbox, Typography } from '@mui/material';
import { FC, CSSProperties } from 'react';
import CheckedIcon from '~/assets/icons/jsx/CheckedIcon';
import useStyles, {
  checkBoxTitleStyle,
  checkboxIconStyle,
  checkboxStyle,
} from './useStyles';
import { Box } from '@mui/system';

interface TermsCheckboxProps {
  title: string;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onClick: (e: any) => void;
  customStyle?: CSSProperties;
}
const TermsCheckbox: FC<TermsCheckboxProps> = ({
  title,
  disabled,
  onClick,
  customStyle,
}) => {
  const classes = useStyles();
  return (
    <Box sx={classes.checkboxContainer} style={customStyle}>
      <Checkbox
        checkedIcon={<CheckedIcon style={checkboxIconStyle} />}
        disabled={disabled}
        sx={checkboxStyle}
        onClick={onClick}
        inputProps={{ 'aria-label': title }}
      />
      <Typography variant="body2" sx={checkBoxTitleStyle}>
        {title}
      </Typography>
    </Box>
  );
};

export default TermsCheckbox;
