import { IChildTerritoryTestDrive } from '~/data/lead/interfaces';
import { ERequestType } from '../enums';
import { GetChildTerritoryListTestDrive } from '../interfaces';
import { LeadRequest } from './LeadRequest';

export interface GetChildTerritoryListTestDriveResponse {
  errorMessage?: string;
  getChildTerritoryListTestDrive: {
    data: IChildTerritoryTestDrive[];
    isActiveMeetingForLead: boolean;
    errorDescription: string;
  };
}

export class GetChildTerritoryListTestDriveRequest extends LeadRequest {
  getChildTerritoryListTestDrive: GetChildTerritoryListTestDrive;

  constructor(getChildTerritoryListTestDrive: GetChildTerritoryListTestDrive) {
    super(ERequestType.GetChildTerritoryListTestDrive);

    this.getChildTerritoryListTestDrive = getChildTerritoryListTestDrive;
  }
}
