import React from 'react';
import { ImageFile } from '~/data/types/images';

const YellowCircleMediumIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="39"
      viewBox="0 0 106 39"
    >
      <path
        d="M29.4375 35.308C46.4873 37.8408 104.41 29.7181 102.35 17.1553C100.052 3.14256 69.4038 2.13062 54.2839 3.06329C39.1641 3.99596 5.92088 3.5284 3.31765 18.3782C2.63507 22.2719 12.7401 30.7298 42.0907 26.4789"
        stroke="#FFE500"
        strokeWidth="5.55844"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default YellowCircleMediumIcon;
