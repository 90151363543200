/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import wishlistAdapter from '~/redux-toolkit/adapters/wishlistAdapter';

export const getWishlistCarsDetails = createAsyncThunk(
  '/api/family-cars?filters[id][$in]=',
  async (ids: number[]): Promise<any> => {
    try {
      const response = await wishlistAdapter.getCarDetails(ids);

      const { data } = response;

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
export const getWishlistFamilyCarsPromoDetails = createAsyncThunk(
  '/api/family-cars?getWishlistFamilyCarsPromoDetails',
  async (ids: number[]): Promise<any> => {
    try {
      const response = await wishlistAdapter.getCarDetails(ids);

      const { data } = response;

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getWishlistLeaseCarsDetails = createAsyncThunk(
  '/api/family-lease-cars/?filters[id][$in]=',
  async (ids: number[]): Promise<any> => {
    try {
      const response = await wishlistAdapter.getLeaseCarDetails(ids);

      const { data } = response;

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getWishlistUsedCarsDetails = createAsyncThunk(
  '/api/used-cars/[id][$in]=',
  async (ids: number[]): Promise<any> => {
    try {
      const response = await wishlistAdapter.getUsedCarById(ids);
      const { data } = response;

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
