/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { ERequestType } from '../enums';

import { LeadProductItem, LeadStatus, ExistLead } from '../interfaces';

type RequstTimestamp = string;

export class LeadRequest {
  requestType: ERequestType;

  requestID: RequstTimestamp = `moveo-${Date.now()}`;

  isActiveProduct: LeadProductItem | null = null;

  leadStatus: LeadStatus | null = null;

  isExistLead: ExistLead | null = null;

  constructor(type: ERequestType) {
    this.requestType = type;
  }
}
