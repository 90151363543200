'use client';
import { CAR_FLOW_TYPE } from '~/data/car/carCardData';
import { CarAcquireType } from '~/data/car/carSubscriptionData';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCarDealOptions } from './useCarDealOptions';
import { useCurrentCar } from './useCurrentCar';
import { useIsLeaseCar } from './useIsLeaseCar';

export const useCarDealType = () => {
  const router = useRouter();
  const car = useCurrentCar();
  const carDealOptions = useCarDealOptions(car);
  const isLeasing = useIsLeaseCar();

  const isSubscription = useMemo(() => {
    return car?.acquireType === CarAcquireType.SUBSCRIPTION;
  }, [car]);

  const urlDealType = useMemo(() => {
    const query = router.query;
    const dealTypeQuery = query.dealType ? String(query.dealType) : null;

    if (!dealTypeQuery && router.asPath) {
      if (router.asPath.includes('dealType')) {
        const match = router.asPath.match(/dealType=([^&]*)/);
        return match ? match[1] : null;
      }
    }

    return dealTypeQuery;
  }, [router.query, router.asPath]);

  const urlDealTypeIsValid = useMemo(() => {
    return (
      carDealOptions.length > 0 &&
      urlDealType !== null &&
      carDealOptions.includes(urlDealType as CAR_FLOW_TYPE)
    );
  }, [carDealOptions, urlDealType]);

  return useMemo(() => {
    const dealType =
      urlDealType && urlDealTypeIsValid
        ? isSubscription
          ? CAR_FLOW_TYPE.LOAN_CAR
          : (urlDealType as CAR_FLOW_TYPE)
        : isLeasing
        ? CAR_FLOW_TYPE.LEASING_CAR
        : carDealOptions[0];
    return dealType;
  }, [
    carDealOptions,
    isSubscription,
    urlDealType,
    urlDealTypeIsValid,
    isLeasing,
  ]);
};
