import { createSlice } from '@reduxjs/toolkit';
import { ISuggestionData } from '~/data/suggestionData';
import { homePageContentType } from '~/data/types/homePageContentType';
import { HomePageMarketingBannerType } from '~/data/types/homePageMarketingBannerType';
import {
  QuickSearchContentType,
  QuickSearchType,
} from '~/data/QuickSearch/quickSearchType';
import { PlanType, SearchPlanContentType } from '~/data/types/searchPlanType';
import {
  getHomePageBannerGallery,
  getHomePageContent,
  getHomePageMarketingBanners,
  getHomePageOurServices,
  getHomePageQuickSearch,
  getHomePageStaticBanners,
  getSearchByNeeds,
  getSearchPlansSearch,
  getSuggestionsContent,
} from '~/redux-toolkit/thunks/homePageThunks';
import cleanObject from '~/utils/cleanObject';
import { OurServicesType } from '~/data/types/ourServices/ourServices.types';
import { HYDRATE } from 'next-redux-wrapper';
import { getMappedBanners } from '../utils/bannersUtils';
import {
  BannerCardType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';

interface HomePageState {
  content: homePageContentType | null;
  isSearchFlowOpen: boolean;
  isInsurancePage: boolean;
  quickSearchContent: QuickSearchContentType[];
  selectedQuickSearchType: QuickSearchContentType;
  searchPlans: SearchPlanContentType;
  MarketingBanners: HomePageMarketingBannerType[];
  suggestionContent: ISuggestionData[];
  isShowMobileFooter: boolean;
  isQuickSearchVisible: boolean;
  galleryBanners: BannerCardType[];
  ourServices: OurServicesType;
  staticBanners: BannerCardType[];
  searchByNeed: SearchByNeedResponseType | null;
}

const initialState: HomePageState = {
  content: null,
  isSearchFlowOpen: false,
  isInsurancePage: false,
  quickSearchContent: [],
  selectedQuickSearchType: {
    id: 0,
    searchType: '',
    searchChips: [],
    page: null,
  },
  searchPlans: {
    mainTitle: '',
    SearchPlans: [],
  },
  MarketingBanners: [],
  suggestionContent: [],
  isShowMobileFooter: false,
  isQuickSearchVisible: false,
  galleryBanners: [],
  ourServices: {
    mainTitle: '',
    subtitle: null,
    services: [],
  },
  staticBanners: [],
  searchByNeed: null,
};

const homePageSlice = createSlice({
  name: 'homePageData',
  initialState,
  reducers: {
    setHomePageContent(state, action) {
      state.content = action.payload;
    },
    setIsSearchFlowOpen(state, action) {
      state.isSearchFlowOpen = action.payload;
    },
    setIsShowMobileFooter(state, action) {
      state.isShowMobileFooter = action.payload;
    },
    setIsQuickSearchVisible(state, action) {
      state.isQuickSearchVisible = action.payload;
    },
    setIsInsurancePage(state, action) {
      state.isInsurancePage = action.payload;
    },
    setQuickSearchType(state, action) {
      const selectedQuickSearch = action.payload || state.quickSearchContent[0];

      state.selectedQuickSearchType = selectedQuickSearch;
    },
    setGalleryBanners(state, action) {
      state.galleryBanners = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageContent.fulfilled, (state, action) => {
      state.content = action.payload?.[0] ?? null;
    });
    builder.addCase(getHomePageQuickSearch.fulfilled, (state, action) => {
      try {
        state.selectedQuickSearchType = {
          id: Number(action.payload[0].id),
          searchType: action.payload[0].attributes?.searchType || '',
          searchChips: action.payload[0].attributes?.searchChips || '',
        };

        state.quickSearchContent = action.payload.map(
          (quickSearchItem: QuickSearchType) => {
            return {
              id: Number(quickSearchItem.id),
              searchType: quickSearchItem.attributes?.searchType || '',
              searchChips: quickSearchItem.attributes?.searchChips || '',
            };
          },
        );

        state.selectedQuickSearchType =
          Math.random() < 0.5
            ? state.quickSearchContent[0] || ''
            : state.quickSearchContent[1] || '';
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getSearchPlansSearch.fulfilled, (state, action) => {
      try {
        state.searchPlans.mainTitle = action.payload.attributes.mainTitle;
        const mappedPlans = action.payload.attributes.SearchPlans.map(
          (plan: PlanType) => {
            return cleanObject({
              id: Number(plan.id),
              title: plan.title,
              subtitle: plan.subtitle,
              searchType: plan.searchType,
              buttonTitle: plan.buttonTitle,
              icon: plan.icon.data?.attributes.url,
            });
          },
        );

        state.searchPlans.SearchPlans = mappedPlans;
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getHomePageMarketingBanners.fulfilled, (state, action) => {
      const mappedBanners = action.payload.map((banner) => {
        const { id, attributes } = banner;
        return {
          id,
          ...attributes,
          bannerImage: attributes.bannerImage.data.attributes.url,
        } as HomePageMarketingBannerType;
      });
      state.MarketingBanners = mappedBanners;
    });
    builder.addCase(getSuggestionsContent.fulfilled, (state, action) => {
      state.suggestionContent = action.payload;
    });
    builder.addCase(getHomePageBannerGallery.fulfilled, (state, action) => {
      const banners = action.payload;
      state.galleryBanners = getMappedBanners(banners);
    });
    builder.addCase(getHomePageStaticBanners.fulfilled, (state, action) => {
      const banners = action.payload;
      state.staticBanners = getMappedBanners(banners);
    });
    builder.addCase(getHomePageOurServices.fulfilled, (state, action) => {
      if (!action.payload.length) return;
      const homePageData = action.payload[0].attributes;
      const { services, mainTitle, subtitle } = homePageData;
      state.ourServices.mainTitle = mainTitle;
      state.ourServices.subtitle = subtitle;

      state.ourServices.services = services?.map((service) => {
        return {
          serviceMainTitle: service.serviceMainTitle,
          serviceSubtitle: service.serviceSubtitle,
          buttonUrlLink: service.buttonUrlLink,
          buttonTitle: service.buttonTitle,
          serviceIcon: service.serviceIcon?.data?.attributes || null,
        };
      });
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { homePage } = action.payload;

      return {
        ...state,
        ...homePage,
      };
    });
    builder.addCase(getSearchByNeeds.fulfilled, (state, action) => {
      state.searchByNeed = action.payload?.[0] ?? null;
    });
  },
});

export const {
  setHomePageContent,
  setIsSearchFlowOpen,
  setIsInsurancePage,
  setQuickSearchType,
  setIsShowMobileFooter,
  setIsQuickSearchVisible,
  setGalleryBanners,
} = homePageSlice.actions;

export default homePageSlice.reducer;
