import { useCallback } from 'react';
import Button from '~/components/Button';
import RESOURCES from '~/resources';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import { Typography } from '@mui/material';

import { EActionEvent, useEvents } from '~/hooks/useEvents';

import {
  contactButton,
  contactButtonText,
  Container,
  needHelpText,
  phoneButton,
  phoneNumberStyle,
} from './useStyles';
import { CONTACT_US_TEXT } from '~/resources/resources';
import WhatsappIcon from '~/assets/icons/jsx/whatsappIcon';
import PhoneDropDown from '~/components/PhoneDropDown';
import { useLeadLocation } from '~/hooks/useLeadLocation';
import useLineOfBusinessTarget from '~/hooks/useLineOfBusinessTarget';
import { ESourceEventOptions } from '~/data/lead/enums';

type ContactUsProps = {
  isNavBar?: boolean;
};

export const ContactUs = ({ isNavBar }: ContactUsProps) => {
  const events = useEvents();
  const { sourceLocation, category } = useLeadLocation();
  const lineOfBusinessTarget = useLineOfBusinessTarget();

  const onClickWhatAppsIcon = useCallback(() => {
    const leadLocation = isNavBar
      ? ESourceEventOptions.NAV_BAR
      : sourceLocation;
    events.sendEvent(
      {
        action: `${EActionEvent.CONTACT_US_MODAL_WHATSAPP}`,
        category,
      },
      {
        leadLocation,
        businessLine: lineOfBusinessTarget,
      },
    );
    window.open(CONTACT_US_TEXT.whatsappLink, '_blank');
  }, [events, sourceLocation, category, lineOfBusinessTarget, isNavBar]);

  return (
    <Container>
      <Typography variant="h4" sx={{ ...needHelpText }}>
        {RESOURCES.CONTACT_US_NEED_FAST_HELP}
      </Typography>
      <PhoneDropDown
        phoneIconCustomStyle={{ width: '30px' }}
        optionContainerCustom={phoneButton}
        phoneNumberStyleCustom={phoneNumberStyle}
        isNavBar={isNavBar}
      />
      <Button
        label={RESOURCES.CONTACT_US_PAGE_WHATSAPP.toLocaleLowerCase()}
        type={BUTTON_TYPES.THIRD}
        size={BUTTON_SIZES.MEDIUM}
        icon={<WhatsappIcon style={{ width: '31px', height: '31px' }} />}
        typographyStyle={contactButtonText}
        customStyle={contactButton}
        onClickFunc={onClickWhatAppsIcon}
      />
    </Container>
  );
};
