import React from 'react';
import { ImageFile } from '~/data/types/images';

const CheckedboxIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="24"
      height="24"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="#00D6D1"
        stroke="#00D6D1"
      />
      <rect
        x="3.1665"
        y="3.16602"
        width="9.66667"
        height="9.66667"
        fill="#00D6D1"
      />
      <path
        d="M5.81314 7.99858C5.68804 7.88607 5.52502 7.82502 5.35679 7.82769C5.18856 7.83035 5.02756 7.89653 4.90608 8.01295C4.7846 8.12936 4.71164 8.2874 4.70182 8.45537C4.692 8.62333 4.74606 8.7888 4.85314 8.91858L6.33314 10.4719C6.39511 10.5369 6.46956 10.5887 6.55205 10.6242C6.63453 10.6597 6.72334 10.6782 6.81314 10.6786C6.90247 10.6791 6.99099 10.6617 7.07345 10.6273C7.1559 10.5929 7.23061 10.5424 7.29314 10.4786L11.8131 5.81191C11.8744 5.74887 11.9227 5.67439 11.9552 5.5927C11.9877 5.51101 12.0038 5.42372 12.0025 5.33581C12.0013 5.24791 11.9828 5.16111 11.948 5.08037C11.9132 4.99962 11.8628 4.92653 11.7998 4.86524C11.7368 4.80396 11.6623 4.75569 11.5806 4.72319C11.4989 4.6907 11.4116 4.67461 11.3237 4.67585C11.2358 4.67709 11.149 4.69563 11.0683 4.73041C10.9875 4.76519 10.9144 4.81554 10.8531 4.87858L6.81981 9.05191L5.81314 7.99858Z"
        fill="black"
      />
      <rect
        x="3.1665"
        y="3.16602"
        width="9.66667"
        height="9.66667"
        stroke="#00D6D1"
      />
    </Component>
  );
};

export default CheckedboxIconIcon;
