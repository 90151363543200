import { createSlice } from '@reduxjs/toolkit';
import { INewCarForSalePageData } from '~/data/types/newCarForSalePageContent/newCarForSalePage.types';
import { getNewCarForSalePageData } from '~/redux-toolkit/thunks/newCarForSalePageContentThunks';

interface NewCarForSalePageState {
  newCarForSalePage: INewCarForSalePageData | null;
}

const initialState: NewCarForSalePageState = {
  newCarForSalePage: null,
};

const newCarForSalePageSlice = createSlice({
  name: 'newCarForSalePageContentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewCarForSalePageData.fulfilled, (state, action) => {
      state.newCarForSalePage = action.payload ?? null;
    });
  },
});

export const {} = newCarForSalePageSlice.actions;

export default newCarForSalePageSlice.reducer;
