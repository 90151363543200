import { ParsedUrlQuery } from 'querystring';
import { PAYMENT_METHOD } from '~/data/searchFlowData';
import { BrandAndModel, ISearchCarsQuery } from '~/data/types/resultPageType';
import {
  ISearchUsedCarsQuery,
  USED_CAR_PAYMENT_METHOD,
} from '~/data/types/usedCar/usedCarData';

interface IBrandModel {
  brand: string | null;
  model: string | null;
  category: string | null;
}

/**
 * decode And Split
 * @param str
 * @returns splitted text
 */
const decodeAndSplit = (str: string): string[] =>
  decodeURIComponent(str).split(',');

/**
 * extract Brand And Model
 * @param params
 * @returns {}
 */
function extractParams(params: string[]): IBrandModel {
  let brand: string | null = null;
  let model: string | null = null;
  let category: string | null = null;

  if (!Array.isArray(params) || params.length === 0) {
    return { brand, model, category };
  }

  if (params[0] === 'category' && params.length > 1) {
    category = params[1];
  } else {
    [brand, model] = params;
  }

  return { brand, model, category };
}

/**
 * Filters a list of models to return a unique list of available models from a given model string array.
 * @param models: string[]
 * @param allModelNames: string[]
 * @returns string[]
 */
export const filterUniqueModels = (
  models: string[],
  allModelNames: string[],
): string[] => {
  const uniqueModels = new Set<string>();

  models?.forEach((model) => {
    const upperCaseModel = model.toUpperCase();
    const formattedModel = model.replace(/-/g, ' ').toUpperCase();

    if (allModelNames.includes(upperCaseModel)) {
      uniqueModels.add(upperCaseModel);
    } else if (allModelNames.includes(formattedModel)) {
      uniqueModels.add(formattedModel);
    }
  });

  return Array.from(uniqueModels);
};

/**
 * Filters a list of brands to return a unique list of available brands from a given brand object.
 * @param brands: string[]
 * @param brandObject: { [key: string]: string }
 * @returns string[]
 */
const filterUniqueBrandsFromObject = (
  brands: string[],
  brandObject: { [key: string]: string },
): string[] => {
  const uniqueBrands = new Set<string>();

  brands?.forEach((brand) => {
    const upperCaseModel = brand.toUpperCase();
    const formattedModel = brand.replace(/-/g, ' ').toUpperCase();

    Object.entries(brandObject).forEach(([key, value]) => {
      if (
        key.toUpperCase() === upperCaseModel ||
        key.toUpperCase() === formattedModel ||
        value.toUpperCase() === upperCaseModel ||
        value.toUpperCase() === formattedModel
      ) {
        uniqueBrands.add(value);
      }
    });
  });

  return Array.from(uniqueBrands);
};

interface ManufacturerItem {
  models: string[];
  ManufacturerName: string;
  ManufacturerEnum: { [key: string]: string };
}

interface ManufacturerMap {
  [key: string]: string;
}

export const brandNamesFilterParams = (brandNamesEnum: ManufacturerItem[]) => {
  return brandNamesEnum?.reduce(
    (acc: ManufacturerMap, item: ManufacturerItem) => {
      let key: string;
      let value: string;

      if (Object.keys(item.ManufacturerEnum).length > 0) {
        key = Object.keys(item.ManufacturerEnum)[0];
        value = item.ManufacturerEnum[key];
      } else {
        key = item.ManufacturerName;
        value = item.ManufacturerName;
      }

      const formattedKey = key.toLowerCase().replace(/\s+/g, '-');

      if (!acc[formattedKey]) {
        acc[formattedKey] = value;
      }

      return acc;
    },
    {},
  );
};

export function getKeyByValue(object: object, valueToFind: string) {
  const foundEntry = Object.entries(object).find(
    ([_key, value]) => value === valueToFind,
  );
  return foundEntry ? foundEntry[0] : null;
}

// populate used cars
export const populateFiltersFromQuery = (
  query: ParsedUrlQuery,
  filters: any,
  promoCardsLen?: number,
) => {
  const pageSize = 28 - (promoCardsLen || 0);
  const initialFilters: ISearchUsedCarsQuery = {
    filters: {
      budget: {
        paymentMethod: USED_CAR_PAYMENT_METHOD.monthly,
        priceRange: { minPrice: null, maxPrice: null },
      },
      colors: [],
      categories: [],
      models: [],
      brands: [],
      carHighlights: [],
      carBodyType: [],
      yearsRange: { minYear: null, maxYear: null },
      kmRange: { minPrice: null, maxPrice: null },
      gearType: [],
      engineType: [],
      carHand: [],
      originOwnership: [],
      imagesLength: false,
    },
    sort: { imagesLength: 'desc' },
    isAdditionFiltersSelected: false,
    pagination: { page: 1, pageSize: pageSize, pageCount: 0, total: 100 },
  };

  const safeParams = Array.isArray(query.params) ? query.params : [];
  const carParams: IBrandModel = extractParams(safeParams);
  const manufacturerEnumObj = brandNamesFilterParams(
    filters.manufactureWithModels,
  );

  const allModelNames = filters?.manufactureWithModels?.flatMap(
    (car: BrandAndModel) => car.models,
  );
  // NOTICE: This methods may help to validate data
  // const brandToModelsMap = filters.reduce(
  //   (
  //     acc: { [x: string]: any },
  //     car: { ManufacturerName: string | number; models: any },
  //   ) => {
  //     acc[car.ManufacturerName] = car.models;
  //     return acc;
  //   },
  //   {},
  // );

  // function isValidModelForBrand(model: string, brand: string) {
  //   return brandToModelsMap[brand]?.includes(model);
  // }

  let brands: string[] = [];

  if (query.brands) {
    const queryBrands = decodeAndSplit(query.brands as string);
    const uniqueBrands = filterUniqueBrandsFromObject(
      queryBrands,
      manufacturerEnumObj,
    );

    brands = brands.concat(uniqueBrands);
  }

  if (
    carParams.brand &&
    manufacturerEnumObj?.hasOwnProperty(
      carParams.brand.toLowerCase().replace(/\s+/g, '-'),
    )
  ) {
    brands.push(
      manufacturerEnumObj[carParams.brand.toLowerCase().replace(/\s+/g, '-')],
    );
  }

  initialFilters.filters.brands = Array.from(new Set(brands));

  // let models: string[] = [];

  if (query.models) {
    const splitModels = decodeAndSplit(query.models as string);
    initialFilters.filters.models = filterUniqueModels(
      splitModels,
      allModelNames,
    );
  }

  if (carParams.category) {
    const foundCategory = filters.categories.find(
      (category: any) => category?.url === carParams.category,
    );
    initialFilters.filters.categories = [foundCategory.name];
  }

  if (carParams.model) {
    const upperCaseModel = carParams.model.toUpperCase();
    const formattedModel = carParams.model.replace(/-/g, ' ').toUpperCase();

    if (
      allModelNames.includes(upperCaseModel) ||
      allModelNames.includes(formattedModel)
    ) {
      initialFilters.filters.models = allModelNames.includes(formattedModel)
        ? [formattedModel]
        : [upperCaseModel];
    }
  }

  if (query.paymentMethod) {
    initialFilters.filters.budget.paymentMethod = decodeURIComponent(
      query.paymentMethod as string,
    );
  }

  if (query.minPrice) {
    initialFilters.filters.budget.priceRange.minPrice = Number(query.minPrice);
  }

  if (query.maxPrice) {
    initialFilters.filters.budget.priceRange.maxPrice = Number(query.maxPrice);
  }

  if (query.colors) {
    initialFilters.filters.colors = decodeAndSplit(query.colors as string);
  }

  if (query.categories) {
    initialFilters.filters.categories = decodeAndSplit(
      query.categories as string,
    );
  }

  if (query.minYear) {
    initialFilters.filters.yearsRange.minYear = String(query.minYear);
  }

  if (query.maxYear) {
    initialFilters.filters.yearsRange.maxYear = String(query.maxYear);
  }

  if (query.minKm) {
    initialFilters.filters.kmRange.minPrice = Number(query.minKm);
  }

  if (query.maxKm) {
    initialFilters.filters.kmRange.maxPrice = Number(query.maxKm);
  }

  if (query.carHand) {
    initialFilters.filters.carHand = decodeAndSplit(query.carHand as string);
  }

  if (query.fuelType) {
    initialFilters.filters.engineType = decodeAndSplit(
      query.fuelType as string,
    );
  }

  if (query.gear) {
    initialFilters.filters.gearType = decodeAndSplit(query.gear as string);
  }

  if (query.originOwnership) {
    initialFilters.filters.originOwnership = decodeAndSplit(
      String(query.originOwnership),
    );
  }

  if (query.highlights) {
    initialFilters.filters.carHighlights = decodeAndSplit(
      query.highlights as string,
    );
  }

  if (query.bodyTypes) {
    initialFilters.filters.carBodyType = decodeAndSplit(
      query.bodyTypes as string,
    );
  }

  return initialFilters;
};

// populate new cars
export const populateNewCarFiltersFromQuery = (
  query: ParsedUrlQuery,
  filters: any,
) => {
  const initialFilters: ISearchCarsQuery = {
    filters: {
      budget: {
        paymentMethod: PAYMENT_METHOD.monthly,
        priceRange: {
          minPrice: null,
          maxPrice: null,
        },
      },
      colors: [],
      categories: [],
      models: [],
      brands: [],
      carDescriptionHighlights: [],
      carBodyType: [],
    },
    pagination: {
      page: 1,
      pageSize: 200,
    },
    sortBy: {
      title: 'מחיר נמוך לגבוה',
      value: 'asc',
    },
    isAdditionFiltersSelected: false,
  };

  const safeParams = Array.isArray(query.params) ? query.params : [];
  const carParams: IBrandModel = extractParams(safeParams);
  const manufacturerEnumObj = brandNamesFilterParams(
    filters.manufactureWithModels,
  );
  const allModelNames = filters?.manufactureWithModels?.flatMap(
    (car: BrandAndModel) => car.models,
  );

  let brands: string[] = [];

  if (query.brands) {
    const queryBrands = decodeAndSplit(query.brands as string);
    const uniqueBrands = filterUniqueBrandsFromObject(
      queryBrands,
      manufacturerEnumObj,
    );

    brands = brands.concat(uniqueBrands);
  }

  if (
    carParams.brand &&
    manufacturerEnumObj?.hasOwnProperty(
      carParams.brand.toLowerCase().replace(/\s+/g, '-'),
    )
  ) {
    brands.push(
      manufacturerEnumObj[carParams.brand.toLowerCase().replace(/\s+/g, '-')],
    );
  }

  initialFilters.filters.brands = Array.from(new Set(brands));

  if (query.models) {
    const splitModels = decodeAndSplit(query.models as string);
    initialFilters.filters.models = filterUniqueModels(
      splitModels,
      allModelNames,
    );
  }

  if (carParams.category) {
    const foundCategory = filters.categories.find(
      (category: any) => category?.url === carParams.category,
    );
    initialFilters.filters.categories = [foundCategory.name];
  }

  if (carParams.model) {
    const upperCaseModel = carParams.model.toUpperCase();
    const formattedModel = carParams.model.replace(/-/g, ' ').toUpperCase();

    if (
      allModelNames.includes(upperCaseModel) ||
      allModelNames.includes(formattedModel)
    ) {
      initialFilters.filters.models = allModelNames.includes(formattedModel)
        ? [formattedModel]
        : [upperCaseModel];
    }
  }

  if (query.paymentMethod) {
    initialFilters.filters.budget.paymentMethod = decodeURIComponent(
      query.paymentMethod as string,
    );
  }

  if (query.minPrice) {
    initialFilters.filters.budget.priceRange.minPrice = Number(query.minPrice);
  }

  if (query.maxPrice) {
    initialFilters.filters.budget.priceRange.maxPrice = Number(query.maxPrice);
  }

  if (query.colors) {
    initialFilters.filters.colors = decodeAndSplit(query.colors as string);
  }

  if (query.categories) {
    initialFilters.filters.categories = decodeAndSplit(
      query.categories as string,
    );
  }

  if (query.highlights) {
    initialFilters.filters.carDescriptionHighlights = decodeAndSplit(
      query.highlights as string,
    );
  }

  if (query.bodyTypes) {
    initialFilters.filters.carBodyType = decodeAndSplit(
      query.bodyTypes as string,
    );
  }

  return initialFilters;
};
