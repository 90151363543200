import carImage from '~/assets/images/CaruselCarImage.jpg';
import carImage2 from '~/assets/images/CaruselCarImage2.jpg';
import emptyCar from '~/assets/images/EmptyCar.png';
import { FamilyCar } from './types/car/cars';

export const CARS = [
  {
    images: [carImage, carImage2, carImage2, emptyCar],
    brandTitle: 'פולסווגן',
    modelTitle: 'Arteon R 2 שורות',
    carPrice: {
      leasingPrice: '2600',
      fundingPrice: '1200',
      buyingPrice: '120000',
    },
  },
  {
    images: [carImage2, carImage2, emptyCar],
    brandTitle: 'אופל',
    modelTitle: 'Astra R 2 שורות',
    carPrice: {
      leasingPrice: '2600',
      fundingPrice: '1200',
      buyingPrice: '120000',
    },
  },
  {
    images: [emptyCar, carImage2, carImage2],
    brandTitle: 'יונדאי',
    modelTitle: 'Venue R 2 שורות',
    carPrice: {
      leasingPrice: '2600',
      fundingPrice: '1200',
      buyingPrice: '120000',
    },
  },
];

export const carPageMock: FamilyCar = {
  id: 0,
  attributes: {
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
    dmvManufacturerCode: '',
    dmvModelCode: '',
    dmvManufacturerName: '',
    manufacturerDisplayName: '',
    modelNameDisplayName: '',
    dmvModelName: '',
    categories: '',
    familyCarId: '',
    familyCarZoneId: '',
    familyCarPromotion: {
      title: '',
      topLabel: '',
      bottomLabel: '',
      isPublish: false,
    },
    newCarMinPrice: 0,
    leaseMinPrice: 0,
    carDescriptionParagraph: 'string',
    carProgrammaticDescription: 'string',
    newCarLoanMinPrice: 0,
    newCarPrice: [],
    newCarLoanPrice: [],
    leaseCarMinPriceList: 0,
    leaseCarPriceList: [],
    leaseCarPrice: [],
    allColors: [],
    freesbeCategories: { data: [] },
    carDescriptionHighlights: { data: [] },
    images: {
      data: [],
    },
    colors: '',
    year: 2025,
    carCardBottomLabel: {
      data: { id: 0, attributes: { title: '', subLabelTitle: '' } },
    },
    caCardTopLabel: {
      data: { id: 0, attributes: { text: '', subLabelTitle: '' } },
    },
    legalInfo: [],
    acquireType: 'regular',
    leadType: '',
    carBodyType: {
      data: {
        id: 0,
        attributes: {
          title: '',
          displayTitleName: '',
          createdAt: '',
          updatedAt: '',
          publishedAt: '',
        },
      },
    },
    carBullets: { data: [] },
    carZoneModelNameEn: '',
    carZoneMakeNameEn: '',
    carPageHeadings: {
      carInfo: {
        title: '',
        subtitle: '',
      },
      interiorDesign: {
        title: '',
        subtitle: '',
      },
    },
    pageViewsOneMonthAgo: 0,
    newCarId: 0,
  },
};
export const paginationDefault = {
  page: 1,
  pageSize: 25,
  pageCount: 1,
  total: 3,
};
export const metaDefault = {
  pagination: paginationDefault,
};

export const optionsMockRP = {
  brands: {
    isSelected: true,
    values: [],
    name: 'יצרן',
  },
  models: {
    isSelected: true,
    values: [],
    name: 'דגם',
  },
  categories: {
    isSelected: true,
    values: [],
    name: 'קטגוריה',
  },
  colors: {
    isSelected: true,

    values: [],
    name: 'צבע',
  },
  manufactureYear: {
    isSelected: true,
    values: [],
    name: 'שנת ייצור',
  },
  carHand: {
    isSelected: true,
    values: [],
    name: 'יד',
  },
  originOwnership: {
    isSelected: true,
    values: [],
    name: 'בעלות מקורית',
  },
  gearType: {
    isSelected: true,
    values: [],
    name: 'תיבת הילוכים',
  },
  imagesLength: false,
  engineType: {
    isSelected: true,
    values: [],
    name: 'מנוע',
  },
  km: { isSelected: true, values: [], name: "קילומטראז'" },
  carHighlights: { isSelected: true, values: [], name: 'מאפייני המכונית' },
  carBodyType: { isSelected: true, values: [], name: 'מרכב' },
};
