import React from 'react';
import { ImageFile } from '~/data/types/images';

const DownIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
  stroke = '#262626',
}) => {
  return (
    <Component
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M12.8334 1.08398L7.00008 6.91732L1.16675 1.08398"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default DownIcon;
