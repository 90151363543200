export const localStorageKey = 'FUNDING_CODE';
export const localStorageViewedKey = 'FUNDING_CODE_VIEWED';
const EXPIRATION_TIME_IN_MS = 1814400000;

export const getFindingCodeFromLocalStorage = (): string => {
  try {
    const localValue = localStorage.getItem(localStorageKey);
    if (localValue) {
      const value = JSON.parse(localValue);
      if (value.timestamp > Date.now() - EXPIRATION_TIME_IN_MS) {
        return value.code;
      }
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const setFindingCodeFromLocalStorage = (code: string) => {
  try {
    const json = { code, timestamp: Date.now() };
    localStorage.setItem(localStorageKey, JSON.stringify(json));
  } catch (error) {
    console.log(error);
  }
};

export const checkFindingCodeFromLocalStorage = (
  code: string,
  action: 'set' | 'get',
  isViewed?: 'yes' | 'no',
) => {
  try {
    if (action === 'set') {
      const json = { code, isViewed: isViewed };
      localStorage.setItem(localStorageViewedKey, JSON.stringify(json));
    } else if (action === 'get') {
      const localValue = localStorage.getItem(localStorageViewedKey);
      if (localValue) {
        const value = JSON.parse(localValue);
        return value.isViewed === 'yes';
      }
    }
  } catch (error) {
    console.log(error);
  }
};
