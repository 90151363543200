import React from 'react';
import { ImageFile } from '~/data/types/images';

const leftArrow: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
    >
      <path
        d="M6.91797 12.3337L1.08463 6.50033L6.91797 0.666992"
        stroke="black"
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default leftArrow;
