import { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useIsMobile } from '~/hooks/useIsMobile';
import { SERVICES_LINKS_TYPE, useServicesFooter } from '~/data/useServices';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import CheckBoxSectionList from '~/components/CheckBoxSectionList';
import {
  listItemStyle,
  DesktopFooterContainer,
  MenuList,
  linksTitle,
  sectionTitle,
  liStyle,
} from './useStyles';
import FooterMenuItem from '../FooterMenuItem';
import RESOURCES from '~/resources';

const FooterMenu = () => {
  const services = useServicesFooter();
  const events = useEvents();
  const isMobile = useIsMobile();
  const [openCategory, setOpenCategory] = useState<string | null>();

  const onClickEvent = useCallback(
    (title: string) => {
      events.sendEvent(
        {
          category: ECategoryEvent.FOOTER_MENU,
          action: EActionEvent.FOOTER_MENU_LINK,
        },
        { linkTitle: title },
      );
    },
    [events],
  );

  const handleCategoryToggle = (category: string) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const renderMobileFooter = (section: any) => {
    if (section.title === RESOURCES.NAV_TITLE_CONTACT_US) {
      return (
        <DesktopFooterContainer key={section.title}>
          <Typography variant="h6" sx={linksTitle}>
            {section.title}
          </Typography>
          <ul>
            {section.links?.map((link: any) =>
              link.type === SERVICES_LINKS_TYPE.ROUTE ? (
                <li key={link.title} style={liStyle}>
                  <FooterMenuItem
                    key={link.title}
                    title={link.title}
                    route={link.path as string}
                    onClick={() => onClickEvent(link.title)}
                    sxName={listItemStyle}
                    routeType={link.type}
                  />
                </li>
              ) : (
                <li key={link.title} style={liStyle}>
                  <FooterMenuItem
                    key={link.title}
                    title={link.title}
                    route={link.path}
                    onClick={() => onClickEvent(link.title)}
                    routeType={link.type}
                  />
                </li>
              ),
            )}
          </ul>
        </DesktopFooterContainer>
      );
    }
    return (
      <div key={section.title}>
        <CheckBoxSectionList
          category={section.title}
          isOpen={openCategory === section.title}
          onCategoryToggle={() => handleCategoryToggle(section.title)}
          dropDownStyle={sectionTitle}
          headerStyle={linksTitle}
        >
          <ul>
            {section.links?.map((link: any) =>
              link.type === SERVICES_LINKS_TYPE.ROUTE ? (
                <li key={link.title} style={liStyle}>
                  <FooterMenuItem
                    key={link.title}
                    title={link.title}
                    route={link.path as string}
                    onClick={() => onClickEvent(link.title)}
                    sxName={listItemStyle}
                    routeType={link.type}
                  />
                </li>
              ) : (
                <li key={link.title} style={liStyle}>
                  <FooterMenuItem
                    key={link.title}
                    title={link.title}
                    route={link.path}
                    onClick={() => onClickEvent(link.title)}
                    routeType={link.type}
                  />
                </li>
              ),
            )}
          </ul>
        </CheckBoxSectionList>
      </div>
    );
  };

  const renderDesktopFooter = (section: any) => (
    <DesktopFooterContainer key={section.title}>
      <Typography variant="h6" sx={linksTitle}>
        {section.title}
      </Typography>
      <ul>
        {section.links?.map((link: any) =>
          link.type === SERVICES_LINKS_TYPE.ROUTE ? (
            <li key={link.title} style={liStyle}>
              <FooterMenuItem
                key={link.title}
                title={link.title}
                route={link.path as string}
                onClick={() => onClickEvent(link.title)}
                sxName={listItemStyle}
                routeType={link.type}
              />
            </li>
          ) : (
            <li key={link.title} style={liStyle}>
              <FooterMenuItem
                key={link.title}
                title={link.title}
                route={link.path}
                onClick={() => onClickEvent(link.title)}
                routeType={link.type}
              />
            </li>
          ),
        )}
      </ul>
    </DesktopFooterContainer>
  );

  return (
    <MenuList component={'nav'} role="navigation">
      {services.map((section) =>
        isMobile ? renderMobileFooter(section) : renderDesktopFooter(section),
      )}
    </MenuList>
  );
};

export default FooterMenu;
