import { Box, SelectChangeEvent, Typography } from '@mui/material';
import Button from '~/components/Button';
import RadioButton from '~/components/RadioButton';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import {
  AGE_OPTIONS,
  EMPLOY_STATUS,
  SALARY_INCOME,
  YOUR_PAST_OPTIONS,
} from '~/data/ApprovalFlowData';
import RESOURCES from '~/resources';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { useCallback, useMemo } from 'react';
import {
  setAgeConfirmed,
  setCreditConfirmed,
  setEmploymentStatus,
  setLastThreeYearsProblems,
  setMonthlySalary,
  setApprovalActiveStep,
} from '~/redux-toolkit/slices/approvalFlowSlice';
import {
  EActionEvent,
  EBusinessLineEvent,
  ECategoryEvent,
  useEvents,
} from '~/hooks/useEvents';
import DropDown from '~/components/Checkbox';
import Doodle from '~/assets/icons/jsx/Doodel';
import useStyles, {
  BottomTextStyle,
  descriptionStyle,
  headerContentStyle,
  submitButtonStyle,
  titleStyle,
  animationStyle,
} from './useStyles';

const ApprovalQueryStep = () => {
  const { approvalFlowData, approvalActiveStep, source } = useSelector(
    (state: RootState) => state.approvalFlow,
  );

  const {
    monthlySalary,
    employmentStatus,
    ageConfirmed,
    lastThreeYearsProblems,
    creditConfirmed,
  } = approvalFlowData;
  const isALlFilled = useMemo(
    () =>
      monthlySalary !== 0 &&
      employmentStatus.length &&
      ageConfirmed.length &&
      creditConfirmed.length &&
      lastThreeYearsProblems.length,
    [
      ageConfirmed.length,
      creditConfirmed.length,
      employmentStatus.length,
      lastThreeYearsProblems.length,
      monthlySalary,
    ],
  );

  const dispatch = useAppDispatch();

  const events = useEvents();

  const handleNext = useCallback(() => {
    events.sendEvent(
      {
        action: EActionEvent.APPROVAL_FLOW_SUBMIT,
        category: ECategoryEvent.FUNDING_APPROVAL,
      },
      {
        approvalLocation: source,
        businessLine: EBusinessLineEvent.FUNDING,
      },
    );
    dispatch(setApprovalActiveStep(approvalActiveStep + 1));
  }, [approvalActiveStep, dispatch, events, source]);

  const onAgeConfirmed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setAgeConfirmed(e.target.value));
    },
    [dispatch],
  );

  const onChangeMonthlySalary = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      e.stopPropagation();
      dispatch(setMonthlySalary(e.target.value));
    },
    [dispatch],
  );
  const onChangesetEmploymentStatus = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      e.stopPropagation();
      dispatch(setEmploymentStatus(e.target.value));
    },
    [dispatch],
  );

  const onChangeLastThreeYearsProblems = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLastThreeYearsProblems(e.target.value));
    },
    [dispatch],
  );
  const onChangeECreditConfirmed = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setCreditConfirmed(e.target.value));
    },
    [dispatch],
  );
  const classes = useStyles();
  return (
    <>
      <Box sx={headerContentStyle}>
        <Box sx={classes.titleWrapper}>
          <Typography
            variant="h2"
            sx={titleStyle}
            tabIndex={-1}
            aria-live="polite"
          >
            {RESOURCES.APPROVAL_QUERY_TITLE}
          </Typography>
          <Doodle Component={animationStyle} />
        </Box>
        <Typography variant="h4" sx={descriptionStyle}>
          {RESOURCES.APPROVAL_QUERY_DESCRIPTION}
        </Typography>
      </Box>
      <RadioButton
        formLabel={RESOURCES.APPROVAL_YOUR_AGE_CHECK}
        labels={AGE_OPTIONS}
        handleChange={(e) => onAgeConfirmed(e)}
      />
      <Box sx={classes.dropsDownsWrapper}>
        <DropDown
          values={EMPLOY_STATUS}
          onChange={onChangesetEmploymentStatus}
        />
        <DropDown values={SALARY_INCOME} onChange={onChangeMonthlySalary} />
      </Box>
      <Box sx={classes.radioWrapper}>
        <RadioButton
          formLabel={RESOURCES.APPROVAL_YOUR_PAST_CHECK}
          labels={YOUR_PAST_OPTIONS}
          handleChange={onChangeLastThreeYearsProblems}
        />
        <RadioButton
          formLabel={RESOURCES.APPROVAL_YOUR_CREDIT_CHECK}
          labels={AGE_OPTIONS}
          handleChange={onChangeECreditConfirmed}
        />
      </Box>
      <Typography sx={BottomTextStyle} tabIndex={0}>
        {RESOURCES.APPROVAL_QUERY_CONCLUSION}
      </Typography>
      <Button
        label={RESOURCES.APPROVAL_GET_YOU_CODE}
        type={isALlFilled ? BUTTON_TYPES.MAIN : BUTTON_TYPES.MAIN_DISABLED}
        size={BUTTON_SIZES.LARGE}
        customStyle={submitButtonStyle}
        onClickFunc={handleNext}
        isDisabled={!isALlFilled}
      />
    </>
  );
};

export default ApprovalQueryStep;
