import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const useStyles = {
  root: {
    direction: 'rtl',
    color: COLORS.black.dark,
    backgroundColor: COLORS.primary.main,
    padding: '8px 12px',
    height: '48px',
    minWidth: '96px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    '&.Mui-disabled': {
      '& .MuiTypography-root': {
        color: COLORS.gray.dark,
      },
    },
    '&:focus-visible': {
      border: '1px solid white',
      outline: '2px solid black  ',
    },
  } as const,
  main: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.primary.main,
      '&:hover': {
        backgroundColor: COLORS.primary.light5,
      },
      '&.Mui-disabled': {
        background: COLORS.primary.light3,
      },
      [theme.breakpoints.down('md')]: {
        '&:hover': {
          backgroundColor: COLORS.primary.main,
        },
      },
    },
  },
  mainDisabled: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.primary.light3,
      cursor: 'default',
      '&:hover': {
        backgroundColor: COLORS.primary.light3,
      },
    },
  },
  secondary: {
    '&.MuiButtonBase-root': {
      backgroundColor: 'transparent',
      border: `solid 1px ${COLORS.primary.main}`,
      color: COLORS.black.main,
      '&:hover': {
        backgroundColor: COLORS.primary.light2,
        color: 'black',
      },
      '&:focus-visible': {
        backgroundColor: COLORS.primary.light2,
        outline: `solid 1px ${COLORS.primary.dark2}`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `solid 1px ${COLORS.gray.light6}`,
      },
    },
  },
  secondaryDisabled: {
    '&.MuiButtonBase-root': {
      cursor: 'default',
      backgroundColor: 'transparent',
      border: `solid 1px ${COLORS.gray.light6}`,
      color: COLORS.black.main,
      '&:hover': {
        backgroundColor: 'transparent',
        border: `solid 1px ${COLORS.gray.light6}`,
      },
    },
  },
  secondaryV2: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.white.main,
      border: `solid 1px ${COLORS.primary.main}`,
      color: COLORS.black.main,
      '&:hover': {
        backgroundColor: COLORS.primary.light2,
        color: 'black',
      },
      '&:focus-visible': {
        backgroundColor: COLORS.primary.light2,
        outline: `solid 1px ${COLORS.primary.dark2}`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `solid 1px ${COLORS.gray.light6}`,
      },
    },
  },
  secondaryDisabledV2: {
    '&.MuiButtonBase-root': {
      cursor: 'default',
      backgroundColor: COLORS.white.main,
      border: `solid 1px ${COLORS.gray.light6}`,
      color: COLORS.black.main,
      '&:hover': {
        backgroundColor: 'transparent',
        border: `solid 1px ${COLORS.gray.light6}`,
      },
    },
  },

  third: {
    '&.MuiButtonBase-root': {
      backgroundColor: 'transparent',
      '& .MuiTypography-root': {
        color: COLORS.primary.dark2,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiTypography-root': {
          color: COLORS.black.main,
        },
      },
      '&.Mui-disabled': {
        border: `solid 1px ${COLORS.gray.light6}`,
      },
    },
  },
  thirdDisabled: {
    cursor: 'default',
    backgroundColor: 'transparent',
    border: `solid 1px ${COLORS.gray.light6}`,
    '& .MuiTypography-root': {
      color: COLORS.primary.dark2,
    },
  },
  mainYellow: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.yellow.main,
      color: COLORS.black.main,
      '&:hover': {
        backgroundColor: COLORS.yellow.dark,
      },
      '&.Mui-disabled': {
        background: COLORS.yellow.dark2,
      },
    },
  },
  mainYellowDisabled: {
    '&.MuiButtonBase-root': {
      cursor: 'default',
      backgroundColor: COLORS.yellow.dark2,
      '&:hover': {
        backgroundColor: COLORS.yellow.dark2,
      },
    },
  },
  secondaryYellow: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.yellow.main,
      color: COLORS.black.main,
      borderRadius: '25px',
      justifyContent: 'space-between',

      '& div': {
        width: '28%',
        aspectRatio: '1/1',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        borderRadius: '50%',
        justifyContent: 'center',
        marginLeft: '-8px',

        [theme.breakpoints.up('md')]: {
          left: '6px',
        },
        '& > svg': {
          width: '20px',
          transition: 'transform 0.3s ease',
        },
      },
      '&:hover': {
        backgroundColor: COLORS.yellow.dark,
        '& div > svg': {
          transform: 'translateX(-2px)',
        },
      },
      '&.Mui-disabled': {
        background: COLORS.yellow.dark2,
      },
    },
  },

  transparent: {
    '&.MuiButtonBase-root': {
      backgroundColor: 'transparent',
      color: COLORS.black.main,
      padding: '0',
    },
  },
  black: {
    '&.MuiButtonBase-root': {
      backgroundColor: COLORS.black.dark,
      color: COLORS.white.main,
      '& p': {
        color: COLORS.white.main,
      },
      '& svg, & path': {
        stroke: COLORS.white.main,
      },
      '&:hover': {
        backgroundColor: COLORS.black.dark,
        color: COLORS.white.main,

        opacity: '0.7',
      },
      '&.Mui-disabled': {
        backgroundColor: COLORS.black.dark,
        color: COLORS.white.main,
        opacity: '0.6',
      },
    },
  },
  bordered: {
    '&.MuiButtonBase-root': {
      border: `1px solid ${COLORS.black.dark}`,
      color: COLORS.black.main,
      '&:hover': {
        borderColor: COLORS.black.dark,
        backgroundColor: COLORS.white.main,
      },
      '&.Mui-disabled': {
        borderColor: COLORS.black.dark,
        opacity: '0.6',
      },
    },
  },
  noResult: {
    '&.MuiButtonBase-root': {
      background: '#ffffff',
      border: `1px solid ${COLORS.gray.light2}`,
      borderRadius: '60px',
      padding: '0 50px',
      gap: '10px',
      color: COLORS.black.main,
      '&:hover': {
        borderColor: COLORS.black.dark,
        backgroundColor: COLORS.white.main,
      },
      '&.Mui-disabled': {
        borderColor: COLORS.black.dark,
        opacity: '0.6',
      },
    },
  },
  placeholderStyle: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    position: 'relative ',
    [theme.breakpoints.down('md')]: {
      position: 'unset',
    },
  },
  placeholder: {
    color: COLORS.black.main,
    fontSize: '12px',
    fontWeight: 400,
    marginLeft: '8px',
    background: '#FFE500',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '2px 6px 0px 6px',
    position: 'absolute',
    left: '-62px',
    [theme.breakpoints.down('md')]: {
      left: 'unset',
      top: '-10px',
      right: '11px',
    },
  },
};

export const ButtonStyle = {
  color: COLORS.black.dark,
  backgroundColor: COLORS.primary.main,
  padding: '8px 12px',
  height: '48px',
  minWidth: '96px',
  border: 'none',
  borderRadius: '4px',
  fontSize: '16px',
  cursor: 'pointer',
  '&:hover': {
    background: COLORS.primary.light5,
  },
};

export const labelSx = {
  fontSize: '16px ',
  color: COLORS.black.main,
  textAlign: 'center',
  fontWeight: 400,
};

export const sizes = {
  fit: {
    width: 'fit-content',
    height: '48px',
  },
  large: {
    width: '172px',
    height: '48px',
  },
  medium: {
    width: '172px',
    height: '40px',
  },
  small: {
    width: '172px',
    height: '24px',
  },
};

export const loaderStyle = {
  marginRight: 2,
};

export const fullWidthStyle = {
  width: '100%',
};
