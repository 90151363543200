import { createAsyncThunk } from '@reduxjs/toolkit';
import { CarTechSpecData } from '~/data/car/carTechSpec';
import carTechSpecAdapter from '~/redux-toolkit/adapters/carTechSpecAdapter';

export interface IGetCarTechSpecProps {
  model: string;
  isCarZeroKm?: boolean;
  carId?: number;
}

export const getCarTechSpec = createAsyncThunk(
  'api/car-tech-spec',
  async ({
    model,
    isCarZeroKm,
    carId,
  }: IGetCarTechSpecProps): Promise<CarTechSpecData[]> => {
    try {
      const response = await carTechSpecAdapter.getTechSpec(
        model,
        isCarZeroKm,
        carId,
      );
      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getLeaseCarTechSpec = createAsyncThunk(
  'api/leasing/car-tech-spec',
  async (model: string): Promise<CarTechSpecData[]> => {
    try {
      const response = await carTechSpecAdapter.getLeasingTechSpec(model);
      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
