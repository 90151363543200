/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetBannerCardsResponseType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';
import lobbyPageAdapter from '../adapters/lobbyPageAdapter';
import { CategoriesResponseType } from '~/data/types/categories/categories';
import { SupportedBrandData } from '~/data/types/brands';
import { homePageContentType } from '~/data/types/homePageContentType';
import { QuickSearchContentType } from '~/data/QuickSearch/quickSearchType';
import { VideoDataType } from '~/data/types/video';
import { seoResultPageDataEntity } from '~/data/types/seo/seoResutlsPages';
import { BudgetCardsResponseType } from '~/data/types/budgetCardTypes';

export const getLobbyContent = createAsyncThunk(
  'lobby-content',
  async (page: string): Promise<homePageContentType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyContent(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyNewCarContent = createAsyncThunk(
  'lobby-content-new-car',
  async (): Promise<homePageContentType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyNewCarContent();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyUsedCarContent = createAsyncThunk(
  'lobby-content-used-car',
  async (): Promise<homePageContentType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyUsedCarContent();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyPageQuickSearch = createAsyncThunk(
  '/api/home-page-quick-searches?populate=*',
  async (page: string): Promise<QuickSearchContentType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyPageQuickSearch(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyPageNewCarQuickSearch = createAsyncThunk(
  'home-page-quick-searches-new-car*',
  async (): Promise<QuickSearchContentType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyPageNewCarQuickSearch();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyPageUsedCarQuickSearch = createAsyncThunk(
  'home-page-quick-searches-used-car*',
  async (): Promise<QuickSearchContentType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyPageUsedCarQuickSearch();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyPageBannerGallery = createAsyncThunk(
  '/lobby-gallery-card-banners',
  async (page: string): Promise<GetBannerCardsResponseType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyPageBannerGallery(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyNewCarPageBannerGallery = createAsyncThunk(
  '/lobby-gallery-card-banners-new-car',
  async (page: string): Promise<GetBannerCardsResponseType[]> => {
    try {
      const response =
        await lobbyPageAdapter.getLobbyNewCarPageBannerGallery(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyUsedCarPageBannerGallery = createAsyncThunk(
  '/lobby-gallery-card-banners-used-car',
  async (page: string): Promise<GetBannerCardsResponseType[]> => {
    try {
      const response =
        await lobbyPageAdapter.getLobbyUsedCarPageBannerGallery(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyCategories = createAsyncThunk(
  '/lobby-categories',
  async (page: string): Promise<CategoriesResponseType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyCategories(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbySupportedBrands = createAsyncThunk(
  '/lobby-supported-brands',
  async (filterShowInFreesbe: boolean): Promise<SupportedBrandData[]> => {
    try {
      const response =
        await lobbyPageAdapter.getLobbySupportedBrands(filterShowInFreesbe);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyVideo = createAsyncThunk(
  '/lobby-video',
  async (page: string): Promise<VideoDataType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyVideo(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getSeoContent = createAsyncThunk(
  '/seo-lobby-page',
  async (page: string): Promise<seoResultPageDataEntity> => {
    try {
      const response = await lobbyPageAdapter.getSeoContent(page);

      return response[0];
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);

export const getLobbyPageStaticBanners = createAsyncThunk(
  '/static-card-banners-lobby',
  async (page: string): Promise<GetBannerCardsResponseType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyPageStaticBanners(page);
      return response;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbySearchByNeeds = createAsyncThunk(
  '/search-by-need-lobby',
  async (page: string): Promise<SearchByNeedResponseType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbySearchByNeeds(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLobbyPageBudgetCards = createAsyncThunk(
  '/budget-cards',
  async (page: string): Promise<BudgetCardsResponseType[]> => {
    try {
      const response = await lobbyPageAdapter.getLobbyPageBudgetCards(page);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);
