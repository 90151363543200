import ICONS_CAR_FLOW from '~/data';
import {
  AccessoryCard,
  AdditionType,
  ICarFlowData,
  SelectedCar,
  WarrantyDataType,
} from './carFlowTypes';

export const ACCESSORIES: AccessoryCard[] = [
  {
    id: '1',
    title: '',
    price: 0,
    imgUrl: ICONS_CAR_FLOW.phoneHolder.src,
  },
];

export const ADDITIONS_MOCK: AdditionType[] = [
  { accessory: 'מראה אחורית', title: '' },
  { accessory: 'חיישני רוורס', title: '' },
  { accessory: 'מראה אחורית', title: '' },
];

export const WARRANTY_PLANS: WarrantyDataType[] = [
  {
    id: 1,
    title: 'אחריות יצרן:',
    description: `תכולת אחריות היצרן ל 3 שנים או 100,000 ק"מ, המוקדם מבניהם:
      `,
    // description: `תכולת אחריות היצרן ל 3 שנים או 100,000 ק"מ, המוקדם מבניהם:
    //  אחריות מלאה על כלל מכללי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.
    //  ברכב חשמלי/ מיקרו היברידי/היברידי (א) האחריות על חלק מדגמי הרכב אינה מוגבלת בנסועה בשנתיים הראשונות, ובחלקה בתוקף לחמש שנים או 100,000 ק״מ, המוקדם מבניהם. (ב) האחריות למערכות ההנעה והסוללה על דגמי הרכב השונים ובכפוף ליצרן הרכב הן ל – 5 עד 8 או 100,000 עד 160,000 ק״מ, המוקדם מבניהם. האחריות תינתן כמפורט בחוברת האחריו`,
    selectedImage: ICONS_CAR_FLOW.unnumberedWarrantySelected,
    image: ICONS_CAR_FLOW.unnumberedWarranty,
    price: 0,
    brand: '',
    isExtendedWarranty: false,
    extendedWarrantyId: '',
    extendedWarrantySFId: '',
    years_battery: '',
    km_battery: '',
    km: '',
    years: '',
    extendedTitle: '',
    extendedDescription: '',
  },
  {
    id: 2,
    title: 'אחריות יצרן:',
    description: `תכולת הארכת האחריות ל-5 שנים או 150,000 ק"מ, המוקדם מבניהם:
      הארכת כתב האחריות המקורי של היצרן לתקופה נוספת, בתכולה זהה למקורית- ניתנת ע"י היבואן.
      האחריות מותנית בין היתר בטיפול ברכב במוסך מורשה יבואן בכל תקופת האחריות.
      ברכב חשמלי/ היברידי - על מערכות ההנעה והסוללה יש אחריות יצרן ללא הארכה, ע"פ כתב האחריות.`,
    price: 2000,
    selectedImage: ICONS_CAR_FLOW.unnumberedWarrantySelected,
    image: ICONS_CAR_FLOW.unnumberedWarranty,
    brand: '',
    isExtendedWarranty: true,
    extendedWarrantyId: '',
    extendedWarrantySFId: '',
    years_battery: '',
    km_battery: '',
    km: '',
    years: '',
    extendedTitle: '',
    extendedDescription: '',
  },
];
// export const THREE_YEARS_WARRANTY = WARRANTY_PLANS[0];
// export const FIVE_YEARS_WARRANTY = WARRANTY_PLANS[1];

export const CHERRY_WARRANTY = {
  // ...FIVE_YEARS_WARRANTY,
  title: 'אחריות ל-8 שנים:',
  description: `אחריות ל-8 שנים או 160,000 ק"מ (המוקדם מבניהם) על סוללת הרכב ואחריות ל-6 שנים או 150,000 ק"מ
  (המוקדם מבניהם) על כלל מכלולי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.
  האחריות תינתן כמפורט בחוברת האחריות. האחריות ביחס לחלק ממחלולי הרכב מוגבלת כמפורט
  בתעודת האחריות. האחריות מתייחסת לשימוש כרכב פרטי.`,
  selectedImage: ICONS_CAR_FLOW.unnumberedWarrantySelected,
  image: ICONS_CAR_FLOW.unnumberedWarranty,
};

export const EVEASY_WARRANTY = {
  ...CHERRY_WARRANTY,
  title: 'אחריות ל-8 שנים:',
  description: `אחריות ל-8 שנים או 150,000 ק"מ (המוקדם מבניהם) על סוללת הרכב ואחריות ל-5 שנים או 150,000 ק"מ
  (המוקדם מבניהם) על כלל מכלולי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.
  האחריות תינתן כמפורט בחוברת האחריות. האחריות ביחס לחלק ממחלולי הרכב מוגבלת כמפורט
  בתעודת האחריות. האחריות מתייחסת לשימוש כרכב פרטי.`,
};

export const STEP_FLOW_OPTION: ICarFlowData = {
  engineTitle: 'מנועים',
  finishLevelTitle: 'רמות גימור',
  colorsDescription: 'צבע חוץ',
  colorTitle: 'צבע',
  warranties: WARRANTY_PLANS,
  engines: [
    {
      title: '',
      description: '',
      imgUrl: ICONS_CAR_FLOW.Engine1.toString(),
      finishLevels: [
        {
          engineTitle: '',
          title: '',
          modelVehicle: '',
          description: '',
          imgUrl: ICONS_CAR_FLOW.Engine1.toString(),
          price: 0,
          timesFrames: [],
          advancePayments: {},
          colors: [
            {
              id: 1,
              title: '',
              color: '',
              description: '',
              price: 0,
              colorId: '',
              loanPrice: 0,
              loanMonth: 0,
              loanAdvancePayment: 0,
              loanFinalPayment: 0,
              additionalPrice: 0,
              modelSFID: '',
              tos: '',
            },
          ],
          rishuiPrice: 1,
        },
      ],
    },
  ],
  finishLevels: [],
  newCarConfPage: {
    id: 0,
    title: '',
    underImageInfo: '',
    bottomDescription: '',
    type: '',
    bulletsTitle: '',
    bullets: [],
  },
};
export const PLANS_DATA = {
  productCode: '',
  productSF: '',
  periodInMonths: '',
  productDescription: '',
  monthlyPayment: '',
  advance: '',
  modelCode: '',
  id: 0,
};

export const selectedCarInitialStates: SelectedCar = {
  engine: STEP_FLOW_OPTION.engines[0],
  finishLevel: STEP_FLOW_OPTION.engines[0].finishLevels[0],
  color: STEP_FLOW_OPTION.engines[0].finishLevels[0].colors[0],
  accessories: [],
  warranty: WARRANTY_PLANS[0],
  distancePackage: { kmPerYear: '', routePrice: [] },
  totalPrice: 0,
  timeFrame: { periodInMonths: '', kmPackages: [] },
  plansAndManagement: PLANS_DATA,
  needsFunding: false,
  needsInsurance: false,
  needsTrade: false,
  accessoriesSumPrice: 0,
  planType: 'basic',
};
