import '../styles/globals.css';
import '../styles/fonts/fonts.css';

import React, { useEffect } from 'react';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ssrWrapper } from '~/redux-toolkit/store/store';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { createEmotionSsrAdvancedApproach } from 'tss-react/next/pagesDir';
import theme from '../theme';
import createEmotionCache from '../createEmotionCache';
import { ImageKitProvider } from '~/providers/imageKitProvider';
import NavBar from '~/components/NavBarV2';
import Footer from '~/components/Footer';
import useShowNavigation from '~/hooks/useShowNavigation';
import Popups from '~/components/Popups/Popups';
import Scripts from '~/components/Scripts/Scripts';
import { usePreloader } from '~/hooks/usePreloader';
import Preloader from '~/components/PreLoader';

// import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga';
import usePersistedQueryParams from '~/hooks/usePersistedQueryParams';
import { useUtmParams } from '~/hooks/useUtmParams';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { getMaskyoo } from '~/redux-toolkit/thunks/maskyooThunks';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(
    process.env.NEXT_PUBLIC_POSTHOG_KEY ||
      'phc_vBKNv56G9eitTaugaabsFWGDOfjN5tLorhz0ljzJL5Y',
    {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    },
  );
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const { augmentDocumentWithEmotionCache } = createEmotionSsrAdvancedApproach({
  key: 'css',
});

export { augmentDocumentWithEmotionCache };

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { store, props: ssrProps } = ssrWrapper.useWrappedStore(pageProps);

  const showNavigation = useShowNavigation();
  const loading = usePreloader();
  usePersistedQueryParams();
  useUtmParams();

  return (
    <React.StrictMode>
      <PostHogProvider client={posthog}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
            <Scripts />
          </Head>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Provider store={store}>
              {showNavigation && <NavBar />}
              {loading && <Preloader />}
              <GlobalDispatch />
              {/* Conditionally render based on path */}
              <ImageKitProvider>
                <Component {...ssrProps} />
              </ImageKitProvider>
              {showNavigation && <Footer />}
              <Popups />
            </Provider>
          </ThemeProvider>
        </CacheProvider>
      </PostHogProvider>
      <GoogleTagManager gtmId="GTM-TJ4QWTH" />
      <GoogleAnalytics gaId="G-93EMFFL3LH" />
    </React.StrictMode>
  );
}

function GlobalDispatch() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getMaskyoo());
  }, [dispatch]);

  return null;
}
