import React from 'react';
import { ImageFile } from '~/data/types/images';

const facebookIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="16"
      viewBox="0 0 10 16"
    >
      <path
        d="M3.48469 15.1957L3.46422 8.8988H0.785645V6.20011H3.46422V4.40099C3.46422 1.97289 4.95664 0.802734 7.10652 0.802734C8.13633 0.802734 9.02141 0.87998 9.27934 0.914505V3.45201L7.78829 3.45269C6.61906 3.45269 6.39267 4.01247 6.39267 4.83389V6.20011H9.71422L8.82136 8.8988H6.39266V15.1957H3.48469Z"
        fill="black"
      />
    </Component>
  );
};

export default facebookIcon;
