/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClientService } from '~/services/HttpsClientService';
import {
  ISearchUsedCarsQuery,
  OfferData,
  UsedCarDataType,
  UsedCarType,
} from '~/data/types/usedCar/usedCarData';
import { MetaType, PaginationType } from '~/data/types/paginationTypes';
import { AxiosResponse } from 'axios';
import { CarTechSpecData } from '~/data/car/carTechSpec';
import {
  CarBodyTypeData,
  carDescriptionHighlights,
} from '~/data/types/car/cars';

export interface IGetUsedCarDataRes {
  data: UsedCarType[];
  pagination: PaginationType;
}

export interface IAdvantagesDataRes {
  attributes: {
    title: string;
    bullets: { title: string }[];
  };
}

export interface IOtp {
  phone: string;
  name: string;
}

export interface IOtpRes {
  phone: string;
  name: string;
  code: string;
}
class UsedCarAdapter {
  static readonly endpointSearchUsedCar = `/api/search-used-cars`;

  static readonly endpointFilters = `/api/used-cars-filters/`;

  static readonly endpointCarById = `/api/used-cars/`;

  static readonly endpointPopularUsedCars = `/api/used-cars?sort=pageViewsWeekAgo:desc&pagination[limit]=6&filters[pageViewsWeekAgo][$notNull]=true`;

  static readonly endpointAdvantagesSection = `/api/advantages-sections/`;

  static readonly endpointTechSpec = `api/used-car-tech-spec?carNumber=`;

  static readonly endpointOTP = `api/otp/create`;

  static readonly endpointValidateOTP = `api/otp/validate`;

  static readonly endpointHighlights =
    '/api/car-highlights?filters[isUsedCar][$eqi]=true&populate[0]=icon';

  static readonly endpointBodyType =
    '/api/car-body-types?filters[isUsedCar][$eqi]=true&populate[0]=icon';

  static readonly endpointYears = '/api/car-body-types?isUsedCar=true';

  static readonly endpointOfferCard = '/api/offer-components';

  async getUsedCarsContent(
    query: ISearchUsedCarsQuery,
  ): Promise<IGetUsedCarDataRes> {
    const res = await HttpClientService.post(
      UsedCarAdapter.endpointSearchUsedCar,
      query,
    );
    return res.data as IGetUsedCarDataRes;
  }

  async getPopularUsedCars(): Promise<UsedCarDataType[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: UsedCarDataType[];
    }>(`${UsedCarAdapter.endpointPopularUsedCars}`);

    return res.data.data
      .map((item) => item.attributes)
      .filter((item): item is UsedCarDataType => item !== undefined);
  }

  async getUsedCarsContentAdditional(
    query: ISearchUsedCarsQuery,
  ): Promise<IGetUsedCarDataRes> {
    const res = await HttpClientService.post(
      UsedCarAdapter.endpointSearchUsedCar,
      query,
    );
    return res.data as IGetUsedCarDataRes;
  }

  async getUsedCarsOfferCard(): Promise<OfferData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: OfferData[];
    }>(
      `${UsedCarAdapter.endpointOfferCard}?populate=deep,4&filters[page][name][$eqi]=used-car-for-sale`,
    );

    return res.data.data;
  }

  async getUsedCarFiltersOptions(filterType: string): Promise<AxiosResponse> {
    const res = await HttpClientService.get(
      `${UsedCarAdapter.endpointFilters}${filterType}`,
    );

    return res;
  }

  async getUsedCarById(id: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: UsedCarDataType;
    }>(`${UsedCarAdapter.endpointCarById}${id}?populate=deep,4`);

    return res;
  }

  async getAdvantagesSectionContent() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: IAdvantagesDataRes[];
    }>(`${UsedCarAdapter.endpointAdvantagesSection}?populate=deep,4`);

    return res;
  }

  async getUsedCarTechSpec(carNumber: string) {
    const res = await HttpClientService.get<CarTechSpecData[]>(
      `${UsedCarAdapter.endpointTechSpec}${carNumber}`,
    );
    return res;
  }

  async getOTP(data: IOtp): Promise<IOtpRes> {
    const res = await HttpClientService.post(UsedCarAdapter.endpointOTP, data);
    return res.data as IOtpRes;
  }

  async validateOTP(data: IOtpRes): Promise<any> {
    const res = await HttpClientService.post(
      UsedCarAdapter.endpointValidateOTP,
      data,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsedCarBodyTypeFilterOptions(): Promise<CarBodyTypeData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarBodyTypeData[];
    }>(`${UsedCarAdapter.endpointBodyType}`);
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getUsedCarHighlightsFilterOptions(): Promise<
    carDescriptionHighlights[]
  > {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: carDescriptionHighlights[];
    }>(`${UsedCarAdapter.endpointHighlights}`);
    return res.data.data;
  }
}

const usedCarAdapter = new UsedCarAdapter();
export default usedCarAdapter;
