import { createSlice } from '@reduxjs/toolkit';
import { ToolTip } from '~/data/types/tooltipContentType';
import { getTooltipContent } from '~/redux-toolkit/thunks/tooltipThunks';

interface TooltipState {
  content?: ToolTip | null;
  isLoading: boolean;
  galleryTooltip: {
    id: number | null;
    isOpen: boolean;
  };
}

const initialState: TooltipState = {
  content: null,
  isLoading: false,
  galleryTooltip: {
    id: null,
    isOpen: false,
  },
};

const tooltipSlice = createSlice({
  name: 'tooltipData',
  initialState,
  reducers: {
    setToolTipContent(state, action) {
      state.content = action.payload;
    },
    setGalleryToolTip(state, action) {
      state.galleryTooltip = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTooltipContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTooltipContent.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { attributes } = action.payload;
      state.content = attributes;
      state.isLoading = false;
    });
  },
});

export const { setToolTipContent, setGalleryToolTip } = tooltipSlice.actions;

export default tooltipSlice.reducer;