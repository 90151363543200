/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Box } from '@mui/material';
import { CSSProperties, FC, RefObject } from 'react';
import Container from '~/components/Container';
import Logo from '~/assets/icons/jsx/WarningIcon';
import rehypeRaw from 'rehype-raw';
import {
  ContainerStyle,
  WarningBannerStyle,
  IconStyle,
  root,
  DescriptionMarkdownStyle,
} from './useStyles';

interface IWarningBannerProps {
  text: string;
  customStyle?: CSSProperties;
  ref?: RefObject<HTMLDivElement>;
}

export const WarningBanner: FC<IWarningBannerProps> = ({
  text,
  customStyle,
  ref,
}) => {
  return (
    <Box
      sx={{ ...root, ...customStyle }}
      tabIndex={0}
      aria-label={text}
      ref={ref}
    >
      <div style={ContainerStyle}>
        <Container style={IconStyle}>
          <Logo />
        </Container>
        <Container sx={WarningBannerStyle}>
          <DescriptionMarkdownStyle rehypePlugins={[rehypeRaw] as any}>
            {text}
          </DescriptionMarkdownStyle>
        </Container>
      </div>
    </Box>
  );
};
