import { styled } from '@mui/system';
import { COLORS } from '~/data/ui/colors';
import ReactMarkdown from 'react-markdown';

export const root = {
  textAlign: 'right',
  alignItems: 'start',
  marginTop: 5,
  marginBottom: 5,
  background: '#FFF6EC',
  borderRadius: '8px',
  width: '100%',
  direction: 'rtl',
};

export const ContainerStyle = {
  display: 'flex',
  justifyContent: 'right',
  padding: 10,
};

export const IconStyle = {
  marginLeft: '24px',
};
export const WarningBannerStyle = {
  display: 'flex',
  textAlign: 'right',
  alignItems: 'start',
  marginLeft: '5px',
};

export const WarningBannerText = {
  fontSize: '14px',
  color: COLORS.gray.dark,
};

export const DescriptionMarkdownStyle = styled(ReactMarkdown)(() => ({
  fontSize: '14px',
  color: COLORS.gray.dark,
  '& a': {
    color: COLORS.black.main,
    fontWeight: 'bold',
  },
}));
