import { COLORS } from '~/data/ui/colors';

const useStyles = () => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'end',
    alignItems: 'flex-start',
    marginTop: '15px',
    direction: 'rtl',
  },
});

export default useStyles;
export const checkBoxTitleStyle = {
  fontSize: '14px',
  textAlign: 'right',
  direction: 'rtl',
};
export const checkBoxSubTitleStyle = {
  fontSize: '14px',
  textAlign: 'right',
  direction: 'rtl',
  paddingRight: '33px',
  marginTop: '15px',
  color: COLORS.gray.dark,
};

export const checkboxStyle = {
  padding: '0',
};
export const checkboxIconStyle = {
  height: '24px',
  width: '24px',
};
