import { Box } from '@mui/system';
import { useStyles } from './useStyles';

interface ColorIcon {
  colorHex: string;
}

const ColorIcon = ({ colorHex }: ColorIcon) => {
  const classes = useStyles();

  return <Box sx={classes.root} style={{ backgroundColor: colorHex }} />;
};

export default ColorIcon;
