import React from 'react';
import { ImageFile } from '~/data/types/images';

const yellowLineMenu: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="9"
      viewBox="0 0 106 9"
    >
      <path
        d="M104 7C92.9302 0.0915294 5.47907 0.580729 2 6.99519"
        stroke="#FFE500"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default yellowLineMenu;
