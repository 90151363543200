import React from 'react';
import { ImageFile } from '~/data/types/images';

const oneStarSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="22"
      viewBox="0 0 77 22"
    >
      <path
        d="M30.358 9.93504C29.9586 9.67671 30.1244 9.05802 30.5995 9.03403L34.7975 8.82209C35.4606 8.7886 36.0265 8.33126 36.1983 7.68989L37.2834 3.64023C37.4061 3.18239 38.0426 3.14804 38.2138 3.59002L39.737 7.52134C39.9754 8.13684 40.5817 8.53005 41.241 8.49677L45.4583 8.28384C45.9328 8.25989 46.1604 8.85733 45.7903 9.15515L42.53 11.7785C42.0108 12.1963 41.8217 12.9021 42.0624 13.5235L43.5742 17.4256C43.7458 17.8685 43.25 18.2721 42.8511 18.0142L39.3052 15.7211C38.7509 15.3626 38.0293 15.4 37.515 15.8138L34.2303 18.4569C33.861 18.754 33.327 18.406 33.4496 17.9482L34.5347 13.8985C34.7066 13.2572 34.4452 12.5782 33.8876 12.2176L30.358 9.93504Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
    </Component>
  );
};

export default oneStarSelectedIcon;
