import { createSlice } from '@reduxjs/toolkit';
import {
  ACCESSORIES,
  SubscriptionColorType,
} from '~/data/car/carSubscriptionData';
import { AccessoryCard } from '~/data/types/carFlow/carFlowTypes';

interface subscriptionCarState {
  needsTrade: boolean;
  selectedColor: SubscriptionColorType | null;
  isSubscriptionFlowOpen: boolean;
  subscriptionActiveStep: number;
  selectedAccessories: AccessoryCard[];
  accessoriesSumPrice: number;
}

const initialState: subscriptionCarState = {
  needsTrade: false,
  selectedColor: null,
  isSubscriptionFlowOpen: false,
  subscriptionActiveStep: 0,
  selectedAccessories: ACCESSORIES,
  accessoriesSumPrice: 0,
};

const subscriptionCarSlice = createSlice({
  name: 'subscriptionCarData',
  initialState,
  reducers: {
    initState() {
      return initialState;
    },
    setNeedTradeIn(state) {
      state.needsTrade = !state.needsTrade;
    },
    setSubscriptionColor(state, action) {
      state.selectedColor = action.payload;
    },
    setSubscriptionFlowOpen(state, action) {
      state.isSubscriptionFlowOpen = action.payload;
    },
    setSubscriptionActiveStep(state, action) {
      state.subscriptionActiveStep = action.payload;
    },
    setSubscriptionAccessories(state, action) {
      state.selectedAccessories = action.payload;
      const accessories = state.selectedAccessories;
      const sumAccessories = accessories.reduce((a, b) => a + b.price, 0);
      state.accessoriesSumPrice = sumAccessories;
    },
  },
});

export const {
  setNeedTradeIn,
  setSubscriptionColor,
  setSubscriptionFlowOpen,
  setSubscriptionActiveStep,
  initState,
  setSubscriptionAccessories,
} = subscriptionCarSlice.actions;

export default subscriptionCarSlice.reducer;
