import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginationType } from '~/data/types/paginationTypes';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import compareAdapter from '../adapters/compareAdapter';

export const getUsedCarsForComparison = createAsyncThunk(
  '/search-used-cars',
  async (
    ids: number[],
  ): Promise<{
    data: UsedCarType[];
    pagination: PaginationType;
  }> => {
    try {
      const query = {
        filters: {
          id: ids,
          colors: [],
          categories: [],
          models: [],
          brands: [],
          carHighlights: [],
          carBodyType: [],
          yearsRange: { minYear: null, maxYear: null },
          kmRange: { minPrice: null, maxPrice: null },
          gearType: [],
          engineType: [],
          carHand: [],
          originOwnership: [],
          imagesLength: false,
        },
        pagination: { page: 1, pageSize: 24, pageCount: 0, total: 100 },
        sort: { imagesLength: 'desc' },
        withTechSpec: true,
        includeUnpublishedAndSold: true,
        isAdditionFiltersSelected: false,
      };

      const response = await compareAdapter.getUsedCars({
        ...query,
        version: 'V1',
      });
      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
