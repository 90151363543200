/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useCallback, useEffect, useMemo, useState } from 'react';
import RESOURCES from '~/resources';
import { useCurrentUrlParams } from '~/hooks/useCurrentUrlParams';
import { ROUTES } from './routes';
import { useIsMobile } from '~/hooks/useIsMobile';

export const FUNDING_ROUTE = `${ROUTES.FINANCE}`;
export const INSURANCE_ROUTE = `${ROUTES.INSURANCE_PAGE}`;
export const SOLUTIONS_ROUTE = `${ROUTES.CHARGING_SOLUTIONS}`;
export const OUR_BRANCHES_ROUTE = `${ROUTES.LOCATION_PAGE}`;
export const CHARGING_SOLUTIONS = `${ROUTES.CHARGING_SOLUTIONS}`;
export const BUSINESS_LEASING = `${ROUTES.BUSINESS_LEASING_PAGE}`;
export const JOBS_LINK = 'https://jobs.freesbe.com/';
export const AG_LINK = 'https://ag.freesbe.com/';

export enum SERVICES_LINKS_TYPE {
  ROUTE = 'route',
  LINK = 'link',
  MENU = 'menu',
}
export interface IServicesLinks {
  title: string;
  value?: string;
  path: string;
  type: string;
  menuItems?: IServicesLinks[];
  isOpen?: boolean;
  setIsOpen?: (newValue: boolean) => void;
  mark?: boolean;
  icon?: any;
  isContact?: boolean;
}

export const FREESBE_EXTERNAL_LINKS = [
  {
    title: RESOURCES.AG_IN_FREESBE,
    path: 'https://ag.freesbe.com/',
    type: 'link',
  },
  {
    title: RESOURCES.CARRER_IN_FREESBE,
    path: 'https://jobs.freesbe.com/',
    type: 'link',
  },
];

export const FREESBE_INTERNAL_LINKS = [
  {
    title: RESOURCES.OUR_BRANCHES,
    path: `${OUR_BRANCHES_ROUTE}`,
    type: 'route',
  },
  {
    title: RESOURCES.NAV_ITEM_CHARGING_SOLUTIONS,
    path: `${ROUTES.CHARGING_SOLUTIONS}`,
    type: 'route',
  },
];
export enum NAV_PARAM_TYPE {
  NAV_PARAM_NEW_CAR = 'new',
  NAV_PARAM_PRIVATE_LEASING_CAR = 'private-leasing',
}

export const useServices = () => {
  const isMobile = useIsMobile();
  const urlParams = useCurrentUrlParams({ cleanAppParams: true });
  const stringUrlParams = urlParams.toString();
  const concatParams = `${stringUrlParams && '?'}${stringUrlParams}`;
  const [isSolutionsOpen, setIsSolutionsOpen] = useState<boolean>(isMobile);
  const [isContactOpen, setIsContactOpen] = useState(isMobile);

  // Toggle menu items
  useEffect(() => {
    setIsSolutionsOpen(isMobile);
    setIsContactOpen(isMobile);
  }, [isMobile, setIsSolutionsOpen, setIsContactOpen]);

  const services: IServicesLinks[] = useMemo(() => {
    const solutionsServices: IServicesLinks[] = [
      {
        title: RESOURCES.NAV_ITEM_FUNDING,
        value: RESOURCES.NAV_ITEM_FUNDING_VALUE,
        path: `${FUNDING_ROUTE}${concatParams}`,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_INSURANCE,
        value: RESOURCES.NAV_ITEM_INSURANCE_VALUE,
        path: `${INSURANCE_ROUTE}${concatParams}`,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_CHARGING_SOLUTIONS,
        value: RESOURCES.NAV_ITEM_CHARGING_SOLUTIONS_VALUE,
        path: `${CHARGING_SOLUTIONS}${concatParams}`,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_BUSINESS_LEASING,
        value: RESOURCES.NAV_ITEM_BUSINESS_LEASING_VALUE,
        path: `${BUSINESS_LEASING}${concatParams}`,
        type: 'route',
      },
      // {
      //   title: RESOURCES.NAV_ITEM_DRIVING_LEASING,
      //   path: process.env.NEXT_PUBLIC_DRIVE_LEASE || '/',
      //   type: 'link',
      //   mark: true,
      //   icon: LeasingNavbar.src,
      // },
    ];

    const contactServices: IServicesLinks[] = [
      {
        title: RESOURCES.NAV_ITEM_CONTACT_LEAVE_APPLICATION,
        value: RESOURCES.NAV_ITEM_CONTACT_LEAVE_APPLICATION,
        path: ROUTES.CONTACT_US_PAGE,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_CONTACT_OUR_BRANCHES,
        value: RESOURCES.NAV_ITEM_CONTACT_OUR_BRANCHES,
        path: ROUTES.LOCATION_PAGE,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_TREATMENT,
        path: process.env.NEXT_PUBLIC_TREATMENTS || '/',
        type: 'link',
      },
    ];

    return [
      {
        title: RESOURCES.NAV_ITEM_NEW_CAR,
        value: RESOURCES.NAV_PARAM_NEW_CAR,
        path: `${ROUTES.CAR_PAGE}${concatParams}`,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_PRIVATE_LEASING_CAR,
        value: RESOURCES.NAV_PARAM_PRIVATE_LEASING_CAR,
        path: `${ROUTES.PRIVATE_LEASING}${concatParams}`,
        type: 'route',
      },
      {
        title: RESOURCES.NAV_ITEM_RENEW_CAR,
        value: RESOURCES.NAV_ITEM_RENEW_CAR,
        path: `${ROUTES.USED_CAR}${concatParams}`,
        type: 'route',
      },
      {
        title: RESOURCES.RENTAL_CAR,
        path: process.env.NEXT_PUBLIC_TEMPORARY_LS4 || RENTAL_LINK || '/',
        type: 'link',
      },
      {
        title: RESOURCES.NAV_ITEM_SOLUTIONS,
        type: 'menu',
        path: '/',
        menuItems: solutionsServices,
        isOpen: isSolutionsOpen,
        setIsOpen: setIsSolutionsOpen,
      },
      {
        title: RESOURCES.NAV_ITEM_DRIVING_LEASING,
        path: process.env.NEXT_PUBLIC_DRIVE_LEASE || '/',
        type: 'link',
      },
      {
        title: RESOURCES.NAV_ITEM_CONTACT_US,
        type: 'menu',
        path: '/',
        menuItems: contactServices,
        isOpen: isContactOpen,
        setIsOpen: setIsContactOpen,
        isContact: true,
      },
    ];
  }, [concatParams, isContactOpen, isSolutionsOpen]);

  return useMemo(() => services, [services]);
};

export const useServicesFooter = () => {
  const urlParams = useCurrentUrlParams({ cleanAppParams: true });
  const stringUrlParams = urlParams.toString();
  const concatParams = `${stringUrlParams && '?'}${stringUrlParams}`;

  const getUrlRoute = useCallback(
    (route: string) => {
      return `${route}${concatParams}`;
    },
    [concatParams],
  );

  const services: { title: string; links: IServicesLinks[] }[] = useMemo(() => {
    return [
      {
        title: RESOURCES.NAV_TITLE_BUY_SELL,
        links: [
          {
            title: RESOURCES.NAV_ITEM_NEW_CAR,
            value: RESOURCES.NAV_PARAM_NEW_CAR,
            path: getUrlRoute(ROUTES.CAR_PAGE),
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_PRIVATE_LEASING_CAR,
            value: RESOURCES.NAV_PARAM_PRIVATE_LEASING_CAR,
            path: getUrlRoute(ROUTES.PRIVATE_LEASING),
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_BUSINESS_LEASING,
            path: `${ROUTES.BUSINESS_LEASING_PAGE}`,
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_RENEW_CAR,
            value: RESOURCES.NAV_ITEM_RENEW_CAR,
            path: getUrlRoute(ROUTES.USED_CAR),
            type: 'route',
          },
          {
            title: RESOURCES.RENTAL_CAR,
            path: process.env.NEXT_PUBLIC_TEMPORARY_LS4 || RENTAL_LINK || '/',
            type: 'link',
          },
          {
            title: RESOURCES.AG_IN_FREESBE,
            path: 'https://ag.freesbe.com/',
            type: 'link',
          },
          {
            title: RESOURCES.NAV_ITEM_CONSTRUCTION_EQUIPMENT_CASE,
            path: process.env.NEXT_PUBLIC_CONSTRUCTION_EQUIPMENT_CASE || '/',
            type: 'link',
          },
          {
            title: RESOURCES.NAV_ITEM_CONSTRUCTION_EQUIPMENT_SANY,
            path: process.env.NEXT_PUBLIC_CONSTRUCTION_EQUIPMENT_SANY || '/',
            type: 'link',
          },
          // TODO: enable this code to add car-fleet link in footer
          // {
          //   title: RESOURCES.CAR_FLEET,
          //   path: ROUTES.CAR_FLEET,
          //   type: 'route',
          // },
        ],
      },
      {
        title: RESOURCES.NAV_TITLE_SOLUTIONS,
        links: [
          {
            title: RESOURCES.NAV_ITEM_FUNDING,
            value: RESOURCES.NAV_ITEM_FUNDING_VALUE,
            path: getUrlRoute(FUNDING_ROUTE),
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_INSURANCE,
            value: RESOURCES.NAV_ITEM_INSURANCE_VALUE,
            path: `${INSURANCE_ROUTE}${concatParams}`,
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_CHARGING_SOLUTIONS,
            path: `${ROUTES.CHARGING_SOLUTIONS}`,
            type: 'route',
          },
        ],
      },
      {
        title: RESOURCES.NAV_TITLE_SERVICE,
        links: [
          {
            title: RESOURCES.NAV_ITEM_TREATMENT,
            path: process.env.NEXT_PUBLIC_TREATMENTS || '/',
            type: 'link',
          },
          {
            title: RESOURCES.NAV_ITEM_DRIVING_LEASING,
            path: process.env.NEXT_PUBLIC_DRIVE_LEASE || '/',
            type: 'link',
          },
        ],
      },
      {
        title: RESOURCES.NAV_TITLE_POLICY,
        links: [
          {
            title: RESOURCES.NAV_ITEM_ABOUT,
            path: getUrlRoute(ROUTES.ABOUT_PAGE) || '/',
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_ACCESSIBILITY,
            path: getUrlRoute(ROUTES.ACCESSIBILITY) || '/',
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_TERMS_OF_USE,
            path: getUrlRoute(ROUTES.TERMS_OF_USE),
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_PRIVACY,
            path: getUrlRoute(ROUTES.PRIVACY) || '/',
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_REFUNDS,
            path: getUrlRoute(ROUTES.REFUNDS) || '/',
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_TERMS_AND_CONDITIONS,
            path: getUrlRoute(ROUTES.TERMS_AND_CONDITIONS) || '/',
            type: 'route',
          },
          {
            title: RESOURCES.NAV_ITEM_EQUAL_SALARY_REPORT,
            path: 'https://freesbe.com/EQUAL_SALARY_REPORT_2024.pdf',
            type: 'link',
          },
          {
            title: RESOURCES.NAV_ITEM_COMMUNITY,
            value: RESOURCES.NAV_ITEM_COMMUNITY,
            path: ROUTES.COMMUNITY_PAGE,
            type: 'route',
          },
        ],
      },
      {
        title: RESOURCES.NAV_TITLE_CONTACT_US,
        links: [
          {
            title: RESOURCES.NAV_ITEM_CONTACT_US,
            value: RESOURCES.NAV_ITEM_CONTACT_US,
            path: ROUTES.CONTACT_US_PAGE,
            type: 'route',
          },
          {
            title: RESOURCES.OUR_BRANCHES,
            path: `${OUR_BRANCHES_ROUTE}`,
            type: 'route',
          },
          {
            title: RESOURCES.CARRER_IN_FREESBE,
            path: 'https://jobs.freesbe.com/',
            type: 'link',
          },
        ],
      },
    ];
  }, [concatParams, getUrlRoute]);

  return useMemo(() => services, [services]);
};

const RENTAL_LINK =
  'https://rental.freesbe.com?utm_source=freesbe&utm_medium=referral';
