import { IObj } from '~/data/car/carColors';
import { RentalLocationType } from './types/rentalLocationsType';
import { SupportedBrand } from './types/brands';

export type OpeningHoursDataType = {
  id: number;
  startHour: string;
  endHour: string;
};

export type OpeningHoursType = {
  startHour: string;
  endHour: string;
  id?: number;
};

export type BranchSpecialDates = {
  id: number;
  startTime: string;
  date: string;
  endTime: string;
  name: string;
  isDateDisable: boolean;
};

type BranchWithId = RentalLocationAttributesType & { id: number };

export type BranchesPageDataType = {
  branchMessage: {
    message: string;
    branches: BranchWithId[];
  }[];
  pageMessage: {
    message: string;
    startDate: string;
    endDate: string;
    showBeforeDays: number;
    showAfterDays: number;
  };
};

export type RentalLocationsAttributesType = {
  phoneNumber: string;
  displayPhoneNumber: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  tafnitID: string;
  isOpenning24_7: boolean;
  rentalLocationDetails: [
    {
      id: number;
      language: string;
      locationName: string;
      address: string;
      countryRegion: string;
      city: string;
      urlTitle: string;
    },
  ];
  mapCoordinates: {
    id: number;
    latitude: number;
    longitude: number;
  };
  specialDates: BranchSpecialDates[];
  weeklyHours: OpeningHoursDataType;
  holidayEveAndFridayHours: OpeningHoursDataType;
  holidayOrSaturdayHours: OpeningHoursDataType;
  wazeLink: string;
};

export type RentalLocationsDataType = {
  id: number;
  attributes: RentalLocationsAttributesType;
};

export type BranchType = {
  labelColorHex: string;
  name: string;
  urlTitle: string;
  rank: number;
};

export type OurBranchesLocationType = {
  id: string;
  name: string;
  phoneNumber: string;
  displayPhoneNumber: string;
  address: string;
  countryRegion: string;
  lat: number;
  lng: number;
  isOpenning24_7: boolean;
  specialDates: BranchSpecialDates[];
  weeklyHours: OpeningHoursType;
  holidayEveAndFridayHours: OpeningHoursType;
  holidayOrSaturdayHours: OpeningHoursType;
  wazeLink: string;
  urlTitle: string;
  branchTypes: BranchType[];
  city: string;
  supportedBrands?: SupportedBrand[];
  openingHours: { [day: string]: string }[];
  orYarok: boolean;
};

export type RentalLocationDataType = {
  id: string;
  attributes: RentalLocationAttributesType;
};

export type RentalLocationAttributesType = {
  name: string;
  phoneNumber: string;
  displayPhoneNumber: string;
  address: string;
  countryRegion: string;
  lat: number;
  lng: number;
  isOpenning24_7: boolean;
  specialDates: BranchSpecialDates[];
  weeklyHours: OpeningHoursType;
  holidayEveAndFridayHours: OpeningHoursType;
  holidayOrSaturdayHours: OpeningHoursType;
  wazeLink: string;
  urlTitle: string;
  branchTypes: brandTypes;
  city: string;
  supportedBrands?: brandTypes;
  accessibility: BranchAccessibility;
  freesbeOfficial: boolean;
  openingHours: { [day: string]: string }[];
};

export type IRentalLocationAttributes = {
  id: string;
  name: string;
  phoneNumber: string;
  displayPhoneNumber: string;
  address: string;
  countryRegion: string;
  lat: number;
  lng: number;
  isOpenning24_7: boolean;
  specialDates: BranchSpecialDates[];
  weeklyHours: OpeningHoursType;
  holidayEveAndFridayHours: OpeningHoursType;
  holidayOrSaturdayHours: OpeningHoursType;
  wazeLink: string;
  urlTitle: string;
  branchTypes: BranchType[];
  city: string;
  supportedBrands?: ISupportedBranch[];
  accessibility: BranchAccessibility;
  freesbeOfficial: boolean;
  openingHours: { [day: string]: string }[];
  orYarok: boolean;
};

export type BranchAccessibility = {
  hearingLoop: string;
  accessibleEntrance: string;
  accessiblePosition: string;
  handicappedBathroom: string;
};

export type ISupportedBranch = {
  englishName: string;
  hebrewName: string;
  name?: string;
};

export type branchTypes = {
  data: {
    attributes: BranchType;
  }[];
};

export type brandTypes = {
  data: {
    attributes: {
      englishName: string;
      hebrewName: string;
      name?: string;
    };
  }[];
};

export type AccumulatorType = Record<string, RentalLocationAttributesType[]>;

export type UsedBranchesAttributes = {
  id: number;
  attributes: [];
};

export type RentalBranchesState = {
  branches: RentalLocationType[];
  fetchingBranches: boolean;
};

export interface IObjArr {
  [key: string]: string[];
}

export const JERUSALEM = 'ירושלים';
export const CENTER_AND_SHARON = 'מרכז והשרון';
export const CENTER = 'מרכז';

export const branchDic: IObj = {
  'מרכז והשרון': 'מרכז והשרון',
  ירושלים: 'ירושלים',
  צפון: 'צפון',
  דרום: 'דרום',
  'כל האיזורים': 'כל האיזורים',
};
export const branchLocationsDic: IObj = {
  every: 'כל האיזורים',
  jerusalem: 'ירושלים',
  centerAndSharon: 'מרכז והשרון',
  north: 'צפון',
  south: 'דרום',
};

export const branchesLeadLocationsDic: IObj = {
  jerusalem: 'ירושלים',
  center: 'מרכז',
  Sharon: 'השרון',
  north: 'צפון',
  south: 'דרום',
};

export const BranchTypeParam = {
  ALL: 'all',
  NEW_CAR: 'new-car',
  RENTAL: 'rental',
  USED_CAR: 'used-car',
  SERVICE_CENTER: 'service-centers',
};
