import { HttpClientService } from '~/services/HttpsClientService';
import { ICarFleetPageData } from '~/data/types/CarFleetPageContent/CarFleetPageContent.types';

class CarFleetPageContentAdapter {
  static readonly endpointGetCarFleetPage = `/api/car-fleet-page/content`;

  // eslint-disable-next-line class-methods-use-this
  async getCarFleetPageContent() {
    const res = await HttpClientService.get<ICarFleetPageData | null>(
      `${CarFleetPageContentAdapter.endpointGetCarFleetPage}?populate=*`,
    );
    return res;
  }
}

const carFleetPageContentAdapter = new CarFleetPageContentAdapter();
export default carFleetPageContentAdapter;
