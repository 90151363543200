import { IObj } from '~/data/car/carColors';
import {
  CarBullet,
  CarBodyType,
  IBannerPromotion,
  IPromotionCard,
  ImageContentDB,
  BannerLeadDetailsType,
  PageSEO,
} from '../car/cars';
import { CarTechSpecData } from '~/data/car/carTechSpec';
import { ResultPageSEO } from '../resultPageType';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface UsedCarDataType {
  id: number;
  modelVehicle: string;
  carNumber: string;
  year: number;
  codeOwner: number;
  gir: number;
  engineType: number;
  engine: string;
  engineVolume: number;
  kms: number;
  color: number;
  carPrice: number;
  previousCarOwnershipHand: string;
  previousCarOwnershipType: number;
  carHand: number;
  manufacturer: number;
  name: string;
  productSfid: string;
  websitePrice: number;
  websiteSmartPrice: number;
  webPriceMonthlyPayment: number;
  pageViewsThreeDaysAgo: number;
  status: string;
  carZoneManufacturerName: string;
  carZoneModelName: string;
  carZoneFinishedLevelName: string;
  carZoneColor?: string;
  categories: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  images: UsedCarImageType[];
  imagesKit?: UsedCarImageType[];
  carLabels: CarLabelObjType[];
  carHighlights: ICarHighlights;
  carPromotion: any;
  carZone: any;
  freesbeCategories: {
    data: any[];
  };
  migrash: MigrashType;
  smartPrice: SmartPrice;
  usedCarSpec: UsedCarSpecData;
  carProgrammaticDescription: string;
  makeNameEn: string;
  modelNameEn: string;
  carBullets: CarBullet[];
  attributes?: UsedCarType;
}
export interface UsedCarType {
  modelNameDisplayName?: string;
  dmvModelName?: string;
  pageViewsThreeDaysAgo: number;
  modelVehicle: string;
  carNumber: string;
  year: number;
  codeOwner: number;
  gir: number;
  engineType: number;
  engine: string;
  engineVolume: number;
  kms: number;
  color: number;
  carPrice: number;
  previousCarOwnershipHand: string;
  previousCarOwnershipType: number;
  carHand: number;
  manufacturer: number;
  name: string;
  productSfid: string;
  websitePrice: number;
  websiteSmartPrice: number;
  webPriceMonthlyPayment: number;
  status: string;
  carZoneManufacturerName: string;
  carZoneModelName: string;
  carZoneMakeName?: string;
  carZoneFinishedLevelName: string;
  categories: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  images: UsedCarImageType[];
  imagesKit?: UsedCarImageType[];
  carLabels: CarLabelObjType[];
  carPromotion: any;
  carZone: any;
  freesbeCategories: {
    data: any[];
  };
  migrash: MigrashType;
  smartPrice: SmartPrice;
  id: number;
  usedCarSpec: UsedCarSpecData;
  carHighlights: {
    title: string;
    icon: { url: string };
    id: number;
  }[];
  carProgrammaticDescription: string;
  makeNameEn: string;
  modelNameEn: string;
  carZoneManufacturerNameEn?: string;
  carZoneColor?: string;
  carBodyType?: CarBodyType;
  carBullets: CarBullet[];
  techSpec?: CarTechSpecData;
  // attributes?: UsedCarType;
  seo?: PageSEO;
}
export interface UsedCarImageType {
  url: string;
  source?: string;
  fileId?: string;
  rank?: number;
  id?: number;
  path?: string;
}

export interface CarLabelAttributes {
  title: string;
  subLabelTitle: string | null;
  subLabelType: string | null;
  isPrioritized?: boolean;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  rank: number;
  type?: string;
}

export interface CarLabelData {
  id: number;
  attributes: CarLabelAttributes;
  title: string;
  rank: number;
  type?: string;
  isPrioritized?: boolean;
}

export interface OfferData {
  id: number;
  title: string;
  highlightText: string;
  buttonText: string;
  showButton: boolean;
  leadDetails: BannerLeadDetailsType | undefined;
  image: ImageContentDB;
}

export type ExtendedCarLabels = {
  carLabels: {
    data: CarLabelData[];
  };
};

export interface CarLabelObjType {
  attributes?: any;
  title: string;
  subLabelTitle: string;
  subLabelType: string;
  isPrioritized?: boolean;
  type?: string;
  rank?: number;
  id?: string;
}

type ICarHighlights = {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  icon: any;
}[];

type MigrashType = {
  id: number;
  address: string;
  city: string;
  contactName: string;
  phoneNumber: string;
};

type SmartPrice = {
  id: number;
  webPrice: number;
  webPriceLoanAdvance: number;
  webPriceLoanBullet: number;
  webPriceLoanMinMonthlyPayment: number;
  webPriceLoanMonth: number;
  webPriceLoanPlanID: number;
  webPriceLoanPrincipal: number;
  webPriceLoanRate: number;
  webSmartPrice: number;
  webSmartPriceLoanAdvance: number;
  webSmartPriceLoanBullet: number;
  webSmartPriceLoanMinMonthlyPayment: number;
  webSmartPriceLoanMonth: number;
  webSmartPriceLoanPlanID: number;
  webSmartPriceLoanPrincipal: number;
  webSmartPriceLoanRate: number;
  Company: any;
  LicenseNumber: any;
};

export interface UsedCarCardProps {
  car: UsedCarType;
  isInWishlist: boolean;
  // eslint-disable-next-line no-unused-vars
  onWishlistButtonClicked: (carId: number) => void;
  isV2?: boolean;
}

export interface UsedCarSpecData {
  data: any;
  year: number;
  modelVehicle: string;
  makeName: string;
  modelName: string;
  seriesName: string;
  trimName: string;
  makeNameEn: string;
  seriesNameEn: string;
  modelNameEn: string;
  trimNameEn: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  carNumber: string;
  spec: SpecType;
  newAndLeaseCar: {
    data: null;
  };

  usedCar: UsedCarDataType;
}

export interface UsedCarSpecDataById {
  year: number;
  modelVehicle: string;
  makeName: string;
  modelName: string;
  seriesName: string;
  trimName: string;
  makeNameEn: string;
  seriesNameEn: string;
  modelNameEn: string;
  trimNameEn: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  carNumber: string;
  spec: SpecType;
  newAndLeaseCar: {
    data: null;
  };
}

export interface SpecType {
  id: number;
  classification: string;
  bodyType: string;
  makeCountry: string;
  manufactureCountry: string;
  safetyStarsNumber: number | null;
  safetyStarsYear: number | null;
  parkingBreakType: string | null;
  isAutomatic: boolean;
  lengthMM: number | null;
  heightMM: number | null;
  widthMM: number | null;
  wheelBaseMM: number | null;
  bootVolumeLiter: number | null;
  totalWeight: number;
  accelerationTo100: number | null;
  topSpeed: number | null;
  engineType: string;
  engineVolume: number;
  enginePower: number;
  engineConsumption: number;
  wheelDriveType: string;
  wheels: string;
  frontTiers: string;
  rearTiers: string;
  seatsNumber: number;
  doorsNumber: number;
  sunRoof: boolean;
  powerAssistedSteering: boolean;
  powerWindowNumber: number;
  registrationFeeGroup: number;
  pollutionGroup: number;
  safetyLevel: number;
  bootPowered: boolean | null;
  multimedia: boolean | null;
  bluetooth: boolean | null;
  mobileConnectivity: boolean | null;
  doorsLocking: boolean | null;
  upholstery: boolean | null;
  frontLight: boolean | null;
  backLight: boolean | null;
  DRL: boolean | null;
  ambientLight: boolean | null;
  ACType: string;
  ACBackExit: boolean | null;
  gaugeCluster: boolean | null;
  gaugeClusterHeadsUpDisplay: boolean | null;
  wipersSensor: boolean | null;
  backDoorWindowBlacked: boolean | null;
  wirelessCharging: boolean | null;
  windMirrorsFolded: boolean | null;
  airbagsNumber: number;
  camera: boolean | null;
  proximitySensors: boolean | null;
  ABS: boolean;
  ESP: boolean;
  afterMarkerSafetySystem: boolean;
  BLIS: boolean;
  RCTA: boolean | null;
  LDWS: boolean;
  AEB: boolean;
  ACC: boolean;
  AEBReverse: boolean | null;
  FCW: boolean;
  pedestriansIdentificationSystem: boolean;
  TPMS: boolean;
  SBR: boolean;
  automaticLightOn: boolean;
  TSR: boolean;
  AHB: boolean;
  EBA: boolean;
  motorRange: number | null;
  color: string | null;
}

export interface UsedCarFilerState {
  usedCarQuery: ISearchUsedCarsQuery;
  isMoreFiltersOpen: boolean;
  yearsOptionInput: string[];
  options: {
    colors: OptionsType;
    categories: OptionsType;
    brands: OptionsType;
    models: OptionsType;
    manufactureYear: OptionsType;
    gearType: OptionsType;
    engineType: OptionsType;
    km: OptionsType;
    carHand: OptionsType;
    originOwnership: OptionsType;
    imagesLength: boolean;
    carHighlights: OptionsType;
    carBodyType: OptionsType;
  };
  isOptionsFetched: boolean;
  brandsAndModels: BrandAndModel[];
  allModels: OptionType[];
  filterPagination: IPagination;
  cars: UsedCarType[];
  carsCurrentPage: UsedCarType[];
  prevPage: number;
  isFetch: boolean;
  allColors: IAllColors;
  bannerPromotion: IBannerPromotion;
  bannersPromotion: IBannerPromotion[] | null;
  promotionCards: IPromotionCard[];
  manufacturerEnum: Record<string, string>;
}
export interface ISearchUsedCarsQuery {
  filters: ISearchUsedCarsFiltersQuery;
  sort: IObj;
  isAdditionFiltersSelected: boolean;
  pagination: IPagination;
  version?: string;
}
export interface ISearchUsedCarsFiltersQuery {
  budget: FilterBudgetType;
  colors: string[];
  categories: string[];
  models: string[];
  brands: string[];
  yearsRange: FilterYearType;
  kmRange: FilterKmType;
  gearType: string[];
  engineType: string[];
  carHighlights: string[];
  carBodyType: string[];
  carHand: string[];
  originOwnership: string[];
  imagesLength: boolean | null;
  id?: number[];
}

export interface IAllColors {
  [key: string]: {
    title: string;
    hex: string;
    allColorsFamily: string[];
  };
}
export interface IPagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

export interface IColorOptionData {
  mainColor: string | number;
  value: { colorTitle: string }[];
}
export interface OptionsType {
  isSelected: boolean;
  name: string;
  values: OptionType[];
}
export interface FilterBudgetType {
  paymentMethod: string;
  priceRange: {
    minPrice: number | null;
    maxPrice: number | null;
  };
}
export interface FilterYearType {
  minYear: string | null;
  maxYear: string | null;
}
export interface FilterKmType {
  minPrice: number | null;
  maxPrice: number | null;
}
export interface BrandAndModel {
  ManufacturerName: string;
  models: string[];
}
export interface categoryType {
  name: string;
  icon: categoryIconType;
}

export interface categoryIconType {
  url: string;
}
export interface OptionType {
  title: string;
  icon?: string;
  code?: number | string;
  hex?: string;
  nameEn?: string;
  url?: string;
  seo?: { page: ResultPageSEO[] };
}

export const KM_METHOD = {
  monthly: 'תשלום חודשי',
  oneTime: 'תשלום מלא',
  oneTimeForUsed: 'לרכב תשלום מלא',
};

export const USED_CAR_PAYMENT_METHOD = {
  monthly: 'חודשי',
  oneTime: 'חד פעמי',
};

export const USED_CAR_PAYMENT_DEFAULT_VALUE = {
  monthly: { minPrice: 1, maxPrice: 1500, step: 100 },
  oneTime: { minPrice: 5000, maxPrice: 150000, step: 1000 },
};
export const USED_CAR_PAYMENT_METHODS_DATA = {
  [USED_CAR_PAYMENT_METHOD.oneTime]: {
    minPrice: 5000,
    maxPrice: 150000,
    step: 1000,
  },
  [USED_CAR_PAYMENT_METHOD.monthly]: {
    minPrice: 1,
    maxPrice: 1500,
    step: 100,
  },
};

export interface SortItemType {
  title: string;
  value: string;
  value2?: string;
  type: string;
}

export const sortOptions: SortItemType[] = [
  {
    title: 'הרלוונטי ביותר',
    value: 'imagesLength',
    type: 'desc',
  },
  {
    title: 'מחיר מהנמוך לגבוה',
    value: 'websitePrice',
    value2: 'webPriceMonthlyPayment',
    type: 'asc',
  },
  {
    title: 'מחיר מהגבוה לנמוך',
    value: 'websitePrice',
    value2: 'webPriceMonthlyPayment',
    type: 'desc',
  },
  {
    title: 'קמ מהגבוה לנמוך',
    value: 'kms',
    type: 'desc',
  },
  {
    title: 'קמ מהנמוך לגבוה',
    value: 'kms',
    type: 'asc',
  },
  {
    title: 'שנה מהגבוה לנמוך',
    value: 'year',
    type: 'desc',
  },
  {
    title: 'שנה מהנמוך לגבוה',
    value: 'year',
    type: 'asc',
  },
];

export interface IAdvantages {
  title: string;
  bullets: { title: string }[];
}

export const getSortByKey = (sort: IObj) => {
  const key = Object.keys(sort)[0];

  return {
    [sortOptions.find((s) => s.value === key || s.value2 === key)?.value ||
    key]: sort[key],
  };
};
