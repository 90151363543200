/* eslint-disable no-shadow */
import RESOURCES from '~/resources';
import { numberWithCommas } from '~/data/utils/numbers';
import { IWarrantyDetailsType } from '~/components/Warranty/Warranty';

/* eslint-disable no-unused-vars */
export const CARS_PURCHASE_METHODS = [
  RESOURCES.LEASING,
  RESOURCES.FUNDING,
  RESOURCES.BUYING,
];
export const CARS_TYPE_METHOD = {
  [CARS_PURCHASE_METHODS[0]]: 'LEASING_CAR',
  [CARS_PURCHASE_METHODS[1]]: 'NEW_CAR_LOAN',
  [CARS_PURCHASE_METHODS[2]]: 'NEW_CAR',
};

export enum CAR_FLOW_TYPE {
  NEW_CAR = 'Buying',
  LOAN_CAR = 'Funding',
  LEASING_CAR = 'Leasing',
  ONLINE_PRICE = 'onlinePrice',
}

export const CAR_DEAL_TYPE_OPTIONS = [
  { label: RESOURCES.LEASING, value: CAR_FLOW_TYPE.LEASING_CAR },
  { label: RESOURCES.FUNDING, value: CAR_FLOW_TYPE.LOAN_CAR },
  { label: RESOURCES.BUYING, value: CAR_FLOW_TYPE.NEW_CAR },
];
export const USED_CAR_DEAL_TYPE_OPTIONS = [
  { label: RESOURCES.BUYING, value: CAR_FLOW_TYPE.NEW_CAR },
  { label: RESOURCES.FUNDING, value: CAR_FLOW_TYPE.LOAN_CAR },
  { label: RESOURCES.ONLINE_PRICE, value: CAR_FLOW_TYPE.ONLINE_PRICE },
];

export const ADVANCE_PAYMENT_TYPE = {
  Buying: 2000,
  Funding: 2000,
  Leasing: 800,
  onlinePrice: 2000,
};

export const getLegalInfoPerDealType = (
  dealType: CAR_FLOW_TYPE,
  totalPrice?: number,
  isUsedCar?: boolean,
) => {
  switch (dealType) {
    case CAR_FLOW_TYPE.LEASING_CAR:
      return RESOURCES.LEGAL_INFO_LEASING;
    case CAR_FLOW_TYPE.LOAN_CAR:
      return isUsedCar
        ? RESOURCES.LEGAL_INFO_FUNDING_USED_CAR
        : RESOURCES.LEGAL_INFO_FUNDING;
    case CAR_FLOW_TYPE.NEW_CAR:
      return `${RESOURCES.LEGAL_INFO_BUYING} ${RESOURCES.NEW_SHEKEL_SIGN}${
        totalPrice && numberWithCommas(totalPrice.toString())
      }. ${RESOURCES.PHOTOS_ILLUSTRATION_ONLY}`;
    default:
      return '';
  }
};

export const CAR_FLOW_ADVANCE_PAYMENT = {
  [CAR_FLOW_TYPE.NEW_CAR]: 2000,
  [CAR_FLOW_TYPE.LOAN_CAR]: 2000,
  [CAR_FLOW_TYPE.LEASING_CAR]: 800,
  [CAR_FLOW_TYPE.ONLINE_PRICE]: 1000,
  TEST: 1,
};

// eslint-disable-next-line no-shadow
export enum EXTENDED_PLAN {
  EXTENDED = '64',
  NORMAL = '47',
}

// Function to replace placeholders in the template with actual values
export const replacePlaceholders = (
  template: string,
  years: string,
  km: string,
  years_battery?: string,
  km_battery?: string,
) => {
  return template
    .replace(/{years}/g, years)
    .replace(/{km}/g, km)
    .replace(/{years_battery}/g, years_battery ?? '')
    .replace(/{km_battery}/g, km_battery ?? '');
};

export const getWarrantyDetails = (warranties: IWarrantyDetailsType[]) => {
  const warrantyDetails = warranties.map((warranty) => {
    let title = '';
    let description = '';

    if (warranty.warrantyByYears && warranty.warrantyByKm) {
      title = `${RESOURCES.ABOUT_CAR}`;
      description = replacePlaceholders(
        '{years} שנים או  {km} ק״מ',
        warranty.warrantyByYears,
        warranty.warrantyByKm,
      );
    }
    const isAutomatic = warranty.isAutomatic || false;
    const engineType = warranty.engineType || '';
    const engineVolume = warranty.engineVolume || '';

    return { title, description, isAutomatic, engineType, engineVolume };
  });

  return warrantyDetails;
};

export const initialDataReq = {
  filters: {
    budget: {
      paymentMethod: 'תשלום חודשי',
      priceRange: {
        minPrice: null,
        maxPrice: null,
      },
    },
    colors: [],
    categories: [],
    models: [],
    carDescriptionHighlights: [],
    carBodyType: [],
    brands: [],
  },
  pagination: {
    page: 1,
    pageSize: 200,
  },
  sortBy: {
    title: 'מחיר נמוך לגבוה',
    value: 'asc',
  },
  isAdditionFiltersSelected: false,
};
