import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMPARE, CompareCarTypeEnum } from '~/data/types/compare/compareTypes';
import {
  getCookie,
  setCookie,
  deleteCookie,
} from '~/utils/cookieStorageHandlers';
import {
  getCompareListsByType,
  setCompareListsByType,
  toggleCompareCar,
  toggleCompareId,
} from '../utils/compareUtils';
import { getUsedCarsForComparison } from '../thunks/compareThunks';

interface CompareCarState {
  compareIds: number[];
  cars: Record<any, any>[];
}

export interface CompareState {
  usedCarComparison: CompareCarState;
  newCarComparison: CompareCarState;
  leasingComparison: CompareCarState;
  comparePopover: {
    isOpen: boolean;
    shouldAutoClose: boolean;
  };
}

const initialState: CompareState = (() => {
  const storedCompare = getCookie(COMPARE) || {};
  return {
    usedCarComparison: {
      compareIds: storedCompare.usedCarComparison || [],
      cars: [],
    },
    newCarComparison: {
      compareIds: storedCompare.newCarComparison || [],
      cars: [],
    },
    leasingComparison: {
      compareIds: storedCompare.leasingComparison || [],
      cars: [],
    },
    comparePopover: {
      isOpen: false,
      shouldAutoClose: false,
    },
  };
})();

const loadComparisonData = (stateData: any, cookieData: any) => {
  if (stateData.compareIds.length === 0 && stateData.cars.length === 0) {
    stateData.compareIds = cookieData?.compareIds || [];
    stateData.cars = cookieData?.cars || [];
  }
};

const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    loadCompareFromCookie: (state) => {
      const storedCompare = getCookie(COMPARE) || {};
      loadComparisonData(
        state.usedCarComparison,
        storedCompare.usedCarComparison,
      );
      loadComparisonData(
        state.newCarComparison,
        storedCompare.newCarComparison,
      );
      loadComparisonData(
        state.leasingComparison,
        storedCompare.leasingComparison,
      );
    },
    toggleCompareCarId: (
      state,
      action: PayloadAction<{
        car: any;
        type: CompareCarTypeEnum;
      }>,
    ) => {
      const { car, type } = action.payload;

      // Get current compareIds and cars by type
      const { compareIds, cars } = getCompareListsByType(state, type);

      // Toggle car ID and car itself
      const updatedCompareIds = toggleCompareId(compareIds, car.id);
      const updatedCars = toggleCompareCar(cars, car);

      // Update the state
      setCompareListsByType(state, type, updatedCompareIds, updatedCars);

      // Update cookie
      if (
        state.usedCarComparison.compareIds.length === 0 &&
        state.newCarComparison.compareIds.length === 0 &&
        state.leasingComparison.compareIds.length === 0
      ) {
        deleteCookie(COMPARE);
      } else {
        setCookie(
          COMPARE,
          {
            usedCarComparison: state.usedCarComparison.compareIds,
            newCarComparison: state.newCarComparison.compareIds,
            leasingComparison: state.leasingComparison.compareIds,
          },
          1,
        );
      }
    },

    addCarByType: (
      state,
      action: PayloadAction<{
        car: any;
        type: CompareCarTypeEnum;
      }>,
    ) => {
      const { car, type } = action.payload;
      const { cars } = getCompareListsByType(state, type);
      const carExists = cars.some((existingCar) => existingCar.id === car.id);

      if (!carExists) {
        cars.push(car);
      }

      setCompareListsByType(state, type, undefined, cars);
    },

    setComparePopupState: (
      state,
      action: PayloadAction<{ isOpen: boolean; shouldAutoClose?: boolean }>,
    ) => {
      state.comparePopover.isOpen = action.payload.isOpen;
      state.comparePopover.shouldAutoClose =
        action.payload.shouldAutoClose || false;
    },

    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.comparePopover.isOpen = action.payload;
    },

    removeAllCarsByType: (
      state,
      action: PayloadAction<{
        type: CompareCarTypeEnum;
        shouldUpdateCookie?: boolean;
      }>,
    ) => {
      const { type, shouldUpdateCookie } = action.payload;

      setCompareListsByType(state, type, [], []);

      if (shouldUpdateCookie) {
        if (
          state.usedCarComparison.compareIds.length === 0 &&
          state.newCarComparison.compareIds.length === 0 &&
          state.leasingComparison.compareIds.length === 0
        ) {
          deleteCookie(COMPARE);
        } else {
          setCookie(
            COMPARE,
            {
              usedCarComparison: state.usedCarComparison.compareIds,
              newCarComparison: state.newCarComparison.compareIds,
              leasingComparison: state.leasingComparison.compareIds,
            },
            1,
          );
        }
      }
    },
    setCarsByType: (
      state,
      action: PayloadAction<{
        type: CompareCarTypeEnum;
        ids: any;
        cars: any;
        shouldUpdateCookie?: boolean;
      }>,
    ) => {
      const { type, shouldUpdateCookie, cars, ids } = action.payload;
      setCompareListsByType(state, type, ids, cars);
      if (shouldUpdateCookie) {
        if (
          state.usedCarComparison.compareIds.length === 0 &&
          state.newCarComparison.compareIds.length === 0 &&
          state.leasingComparison.compareIds.length === 0
        ) {
          deleteCookie(COMPARE);
        } else {
          setCookie(
            COMPARE,
            {
              usedCarComparison: state.usedCarComparison.compareIds,
              newCarComparison: state.newCarComparison.compareIds,
              leasingComparison: state.leasingComparison.compareIds,
            },
            1,
          );
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsedCarsForComparison.fulfilled, (state, action) => {
      if (!action.payload) return;

      const cars = action.payload.data || [];
      const ids = cars.map((car) => car.id);

      if (!state.usedCarComparison.cars.length) {
        setCompareListsByType(state, CompareCarTypeEnum.USED, ids, cars);
      }
    });
  },
});

export const {
  loadCompareFromCookie,
  toggleCompareCarId,
  addCarByType,
  setComparePopupState,
  setIsOpen,
  removeAllCarsByType,
  setCarsByType,
} = compareSlice.actions;
export default compareSlice.reducer;
