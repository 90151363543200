import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';
import { Box, styled } from '@mui/material';

export const StyledFreesbeLogo = styled('svg')(() => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const StyledFreesbeLogoMobile = styled('svg')(() => ({
  display: 'block',
  margin: '0 auto 24px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const classes = {
  poweredByMoveoDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.primary.main,
    position: 'relative',
  },

  poweredByMoveo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    direction: 'ltr',
    backgroundColor: COLORS.primary.main,
    gap: '6px',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      borderTop: `1px solid ${COLORS.black.dark}`,
      paddingTop: '20px',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },

  listItemStyle: {
    width: 'auto',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  } as const,
};

export default classes;

export const ContainerStyle = styled('div')({
  width: '100%',
  padding: '32px 20px 0px 20px',
  borderTop: `1px solid ${COLORS.gray.light7}`,
  backgroundColor: 'white',
  [theme.breakpoints.up('md')]: {
    padding: '66px 40px 20px 40px',
    display: 'flex',
  },
});

export const ChildContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '9px',
  backgroundColor: 'white',
});

export const FooterContentContainer = styled(Box)({
  width: '1440px',
  height: 'max-content',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  padding: '24px',
  backgroundColor: 'inherit',
  borderRadius: '9px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '360px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
});

export const ScrollUpBtnSection = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  paddingBottom: '24px',
  borderBottom: `1px solid ${COLORS.white.dark}`,
  [theme.breakpoints.up('md')]: {
    width: 'max-content',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'none',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '0',
  },
});

export const scrollUpText = {
  marginBottom: '24px',
};

export const linkStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
};

export const scrollUpBtn = {
  border: '1px solid black',
  borderRadius: '5px',
  padding: '9px  45px',
};

export const poweredByText = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'black',
};

export const FooterDiv = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: COLORS.primary.main,
  padding: '20px 40px 20px 40px',
  [theme.breakpoints.down('md')]: {
    padding: '20px 40px 84px 40px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row-reverse',
  },
});
