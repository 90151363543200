import theme from '~/theme';

const classes = {
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      flex: 'row',
    },
  },
};

export const header = {
  marginLeft: '10px',
  fontSize: '18px',
  whiteSpace: 'nowrap',
  fontWeight: '400',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
};

export default classes;
