import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

const useStyles = () => ({
  timerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },
  },
  digitContainer: {
    display: 'flex',
    gap: '5px',
    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },
  },
  digitContainerRevert: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '5px',
    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },
  },
  timeBox: {
    width: '33.77px',
    height: '36.37px',
    borderRadius: '5.2px',
    backgroundColor: COLORS.blue.light2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '26px',
      height: '28px',
      borderRadius: '4px',
    },
  },
  digitText: {
    fontSize: '32px',
    fontWeight: 400,
    textAlign: 'center',
    marginTop: '4px',
    color: COLORS.black,
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
  labelText: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    color: COLORS.black,
  },
  separator: {
    width: '18px',
    [theme.breakpoints.down('md')]: {
      width: '14px',
    },
  },
});

export default useStyles;
