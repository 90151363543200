/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AccessoryDataType,
  IOrderDetails,
  EngineType,
  LeaseCarType,
  NewCarConfPageType,
} from '~/data/types/carFlow/carFlowTypes';
import carFlowAdapter from '~/redux-toolkit/adapters/carFlowAdapter';
import { CAR_FLOW_TYPE } from '~/data/car/carCardData';

export const getCarConfigurator = createAsyncThunk(
  'GetCarFlowData',
  async (params: {
    dmvModelName: string;
    dealType: CAR_FLOW_TYPE.NEW_CAR | CAR_FLOW_TYPE.LOAN_CAR | '';
    carId: number;
  }): Promise<{
    engines: EngineType[];
    newCarConfPage: NewCarConfPageType;
  } | null> => {
    try {
      const carFlowRes = await carFlowAdapter.getCarFlowContent(
        params.dmvModelName,
        params.dealType,
        params.carId,
      );

      const engines = (carFlowRes.data as any).engines;
      const newCarConfPage = (carFlowRes.data as any).newCarConfPage;
      return {
        engines,
        newCarConfPage,
      };
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);

export const getLeaseCarConfigurator = createAsyncThunk(
  'GetLeasingCarFlowData',
  async (
    dmvModelName: string,
  ): Promise<{
    leaseEngines: LeaseCarType[];
    newCarConfPage: NewCarConfPageType;
  } | null> => {
    try {
      const leaseCarFlow = await carFlowAdapter.getLeaseCarData(dmvModelName);
      const engines = (leaseCarFlow.data as any).engines;
      const newCarConfPage = (leaseCarFlow.data as any).newCarConfPage;
      return {
        leaseEngines: engines,
        newCarConfPage,
      };
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);

interface getCarFlowAdditionsProps {
  modelVehicle: string;
  colorId: string;
}
export const getCarFlowAdditions = createAsyncThunk(
  'filters[carModelCode][$eq]=0185-0022&filters[carColorId][$eq]=IC-KAD',
  async ({ modelVehicle, colorId }: getCarFlowAdditionsProps): Promise<any> => {
    try {
      const response = await carFlowAdapter.getCarFlowAdditions(
        modelVehicle,
        colorId,
      );
      const { data } = response;
      return data.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getCarFlowAccessories = createAsyncThunk(
  '?populate=*&filters[newCarId][modelVehicle][$eq]=000-653',
  async (modelVehicle: string): Promise<AccessoryDataType[]> => {
    try {
      const response = await carFlowAdapter.getCarFlowAccessories(modelVehicle);
      const { data } = response;
      return data.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

interface accessoriesProps {
  modelVehicle: string;
  productCode: string;
}
export const getLeasingAccessories = createAsyncThunk(
  '/api/lease-cars-accessories',
  async ({ modelVehicle, productCode }: accessoriesProps): Promise<any> => {
    try {
      const response = await carFlowAdapter.getLeasingAccessories(
        modelVehicle,
        productCode,
      );
      const { data } = response;
      return data.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getLeasingAdditions = createAsyncThunk(
  '/api/lease-cars-additions',
  async ({ modelVehicle, productCode }: accessoriesProps): Promise<any> => {
    try {
      const response = await carFlowAdapter.getLeasingAdditions(
        modelVehicle,
        productCode,
      );
      const { data } = response;
      return data.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getCar = createAsyncThunk(
  '?filters[modelVehicle][$eq]==000-653',
  async (modelVehicle: string): Promise<any> => {
    try {
      const response = await carFlowAdapter.getCar(modelVehicle);
      const { data } = response;
      return data.data[0];
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const createNewCarOrder = createAsyncThunk(
  'createNewCarOrder',
  async (data: IOrderDetails): Promise<string> => {
    try {
      const response = await carFlowAdapter.createNewCarOrder(data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return '';
    }
  },
);
export const createLeaseCarOrder = createAsyncThunk(
  'createNewCarOrder',
  async (data: IOrderDetails): Promise<string> => {
    try {
      const response = await carFlowAdapter.createLeaseCarOrder(data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return '';
    }
  },
);
export const createLeaseCustomer = createAsyncThunk(
  'createLeaseCustomer',
  async (data: IOrderDetails): Promise<any> => {
    try {
      const response = await carFlowAdapter.createLeaseCustomer(data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  },
);
