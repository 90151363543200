export const SCROLLBAR = {
  width: '6px',
  track: {
    background: '#f1f1f1',
    borderRadius: '6px',
  },
  thumb: {
    background: '#888',
    borderRadius: '6px',
    hover_background: '#555',
  },
};

export const SCROLLBARMINI = {
  width: '3px',
  track: {
    background: '#f1f1f1',
    borderRadius: '3px',
  },
  thumb: {
    background: '#888',
    borderRadius: '3px',
    hover_background: '#555',
  },
};
