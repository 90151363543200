import { CarTechSpecData } from '~/data/car/carTechSpec';
import { HttpClientService } from '~/services/HttpsClientService';

class CarTechSpecAdapter {
  static readonly endpoint = `api/car-tech-spec?dmvModelName=`;

  static readonly leasingEndpoint = `/api/leasing/car-tech-spec?dmvModelName=`;

  // eslint-disable-next-line class-methods-use-this
  async getTechSpec(model: string, isZeroKm?: boolean, carId?: number) {
    const res = await HttpClientService.get<CarTechSpecData[]>(
      `${CarTechSpecAdapter.endpoint}${model}&isZeroKm=${Boolean(
        isZeroKm,
      )}&carId=${Number(carId)}`,
    );
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLeasingTechSpec(model: string) {
    const res = await HttpClientService.get<CarTechSpecData[]>(
      `${CarTechSpecAdapter.leasingEndpoint}${model}`,
    );
    return res;
  }
}

const carTechSpecAdapter = new CarTechSpecAdapter();
export default carTechSpecAdapter;
