import React from 'react';
import { ImageFile } from '~/data/types/images';

const CheckBoxIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="24"
      height="24"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="5.5"
        fill="white"
        stroke="#d8d8d8"
      />
    </Component>
  );
};

export default CheckBoxIconIcon;
