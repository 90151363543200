import { IconButton } from '@mui/material';
import { APPROVAL_FLOW_STEPS } from '~/data/approvalFlowComponent';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import {
  initState,
  setIsApprovalFlowOpen,
} from '~/redux-toolkit/slices/approvalFlowSlice';
import { useCallback } from 'react';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { ARIA_LABELS_RESOURCES } from '~/resources/resources';
import {
  ApprovalFlowPopupDialog,
  ContentBoxWidget,
  LayoutImage,
  ModalWidget,
  closeButtonStyle,
  layoutContainer,
} from './useStyles';
import CloseIcon from '~/assets/images/jsx/close';

const ApprovalFlowPopup = () => {
  const { isApprovalFlowOpen, approvalActiveStep } = useSelector(
    (state: RootState) => state.approvalFlow,
  );
  const dispatch = useAppDispatch();

  const onClickOpenApprovalFlow = useCallback(
    (_event: object) => {
      dispatch(setIsApprovalFlowOpen(!isApprovalFlowOpen));
      dispatch(initState());
    },
    [dispatch, isApprovalFlowOpen],
  );

  return (
    <ApprovalFlowPopupDialog
      open={isApprovalFlowOpen}
      onClose={onClickOpenApprovalFlow}
      sx={{ direction: 'rtl' }}
      aria-label={`${ARIA_LABELS_RESOURCES.POPUP_WINDOW_OPEN} ${ARIA_LABELS_RESOURCES.FUNDING_APPROVAL_FLOW}`}
      aria-live="polite"
    >
      <ModalWidget>
        <IconButton
          id="closeBtn"
          onClick={onClickOpenApprovalFlow}
          sx={closeButtonStyle}
          aria-label={ARIA_LABELS_RESOURCES.POPUP_WINDOW_CLOSE}
          autoFocus
        >
          <CloseIcon />
        </IconButton>

        {APPROVAL_FLOW_STEPS[approvalActiveStep].imgUrl && (
          <LayoutImage
            src={
              (
                APPROVAL_FLOW_STEPS[approvalActiveStep].imgUrl as {
                  src: string;
                }
              ).src
            }
            alt=""
          />
        )}
        <ContentBoxWidget sx={layoutContainer}>
          {APPROVAL_FLOW_STEPS[approvalActiveStep].component}
        </ContentBoxWidget>
      </ModalWidget>
    </ApprovalFlowPopupDialog>
  );
};

export default ApprovalFlowPopup;
