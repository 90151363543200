import React from 'react';
import { ImageFile } from '~/data/types/images';

const longPlanSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
    >
      <path
        d="M74.5 32.0001C58.5 49.0001 33.6681 2.68043 24 22.5001C14 43.0001 69.8028 39.2635 58.5 59.1318C47.1972 79.0001 38 41.0001 14 59.1318"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
      <path
        d="M13.9798 37.0701C26.6551 37.0701 23.459 54.0522 14.3851 59.3882C13.9999 59.6147 13.5183 59.5797 13.17 59.2998C5.77262 53.3553 1.33805 37.0701 13.9798 37.0701Z"
        fill="#00D6D1"
        stroke="#262626"
        strokeWidth="2"
      />
      <circle
        cx="14.1855"
        cy="45.5155"
        r="2.1484"
        fill="#FFFEFE"
        stroke="#262626"
        strokeWidth="2"
      />
      <path
        d="M77.8237 35.9334C75.3835 37.7436 71.9379 37.2328 70.1277 34.7926C68.3176 32.3523 68.8283 28.9067 71.2686 27.0965C73.7088 25.2864 77.1544 25.7972 78.9646 28.2374C80.7747 30.6776 80.264 34.1233 77.8237 35.9334Z"
        fill="#00D6D1"
      />
      <path
        d="M70.221 35.1336C67.5052 31.1155 69.4119 27.9008 72.1445 26.5725C74.8771 25.2442 78.1691 26.3826 79.4974 29.1151C80.8257 31.8477 79.6873 35.1397 76.9547 36.468C76.3151 36.779 75.6448 36.9547 74.9763 37.0065"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default longPlanSelectedIcon;
