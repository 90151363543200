import { HttpClientService } from '~/services/HttpsClientService';
import { MetaType } from '~/data/types/paginationTypes';
import {
  GetBannerCardsResponseType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';
import { CategoriesResponseType } from '~/data/types/categories/categories';
import { SupportedBrandData } from '~/data/types/brands';
import { homePageContentType } from '~/data/types/homePageContentType';
import { QuickSearchContentType } from '~/data/QuickSearch/quickSearchType';
import { VideoDataType } from '~/data/types/video';
import {
  Meta,
  seoResultPageDataEntity,
} from '~/data/types/seo/seoResutlsPages';
import { BudgetCardsResponseType } from '~/data/types/budgetCardTypes';

class LobbyPageAdapter {
  static readonly endpointHero = `/api/home-page-contents`;
  static readonly endpointQuickSearch = `/api/home-page-quick-searches`;
  static readonly endpointBannerGallery = `/api/banners-galleries`;
  static readonly endpointCategories = `/api/freesbe-categories`;
  static readonly endpointSupportedBrands = `/api/supported-brands`;
  static readonly endpointVideo = `/api/videos`;
  static readonly endpointSeoResultsPage = `/api/seo-results-page`;
  static readonly endpointStaticBanner = `/api/card-banners`;
  static readonly endpointSearchByNeeds = `/api/search-by-needs`;
  static readonly endpointBudgetCards = `/api/budget-cards`;

  // eslint-disable-next-line class-methods-use-this
  async getLobbyContent(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: homePageContentType[];
    }>(
      `${LobbyPageAdapter.endpointHero}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyNewCarContent() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: homePageContentType[];
    }>(`${LobbyPageAdapter.endpointHero}?populate=deep,*`);
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyUsedCarContent() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: homePageContentType[];
    }>(`${LobbyPageAdapter.endpointHero}?populate=deep,*`);
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyPageQuickSearch(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: QuickSearchContentType[];
    }>(
      `${LobbyPageAdapter.endpointQuickSearch}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyPageNewCarQuickSearch() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: QuickSearchContentType[];
    }>(`${LobbyPageAdapter.endpointQuickSearch}?populate=deep,*`);
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyPageUsedCarQuickSearch() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: QuickSearchContentType[];
    }>(`${LobbyPageAdapter.endpointQuickSearch}?populate=deep,*`);
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyPageBannerGallery(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: GetBannerCardsResponseType[];
    }>(
      `${LobbyPageAdapter.endpointBannerGallery}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }

  async getLobbyNewCarPageBannerGallery(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: GetBannerCardsResponseType[];
    }>(
      `${LobbyPageAdapter.endpointBannerGallery}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }
  async getLobbyUsedCarPageBannerGallery(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: GetBannerCardsResponseType[];
    }>(
      `${LobbyPageAdapter.endpointBannerGallery}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyCategories(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CategoriesResponseType[];
    }>(
      `${LobbyPageAdapter.endpointCategories}?populate=deep,*&filters[pages][name]=${page}`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbySupportedBrands(filterShowInFreesbe: boolean = true) {
    const filterQuery = filterShowInFreesbe
      ? '&filters[showInFreesbe][$eq]=true'
      : '';
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: SupportedBrandData[];
    }>(
      `${LobbyPageAdapter.endpointSupportedBrands}?populate=deep,*${filterQuery}`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbyVideo(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: VideoDataType[];
    }>(
      `${LobbyPageAdapter.endpointVideo}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSeoContent(page: string): Promise<any> {
    const res = await HttpClientService.get<{
      data: seoResultPageDataEntity;
      meta: Meta;
    }>(
      `${LobbyPageAdapter.endpointSeoResultsPage}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  // eslint-disable-next-line no-unused-vars
  async getLobbyPageStaticBanners(_page: string): Promise<any> {
    const res = await HttpClientService.get<{
      data: seoResultPageDataEntity;
      meta: Meta;
    }>(`${LobbyPageAdapter.endpointStaticBanner}?populate=deep,*`);
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getLobbySearchByNeeds(page: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: SearchByNeedResponseType[];
    }>(
      `${LobbyPageAdapter.endpointSearchByNeeds}?populate=*&filters[pages][name]=${page}`,
    );
    return res.data;
  }

  async getLobbyPageBudgetCards(page: string) {
    const res = await HttpClientService.get<{
      data: BudgetCardsResponseType[];
      meta: Meta;
    }>(
      `${LobbyPageAdapter.endpointBudgetCards}?populate=deep,*&filters[page][name]=${page}`,
    );
    return res.data;
  }
}

const lobbyPageAdapter = new LobbyPageAdapter();
export default lobbyPageAdapter;
