import { ILeadConfig, IUserDetails } from '~/data/lead/interfaces';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';
import { UpsertLeadRequest } from '~/services/LeadService/requests/UpsertLeadRequest';
import {
  addLeadProducts,
  createLead,
  getMeetingCityTerritories,
  getMeetingCityTerritorySlots,
  getTestDriveCityTerritories,
  getTestDriveTerritorySlots,
} from '~/redux-toolkit/thunks/contactUsThunks';
import { RequiredLeadParams } from '~/services/LeadService/interfaces';
import { UpsertLead } from '~/services/LeadService/UpsertData';
import { getLineOfBusinessByCampaignNumber } from '~/data/lead/lineOfBusiness';

import { useCampaignNumber } from './useCampaignNumber';
import { useCarDealType } from './useCarDealType';
import { useSelectedCarConfiguratorData } from './useSelectedCarConfiguratorData';
import { useCurrentCar } from './useCurrentCar';
import { useGetUtmToLead } from './useGetUtmToLead';
// import { useSubMediaNumbers } from './useSubMediaNumbers';
import { getSubMediaByCampaignNumber } from '~/data/lead/subMedia';

export const useLeadActions = (config?: { withFailureCampaign: boolean }) => {
  const campaignNumber = useCampaignNumber(
    Boolean(config?.withFailureCampaign),
  );

  const { isApprovalFlowOpen } = useAppSelector(
    (state: RootState) => state.approvalFlow,
  );

  const lineOfBusiness = getLineOfBusinessByCampaignNumber(campaignNumber);
  // const subMedia = useSubMediaNumbers(campaignNumber);

  const selectedCarData = useSelectedCarConfiguratorData();
  const dealType = useCarDealType();
  const dispatch = useAppDispatch();
  const utmData = useGetUtmToLead();
  const car = useCurrentCar();

  const {
    loading: isProcessingLead,
    error: createLeadError,
    leadId,
    subMedia,
  } = useSelector((state: RootState) => state.contactUsSlice);

  const buildUpsertLead = useCallback(
    (details: IUserDetails, leadConfig?: ILeadConfig) => {
      // 1. required lead params
      const requiredParams: RequiredLeadParams = {
        firstName: details.firstName,
        lastName: details.lastName,
        mobilePhone: details.phone,
        email: details.email,
        mailsApproved: details.mailsApproved,
        campaignNumber: leadConfig?.campaignNumber || campaignNumber,
        lineOfBusiness: leadConfig?.lineOfBusiness || lineOfBusiness || '',
        // subMediaCode: leadConfig?.subMedia || utmData.subMedia || subMedia,
        subMediaCode:
          leadConfig?.subMedia ||
          getSubMediaByCampaignNumber(
            leadConfig?.campaignNumber || campaignNumber,
          ),
        note: leadConfig?.note || '',
        hotLead: Boolean(leadConfig?.hotLead),
        utmCampaign: utmData.campaign,
        utmMedium: utmData.medium,
        utmSource: utmData.source,
        utmContent: utmData.content,
        utmTerm: utmData.term,
        secondarySubMedia: utmData.subMedia,
      };

      const upsertLead = new UpsertLead(requiredParams);
      upsertLead.meetingCity = details.city;
      upsertLead.agency = details.agency;

      upsertLead.build({
        car: selectedCarData,
        dealType,
        isApprovalFlowOpen,
        products: leadConfig?.products,
        currentCar: car,
        leadId,
      });

      return upsertLead;
    },
    [
      campaignNumber,
      lineOfBusiness,
      utmData,
      selectedCarData,
      dealType,
      isApprovalFlowOpen,
      car,
      leadId,
    ],
  );

  const createLeadRequest = useCallback(
    async (details: IUserDetails, leadConfig?: ILeadConfig) => {
      const upsertLead = buildUpsertLead(details, leadConfig);
      await dispatch(createLead({ upsertLead }));
    },
    [buildUpsertLead, dispatch],
  );

  const addLeadProductsRequest = useCallback(
    (details: IUserDetails, leadConfig?: ILeadConfig) => {
      const upsertLead = buildUpsertLead(details, leadConfig);

      dispatch(addLeadProducts({ upsertLead }));
    },
    [buildUpsertLead, dispatch],
  );

  const getCityTerritories = useCallback(
    (details: IUserDetails) => {
      const upsertLead = buildUpsertLead(details, { subMedia: subMedia });
      return dispatch(getMeetingCityTerritories({ upsertLead }));
    },
    [buildUpsertLead, dispatch, subMedia],
  );

  const getCityTerritoriesTestDrive = useCallback(
    (details: IUserDetails, familyId: string) => {
      const upsertLead = buildUpsertLead(details, { subMedia: subMedia });
      return dispatch(getTestDriveCityTerritories({ upsertLead, familyId }));
    },
    [buildUpsertLead, dispatch, subMedia],
  );

  const getUsedCityTerritories = useCallback(
    (details: IUserDetails, leadConfig?: ILeadConfig) => {
      const upsertLead = buildUpsertLead(details, leadConfig);

      return dispatch(getMeetingCityTerritories({ upsertLead }));
    },
    [buildUpsertLead, dispatch],
  );

  const getCityTerritorySlots = useCallback(
    (territoryId: string, modelSkill?: string) => {
      // if (leadId) {
      if (modelSkill) {
        dispatch(
          getTestDriveTerritorySlots({
            leadId: leadId || '',
            campaignNumber,
            territoryId,
            modelSkill,
          }),
        );
      } else {
        dispatch(
          getMeetingCityTerritorySlots({
            leadId: leadId || '',
            campaignNumber,
            territoryId,
          }),
        );
        // }
      }
    },
    [campaignNumber, dispatch, leadId],
  );

  const buildLeadRequest = useCallback(
    (details: IUserDetails, leadConfig?: ILeadConfig) => {
      const upsertLead = buildUpsertLead(details, leadConfig);
      const request = new UpsertLeadRequest(upsertLead);
      return request;
    },
    [buildUpsertLead],
  );

  return useMemo(() => {
    return {
      createLeadRequest,
      isProcessingLead,
      createLeadError,
      createLeadFinished: leadId && !createLeadError,
      leadId,
      buildLeadRequest,
      getCityTerritories,
      getCityTerritorySlots,
      addLeadProductsRequest,
      getUsedCityTerritories,
      buildUpsertLead,
      getCityTerritoriesTestDrive,
    };
  }, [
    createLeadRequest,
    isProcessingLead,
    createLeadError,
    leadId,
    buildLeadRequest,
    getCityTerritories,
    getCityTerritorySlots,
    addLeadProductsRequest,
    getUsedCityTerritories,
    buildUpsertLead,
    getCityTerritoriesTestDrive,
  ]);
};
