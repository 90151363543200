const ContactUsPopup = dynamic(() => import('../ContactUsPopup'), {
  ssr: false,
});
import { useContactUsPopup } from '~/hooks/useContactUsPopup';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';
import { PopupContainer } from './useStyles';
import ApprovalFlowPopup from '../ApprovalFlowPopup/ApprovalFlowPopup';
import dynamic from 'next/dynamic';

const Popups = () => {
  const { closeContactUsModal } = useContactUsPopup();
  const { isContactUsModalOpen } = useAppSelector(
    (state: RootState) => state.contactUsSlice,
  );

  return (
    <PopupContainer>
      <ContactUsPopup
        isOpen={isContactUsModalOpen}
        handleClose={closeContactUsModal}
      />
      <ApprovalFlowPopup />
    </PopupContainer>
  );
};
export default Popups;
