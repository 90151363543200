import React from 'react';
import { ImageFile } from '~/data/types/images';

const EmailIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="20"
      height="21"
      className={className}
      style={style}
      fill="none"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.919 7.87695L11.2163 10.8878C10.5167 11.4428 9.53244 11.4428 8.83286 10.8878L5.09888 7.87695"
        stroke="black"
        strokeWidth="1.11345"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0908 18.0009C16.6253 18.008 18.3334 15.9256 18.3334 13.3663V7.64265C18.3334 5.08333 16.6253 3.00098 14.0908 3.00098H5.90936C3.3749 3.00098 1.66675 5.08333 1.66675 7.64265V13.3663C1.66675 15.9256 3.3749 18.008 5.90936 18.0009H14.0908Z"
        stroke="black"
        strokeWidth="1.11345"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default EmailIcon;
