import React from 'react';
import { ImageFile } from '~/data/types/images';

const fourStarsIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="22"
      viewBox="0 0 77 22"
    >
      <path
        d="M58.9293 9.93504C58.5299 9.67671 58.6956 9.05802 59.1708 9.03403L63.3687 8.82209C64.0319 8.7886 64.5978 8.33126 64.7696 7.68989L65.8547 3.64023C65.9774 3.18239 66.6139 3.14804 66.7851 3.59002L68.3083 7.52134C68.5467 8.13684 69.153 8.53005 69.8122 8.49677L74.0296 8.28384C74.5041 8.25989 74.7317 8.85733 74.3616 9.15515L71.1013 11.7785C70.5821 12.1963 70.393 12.9021 70.6337 13.5235L72.1455 17.4256C72.3171 17.8685 71.8213 18.2721 71.4224 18.0142L67.8765 15.7211C67.3222 15.3626 66.6006 15.4 66.0863 15.8138L62.8016 18.4569C62.4323 18.754 61.8982 18.406 62.0209 17.9482L63.106 13.8985C63.2779 13.2572 63.0165 12.5782 62.4589 12.2176L58.9293 9.93504Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M40.0729 9.93504C39.6734 9.67671 39.8392 9.05802 40.3143 9.03403L44.5123 8.82209C45.1754 8.7886 45.7413 8.33126 45.9132 7.68989L46.9983 3.64023C47.121 3.18239 47.7574 3.14804 47.9287 3.59002L49.4518 7.52134C49.6903 8.13684 50.2966 8.53005 50.9558 8.49677L55.1732 8.28384C55.6476 8.25989 55.8752 8.85733 55.5051 9.15515L52.2448 11.7785C51.7256 12.1963 51.5365 12.9021 51.7773 13.5235L53.2891 17.4256C53.4607 17.8685 52.9648 18.2721 52.5659 18.0142L49.0201 15.7211C48.4658 15.3626 47.7441 15.4 47.2298 15.8138L43.9451 18.4569C43.5758 18.754 43.0418 18.406 43.1645 17.9482L44.2496 13.8985C44.4214 13.2572 44.16 12.5782 43.6025 12.2176L40.0729 9.93504Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M21.2155 9.93553C20.816 9.6772 20.9818 9.05851 21.4569 9.03452L25.6549 8.82257C26.318 8.78909 26.8839 8.33175 27.0558 7.69038L28.1409 3.64072C28.2635 3.18288 28.9 3.14853 29.0713 3.59051L30.5944 7.52183C30.8329 8.13733 31.4391 8.53054 32.0984 8.49725L36.3157 8.28433C36.7902 8.26038 37.0178 8.85782 36.6477 9.15563L33.3874 11.779C32.8682 12.1968 32.6791 12.9026 32.9198 13.524L34.4316 17.426C34.6033 17.869 34.1074 18.2726 33.7085 18.0146L30.1626 15.7216C29.6084 15.3631 28.8867 15.4005 28.3724 15.8143L25.0877 18.4574C24.7184 18.7545 24.1844 18.4065 24.3071 17.9487L25.3922 13.899C25.564 13.2577 25.3026 12.5786 24.7451 12.2181L21.2155 9.93553Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M2.35804 9.93504C1.95857 9.67671 2.12435 9.05802 2.59947 9.03403L6.79745 8.82209C7.4606 8.7886 8.02648 8.33126 8.19834 7.68989L9.28344 3.64023C9.40612 3.18239 10.0426 3.14804 10.2138 3.59002L11.737 7.52134C11.9754 8.13684 12.5817 8.53005 13.241 8.49677L17.4583 8.28384C17.9328 8.25989 18.1604 8.85733 17.7903 9.15515L14.53 11.7785C14.0108 12.1963 13.8217 12.9021 14.0624 13.5235L15.5742 17.4256C15.7458 17.8685 15.25 18.2721 14.8511 18.0142L11.3052 15.7211C10.7509 15.3626 10.0293 15.4 9.515 15.8138L6.23027 18.4569C5.86098 18.754 5.32695 18.406 5.44963 17.9482L6.53474 13.8985C6.70659 13.2572 6.4452 12.5782 5.88763 12.2176L2.35804 9.93504Z"
        fill="white"
        stroke="black"
        strokeWidth="1.04008"
      />
    </Component>
  );
};

export default fourStarsIcon;
