import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { EUrlParam } from '~/data/urlParams';

const usePersistedQueryParams = (
  storageKey = 'queryParams',
  excludedParams = Object.values(EUrlParam),
) => {
  const router = useRouter();

  // Function to filter out excluded parameters
  const filterParams = useCallback(
    (params: any) => {
      const filteredParams = { ...params };
      excludedParams.forEach((param) => delete filteredParams[param]);
      return filteredParams;
    },
    [excludedParams],
  );
  // Save current query params before unmounting
  useEffect(() => {
    const handleRouteChange = () => {
      const queryParams = new URLSearchParams(
        filterParams(router.query),
      ).toString();
      localStorage.setItem(storageKey, queryParams);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.query, router.events, storageKey, excludedParams, filterParams]);

  // Restore query params on mount
  useEffect(() => {
    const savedParams = localStorage.getItem(storageKey);
    if (savedParams && Object.keys(router.query).length === 0) {
      router.replace(
        { pathname: router.pathname, query: savedParams },
        undefined,
        { shallow: true },
      );
    }
  }, [router, storageKey]);
};

export default usePersistedQueryParams;
