import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { CAR_FLOW_TYPE } from '~/data/car/carCardData';
import { CarTechSpecData } from '~/data/car/carTechSpec';
import '~/data/types/car/cars';
import { carDescriptionHighlights } from '~/data/types/car/cars';
import {
  IAdvantages,
  IPagination,
  UsedCarSpecDataById,
  UsedCarType,
} from '~/data/types/usedCar/usedCarData';
import { createLead } from '~/redux-toolkit/thunks/contactUsThunks';
import {
  getAdvantagesContent,
  getMoreUsedCarsContent,
  getPopularUsedCars,
  getUsedCarById,
  getUsedCarFiltersOptions,
  getUsedCarTechSpec,
  getUsedCarsContent,
  getUsedCarsContentAdditional,
  isExistOpenLead,
  validateOTP,
} from '~/redux-toolkit/thunks/usedCarThunks';
import { getSeoProgModelContent } from '../thunks/resultPageThunks';
import { deleteCookie } from '~/utils/cookieStorageHandlers';

interface CarState {
  usedCars: UsedCarType[];
  usedCarsAdditional: UsedCarType[];
  usedCarsId: number[];
  productsSf: string[];
  fetchingCar: boolean;
  pagination: IPagination;
  usedCar: UsedCarType;
  advantagesContent: IAdvantages;
  useCarTechSpec?: CarTechSpecData[];
  isLazyLoading: boolean;
  isExistOpenLeadUsedCar: boolean;
  selectedBranch: string;
  otpResponse: { StatusDescription: string; StatusId: number };
  seoPage: { seoTitle: string; seoDesc: string } | null;
  exposedCarIds: number[];
  popularUsedCars: UsedCarType[];
}

const initialState: CarState = {
  usedCars: [],
  usedCarsAdditional: [],
  usedCarsId: [],
  productsSf: [],
  fetchingCar: false,
  selectedBranch: '',
  pagination: {
    page: 1,
    pageSize: 24,
    pageCount: 0,
    total: 100,
  },
  usedCar: {} as UsedCarType,
  advantagesContent: {} as IAdvantages,
  useCarTechSpec: [],
  isLazyLoading: false,
  isExistOpenLeadUsedCar: false,
  otpResponse: { StatusDescription: ' ', StatusId: 0 },
  exposedCarIds: [],
  seoPage: null,
  popularUsedCars: [],
};

const usedCarSlice = createSlice({
  name: 'usedCarSlice',
  initialState,
  reducers: {
    setIsExistOpenLeadUsedCar(state, action) {
      state.isExistOpenLeadUsedCar = action.payload;
    },
    setSelectedBranch(state, action) {
      state.selectedBranch = action.payload;
    },
    setProductSf(state, action) {
      const productsSf = [...state.productsSf, action.payload];
      state.productsSf = productsSf.filter(
        (item, index) => productsSf.indexOf(item) === index,
      );
    },
    setProductsSf(state, action) {
      const productsSf = [...state.productsSf, ...action.payload];
      state.productsSf = productsSf.filter(
        (item, index) => productsSf.indexOf(item) === index,
      );
    },
    setUsedCar(state, action) {
      state.fetchingCar = false;
      state.usedCar = {
        ...action.payload,
        webPriceMonthlyPayment: action.payload.webPriceMonthlyPayment,
        usedCarSpec: action.payload.usedCarSpec,
        makeNameEn:
          (action.payload.usedCarSpec as UsedCarSpecDataById)?.makeNameEn ||
          null,
        modelNameEn:
          (action.payload.usedCarSpec as UsedCarSpecDataById)?.modelNameEn ||
          null,
        id: action.payload.id,
        carHighlights: action.payload.carHighlights.data.map(
          (item: carDescriptionHighlights) => {
            return {
              id: item.id,
              title: item?.attributes?.title,
              icon: item?.attributes?.icon?.data?.attributes?.url || null,
            };
          },
        ),
      };
    },
    setUsedCarPagination(state, action) {
      state.pagination = action.payload.pagination;
    },
    setExposedCarIds(state, action) {
      state.exposedCarIds = action.payload;
    },
    setUsedCarsContent(state, action) {
      state.isLazyLoading = false;
      state.pagination = action.payload.pagination;

      state.usedCars = [...action?.payload?.data];
      state.usedCarsId = action?.payload?.data.map((c: UsedCarType) => c.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsedCarsContent.pending, (state) => {
      state.fetchingCar = true;
    });
    builder.addCase(getUsedCarFiltersOptions.pending, (state) => {
      state.fetchingCar = true;
    });
    builder.addCase(getUsedCarFiltersOptions.fulfilled, (state) => {
      state.fetchingCar = false;
    });

    builder.addCase(getUsedCarsContent.fulfilled, (state, action) => {
      state.fetchingCar = false;

      state.pagination = action.payload.pagination;
      state.usedCars = action.payload.data.map((item) => {
        return {
          ...item,
          usedCarSpec: item.usedCarSpec,
        };
      });
      state.usedCarsId = action?.payload?.data.map((c: UsedCarType) => c.id);
    });
    builder.addCase(getPopularUsedCars.fulfilled, (state, action) => {
      state.popularUsedCars = action.payload.data.map((item) => {
        return {
          ...item,
        };
      });
    });
    builder.addCase(getUsedCarsContentAdditional.fulfilled, (state, action) => {
      state.usedCars = [
        ...state.usedCars,
        ...action.payload.data.map((item) => ({
          ...item,
          usedCarSpec: item.usedCarSpec,
        })),
      ];
    });

    builder.addCase(getMoreUsedCarsContent.pending, (state) => {
      state.isLazyLoading = true;
    });

    builder.addCase(getMoreUsedCarsContent.fulfilled, (state, action) => {
      state.isLazyLoading = false;

      const moreCars = action.payload.data.map((item) => {
        return {
          ...item,
          usedCarSpec: item.usedCarSpec,
        };
      });
      // Filter out cars that already exist in the state
      const newUsedCars = moreCars.filter(
        (car) => !state.usedCarsId.includes(car.id),
      );

      // Update state with only new cars
      state.usedCars = [...state.usedCars, ...newUsedCars];

      // Update the usedCarsId array with IDs of new cars
      state.usedCarsId = [
        ...state.usedCarsId,
        ...newUsedCars.map((car) => car.id),
      ];

      // state.usedCars = [...state.usedCars, ...moreCars];
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getUsedCarById.pending, (state) => {
      state.fetchingCar = true;
      state.usedCar = {} as UsedCarType;
    });
    builder.addCase(getUsedCarById.rejected, (state) => {
      state.fetchingCar = false;
    });
    builder.addCase(getUsedCarById.fulfilled, (state, action) => {
      state.fetchingCar = false;

      state.usedCar = {
        ...action.payload,
        webPriceMonthlyPayment: action.payload.webPriceMonthlyPayment,
        usedCarSpec: action.payload.usedCarSpec,
        makeNameEn:
          (action.payload?.usedCarSpec as UsedCarSpecDataById)?.makeNameEn ||
          '',
        modelNameEn:
          (action.payload.usedCarSpec as UsedCarSpecDataById)?.modelNameEn ||
          '',
        id: action.payload.id,
        carHighlights: action.payload.carHighlights.map((item) => {
          return {
            id: item.id,
            title: item?.title,
            icon: item?.icon?.data?.attributes?.url || null,
          };
        }),
      };
    });
    builder.addCase(getAdvantagesContent.fulfilled, (state, action) => {
      if (!action.payload) return;

      const { attributes } = action.payload;

      state.advantagesContent = {
        title: attributes.title,
        bullets: attributes.bullets,
      };
    });
    builder.addCase(getUsedCarTechSpec.fulfilled, (state, action) => {
      state.useCarTechSpec = Array.isArray(action.payload)
        ? action.payload
        : [];
    });

    builder.addCase(validateOTP.fulfilled, (state, action) => {
      state.otpResponse = action.payload;
    });
    builder.addCase(isExistOpenLead.fulfilled, (state, action) => {
      const isOpenLead = Boolean(action.payload);
      state.isExistOpenLeadUsedCar = isOpenLead;
      if (!isOpenLead) {
        deleteCookie('onlinePriceExposed');
      }
    });
    builder.addCase(createLead.fulfilled, (state, action) => {
      const leadId =
        (action.payload?.upsertLead.isSuccessLead === 'true' &&
          action.payload?.upsertLead.leadSfid) ||
        undefined;
      if (
        leadId &&
        window.location.pathname.includes(CAR_FLOW_TYPE.ONLINE_PRICE)
      ) {
        state.isExistOpenLeadUsedCar = true;
      }
    });
    builder.addCase(createLead.pending, (state) => {
      state.isExistOpenLeadUsedCar = false;
    });
    builder.addCase(createLead.rejected, (state) => {
      state.isExistOpenLeadUsedCar = false;
    });
    builder.addCase(getSeoProgModelContent.fulfilled, (state, action) => {
      if (!action.payload) return;

      const data = action.payload;
      state.seoPage = {
        seoDesc: data.seoDesc,
        seoTitle: data.seoTitle,
      };
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { usedCarSlice } = action.payload;
      return {
        ...state,
        ...usedCarSlice,
      };
    });
  },
});
export const {
  setIsExistOpenLeadUsedCar,
  setSelectedBranch,
  setProductsSf,
  setProductSf,
  setUsedCar,
  setUsedCarPagination,
  setUsedCarsContent,
  setExposedCarIds,
} = usedCarSlice.actions;

export default usedCarSlice.reducer;
