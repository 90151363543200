import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  AccumulatorType,
  RentalLocationAttributesType,
  RentalLocationDataType,
  branchLocationsDic,
  branchesLeadLocationsDic,
} from '~/data/rentalLocation.data';
import { OurBranchesState } from '~/data/types/rentalLocationsType';
import {
  getBranchTypes,
  getFilteredBranches,
  getOurBranches,
  getOurBranchesForGenericPages,
  getOurBranchesV2,
} from '~/redux-toolkit/thunks/ourBranchesThunks';
import _ from 'lodash';
import {
  branchTypeNameMap,
  ourBranchesTypesMap,
} from '~/components/GenericContentPage/data';

const initialState: OurBranchesState = {
  ourBranches: [],
  fetchingBranches: false,
  branchTypes: [],
  usedBranches: {},
  selectedArea: branchLocationsDic.every,
  selectedServiceType: [],
  selectedBranch: null,
  genericPageBranches: {},
};

const ourBranchesSlice = createSlice({
  name: 'ourBranches',
  initialState,
  reducers: {
    setOurBranches: (state, action) => {
      state.ourBranches = action.payload;
    },
    setBranchTypes: (state, action) => {
      state.branchTypes = action.payload;
    },
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    setSelectedServiceType: (state, action) => {
      state.selectedServiceType = action.payload;
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOurBranches.pending, (state) => {
      state.fetchingBranches = true;
    });
    builder.addCase(getOurBranches.rejected, (state) => {
      state.fetchingBranches = false;
    });
    // builder.addCase(getOurBranches.fulfilled, (state, action) => {
    //   state.ourBranches = action.payload.map((item) => {
    //     return {
    //       ...item,
    //       branchTypes: item.branchTypes.map((item2) => item2),
    //       id: item.id,
    //       supportedBrands: item?.supportedBrands?.data?.map(
    //         (brand) => {
    //           return {
    //             hebrewName: brand.attributes.hebrewName,
    //             englishName: brand.attributes.englishName,
    //           };
    //         },
    //       ),
    //     };
    //   });
    // });
    builder.addCase(getOurBranchesV2.fulfilled, (state, action) => {
      state.ourBranches = action.payload.map((item) => {
        return {
          ...item,
          branchTypes: item.branchTypes.map((item2) => item2),
          id: item.id,
          supportedBrands:
            item?.supportedBrands?.map((brand) => {
              return {
                hebrewName: brand.hebrewName,
                englishName: brand.englishName,
              };
            }) ?? [],
        };
      });
    });
    builder.addCase(getFilteredBranches.fulfilled, (state, action) => {
      const filterAndGroupByCountryRegion = (
        data: RentalLocationDataType[],
        name: string,
      ) => {
        return data
          .filter(
            (item) =>
              item.attributes.branchTypes.data.length > 0 &&
              item.attributes.branchTypes.data.some(
                (branchType: {
                  attributes: {
                    name?: string;
                    englishName: string;
                    hebrewName: string;
                  };
                }) =>
                  branchType.attributes.name === name ||
                  branchType.attributes.englishName === name ||
                  branchType.attributes.hebrewName === name,
              ),
          )
          .reduce((acc: AccumulatorType, item) => {
            const countryRegion = item.attributes.countryRegion;

            if (!acc[countryRegion]) {
              acc[countryRegion] = [];
            }

            acc[countryRegion].push(item.attributes);
            return acc;
          }, {});
      };

      // Filter data by name and group by countryRegion
      const targetName = 'יד שנייה וטרייד אין';
      const filteredAndGroupedByCountryRegion = filterAndGroupByCountryRegion(
        action.payload,
        targetName,
      );
      const branches = {
        [branchesLeadLocationsDic.north]:
          filteredAndGroupedByCountryRegion[branchesLeadLocationsDic.north] ??
          null,
        [branchesLeadLocationsDic.center]:
          filteredAndGroupedByCountryRegion[branchesLeadLocationsDic.center] ??
          null,
        [branchesLeadLocationsDic.Sharon]:
          filteredAndGroupedByCountryRegion[branchesLeadLocationsDic.Sharon] ??
          null,
        [branchesLeadLocationsDic.jerusalem]:
          filteredAndGroupedByCountryRegion[
            branchesLeadLocationsDic.jerusalem
          ] ?? null,
        [branchesLeadLocationsDic.south]:
          filteredAndGroupedByCountryRegion[branchesLeadLocationsDic.south] ??
          null,
      };
      state.usedBranches = branches;
    });
    builder.addCase(getBranchTypes.fulfilled, (state, action) => {
      state.branchTypes = action.payload.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => {
          return {
            name: item.attributes.name,
            urlTitle: item.attributes.urlTitle,
            rank: item.attributes?.rank ?? 0,
          };
        },
      );
    });
    builder.addCase(
      getOurBranchesForGenericPages.fulfilled,
      (state, action) => {
        const result = _(action.payload)
          .flatMap((item) => {
            const branchTypes = item.attributes.branchTypes?.data || [];
            const supportedBrands = item.attributes.supportedBrands?.data || [];

            // Generate a list of items for each combination of branchType and supportedBran
            return branchTypes.flatMap((brand) =>
              supportedBrands.length > 0
                ? supportedBrands.map((brandItem) => ({
                    ...item,
                    branchType: brand.attributes.name,
                    hebrewName: brandItem.attributes.hebrewName || 'unbranded',
                  }))
                : [
                    {
                      ...item,
                      branchType: brand.attributes.name,
                      hebrewName: 'unbranded',
                    },
                  ],
            );
          })
          .groupBy(
            (item) =>
              branchTypeNameMap[item?.branchType ?? ''] || item.branchType,
          )
          .mapValues((items, branchType) =>
            _.chain(items)
              .groupBy('hebrewName')
              .mapValues((items) =>
                _.chain(items)
                  .filter(
                    (item) =>
                      branchType !==
                        branchTypeNameMap[ourBranchesTypesMap.SERVICE_CENTER] ||
                      item.attributes.freesbeOfficial === true,
                  )
                  .sortBy((item) => item.attributes?.city)
                  .map((item) => ({
                    ..._.omit(item, ['branchType', 'hebrewName', 'attributes']),
                    ...item.attributes,
                  }))
                  .value(),
              )
              .omitBy((value) => _.isEmpty(value))
              .value(),
          )
          .omitBy((value) => _.isEmpty(value))
          .toPairs()
          .sortBy(([key]) => key)
          .map(([key, value]) => [
            key,
            _(value)
              .toPairs()
              .sortBy(([innerKey]) => innerKey)
              .fromPairs()
              .value(),
          ])
          .fromPairs()
          .value() as {
          [key: string]: { [key: string]: RentalLocationAttributesType[] };
        };

        state.genericPageBranches = result;
      },
    );
    builder.addCase(HYDRATE, (state, action: any) => {
      const { ourBranches } = action.payload;

      return {
        ...state,
        ...ourBranches,
      };
    });
  },
});

export const {
  setBranchTypes,
  setOurBranches,
  setSelectedArea,
  setSelectedBranch,
  setSelectedServiceType,
} = ourBranchesSlice.actions;

export default ourBranchesSlice.reducer;
