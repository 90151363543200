export const numberWithCommas = (strNumber: string) => {
  return strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getValidNumberInRage = (
  num: number,
  minNum: number,
  maxNum: number,
) => {
  if (num < minNum) {
    return minNum;
  }
  if (num > maxNum) {
    return maxNum;
  }
  return num;
};
