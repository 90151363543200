import { CAR_FLOW_TYPE } from '~/data/car/carCardData';
import { SelectedCar } from '~/data/types/carFlow/carFlowTypes';
import { getFindingCodeFromLocalStorage } from '~/utils/ApprovalFlow';
import RESOURCES from '~/resources';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { CarCardType } from '~/data/types/car/cars';
import { CampaignNumber } from './enums';
import {
  CreateTaskListItem,
  LeadProductItem,
  RequiredLeadParams,
} from './interfaces';

export class UpsertLead {
  isUpsertLeadNeeded = true;

  leadId: string | undefined = undefined;

  agency: string | undefined = undefined;

  accountNumber: string | undefined = undefined;

  firstName: string;

  lastName: string;

  mobilePhone: string;

  email: string | undefined = undefined;

  campaignNumber: CampaignNumber;

  usedBuyManufacturerCode: string | undefined = undefined;

  usedBuyProductCode: string | undefined = undefined;

  usedBuyLicenseNumber: string | undefined = undefined;

  last4Digits: string | undefined = undefined;

  advancePaymentAmount: string | undefined = undefined;

  advancePaymentFromWebsite = undefined;

  usedSellProduct: string | undefined = undefined;

  autoCashFunding: boolean | undefined = undefined;

  interestedInInsurance: boolean | undefined = undefined;

  interestedInTrade: boolean | undefined = undefined;

  hotLead: boolean | undefined = undefined;

  notes = '';

  interestedCommercialSMS: boolean | undefined;

  leadSource = 'Freesbe Website';

  subMediaCode: string;

  meetingCity: string | undefined = undefined;

  lineOfBusiness?: string;

  withoutLineOfBusiness: boolean | undefined = undefined;

  creatLeadProductList: LeadProductItem[] = [];

  createTaskList: CreateTaskListItem[] | undefined = undefined;

  UTMCampaign: string | undefined = undefined;

  UTMMedium: string | undefined = undefined;

  UTMSource: string | undefined = undefined;

  UTMContent: string | undefined = undefined;

  UTMTerm: string | undefined = undefined;

  secondarySubMedia: string | undefined = undefined;

  familyId?: string;

  constructor(requiredParams: RequiredLeadParams) {
    this.firstName = requiredParams.firstName;
    this.lastName = requiredParams.lastName;
    this.mobilePhone = requiredParams.mobilePhone;
    this.email = requiredParams.email;
    this.interestedCommercialSMS = requiredParams.mailsApproved;
    this.campaignNumber = requiredParams.campaignNumber;
    this.hotLead = Boolean(requiredParams.hotLead);

    // LeadPromotion note
    this.notes += (requiredParams.note && `${requiredParams.note}, `) || '';

    this.subMediaCode = requiredParams.subMediaCode;

    if (requiredParams.utmCampaign) {
      this.UTMCampaign = requiredParams.utmCampaign;
    }

    if (requiredParams.utmContent) {
      this.UTMContent = requiredParams.utmContent;
    }

    if (requiredParams.utmMedium) {
      this.UTMMedium = requiredParams.utmMedium;
    }

    if (requiredParams.utmSource) {
      this.UTMSource = requiredParams.utmSource;
    }

    if (requiredParams.utmTerm) {
      this.UTMTerm = requiredParams.utmTerm;
    }

    if (requiredParams.secondarySubMedia) {
      this.secondarySubMedia = requiredParams.secondarySubMedia;
    }

    if (!requiredParams.lineOfBusiness) {
      this.withoutLineOfBusiness = true;
    } else {
      this.lineOfBusiness = requiredParams.lineOfBusiness;
    }
  }

  addLeadProducts(...items: LeadProductItem[]) {
    if (!this.creatLeadProductList) {
      this.creatLeadProductList = items;
    } else {
      this.creatLeadProductList.push(...items);
    }
  }

  build({
    car,
    dealType,
    isApprovalFlowOpen,
    products,
    currentCar,
    leadId,
  }: {
    car: SelectedCar | UsedCarType;
    dealType: CAR_FLOW_TYPE;
    isApprovalFlowOpen: boolean;
    products?: string[];
    currentCar: CarCardType | null;
    leadId?: string | null;
  }) {
    if ((car as UsedCarType).carNumber) {
      // TODO: Unique detail to used car
    } else {
      this.addNewCarProducts({
        car: car as SelectedCar,
        dealType,
        isApprovalFlowOpen,
        currentCar,
      });
    }

    if (products) {
      this.addLeadProducts(
        ...products.map((p) => {
          return { productId: p, leadId };
        }),
      );
    }
    if (leadId) {
      this.leadId = leadId;
    }

    // 7. build notes:
    const approvalCode = getFindingCodeFromLocalStorage();

    // קוד מימון - fjaisifwn
    this.notes +=
      (approvalCode && `${RESOURCES.LOAN_APPROVAL} - ${approvalCode},`) || '';
  }

  updateLeadId(leadId: string) {
    this.creatLeadProductList = this.creatLeadProductList?.map((p) => ({
      productId: p.productId,
      leadId,
    }));
  }

  hasLeadProducts() {
    return this.creatLeadProductList.length > 0;
  }

  removeLeadProducts() {
    this.creatLeadProductList = [];
  }

  addNewCarProducts({
    car,
    dealType,
    isApprovalFlowOpen,
    currentCar,
  }: {
    car: SelectedCar;
    dealType: CAR_FLOW_TYPE;
    isApprovalFlowOpen: boolean;
    currentCar: CarCardType | null;
  }) {
    // 2. build car model code in CreateLeadProductList
    if (car?.color.modelSFID) {
      this.addLeadProducts({
        productId: car?.color.modelSFID,
      });
    }

    // 3. build accessories in CreateLeadProductList only for new/loan car
    if (dealType !== CAR_FLOW_TYPE.LEASING_CAR) {
      const accessories: LeadProductItem[] =
        car.accessories
          .filter((a) => a.sfCode)
          .map((a) => {
            return {
              productId: a.sfCode || '',
            };
          }) || [];
      this.addLeadProducts(...accessories);
    }

    // 4. build isExtendedWarranty in CreateLeadProductList
    if (car.warranty.isExtendedWarranty) {
      this.addLeadProducts({ productId: car.warranty.extendedWarrantySFId });
    }

    // 5. build interestedInInsurance
    this.interestedInInsurance = car.needsInsurance;

    // 6. build interestedInTradin
    this.interestedInTrade = car.needsTrade;

    // 7. build autoCashFunding
    if (dealType === CAR_FLOW_TYPE.LOAN_CAR || isApprovalFlowOpen) {
      this.autoCashFunding = true;
    }

    /** car color title
     *  loan code if there is
     */
    if (dealType === CAR_FLOW_TYPE.LEASING_CAR) {
      // build manufacturerCode & modelCode-year
      this.usedBuyManufacturerCode = car.color.manufacturerCode;
      this.usedBuyProductCode =
        car.color.modelCode && `${car.color.modelCode}-${car.color.year}`;

      // TODO: ADD sfid as create product list leas EXTENDED_PLAN 47 / 49
      if (car.plansAndManagement?.productSF) {
        this.addLeadProducts({
          productId: car.plansAndManagement.productSF,
        });
      }
      // תקופת ליסינג - 20000
      this.notes +=
        (car &&
          car.timeFrame?.periodInMonths &&
          `${RESOURCES.CAR_FLOW_LEASE_TIME_FRAME} - ${car.timeFrame.periodInMonths},`) ||
        '';
      // חבילת ק״מ - 30
      this.notes +=
        car &&
        car.distancePackage.kmPerYear &&
        `${RESOURCES.CAR_FLOW_DISTANCE_PACKAGES_LABEL} - ${car.distancePackage.kmPerYear},`;
    }

    // 7. build notes:
    const approvalCode = getFindingCodeFromLocalStorage();

    // add car model
    // this.notes +=
    //   currentCar &&
    //   `${RESOURCES.MODEL_NAME} - ${
    //     currentCar?.modelNameDisplayName || currentCar.dmvModelName
    //   },`;

    // // צבע -  לבן
    // this.notes +=
    //   (car.color.title && `${RESOURCES.COLOR} -  ${car.color.title},`) || '';

    this.notes +=
      (approvalCode &&
        `${RESOURCES.APPROVAL_CODE_TITLE} -  ${approvalCode},`) ||
      '';

    this.notes += currentCar?.familyCarZoneId.includes('Chassis')
      ? RESOURCES.ZERO_CAR
      : '';
    ('');

    this.notes = this.notes.replaceAll('null', '');
  }
}
