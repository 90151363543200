/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
class FormValidation {
  validateText(text: string) {
    return /^[a-z\u0590-\u05fe ]+$/i.test(text);
  }

  // wont allow: '   '
  validateTextIsNotAllEmptySpaces(text: string) {
    return /^(?!\s*$).+/.test(text);
  }

  validateTextAndEmptySpaces(text: string) {
    // also limit to min 2 max 40 chars
    return (
      /^(?!\s*$).+/.test(text) && /^[a-z\u0590-\u05fe ]{2,30}$/i.test(text)
    );
  }

  validateDigitsOnly(char: string) {
    return /^\d|Backspace|Delete|Tab|ArrowUp|ArrowDown|ArrowLeft|ArrowRight$/i.test(
      char,
    );
  }

  validateAddress(text: string) {
    // also limit to min 2 max 40 chars
    return /^[a-zA-Z0-9\u0590-\u05fe\s,'-()-]{2,40}$/i.test(text);
  }

  validateGoogleMap(text: string) {
    return !!text.length;
  }

  validateEmail(email: string) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s-@+%&$"]+(\.[^<>()[\]\\.,;:\s-@+%&$"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
  }

  validateEmailEnglishOnly(email: string) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s-@+%&$"]+(\.[^<>()[\]\\.,;:\s-@+%&$"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
  }

  validatePhone(phone: string) {
    const pattern = /^(05\d{8}|0(2|3|4|8|9)\d{7}|07\d{8})$/;
    const result = pattern.test(phone);
    return result;
  }

  validateIsraeliCellPhone(phone: string) {
    return /^(05[0-9]{8})$/.test(phone);
  }

  validateID(id: string) {
    let newId = String(id).trim();
    if (newId.length > 9 || newId.length === 0) return false;
    newId = newId.length < 9 ? `00000000${newId}`.slice(-9) : newId;
    return (
      Array.from(newId, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) %
        10 ===
      0
    );
  }

  validateLicenseNumber(licenseNumber: string) {
    const newId = String(licenseNumber).trim();
    return newId.length > 10 || newId.length < 6 ? false : true;
  }
}

export const formValidation = new FormValidation();

export default formValidation;
