import { styled } from '@mui/material';
import theme from '~/theme';

const classes = {
  iconsContainer: {
    display: 'flex',
    gap: '35px',
    marginTop: '40px',
    whiteSpace: 'nowrap',
    '& div': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    gap: '10px',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: '16px',
      lineHeight: '24px',
    },

    '& img': {
      width: '55px',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
};

export const contactUsItemStyle = {
  marginTop: 0,
  direction: 'ltr',
};

export const phoneIconStyle = { width: '55px', height: '55px' };
export const phoneNumberStyle = {
  position: 'absolute',
  bottom: '-30px',
};

export default classes;

export const PhoneDropDownArrowStyled = styled('svg')(() => ({
  height: '10px',
  width: '12px',
  color: 'black',
}));
