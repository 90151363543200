import longWaySelected from '~/assets/icons/jsx/longPlanSelected';
import longWay from '~/assets/icons/jsx/longPlan';
import shortWaySelected from '~/assets/icons/jsx/shortPlanSelected';
import shortWay from '~/assets/icons/jsx/shortPlan';
import shortKm from '~/assets/icons/jsx/smallKm';
import mediumKm from '~/assets/icons/jsx/normalKm';
import longKm from '~/assets/icons/jsx/highKm';
import shortKmSelected from '~/assets/icons/jsx/smallKmSelected';
import mediumKmSelected from '~/assets/icons/jsx/normalKmSelected';
import longKmSelected from '~/assets/icons/jsx/highKmSelected';
import normalRoad from '~/assets/icons/jsx/normalRoad';
import normalRoadSelected from '~/assets/icons/jsx/normalRoadSelected';
import extendedRoad from '~/assets/icons/jsx/extendedRoad';
import extendedRoadSelected from '~/assets/icons/jsx/extendedRoadSelected';
import oneStar from '~/assets/icons/jsx/oneStar';
import twoStars from '~/assets/icons/jsx/twoStars';
import threeStars from '~/assets/icons/jsx/threeStars';
import fourStars from '~/assets/icons/jsx/fourStars';
import fiveStars from '~/assets/icons/jsx/fiveStars';
import oneSelectedStar from '~/assets/icons/jsx/oneStarSelected';
import twoSelectedStar from '~/assets/icons/jsx/twoStarsSelected';
import threeSelectedStar from '~/assets/icons/jsx/threeStarsSelected';
import fourSelectedStars from '~/assets/icons/jsx/fourStarsSelected';
import fiveSelectedStars from '~/assets/icons/jsx/fiveStarsSelected';
import insuranceSelected from '~/assets/icons/jsx/insuranceSelected';
import tradeInSelected from '~/assets/icons/jsx/tradeInSelected';
import Engine1 from '~/assets/icons/jsx/engine1';
import treeYearsWarranty from '~/assets/icons/warrantyThree.svg';
import fiveYearsWarrantySelected from '~/assets/icons/warrantyFiveSelected.svg';
import treeYearsWarrantySelected from '~/assets/icons/warrantyThreeSelected.svg';
import unnumberedWarrantySelected from '~/assets/icons/warrantyUnnumberedSelected.svg';
import unnumberedWarranty from '~/assets/icons/warrantyUnnumbered.svg';
import fiveYearsWarranty from '~/assets/icons/warrantyFive.svg';
import extendedInsurance from '~/assets/icons/jsx/insurance';
import tradeInLogo from '~/assets/icons/jsx/tradeIn';
import greenListBullet from '~/assets/icons/jsx/greenListBullet';
import AccessoryIcon from '~/assets/icons/jsx/accessoryIcon';
// PNG
// import summaryStepImage from '~/assets/images/summaryStepImage.png';
import summaryStepImage from '~/assets/images/jsx/summaryStepImage';
import phoneHolder from '~/assets/images/phoneHolder.png';

const ICONS_CAR_FLOW = {
  longWaySelected,
  longWay,
  shortWaySelected,
  shortWay,
  shortKm,
  mediumKm,
  longKm,
  shortKmSelected,
  mediumKmSelected,
  longKmSelected,
  normalRoad,
  normalRoadSelected,
  extendedRoad,
  extendedRoadSelected,
  oneStar,
  twoStars,
  threeStars,
  fourStars,
  fiveStars,
  oneSelectedStar,
  twoSelectedStar,
  threeSelectedStar,
  fourSelectedStars,
  fiveSelectedStars,
  insuranceSelected,
  tradeInSelected,
  Engine1,
  treeYearsWarranty,
  fiveYearsWarranty,
  fiveYearsWarrantySelected,
  treeYearsWarrantySelected,
  extendedInsurance,
  tradeInLogo,
  summaryStepImage,
  phoneHolder,
  greenListBullet,
  AccessoryIcon,
  unnumberedWarrantySelected,
  unnumberedWarranty,
};

export default ICONS_CAR_FLOW;
