import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CAR_TARGET } from '~/data/consts';
import { ROUTES } from '~/data/routes';
import { EBusinessLineEvent } from './useEvents';

export const routeTargetMap = {
  [ROUTES.PRIVATE_LEASING]: CAR_TARGET.LEASING,
  [ROUTES.CAR_PAGE]: CAR_TARGET.NEW_CAR,
  [ROUTES.USED_CAR]: CAR_TARGET.USED_CAR,
  [ROUTES.CAR_FLEET]: CAR_TARGET.FLEET,
  [ROUTES.BUSINESS_LEASING_PAGE]: EBusinessLineEvent.BUSINESS_LEASING,
  [ROUTES.INSURANCE_PAGE]: EBusinessLineEvent.INSURANCE,
  [ROUTES.FINANCE]: EBusinessLineEvent.FUNDING,
  [ROUTES.CHARGING_SOLUTIONS]: EBusinessLineEvent.ENERGY,
};

const useLineOfBusinessTarget = (dealType?: string) => {
  const router = useRouter();
  const [target, setTarget] = useState<string | null>(null);

  useEffect(() => {
    const path = router.asPath;
    const matchingTarget = Object.entries(routeTargetMap).find(([route]) =>
      path.includes(route.replace('/', '')),
    )?.[1];

    const overwriteNewCarTarget =
      matchingTarget === CAR_TARGET.NEW_CAR && dealType?.includes('Leasing');

    setTarget(
      overwriteNewCarTarget ? CAR_TARGET.LEASING : matchingTarget || null,
    );
  }, [router.asPath, dealType]);

  return target;
};

export default useLineOfBusinessTarget;
