import { HttpClientService } from '~/services/HttpsClientService';
import { BlogContentType } from '~/data/types/blog/blogContentType';
import { MetaType } from '~/data/types/paginationTypes';

class BlogAdapter {
  static readonly endpoint = `/api/blogs`;

  // eslint-disable-next-line class-methods-use-this
  async getBlogContent() {
    const res = await HttpClientService.get<{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      meta: any;
      data: BlogContentType[];
    }>(
      `${BlogAdapter.endpoint}?populate=deep,4&sort=rank:asc&pagination[pageSize]=100`,
    );

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getBlogById(id: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: BlogContentType;
    }>(`${BlogAdapter.endpoint}/${id}?populate=deep,4`);
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getBlogByUrlName(name: string) {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: BlogContentType[];
    }>(
      `${BlogAdapter.endpoint}?filters[urlTitle][$eq]=${name}&populate=deep,4`,
    );
    return res;
  }
}

const blogAdapter = new BlogAdapter();
export default blogAdapter;
