import { Box, Typography } from '@mui/material';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import Button from '~/components/Button';
import RESOURCES from '~/resources';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import {
  initState,
  setIsApprovalFlowOpen,
} from '~/redux-toolkit/slices/approvalFlowSlice';
import { useLeadActions } from '~/hooks/useLeadActions';
import WarningBanner from '~/components/WarningBanner';
import FormValidation from '~/Validators/FormValidation';
import {
  EActionEvent,
  EBusinessLineEvent,
  ECategoryEvent,
  useEvents,
} from '~/hooks/useEvents';
import useStyles, {
  buttonCustomStyle,
  offerDescriptionStyle,
  offerTitleStyle,
  root,
} from './useStyles';
import ContactUsStepForm from '../ContactUsStep/ContactUsStepForm';

const ContactUsStep = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const events = useEvents();

  const { firstName, lastName, phone, email, mailsApproved } = useSelector(
    (state: RootState) => state.approvalFlow.details,
  );

  const { details, source } = useSelector(
    (state: RootState) => state.approvalFlow,
  );

  const {
    isProcessingLead,
    createLeadRequest,
    createLeadError,
    createLeadFinished,
  } = useLeadActions();

  const isDisabled = useMemo(
    () =>
      !FormValidation.validateText(lastName) ||
      !FormValidation.validateText(firstName) ||
      (email && !FormValidation.validateEmail(email)) ||
      !FormValidation.validatePhone(phone),
    [email, firstName, lastName, phone],
  );

  useEffect(() => {
    events.sendEvent(
      {
        action: EActionEvent.APPROVAL_FLOW_RESULT,
        category: ECategoryEvent.FUNDING_APPROVAL,
      },
      {
        approvalLocation: source,
        approvalStatus: EActionEvent.APPROVAL_FLOW_DENIED,
        businessLine: EBusinessLineEvent.FUNDING,
      },
    );
  }, [events, source]);

  useEffect(() => {
    if (createLeadFinished) {
      dispatch(setIsApprovalFlowOpen(false));
      dispatch(initState());
    }
  }, [createLeadFinished, dispatch]);

  const handleConfirm = useCallback(async () => {
    events.sendEvent(
      {
        action: EActionEvent.APPROVAL_FLOW_LEAD,
        category: ECategoryEvent.FUNDING_APPROVAL,
      },
      {
        didApproveEmails: mailsApproved,
        approvalLocation: source,
        businessLine: EBusinessLineEvent.FUNDING,
      },
    );
    createLeadRequest(details);
  }, [createLeadRequest, details, events, mailsApproved, source]);

  const closeFlow = useCallback(() => {
    dispatch(setIsApprovalFlowOpen(false));
    dispatch(initState());
  }, [dispatch]);

  return (
    <Box sx={root}>
      {createLeadError && <WarningBanner text={createLeadError} />}
      <Box sx={classes.boxStyle}>
        <Typography variant="h4" sx={offerTitleStyle}>
          {RESOURCES.APPROVAL_CONTACT_US_DENIED_TITLE}
        </Typography>
        <Typography sx={offerDescriptionStyle} variant="h4">
          {RESOURCES.APPROVAL_CONTACT_US_DENIED_DESCRIPTION}
        </Typography>

        <ContactUsStepForm />
      </Box>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Button
          isDisabled={isProcessingLead}
          label={RESOURCES.APPROVAL_CONTACT_US_MAYBE_NEXT_TIME}
          type={
            isProcessingLead
              ? BUTTON_TYPES.SECONDARY_DISABLED
              : BUTTON_TYPES.SECONDARY
          }
          size={BUTTON_SIZES.LARGE}
          customStyle={{ ...buttonCustomStyle, marginLeft: 1 }}
          onClickFunc={closeFlow}
        />
        <Button
          loading={isProcessingLead}
          label={RESOURCES.APPROVAL_CONTACT_US_CONTACT_ME}
          type={
            isDisabled || isProcessingLead
              ? BUTTON_TYPES.MAIN_DISABLED
              : BUTTON_TYPES.MAIN
          }
          size={BUTTON_SIZES.LARGE}
          customStyle={{ ...buttonCustomStyle, marginRight: 1 }}
          isDisabled={isDisabled}
          onClickFunc={handleConfirm}
          ariaDescribedBy="contact-declaimer"
        />
      </div>
    </Box>
  );
};

export default ContactUsStep;
