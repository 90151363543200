import { COLORS } from '~/data/ui/colors';
import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import theme from '~/theme';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'right',
  width: '100%',
  gap: '10px',
  border: '1px solid #d8d8d8',
  padding: '15px',
  borderRadius: '5px',
  boxSizing: 'border-box',
  marginTop: '5px',

  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
    width: 'max-content',
  },
}));

export const phoneButton: CSSProperties = {
  gap: '8px',
  justifyContent: 'end',
  alignSelf: 'start',
  width: 'max-content',
  textTransform: 'capitalize',
  flexDirection: 'row',
  padding: '8px 0',
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'start',
    alignSelf: 'start',
    flexDirection: 'row',
  },
};

export const phoneText = {
  fontSize: '16px',
  color: `${COLORS.black.main} !important`,
};
export const phoneNumberStyle: CSSProperties = {
  position: 'absolute',
  bottom: '-10px',
  color: COLORS.gray.dark,
};

export const contactButtonText = {
  fontSize: '16px',
  color: `${COLORS.black.main} !important`,
};
export const contactButton = {
  display: 'flex',
  gap: '8px',
  justifyContent: 'end',
  alignSelf: 'start',
  width: 'max-content',
  textTransform: 'capitalize',
  padding: '8px 0',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'start',
    alignSelf: 'start',
  },
};

export const needHelpText = {
  position: 'relative',
  direction: 'ltr',
  fontSize: '16px',
  lineHeight: '18px',
  color: COLORS.black.main,
  textDecoration: 'solid',
  marginBottom: '6px',

  '&:after': {
    content: '""',
    position: 'absolute',
    height: '1px',
    width: '70%',
    backgroundColor: COLORS.gray.light3,
    top: '24px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
};
