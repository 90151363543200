/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { metaDefault } from '~/data/resultsCarsDefaultData';
import { FILTER_OPTION_TYPE_PARAMS } from '~/data/filtersParams';
import {
  BannerPromotionData,
  CarBodyTypeData,
  carDescriptionHighlights,
  FamilyCar,
  IPromotionCardData,
  ISearchCar,
} from '~/data/types/car/cars';
import { MetaType } from '~/data/types/paginationTypes';
import resultPageAdapter from '~/redux-toolkit/adapters/resultPageAdapter';
import {
  FamilyCarPromoData,
  ISearchCarsQuery,
} from '~/data/types/resultPageType';
import { IPromotionCampaignCard } from '~/data/types/car/cars';
import {
  seoResultPageDataEntity,
  seoResutlsPageAttributes,
} from '~/data/types/seo/seoResutlsPages';

export const getResultPageContent = createAsyncThunk(
  '/getFamilyCars',
  async (
    params: string,
  ): Promise<{
    meta: MetaType;
    data: FamilyCar[];
  }> => {
    try {
      const response = await resultPageAdapter.getResultPageContent(params);
      const { data, meta } = response;

      return { data, meta };
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
        meta: metaDefault,
      };
    }
  },
);

export const getResultPageCarsContent = createAsyncThunk(
  '/searchCars',
  async (
    query: ISearchCarsQuery,
  ): Promise<{
    data: ISearchCar[];
  }> => {
    try {
      const response = await resultPageAdapter.getResultPageCarsContent(query);
      return response;
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
      };
    }
  },
);

export const getResultPageAppend = createAsyncThunk(
  '/appendFamilyCars',
  async (
    params: string,
  ): Promise<{
    meta: MetaType;
    data: FamilyCar[];
  }> => {
    try {
      const response = await resultPageAdapter.getResultPageContent(params);
      const { data, meta } = response;

      return { data, meta };
    } catch (error: any) {
      console.log(error);
      return {
        data: [],
        meta: metaDefault,
      };
    }
  },
);

export const getFiltersOptions = createAsyncThunk(
  '/filters',
  async (): Promise<any> => {
    try {
      const [manufactureWithModelsRes, categoriesRes, colorsRes] =
        await Promise.all(
          Object.values(FILTER_OPTION_TYPE_PARAMS).map((optionType) =>
            resultPageAdapter.getFiltersOptions(optionType),
          ),
        );
      return {
        manufactureWithModels: manufactureWithModelsRes.data,
        categories: categoriesRes.data,
        colors: colorsRes.data,
      };
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);
export const getBannersPromotion = createAsyncThunk(
  '/getBannersPromotion',
  async (target: string): Promise<BannerPromotionData[]> => {
    try {
      const response = await resultPageAdapter.getBannerPromotion(target);

      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
export const getBannerPromotion = createAsyncThunk(
  '/getBannerPromotion',
  async (target: string): Promise<BannerPromotionData> => {
    try {
      const response = await resultPageAdapter.getBannerPromotion(target);

      return response[0];
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
export const getCardsPromotion = createAsyncThunk(
  '/getCardsPromotion',
  async (target: string): Promise<IPromotionCardData[]> => {
    try {
      const response = await resultPageAdapter.getCardsPromotion(target);

      return response;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);

export const getHighlightsFilterOptions = createAsyncThunk(
  'highlights-filter',
  async (): Promise<carDescriptionHighlights[]> => {
    try {
      const data = await resultPageAdapter.getHighlightsFilterOptions();
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getCarBodyTypeFilterOptions = createAsyncThunk(
  'car-body-types-filter',
  async (): Promise<CarBodyTypeData[]> => {
    try {
      const data = await resultPageAdapter.getCarBodyTypeFilterOptions();

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);

export const getSeoContent = createAsyncThunk(
  '/seo-results-page',
  async (target: string): Promise<seoResultPageDataEntity> => {
    try {
      const response = await resultPageAdapter.getSeoContent(target);

      return response[0];
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);

export const getPromotionCampaignCards = createAsyncThunk(
  '/getPromotionCampaignCards',
  async (): Promise<IPromotionCampaignCard[]> => {
    try {
      const response = await resultPageAdapter.getPromotionCampaignCards();
      return response.data;
    } catch (error: any) {
      console.log(error);
      return [];
    }
  },
);
export const getSeoProgModelContent = createAsyncThunk(
  '/seo-results-model-prog',
  async (modelName: string): Promise<seoResutlsPageAttributes> => {
    try {
      const response =
        await resultPageAdapter.getSeoProgModelContent(modelName);

      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
export const getFamilyCarsPromo = createAsyncThunk(
  '/getFamilyCarsPromo',
  async (): Promise<FamilyCarPromoData[]> => {
    try {
      const response = await resultPageAdapter.getFamilyCarsPromo();

      return response;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  },
);
