import { HttpClientService } from '~/services/HttpsClientService';
import { AxiosResponse } from 'axios';
import { QuickSearchType } from '~/data/QuickSearch/quickSearchType';
import { MetaType } from '~/data/types/paginationTypes';
import { SearchPlanType } from '~/data/types/searchPlanType';
import { HomePageMarketingBannerDataType } from '~/data/types/homePageMarketingBannerType';
import { ISuggestionData } from '~/data/suggestionData';
import { GetOurServicesResponseType } from '~/data/types/ourServices/ourServices.types';
import {
  GetBannerCardsResponseType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';

class HomePageAdapter {
  static readonly endpointHero = `/api/home-page-contents`;

  static readonly endpointQuickSearch = `/api/home-page-quick-searches`;

  static readonly endpointSearchPlans = `/api/home-page-search-plans`;

  static readonly endpointMarketingBanners = `/api/home-page-marketing-banners`;

  static readonly endpointSuggestionContent = `api/get-suggestions-search-carousel`;

  static readonly endpointBannerGallery = `/api/banners-galleries`;
  static readonly endpointStaticBanner = `/api/card-banners`;

  static readonly endpointOurServices = `api/our-services`;

  static readonly endpointSearchByNeeds = `/api/search-by-needs`;

  // eslint-disable-next-line class-methods-use-this
  async getHomePageContent(): Promise<AxiosResponse> {
    const res = await HttpClientService.get(
      `${HomePageAdapter.endpointHero}?populate=*&filters[page][name]=homePage`,
    );

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHomePageQuickSearch() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: QuickSearchType[];
    }>(`${HomePageAdapter.endpointQuickSearch}?populate=*`);
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSearchPlansSearch() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: SearchPlanType[];
    }>(`${HomePageAdapter.endpointSearchPlans}?populate=deep,3`);
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHomePageMarketingBanners() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: HomePageMarketingBannerDataType[];
    }>(
      `${HomePageAdapter.endpointMarketingBanners}?populate=deep,2&sort=rank:asc`,
    );
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSuggestionsContent() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: ISuggestionData[];
    }>(`${HomePageAdapter.endpointSuggestionContent}?populate=*&sort=rank:asc`);

    return res.data;
  }
  // eslint-disable-next-line class-methods-use-this
  async getHomePageBannerGallery() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: GetBannerCardsResponseType[];
    }>(
      `${HomePageAdapter.endpointBannerGallery}?populate=deep,*&filters[page][name]=homePage`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHomePageOurServices() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: GetOurServicesResponseType[];
    }>(
      `${HomePageAdapter.endpointOurServices}?populate=deep,*&filters[page][name]=homePage`,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHomePageStaticBanners() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: GetBannerCardsResponseType[];
    }>(`${HomePageAdapter.endpointStaticBanner}?populate=deep,*`);
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSearchByNeeds() {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: SearchByNeedResponseType[];
    }>(
      `${HomePageAdapter.endpointSearchByNeeds}?populate=*&filters[pages][name]=homePage`,
    );
    return res.data;
  }
}

const homePageAdapter = new HomePageAdapter();
export default homePageAdapter;
