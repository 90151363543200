import { createSlice } from '@reduxjs/toolkit';
import {
  CAR_FLOW,
  CAR_FLOW_TYPES,
  SEARCH_FLOW,
  SEARCH_FLOW_TYPES,
} from '~/data/consts';
import { DATE_TYPES, COPY_PLANS_DATA_ARRAY } from '~/data/searchFlowData';
import { getCarsDealBullets } from '../thunks/searchFlowThunks';

interface SearchFlowState {
  selectedSearchFlow: SEARCH_FLOW_TYPES;
  activeStep: number;
  selectedCarType?: CAR_FLOW_TYPES | null;
  isNextButtonDisable: boolean;
  budgetStep: {
    paymentMethod: string;
    priceRange: { minPrice: number | null; maxPrice: number | null };
  };

  dateRangePickerStep: {
    startDate: Date | null;
    endDate: Date | null;
    datesType: null | (typeof DATE_TYPES)[keyof typeof DATE_TYPES];
  };
  categoriesStep: {
    categories: string[];
  };
  cars: [];
  carsDeal: CarDeal[];
  selectedCarDeal: CarDeal;
  isLoading: boolean;
  isSearchFromHomePage: boolean;
  budgetFlow: {
    step: number;
  };
}

export interface CarDeal {
  carDeal: CAR_FLOW_TYPES;
  bullets: string[];
  carTipTitle: string;
}

const initialState: SearchFlowState = {
  selectedSearchFlow: SEARCH_FLOW.ACCURATE_SEARCH,
  activeStep: 0,
  selectedCarType: null,
  isNextButtonDisable: false,
  budgetStep: {
    paymentMethod: '',
    priceRange: {
      minPrice: null,
      maxPrice: null,
    },
  },
  dateRangePickerStep: {
    startDate: null,
    endDate: null,
    datesType: null,
  },
  categoriesStep: {
    categories: [],
  },
  cars: [],
  carsDeal: COPY_PLANS_DATA_ARRAY,
  selectedCarDeal: { carDeal: CAR_FLOW.NEW_CAR, bullets: [], carTipTitle: '' },
  isLoading: false,
  isSearchFromHomePage: false,
  budgetFlow: {
    step: 1,
  },
};

const searchFlowSlice = createSlice({
  name: 'searchFlowData',
  initialState,
  reducers: {
    addSelectedFlow(state, action) {
      state.selectedCarType = action.payload;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setBudgetActiveStep(state, action) {
      state.budgetFlow.step = action.payload;
    },
    setSelectedSearchFlow(state, action) {
      state.selectedSearchFlow = action.payload;
    },
    setBudgetData(state, action) {
      const budgetStep = {
        paymentMethod:
          action.payload.paymentMethod || state.budgetStep.paymentMethod,
        priceRange: action.payload.priceRange || state.budgetStep.priceRange,
      };
      state.budgetStep = budgetStep;
    },
    setSelectedCarDeal(state, action) {
      state.selectedCarDeal = action.payload;
    },
    addDateData(state, action) {
      state.dateRangePickerStep.startDate =
        new Date(action.payload.startDate) ||
        state.dateRangePickerStep.startDate;
      state.dateRangePickerStep.endDate =
        new Date(action.payload.endDate) || state.dateRangePickerStep.endDate;
      state.dateRangePickerStep.datesType = action.payload.datesType;
    },
    updateFinishSearchProcess(state, action) {
      state.isNextButtonDisable =
        state.selectedCarType && action.payload.buttonDisabled;
    },
    resetProcess(state) {
      state.selectedCarType = initialState.selectedCarType;
      state.isNextButtonDisable = initialState.isNextButtonDisable;
      state.budgetStep = initialState.budgetStep;
      state.dateRangePickerStep = initialState.dateRangePickerStep;
      state.cars = initialState.cars;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsSearchFromHomePage(state, action) {
      state.isSearchFromHomePage = action.payload;
    },
    initState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCarsDealBullets.fulfilled, (state, action) => {
      try {
        state.carsDeal = action.payload.map((car) => {
          return {
            carDeal: car.attributes
              .carDeal as (typeof CAR_FLOW)[keyof typeof CAR_FLOW],
            bullets: car.attributes.tipToolCarDeal.map((tip) => tip.labelText),
            carTipTitle: car.attributes.carTipTitle,
          };
        });
      } catch (error) {
        console.log(error);
      }
    });
  },
});

export const {
  addSelectedFlow,
  setActiveStep,
  setBudgetData,
  addDateData,
  updateFinishSearchProcess,
  resetProcess,
  setSelectedCarDeal,
  setIsLoading,
  setSelectedSearchFlow,
  setIsSearchFromHomePage,
  initState,
  setBudgetActiveStep,
} = searchFlowSlice.actions;

export default searchFlowSlice.reducer;
