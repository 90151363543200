import { IDayItem } from "~/data/lead/interfaces";


export const mockDates: IDayItem[] = [
    {
        day: '2024-07-30',
        intervalList: [{
            carAvailable: 'true',
            finish: '12:30',
            finishTime: '12:30',
            start: '11:30',
            startTime: '11:30',
        },
        {
            carAvailable: 'true',
            finish: '13:30',
            finishTime: '13:30',
            start: '12:30',
            startTime: '12:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '14:30',
            startTime: '14:30',
        }
        ],
    },
    {
        day: '2024-07-31',
        intervalList: [{
            carAvailable: 'true',
            finish: '12:30',
            finishTime: '12:30',
            start: '11:30',
            startTime: '11:30',
        },
        {
            carAvailable: 'true',
            finish: '13:30',
            finishTime: '13:30',
            start: '12:30',
            startTime: '12:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '14:30',
            startTime: '14:30',
        }, {
            carAvailable: 'true',
            finish: '16:30',
            finishTime: '14:30',
            start: '15:30',
            startTime: '15:30',
        }
        ],
    },
    {
        day: '2024-08-01',
        intervalList: [{
            carAvailable: 'true',
            finish: '12:30',
            finishTime: '12:30',
            start: '11:30',
            startTime: '11:30',
        },
        {
            carAvailable: 'true',
            finish: '13:30',
            finishTime: '13:30',
            start: '12:30',
            startTime: '12:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '14:30',
            startTime: '14:30',
        }, {
            carAvailable: 'true',
            finish: '16:30',
            finishTime: '14:30',
            start: '15:30',
            startTime: '15:30',
        }
        ],
    },
    {
        day: '2024-08-02',
        intervalList: [{
            carAvailable: 'true',
            finish: '12:30',
            finishTime: '12:30',
            start: '11:30',
            startTime: '11:30',
        },
        {
            carAvailable: 'true',
            finish: '13:30',
            finishTime: '13:30',
            start: '12:30',
            startTime: '12:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '14:30',
            startTime: '14:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '17:30',
            startTime: '17:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '19:30',
            startTime: '19:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '20:30',
            startTime: '20:30',
        },
        {
            carAvailable: 'true',
            finish: '14:30',
            finishTime: '14:30',
            start: '10:30',
            startTime: '10:30',
        }
        ],
    },
]

export const mockShowrooms = [
    {
        modelName: 'NEW CAPTUR 1.33',
        modelSkill: '111',
        relevantTerritories: [
            {
                territoryId: '112',
                territoryName: 'אולם תצוגה רנו- חיפה',
            },
            {
                territoryId: '114',
                territoryName: 'אולם תצוגה רנו- תל אביב',
            }
        ]
    },
    {
        modelName: 'NEW CAPTUR 1.35',
        modelSkill: '112',
        relevantTerritories: [{
            territoryId: '112',
            territoryName: 'אולם תצוגה רנו- חיפה',
        }
        ]
    },
    {
        modelName: 'NEW CAPTUR 1.23',
        modelSkill: '113',
        relevantTerritories: [{
            territoryId: '112',
            territoryName: 'אולם תצוגה רנו- ראשון לציון',
        }, {
            territoryId: '114',
            territoryName: 'אולם תצוגה רנו- תל אביב',
        }
        ]
    },
    {
        modelName: 'NEW CAPTUR 1.24',
        modelSkill: '116',
        relevantTerritories: [{
            territoryId: '112',
            territoryName: 'אולם תצוגה רנו- ראשון לציון',
        }, {
            territoryId: '114',
            territoryName: 'אולם תצוגה רנו- תל אביב',
        }
        ]
    }
]
