import _ from 'lodash';

export type AnyObject = {
  [key: string]: any;
};

// Function to flatten an object
export const flattenObject = (obj: AnyObject, prefix = ''): AnyObject =>
  Object.keys(obj).reduce((acc: AnyObject, k: string) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (_.isObject(obj[k]) && !_.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});

// Function to flatten an array of objects
export const flattenArray = (arr: AnyObject[]): AnyObject[] =>
  arr.map((item) => flattenObject(item));
