import { createSlice } from '@reduxjs/toolkit';
import {
  ICommunityPageRes,
  IFreeSbeActivity,
  IGalleryHero,
  initialGalleryHero,
} from '~/data/types/communityPage/communityPageType';
import {
  getCommunityPageData,
  getFreesbeActivities,
} from '~/redux-toolkit/thunks/communityPageThunks';

interface CommunityPageState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];

  galleryHero: IGalleryHero;
  freesbeActivities: IFreeSbeActivity[];
  loading: boolean;
}

const initialState: CommunityPageState = {
  data: [],
  galleryHero: initialGalleryHero,
  loading: false,
  freesbeActivities: [],
};

const communityPageSlice = createSlice({
  name: 'communityPageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCommunityPageData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCommunityPageData.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCommunityPageData.fulfilled, (state, action) => {
      state.loading = false;

      if (!action.payload) return;
      const { attributes } = action.payload as ICommunityPageRes;
      const { galleryHero } = attributes;

      state.galleryHero = {
        ...galleryHero,
        carouselImages: galleryHero.carouselImages.map((carouselImage) => {
          return {
            ...carouselImage,
            image: carouselImage?.image?.data?.attributes.url,
            icon: carouselImage?.icon?.data?.attributes.url,
          };
        }),
      };
    });
    builder.addCase(getFreesbeActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFreesbeActivities.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFreesbeActivities.fulfilled, (state, action) => {
      state.loading = false;
      const freesbeActivities = action.payload?.map((activity) => {
        const cardIcon =
          activity?.attributes?.card?.icon?.data?.attributes?.url || '';
        const cardImage =
          activity?.attributes?.card?.image?.data?.attributes?.url || '';
        const logo = activity?.attributes?.logo?.data?.attributes?.url || '';
        const images =
          activity?.attributes?.activityPage?.images?.data?.map(
            (image) => image.attributes.url,
          ) || [];
        const activityPage = { ...activity?.attributes?.activityPage, images };
        const card = {
          ...activity?.attributes?.card,
          icon: cardIcon,
          image: cardImage,
        };

        return {
          ...activity.attributes,
          logo,
          activityPage,
          card,
        } as IFreeSbeActivity;
      });
      state.freesbeActivities = freesbeActivities;
    });
  },
});

// export const {} = communityPageSlice.actions;

export default communityPageSlice.reducer;
