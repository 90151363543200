import {
  CarLabelAttributes,
  CarLabelData,
  CarLabelObjType,
} from '~/data/types/usedCar/usedCarData';

/**
 * This function recursively cleans an object from undefined values to avoid SSR serialize errors.
 * @param obj - The object to clean.
 * @returns A cleaned object.
 */
function cleanObject(obj: any): any {
  if (typeof obj !== 'object' || obj === null) return obj; // return non-object values unchanged

  if (Array.isArray(obj)) {
    return obj.map(cleanObject);
  }

  return Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .reduce((acc: any, [key, value]) => {
      if (typeof value === 'object') {
        // Recursively clean nested objects
        acc[key] = cleanObject(value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
}

export const sortLabelsByRank = (
  labels: CarLabelData[] | CarLabelAttributes[] | CarLabelObjType[],
) => {
  // Create a copy of the array to avoid modifying the original array
  const labelsCopy = [...labels];

  return labelsCopy.sort((a, b) => {
    if (a.rank && b.rank) {
      return a.rank - b.rank;
    }
    if (a.rank) {
      return -1;
    }
    if (b.rank) {
      return 1;
    }
    return 0;
  });
};

export default cleanObject;
