import React from 'react';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';
import { styled } from '@mui/material';
import Link from 'next/link';

const UNDERLINE_WIDTH = '3px';

const underlineNoJump = {
  content: "''",
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '3px',
  background: `${COLORS.primary.main}`,
};

export const classes = {
  linked: {
    textDecoration: 'none',
    fontWeight: '200',
    position: 'relative',
    '&:hover::before': underlineNoJump,
  },
  activeLink: {
    fontFamily: 'EricaSansFOT,GillSans',
    fontWeight: '800 !important',
    textDecoration: 'none',
  },
  listItemStyle: {
    width: 'auto',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    fontFamily: 'EricaSansFOT, GillSans',
    fontWeight: '200',
    position: 'relative',
    '&:hover::before': underlineNoJump,
  },
  navList: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      direction: 'rtl',
      gap: '32px',
      fontFamily: 'EricaSansFOT, GillSans',
      fontWeight: '200',
    },
    '& :hover': {
      '&.MuiLink-root': {
        borderBottom: `${UNDERLINE_WIDTH} solid ${COLORS.primary.main}`,
        lineHeight: '60px',
      },
    },
    '& .MuiLink-root': {
      lineHeight: '60px',
    },
  },
  columnContainer: {
    fontFamily: 'EricaSansFOT, GillSans',
    fontWeight: '200',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0 10px 0px',
    gap: '30px',
    justifyContent: 'center',
    width: '140px',
    '& :hover': {
      fontWeight: '500',
    },
  },
  menuItemTitle: {
    fontFamily: 'EricaSansLight, GillSans',
    fontWeight: '200',
    color: 'black',
    margin: '0',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
      fontFamily: 'EricaSansFOT,GillSans',
      fontWeight: '800',
    },
  },
  triangleContainer: {
    paddingRight: '20px',
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: `15px solid ${COLORS.white.main}`,
  },
  compareButton: {
    width: '30px',
    height: 'unset',
    minWidth: 'unset',
  },
  actionsContainer: {
    flexDirection: 'row',
    gap: '12px',
  },
};

export const root = {
  position: 'fixed',
  backgroundColor: COLORS.white.main,
  transition: 'background-color 0.4s ease',
  top: '-1px',
  width: '100%',
  padding: '0px',
  margin: '0px',
  alignItems: 'center',
  zIndex: '121',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
};

export const navbarTextColor = {
  color: COLORS.black.main,
};

export const navWrapper = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '72px',
  margin: '0px 20px',
  maxWidth: 'unset',
  padding: '0',
  [theme.breakpoints.up('md')]: {
    margin: '0px 35px',
    maxWidth: 'unset',
    padding: '0',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1920px',
    padding: '0 35px',
    margin: 'auto',
  },
};

export const wishlistWrapper = {
  order: 3,
  [theme.breakpoints.up('md')]: {
    marginRight: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    order: 0,
    width: '100%',
    justifyContent: 'space-between',
  },
};

export const popupMenu = {
  top: '0',
  display: 'flex',
  alignItems: 'top',
  position: 'fixed',
  flexDirection: 'column',
  zIndex: 200,
  padding: '24px 20px',
  width: '100%',
  height: '100vh',
  boxShadow: ' 0px 0px 0px 9999px rgba(0, 0, 0, 0.5)',
};

export const buttonStyle = {
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
};

export const menuIconStyle = () => ({
  alignSelf: 'center',
  cursor: 'pointer',
  color: COLORS.black.main,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
});

export const mobileSearchButton: React.CSSProperties = {
  width: 'fit-content',
  color: COLORS.black.main,
  marginBottom: '4px',
};
export const mobileMenuContentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  width: '70vw',
  boxSizing: 'border-box',
  boxShadow: 'none',
  padding: '24px 20px',
  gap: '30px',
};

export const boldFont = {
  fontFamily: 'EricaSansFOT,GillSans',
  fontWeight: '800',
};
export const normalFont = {
  fontWeight: '400',
};

export const navbarBg = (props: { color: string }) => ({
  backgroundColor: props,
});

export const skipToContentLink = {
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    position: 'absolute',
    left: '-9999px',
    zIndex: '999',
    padding: '1em',
    backgroundColor: 'grey',
    color: 'white',
    opacity: '0',
    border: `1px solid ${COLORS.black.main}`,
    '&:focus': {
      bottom: '-60px',
      left: '30px',
      opacity: 1,
    },
  },
} as const;

export const wishlistStyles = {
  backgroundColor: 'unset',
  borderRadius: 'unset',
};

export const menuButton = {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  width: '100%',
  height: 'fit-content',
};

export const menuTypography = {
  fontSize: '18px',
  fontWeight: '400',
  textTransform: 'lowercase' as const,
};

export const Service = styled('div')<{ isMenuItems: boolean }>((props) => ({
  display: 'flex',
  borderBottom: !props.isMenuItems ? `2px solid ${COLORS.gray.light4}` : '',
  width: '100%',
  justifyContent: 'flex-start',
  paddingBottom: !props.isMenuItems ? '17px' : '',
}));

export const NavContainer = styled('nav')<{ isMenuItems: boolean }>(
  ({ isMenuItems }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'flex-end',
    width: '100%',
    ...(isMenuItems && {
      gap: '16px',
      marginTop: '15px',
    }),
  }),
);

export const StyledAnchor = styled(Link)(() => ({
  width: 'auto',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  '& :hover': {
    cursor: 'pointer',
    borderBottom: `3px solid ${COLORS.primary.main}`,
    lineHeight: '25px',
  },
}));

export const MenuContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '7px',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: '1',
    '&:hover::before': underlineNoJump,
  },
}));

export const MenuItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
}));

export const MenuItemsDeskContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingTop: '1.5px',
}));

export const MenuItemContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}));

export const LogoStyle = styled('div')(() => ({
  order: '3',
  [theme.breakpoints.up('md')]: {
    order: '0',
  },
}));

export const MenuDeskContainer = styled('div')<{ isMenuItems: boolean }>(
  ({ isMenuItems }) => ({
    ...(isMenuItems && {
      position: 'absolute',
      top: '0',
      right: '0',
      paddingTop: '50px',
      filter: `drop-shadow(0 0 0.25rem ${COLORS.gray.light11})`,
    }),
  }),
);

export const RowContainer = styled('div')<{ isMenuItems: boolean }>(
  ({ isMenuItems }) => ({
    ...(isMenuItems && {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: `${COLORS.white.main}`,
      padding: '20px',
      borderRadius: '9px',
      marginTop: '-5px',
    }),
  }),
);
