import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google';
import posthog from 'posthog-js';
import { useCallback, useMemo } from 'react';
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';
import { getCarIdByPath } from '~/utils/getCarId';
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum EActionEvent {
  HOMEPAGE_SEARCH_FLOW_CLICKED = 'Homepage:Search_flow_button',
  HOMEPAGE_SEARCH_FLOW_CLICKED_WISHLIST = 'Homepage:Wishlist-search_button',
  HOMEPAGE_WHATS_YOUR_FREESBE = 'Homepage:Whats-your-freesbe',
  HOMEPAGE_CAR_CATEGORY_SEARCH = 'Car_category_search',
  HOMEPAGE_LEAVE_DETAILS = 'Homepage:Lead-leave-details',
  HOMEPAGE_CALL_US = 'Homepage:Call-us',
  HOMEPAGE_PAGE_LOAD = 'HomePage:Page_load',
  NOT_FOUND_PAGE_LOAD = '404:Page_load',
  FOOTER_MENU_LINK = 'Footer_menu_link',
  PRIMARY_MENU_LINK = 'Primary_menu_link',
  EXPOSE_PHONE = 'Expose_phone',
  SEARCH_FLOW_PRODUCT_TYPE = 'SearchFlow:Product_type',
  SEARCH_FLOW_SUBMIT_NEW_CAR = 'SearchFlow:newCar_submit',
  SEARCH_FLOW_SUBMIT_LEASING = 'SearchFlow:leasing_submit',
  SEARCH_FLOW_SUBMIT_USED_CAR = 'SearchFlow:usedCar_submit',
  SEARCH_FLOW_SUBMIT_TEMPORARY_CAR = 'SearchFlow:TemporaryCar_submit',
  SEARCH_FLOW_CHOOSE_CATEGORIES = 'Search_flow:Category',
  SEARCH_FLOW_CHOOSE_BUSINESS = 'searchByBudget_LOB',
  SEARCH_FLOW_CHOOSE_PAYMENT = 'searchByBudget_payment',
  SEARCH_FLOW_CHOOSE_RESULTS = 'searchByBudget_results',
  SEARCH_FLOW_CHOOSE_RESULT_SELECTION = 'searchByBudget_userSelection',

  RESULTS_PAGE_LOAD = 'ResultsPageLoad',
  ADD_TO_WISHLIST = 'Add_favorites',
  REMOVE_WISHLIST = 'Remove_favorites',
  CAR_RESULTS_PAGE = 'ResultsPageCarSelection',
  CAR_PAGE_ADD_TO_WISHLIST = 'Car_page:Add_to_Wishlist',
  CAR_PAGE_PAGE_REMOVE_WISHLIST = 'Car_page:Remove_Wishlist',
  CAR_PAGE_PAGE_LOAD = 'Car_page:Page_load',
  CAR_FLOW_APPOINTMENT = 'Appointment:page_load',
  CAR_PAGE_APPOINTMENT = 'Car_page:Appointment',
  CAR_PAGE_CREATE_CAR = 'Car_page:Continue',
  CAR_PAGE_COLOR_CHANGE = 'colorChange_carPage',
  CAR_PAGE_LEAD = 'Lead:Page_load',
  FAILURE_LEAD = 'Lead_Failure',
  APPOINTMENT_CONFIRMATION = 'Appointment_Confirmation',
  APPOINTMENT_CHOOSE_DATE = 'Appointment:Choose_date_and_time',
  APPOINTMENT_CHOOSE_TIME_SLOTS = 'Appointment:Choose_timeSlot',
  APPOINTMENT_CHOOSE_BRANCH = 'Appointment:Choose_branch',
  APPOINTMENT_CHOOSE_SHOWROOM = 'Appointment_Choose_city',
  APPOINTMENT_SET_MEETING = 'Appointment:Set_meeting',
  APPOINTMENT_PAGE_LOAD = 'Appointment:page_load',
  APPOINTMENT_ERROR = 'Appointment_Error',
  APPOINTMENT_FILLED_USER_DETAILS = 'Appointment:Filled_user_details',
  CAR_CONFIGURATION_BUY = 'Buy_configurator',
  CAR_CONFIGURATION_FINANCE = 'Finance_configurator',
  CAR_CONFIGURATION_LEASING = 'Leasing_configurator',
  CAR_CONFIGURATION_NEXT = 'Car_Configurator:Next',
  CAR_CONFIGURATION_SUMMARY_STEP = 'Car_configurator:Summary_step',
  CAR_CONFIGURATION_CONTACT_ME = 'Car_configurator:Contact_me',
  CAR_CONFIGURATION_SUMMARY_CONTACT_US = 'Lead:Page_load',
  CAR_CONFIGURATION_APPROVAL_FLOW = 'funding_approval_start',
  CONTACT_US_MODAL_CALL_US = 'contactUs_phone',
  CONTACT_US_MODAL_WHATSAPP = 'contactUs_whatsapp',
  CONTACT_US_MODAL_EMAIL = 'contactUs_email',
  CHECKOUT_PAYMENT_SUCCESS = 'Checkout:Payment_success',
  CHECKOUT_PAYMENT_FAILED = 'Checkout:Payment_failed',
  CHECKOUT_SAVE_USER_DETAILS = 'Checkout:Save_user_details',
  CHECKOUT_RESERVE_CAR = 'Reserve_car',
  RESULT_PAGE_FILTER_CATEGORY = 'Filter:Category',
  RESULT_PAGE_FILTER_BRAND = 'Filter:Brand',
  RESULT_PAGE_FILTER_MODEL = 'Filter:Model',
  RESULT_PAGE_FILTER_HIGHLIGHTS = 'Filter:Highlights',
  RESULT_PAGE_FILTER_COLOR = 'Filter:Color',
  RESULT_PAGE_FILTER_BODY_TYPE = 'Filter:BodyType',
  RESULT_PAGE_APPROVAL_FLOW = 'funding_approval_start',
  RESULT_PAGE_LINK = 'ResultPage:link',
  LEAD_CONTACT_ME = 'Lead:Contact_me',
  LEAD_CONFIRMATION = 'Lead_Confirmation',
  APPROVAL_FLOW_RESULT = 'funding_approval_result',
  APPROVAL_FLOW_SUBMIT = 'funding_approval_submit',
  APPROVAL_FLOW_DENIED = 'Not_Approved',
  APPROVAL_FLOW_APPROVED = 'Approved',
  APPROVAL_FLOW_GENERATE_CODE = 'funding_approval_code',
  APPROVAL_FLOW_VIEW_CODE = 'funding_approval_viewCode',
  APPROVAL_FLOW_LEAD = 'funding_approval_lead',
  APPROVAL_FLOW_START = 'funding_approval_start',
  CASE_SUBMIT = 'case_submit',
  CASE_FAILED = 'case_failure',
  CASE_APPROVED = 'case_confirmation',
  LEAD_LOAD = 'Lead:Page_load',
  PAYMENT_PAGE_SUCCESS = 'PaymentPage:Success',
  PAYMENT_PAGE_FAILED = 'PaymentPage:Failed',
  PROMOTION_BANNER = 'PromotionBanner:Clicked',
  BLOG_SEARCH_TRIGGERED = 'Blog:search_triggered',
  BLOG_CATEGORY_SELECTED = 'blog_categorySelected',
  BLOG_LOAD_MORE = 'blog_loadMore',
  BLOG_READ_MORE = 'blog_ArticleClick',
  HOMEPAGE_BLOG_ARTICLE = 'HomePage:blog_article',
  HOMEPAGE_BLOG_ARTICLE_CLICK = 'home_blogArticleClick',
  HOMEPAGE_BLOG_ALL_ARTICLE = 'home_AllArticles',
  HOMEPAGE_BLOG_CLICK_RIGHT = 'blog_scrollRight',
  HOMEPAGE_BLOG_CLICK_LEFT = 'blog_scrollLeft',
  RECOMMEND_CAR_CLICKED = 'Recommendation_clicked',
  SHOW_ONLINE_PRICE = 'ShowOnlinePrice',
  OTP_LEAD_DETAILS_SUBMIT = 'OTP_leadDetailsSubmit',
  OTP_FILL_SMS_NUMBER = 'OTP_fillSmsNumber',
  OTP_SMS_SUBMIT = 'OTP_SmsSubmit',
  OTP_SEND_AGAIN = 'OTP_SendAgain',
  OTP_CONFIRMATION = 'OTP_Confirmation',
  OTP_FAILURE = 'OTP_failure',
  SEARCH_FLOW_SEARCH_TYPE = 'searchFlow_searchType',
  BUSINESS_LINE_TOGGLE_CHANGED = 'businessLineToggleChange',
  POPULAR_SEARCH_CLICKED = 'popularSearchClicked',
  ALL_CARS_CLICKED = 'Home_AllCarsClicked',
  LOBBY_ALL_CARS_CLICKED = 'Lobby_AllCarsClicked',
  RENTAL_BANNER_CLICKED = 'RentalBannerClicked',
  CAR_CAROUSEL_NEXT = 'carCarousel_next',
  CAR_CAROUSEL_PREVIOUS = 'carCarousel_previous',
  CAR_CAROUSEL_ALL_CARS = 'carCarousel_allCars',
  CAR_CAROUSEL_SEARCH_CAR = 'carCarousel_searchCar',
  BANNER_CLICKED = 'bannerClicked',
  RENTAL_SEARCH = 'RentalSearch',
  SELL_CAR_SEARCH_CAR = 'SellCar_SearchCar',
  SELL_CAR_NOT_FOUND = 'SellCar_NotFound',
  SELL_CAR_CAR_DETAILS_SUBMIT = 'SellCar_CarDetailsSubmit',
  SELL_CAR_CONTACT_DETAILS_SUBMIT = 'SellCar_ContactDetailsSubmit',
  SELL_CAR_CONFIRMATION = 'SellCar_Confirmation',
  SELL_CAR_FAILED = 'SellCar_Failed',
  CONTACT_US_NEED_HELP_CLICKED = 'contact_us_clicked',
  HOMEPAGE_WHATSAPP = 'Homepage:Whatsapp',
  RESULT_PAGE_CALL_US = 'NewCarResultPage:Call_us',
  RESULT_PAGE_WHATSAPP = 'NewCarResultPage:Whatsapp',
  SERVICE_AGREEMENT_CALL_US = 'ServiceAgreement:Call_us',
  SERVICE_AGREEMENT_WHATSAPP = 'ServiceAgreement:Whatsapp',
  FINANCE_CALL_US = 'Finance:Call_us',
  FINANCE_WHATSAPP = 'Finance:Whatsapp',
  CALL_US = ':Call_us',
  WHATSAPP = ':Whatsapp',
  EMAIL = ':Email_us',
  BANNER_CAROUSEL_CLICKED = 'bannerCarouselClicked',
  BANNER_CAROUSEL_CLICKED_ARROW_R = 'gallery_scrollRight',
  BANNER_CAROUSEL_CLICKED_ARROW_L = 'gallery_scrollLeft',
  OUR_SERVICE_CLICKED = 'OurServicesClicked',
  HOME_PAGE_HERO_SECTION = 'LOBselected',
  SEARCH_BY_BRAND = 'searchByBrand',
  LOB_CHANGED = 'LOB_changed',
  FUNDING_SEARCH = 'funding_search',
  VIDEO_PLAY = 'video_play',
  APPLIED_FILTER = 'applied_filter',
  INSTALLMENT_SEARCH = 'installments_search',
  USER_SHARE = 'user_share',
  LOBBY_PAGE_LOAD = 'lobbyPageLoad',
  CAR_FLEET_PAGE_LOAD = 'carFleet_pageLoad',
  CAR_FLEET_RESULTS = 'carFleet_result',
  CAR_FLEET_SPEC = 'carFleet_specClicked',
  CAR_FLEET_CHANGE_BRAND = 'carFleet_brandChange',
  CAR_FLEET_VIDEO = 'carFleet_videoClicked',
  ADD_COMPARE = 'Add_Compare',
  REMOVE_COMPARE = 'Remove_Compare',
  COMPARAISON_CLICKED = 'comparisonClicked',
  SEARCH_USED_CAR_FOR_COMPARAISON_CLICKED = 'searchUsedCarForComparisonClicked',
  CLEAR_COMPARISON = 'clearComparison',
  COMPARISÍON_PAGE_LOAD = 'comparisonPageLoad',
  COMPARE_CAR_SELECTED = 'compareCarSelected',
  COMPARE_ADD_CAR = 'compareAddCar',
  COMPARE_FINISH_LEVELS = 'compareFinishLevels',
  DEAL_TYPE_SELECT = 'dealTypeSelect',
}

export enum ECategoryEvent {
  HOMEPAGE = 'HOMEPAGE',
  INSURANCE = 'INSURANCE',
  PRIMARY_MENU = 'PRIMARY_MENU',
  FOOTER_MENU = 'FOOTER_MENU',
  SEARCH_FLOW = 'SEARCH_FLOW',
  CAR_PAGE = 'CAR_PAGE',
  COMPARE = 'COMPARE',
  USED_CAR_PAGE = 'USED_CAR_PAGE',
  RESULT_PAGE = 'RESULT_PAGE',
  LOCATIONS_PAGE = 'LOCATIONS_PAGE',
  RESULT_PAGE_CARD = 'RESULT_PAGE_CARD',
  CAR_FLOW = 'CAR_FLOW',
  APPOINTMENT = 'APPOINTMENT',
  CAR_CONFIGURATION = 'CAR_CONFIGURATOR',
  CONTACT_US_MODAL = 'CONTACT_US_MODAL',
  CONTACT_US_PAGE = 'CONTACT_US_PAGE',
  CONTACT_US = 'CONTACT_US',
  PHONE_CALL = 'Phone_call',
  PAYMENT_PAGE = 'PAYMENT_PAGE',
  FILTERS = 'FILTERS',
  LEAD = 'LEAD',
  TRADE_IN_OFFER = 'trade_in_offer',
  APPROVAL_FLOW = 'APPROVAL_FLOW',
  FUNDING_APPROVAL = 'Funding_approval',
  CASE = 'CASE',
  CHECKOUT = 'Checkout',
  PROMOTION_BANNER = 'PROMOTION_BANNER',
  CONFIGURATOR = 'CONFIGURATOR',
  SEARCH = 'Search',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  ONLINE_PRICE = 'ONLINE_PRICE',
  RENTAL = 'RENTAL',
  CAR_CAROUSEL = 'CAR_CAROUSEL',
  BLOG = 'BLOG',
  SELL_CAR = 'SELL CAR',
  NOT_FOUND = 'NOT_FOUND',
  OUR_SERVICE = 'OUR_SERVICE',
  BANNER = 'BANNER',
  LOBBY = 'LOBBY',
  USER = 'USER',
  CAR_FLEET = 'CAR_FLEET',
  BUSINESS_LEASING = 'BUSINESS_LEASING',
  SEARCH_FLOW_LOB = 'searchFlow_LOB',
}

export enum EBusinessLineEvent {
  CAR_FLEET = 'carFleet',
  FUNDING = 'Funding',
  ENERGY = 'Energy',
  INSURANCE = 'Insurance',
  BUSINESS_LEASING = 'businessLeasing',
}

export interface IEvent {
  category: ECategoryEvent;
  action: EActionEvent | string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: Object;
  label?: string;
}
/* eslint-enable no-unused-vars */
/* eslint-enable no-shadow */

declare function fbq(event: string, action: string, data?: object): void;

interface SubmitEvent {
  name: string;
  submit: boolean;
  content_ids: number[];
  content_type: string;
  currency?: string;
  price?: number;
  num_items?: number;
  car_year?: number;
  category?: string;
  car_brand?: string;
  title?: string;
  [key: string]: any;
}

export const useEvents = () => {
  const getCarIdEvent = (dataLayer: any) => {
    const carId = getCarIdByPath(dataLayer.pagePath) as number;
    const carIdDataLayer = dataLayer?.carId;
    const getCarId = carId ?? carIdDataLayer;

    if (getCarId) {
      return getCarId;
    }

    return null;
  };

  const faceBookTrack = useCallback((dataLayer: any) => {
    const createSubmitEvent = (): SubmitEvent => ({
      name: '',
      submit: false,
      content_ids: [],
      content_type: '',
      title: '',
      category: '',
      car_brand: '',
      car_year: 0,
    });

    const submitEvent = createSubmitEvent();

    if (dataLayer.category === ECategoryEvent.RESULT_PAGE) {
      submitEvent.name = 'PageView';
      submitEvent.content_type = dataLayer.businessLine;
      submitEvent.submit = true;

      Object.keys(dataLayer).forEach(
        (key) => (submitEvent[key] = dataLayer[key]),
      );
    }

    if (dataLayer.category === ECategoryEvent.CAR_PAGE) {
      const carId = getCarIdEvent(dataLayer);
      if (carId) {
        submitEvent.content_ids = [carId];
        submitEvent.name = 'ViewContent';
        submitEvent.content_type = dataLayer.businessLine;
        submitEvent.currency = 'ILS';
        submitEvent.price = dataLayer.price || 1;
        submitEvent.title = `${dataLayer.carMake} ${dataLayer.carModel}`;
        submitEvent.category = dataLayer.categories;
        submitEvent.car_brand = dataLayer.carMake;
        submitEvent.car_year = dataLayer.carYear;
        submitEvent.submit = true;

        if (dataLayer.businessLine === 'usedCar') {
          Object.keys(dataLayer).forEach(
            (key) => (submitEvent[key] = dataLayer[key]),
          );
        }
      }
    }

    if (
      dataLayer.event === EActionEvent.CAR_PAGE_ADD_TO_WISHLIST ||
      dataLayer.event === EActionEvent.ADD_TO_WISHLIST
    ) {
      const carId = getCarIdEvent(dataLayer);
      if (carId) {
        submitEvent.content_ids = [carId];
        submitEvent.name = 'AddToCart';
        submitEvent.content_type = dataLayer.businessLine;
        submitEvent.title = `${dataLayer.carMake} ${dataLayer.carModel}`;
        submitEvent.car_brand = dataLayer.carMake;
        submitEvent.car_year = dataLayer.carYear;
        submitEvent.currency = 'ILS';
        submitEvent.price = 1;
        submitEvent.submit = true;

        Object.keys(dataLayer).forEach(
          (key) => (submitEvent[key] = dataLayer[key]),
        );
      }
    }

    if (dataLayer.event === EActionEvent.LEAD_CONFIRMATION) {
      const carId = getCarIdEvent(dataLayer);
      if (carId) {
        submitEvent.content_ids = [carId];
        submitEvent.name = 'Purchase';
        submitEvent.content_type = dataLayer.businessLine;
        submitEvent.price = dataLayer.price || 1;
        submitEvent.title = `${dataLayer.carMake} ${dataLayer.carModel}`;
        submitEvent.category = dataLayer.categories;
        submitEvent.car_brand = dataLayer.carMake;
        submitEvent.car_year = dataLayer.carYear;
        submitEvent.currency = 'ILS';
        submitEvent.submit = true;

        Object.keys(dataLayer).forEach(
          (key) => (submitEvent[key] = dataLayer[key]),
        );
      }
    }

    if (submitEvent.submit) {
      try {
        fbq('track', submitEvent.name, {
          ...submitEvent,
        });
      } catch (error) {
        console.warn('error facebook', 'fbq is not defined');
      }
    }
  }, []);

  const postHogTrack = useCallback((dataLayer: any) => {
    const allowTrack = [
      EActionEvent.CAR_CONFIGURATION_SUMMARY_CONTACT_US,
      EActionEvent.CAR_PAGE_LEAD,
      EActionEvent.LEAD_CONFIRMATION,
    ];

    if (
      allowTrack.includes(dataLayer.action) ||
      allowTrack.includes(dataLayer.event)
    ) {
      posthog.capture(dataLayer.event, dataLayer);
    }
  }, []);

  const getPath = () => {
    if (typeof window !== 'undefined') {
      return window.location.pathname + window.location.search;
    }
    return '';
  };

  const sendEvent = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: IEvent, data: Object = {}) => {
      const path = getPath();

      const dataLayer: Record<string, any> = {
        event: event.action,
        category: event.category,
        pagePath: path,
        distinctId: posthog.get_distinct_id(),
        ...data,
      };

      const posthogAbTrack = [
        EActionEvent.CAR_PAGE_PAGE_LOAD,
        EActionEvent.CAR_RESULTS_PAGE,
        EActionEvent.LEAD_CONFIRMATION,
        EActionEvent.APPOINTMENT_CONFIRMATION,
        EActionEvent.CHECKOUT_RESERVE_CAR,
        EActionEvent.CAR_PAGE_LEAD,
        EActionEvent.APPOINTMENT_PAGE_LOAD,
      ];

      if (posthogAbTrack.includes(event.action as EActionEvent)) {
        postHogTrack(dataLayer);
      }

      if (process.env.NEXT_PUBLIC_DEBUG_ANALYTICS) {
        console.log({ dataLayer });
      }
      // TagManager.dataLayer({ dataLayer });

      sendGTMEvent(dataLayer);
      faceBookTrack(dataLayer);
    },
    [faceBookTrack, postHogTrack],
  );

  const trackCurrentPageView = useCallback(() => {
    const path = window.location.pathname + window.location.search;
    sendGAEvent({
      event: 'page_view',
      page_path: path,
    });
  }, []);

  return useMemo(
    () => ({
      sendEvent,
      trackCurrentPageView,
    }),
    [sendEvent, trackCurrentPageView],
  );
};
