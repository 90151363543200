import { CarDeal } from '~/redux-toolkit/slices/searchFlowSlice';
import { EActionEvent } from '~/hooks/useEvents';
import { RESOURCES } from '~/resources/resources';
import { CAR_FLOW } from '~/data/consts';
import {
  USED_END_OF_PERIOD_LIMIT_PERCENTAGE,
  USED_CAR_ADVANCED_LIMIT_PERCENTAGE,
  USED_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
  NEW_CAR_ADVANCED_LIMIT_PERCENTAGE,
  NEW_CAR_END_OF_PERIOD_LIMIT_PERCENTAGE,
  NEW_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
} from '~/redux-toolkit/slices/fundingSlice';
export const PAYMENT_METHOD = {
  monthly: 'תשלום חודשי',
  oneTime: 'תשלום מלא',
  oneTimeForUsed: 'לרכב תשלום מלא',
};

export const DATE_RANGE_OPTION = {
  daily: 'יומי',
  weekend: 'סוף שבוע',
  sixMonths: 'חצי שנה',
};

export const PAYMENT_METHODS_DATA = {
  [PAYMENT_METHOD.monthly]: { minPrice: 1000, maxPrice: 6000, step: 100 },
  [PAYMENT_METHOD.oneTime]: { minPrice: 80000, maxPrice: 250000, step: 5000 },
};
export const PAYMENT_METHODS_USED_CAR = {
  [PAYMENT_METHOD.monthly]: { minPrice: 1, maxPrice: 5000, step: 100 },
  [PAYMENT_METHOD.oneTime]: {
    minPrice: 5000,
    maxPrice: 200000,
    step: 5000,
  },
};

export const DATE_RANGE_DATA = {
  [DATE_RANGE_OPTION.daily]: { days: 1 },
  [DATE_RANGE_OPTION.weekend]: { days: 3 },
  [DATE_RANGE_OPTION.sixMonths]: { days: 180 },
};

export const DATE_RANGE_DAT = {
  [DATE_RANGE_OPTION.daily]: 1,
  [DATE_RANGE_OPTION.weekend]: 3,
  [DATE_RANGE_OPTION.sixMonths]: 6,
};
const PURCHASE_METHOD = {
  buying: 'קניה',
  leasing: 'ליסינג',
  funding: 'מימון',
};

export const FUNDING_PAY_TYPE = {
  advance: 'advance',
  monthly: 'monthly',
  endOfPeriod: 'endOfPeriod',
};

export const PURCHASE_METHODS = [
  PURCHASE_METHOD.buying,
  PURCHASE_METHOD.leasing,
  PURCHASE_METHOD.funding,
];

export const DATE_TYPES = {
  ...DATE_RANGE_OPTION,
  range: 'range',
};

export const PAYMENT_DEFAULT_VALUE = {
  monthly: { minPrice: 1000, maxPrice: 6000, step: 500 },
  oneTime: { minPrice: 80000, maxPrice: 250000, step: 5000 },
};

export const PAYMENT_DEFAULT_VALUE_USED_CAR = {
  monthly: { minPrice: 1000, maxPrice: 6000, step: 500 },
  oneTime: { minPrice: 1, maxPrice: 250000, step: 5000 },
};

export const LOBBY_BUDGET_DEFAULT_VALUES = {
  maxPrice: 10000,
};

export const KM_RANGE_VALUE = {
  minValue: 0,
  maxValue: 200000,
  step: 5000,
};

export const COPY_PLANS_DATA = {
  [CAR_FLOW.NEW_CAR]: {
    carDeal: CAR_FLOW.NEW_CAR,
    bullets: [
      'המון מכוניות מכל מיני קטגוריות – יש אצלנו הכול, לכם נשאר רק לבחור',
      'אפשר לקנות, אפשר בתשלום חודשי',
      'התחרטתם לאחר שכבר נסעתם? אפשר גם להחזיר',
    ],
    carTipTitle: 'ליהנות ממכונית חדשה עם כל היתרונות שלנו',
  },
  [CAR_FLOW.USED_CAR]: {
    carDeal: CAR_FLOW.USED_CAR,
    bullets: [
      'יד שנייה עם אחריות - אצלנו יש כזה דבר',
      'כל המכוניות שלנו עוברות בדיקה קפדנית ורענון',
      'התחרטתם לאחר שכבר נסעתם? אפשר גם להחזיר',
    ],
    carTipTitle: 'ליהנות ממכונית מחודשת עם כל היתרונות שלנו',
  },
  [CAR_FLOW.LEASING]: {
    carDeal: CAR_FLOW.LEASING,
    bullets: [
      'ליהנות ממכונית חדשה בלי לשבור את קופת החסכון',
      'לא צריך לדאוג מירידת ערך וממכירה עתידית',
      'לא רוצה להתעסק עם ביטוח, רישוי, טיפולים ועוד? נשמח לדאוג גם לזה',
    ],
    carTipTitle: 'ליהנות ממכונית מחודשת עם כל היתרונות שלנו',
  },
  [CAR_FLOW.RENTAL_CAR]: {
    carDeal: CAR_FLOW.RENTAL_CAR,
    bullets: [
      'מכונית זמנית לעבודה, לחופש, להעביר דירה',
      'יש צורך במכונית אבל לא ברור לכמה זמן? יש לנו פתרון מצוין בשבילך',
      'קטנות, גדולות ומגניבות. יש אצלנו כל מה שאפשר לחשוב עליו',
    ],
    carTipTitle: 'ליהנות ממכונית זמנית עם כל היתרונות שלנו',
  },
};

export const COPY_PLANS_DATA_ARRAY: CarDeal[] = Object.entries(
  COPY_PLANS_DATA,
).map(([id, item]) => ({ ...item, id }));

export const getRentCarForPeriodInfo = (input: string) => {
  switch (input) {
    case DATE_RANGE_OPTION.daily:
      return RESOURCES.RENT_CAR_FOR_A_PERIOD_DAILY;
    case DATE_RANGE_OPTION.sixMonths:
      return RESOURCES.RENT_CAR_FOR_A_PERIOD_SIX_MONTHS;
    case DATE_RANGE_OPTION.weekend:
      return RESOURCES.RENT_CAR_FOR_A_PERIOD_WEEKEND;
    default:
      return '';
  }
};

export type SearchRentalType = {
  pickupBranch?: string;
  returnBranch?: string;
  startDate: string | null;
  endDate: string | null;
  age?: number;
  isReturnBranch?: boolean;
};

const RENTAL_CAR_REDIRECT_URL = 'https://rental.freesbe.com/resultsPage/';
const RENTAL_CAR_PICKUP_BRANCH_PARAM = 'pickupBranch';
const RENTAL_CAR_RETURN_BRANCH_PARAM = 'returnBranch';
const RENTAL_CAR_START_DATE_PARAM = 'startDate';
const RENTAL_CAR_END_DATE_PARAM = 'endDate';
const RENTAL_CAR_PICKUP_TIME_PARAM = 'pickupTime=08%3A00';
const RENTAL_CAR_RETURN_TIME_PARAM = 'returnTime=08%3A00';
const RENTAL_CAR_RETURN_SAME_AS_PARAM = 'isReturnBranch';
const RENTAL_CAR_AGE_PARAM = 'age';

export const buildRentalCarRedirectUrl = ({
  startDate,
  endDate,
  pickupBranch,
  returnBranch,
  age = 24,
  isReturnBranch = true,
}: SearchRentalType): string => {
  const pickupB = pickupBranch || 'tel aviv';
  const returnB = returnBranch || 'tel aviv';
  const baseUrl = `${RENTAL_CAR_REDIRECT_URL}/?${RENTAL_CAR_PICKUP_BRANCH_PARAM}=${pickupB}&${RENTAL_CAR_RETURN_BRANCH_PARAM}=${returnB}&${RENTAL_CAR_PICKUP_TIME_PARAM}&${RENTAL_CAR_RETURN_TIME_PARAM}&${RENTAL_CAR_AGE_PARAM}=${age}&${RENTAL_CAR_RETURN_SAME_AS_PARAM}=${isReturnBranch}&${RENTAL_CAR_START_DATE_PARAM}=${startDate}&utm_source=freesbe&utm_medium=referral`;
  return endDate
    ? `${baseUrl}&${RENTAL_CAR_END_DATE_PARAM}=${endDate}`
    : baseUrl;
};

const USED_CAR_REDIRECT_URL = `${process.env.NEXT_PUBLIC_TRADE_LS4}shop/?filters=`;
const USED_CAR_CATEGORIES_PARAM = 'product_cat';
const USED_CAR_MONTHLY_PRICE_PARAM = 'min_monthly_payment';
const USED_CAR_WHOLE_PRICE_PARAM = 'price';

export const buildUsedCarRedirectUrl = (
  categories: string[],
  minPrice: number,
  maxPrice: number,
  paymentMethod: string,
): string => {
  const formattedCategories = categories
    .map((category) => category.replaceAll(' ', '-'))
    .toString()
    .replaceAll(',', '-')
    .replaceAll('+', '');
  let url = categories?.length
    ? `${USED_CAR_REDIRECT_URL}${USED_CAR_CATEGORIES_PARAM}[${formattedCategories}]|`
    : USED_CAR_REDIRECT_URL;
  url += `${
    paymentMethod === PAYMENT_METHOD.monthly
      ? USED_CAR_MONTHLY_PRICE_PARAM
      : USED_CAR_WHOLE_PRICE_PARAM
  }[${minPrice}_${maxPrice}]`;
  return url;
};

export const SEARCH_FLOW_EVENT_NAME_MAP: { [key: string]: EActionEvent } = {
  [CAR_FLOW.NEW_CAR]: EActionEvent.SEARCH_FLOW_SUBMIT_NEW_CAR,
  [CAR_FLOW.USED_CAR]: EActionEvent.SEARCH_FLOW_SUBMIT_USED_CAR,
  [CAR_FLOW.LEASING]: EActionEvent.SEARCH_FLOW_SUBMIT_LEASING,
  [CAR_FLOW.RENTAL_CAR]: EActionEvent.SEARCH_FLOW_SUBMIT_TEMPORARY_CAR,
};

export const BudgetStepInitialData = {
  selectedAdvancement: 20000,
  selectedMonthlyPayment: 1700,
  numberOfMonths: 60,
  totalBudget: 200000,
  endOfPeriodPayment: 0,
  loanRange: {
    primeRate: 9.85,
    usedCarPrimeRate: 6.5,
    usedCarLoanRange: [],
    newCarLoanRange: [],
    newCarBaseRate: 2.95,
    usedCarTooltipDesc: '',
    newCarTooltipDesc: '',
    usedCarFinanceCalculator: {
      advancedLimitPercentage: USED_CAR_ADVANCED_LIMIT_PERCENTAGE,
      endOfPeriodLimitPercentage: USED_END_OF_PERIOD_LIMIT_PERCENTAGE,
      minCarAdvancedPercentage: USED_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
    },
    newCarFinanceCalculator: {
      advancedLimitPercentage: NEW_CAR_ADVANCED_LIMIT_PERCENTAGE,
      endOfPeriodLimitPercentage: NEW_CAR_END_OF_PERIOD_LIMIT_PERCENTAGE,
      minCarAdvancedPercentage: NEW_CAR_MIN_CAR_ADVANCED_PERCENTAGE,
    },
  },
};

export const searchFlowMetaData = {
  accurateSearch: {
    title: 'חיפוש רכב למכירה לפי יצרן ודגם | freesbe',
    description:
      'רוצים לקנות מכונית חדשה או משומשת, וחולמים על דגם ספציפי? אין בעיה. פריסבי מאפשרת לכם לחפש לפי שם היצרן והדגם',
  },
  generalSearch: {
    title: 'חיפוש רכב למכירה בהתאם לצרכים שלך | freesbe',
    description:
      'מחפשים מכונית עם תא מטען גדול? אולי חסכונית בדלק? אולי בעצם מכונית עם 7 מקומות? בפריסבי תוכלו למצוא את המכונית הבאה שלכם בדיוק לפי מה שאתם צריכים',
  },
  budget: {
    title: 'חיפוש רכב למכירה בהתאמה לתקציב שלך | freesbe',
    description:
      'מחפשים מכונית שתתאים בול לתקציב שלכם. בפריסבי בטוח תמצאו את מה שאתם מחפשים, ואולי אפילו תופתעו מהמחיר',
  },
};
