import { ESourceEventOptions } from '~/data/lead/enums';
import { useCallback } from 'react';
import {
  initState,
  setCampaignNumber,
  setFlow,
  setLineOfBusiness,
  setNote,
  setSource,
  setOpenContactUsModal,
  setSubMedia,
  setMeetingCar,
  setMeetingUsedCar,
  setRedirectTo,
  setLeadLocation,
  setIsContactUsModalBrandsLead,
  setBrandsLeadDataDropdownOption,
  setScreen,
  setLeadTitle,
  setShowNote,
  setCar,
} from '~/redux-toolkit/slices/contactUsSlice';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { CampaignNumber } from '~/services/LeadService/enums';
import { BrandLeadData, CarCardType } from '~/data/types/car/cars';
import { getLineOfBusinessByCampaignNumber } from '~/data/lead/lineOfBusiness';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { useCampaignNumber } from './useCampaignNumber';
import { EContactUsFlow } from '~/data/ContactUsPopup/enums';
import { setFamilyCarPromoCard } from '~/redux-toolkit/slices/resultPageSlice';

export interface ContactUsConfig {
  flow?: EContactUsFlow;
  campaignNumber?: CampaignNumber | string;
  lineOfBusiness?: string;
  subMedia?: string;
  note?: string;
  leadTitle?: string;
  showNote?: boolean;
  category?: string;
  source?: ESourceEventOptions;
  car?: CarCardType;
  usedCar?: UsedCarType | null;
  redirectTo?: string;
  leadLocation?: string | null;
  isBrandsLead?: boolean | null;
  brandsLeadDataDropdownOptions?: BrandLeadData[];
  screen?: string;
}

export const useContactUsPopup = () => {
  const dispatch = useAppDispatch();
  const campaignNumber = useCampaignNumber();

  const openContactUsModal = useCallback(
    (config?: ContactUsConfig) => {
      dispatch(initState());
      dispatch(setOpenContactUsModal(true));
      dispatch(setScreen(config?.screen));
      dispatch(setFlow(config?.flow || EContactUsFlow.NONE));
      dispatch(setCampaignNumber(config?.campaignNumber || campaignNumber));
      dispatch(setLineOfBusiness(config?.lineOfBusiness || ''));
      dispatch(setSubMedia(config?.subMedia || ''));
      dispatch(setNote(config?.note || ''));
      dispatch(setLeadTitle(config?.leadTitle || ''));
      dispatch(setShowNote(config?.showNote || false));
      dispatch(setSource(config?.source || undefined));
      dispatch(setMeetingCar(config?.car));
      dispatch(setMeetingUsedCar(config?.usedCar));
      dispatch(setRedirectTo(config?.redirectTo));
      dispatch(setLeadLocation(config?.leadLocation ?? null));
      dispatch(setIsContactUsModalBrandsLead(config?.isBrandsLead || false));
      dispatch(
        setBrandsLeadDataDropdownOption(
          config?.brandsLeadDataDropdownOptions || [],
        ),
      );
      dispatch(setCar(config?.car || config?.usedCar));
      dispatch(setLineOfBusiness(getLineOfBusinessByCampaignNumber(config?.campaignNumber || "") || ""))
    },
    [dispatch, campaignNumber],
  );

  const closeContactUsModal = useCallback(() => {
    dispatch(setOpenContactUsModal(false));
    dispatch(setScreen(''));
    dispatch(setFamilyCarPromoCard(null));
  }, [dispatch]);

  // Return the width so we can use it in our components
  return { openContactUsModal, closeContactUsModal };
};
