import { createSlice } from '@reduxjs/toolkit';
import { filterCategoriesType } from '~/data/types/filters/filterCategoriesType';
import {
  getAccurateFlowFiltersOptions,
  getAccurateLeaseFlowFiltersOptions,
  getCarBodyTypeOptions,
  getDMVFiltersCategories,
  getFiltersCategories,
  getHighlightsOptions,
  getLeaseCarBodyTypeOptions,
  getLeaseHighlightsOptions,
  getLikeNewsCategories,
  getUsedCarAccurateFlowFiltersOptions,
  getUsedCarBodyTypeOptions,
  getUsedCarHighlightsOptions,
} from '~/redux-toolkit/thunks/filtersThunk';
import { BrandAndModel } from '~/data/types/usedCar/usedCarData';
import { CarBodyType, CarHightLights } from '~/data/types/car/cars';
import {
  getMappedFiltersOptions,
  getMappedCategoriesOptions,
  removeDuplicatesOfBodyType,
} from '~/redux-toolkit/utils/filtersUtils';
import { getPrimeRate } from '~/redux-toolkit/thunks/fundingThunks';
import { IPrimeRateRange } from '~/data/types/approvalFlow/approvalFlowType';
import { BudgetStepInitialData, FUNDING_PAY_TYPE } from '~/data/searchFlowData';
import { HYDRATE } from 'next-redux-wrapper';

interface SearchFiltersState {
  accurateSearch: {
    newCarCategories: filterCategoriesType[];
    usedCarCategories: filterCategoriesType[];
    dmvCategories: filterCategoriesType[];
    newCarBrandsAndModels: BrandAndModel[];
    usedCarBrandsAndModels: BrandAndModel[];
    selectedCategories: string[];
    selectedBrands: string[];
    selectedModels: string[];
    selectedYears: {
      minYear: string;
      maxYear: string;
    };
  };
  generalSearch: {
    newCarHighlights: CarHightLights[];
    usedCarHighlights: CarHightLights[];
    leaseCarHighlights: CarHightLights[];
    selectedHighlights: string[];
    newCarBodyType: CarBodyType[];
    usedCarBodyType: CarBodyType[];
    selectedCarBodyType: string[];
  };
  budgetSearch: {
    selectedAdvancement: number | undefined;
    selectedMonthlyPayment: number | undefined;
    endOfPeriodPayment: number;
    totalBudget: number;
    numberOfMonths: number;
    loanRange: IPrimeRateRange;
  };
}

const initialState: SearchFiltersState = {
  accurateSearch: {
    newCarCategories: [],
    dmvCategories: [],
    usedCarCategories: [],
    newCarBrandsAndModels: [],
    usedCarBrandsAndModels: [],
    selectedCategories: [],
    selectedBrands: [],
    selectedModels: [],
    selectedYears: {
      minYear: '',
      maxYear: '',
    },
  },
  generalSearch: {
    newCarHighlights: [],
    usedCarHighlights: [],
    leaseCarHighlights: [],
    selectedHighlights: [],
    newCarBodyType: [],
    usedCarBodyType: [],
    selectedCarBodyType: [],
  },
  budgetSearch: BudgetStepInitialData,
};

const searchFiltersSlice = createSlice({
  name: 'searchFiltersSlice',
  initialState,
  reducers: {
    setSelectedCategories(state, action) {
      state.accurateSearch.selectedCategories = action.payload;
    },
    setSelectedBrands(state, action) {
      state.accurateSearch.selectedBrands = action.payload;
    },
    setSelectedModels(state, action) {
      state.accurateSearch.selectedModels = action.payload;
    },
    setSelectedYears(state, action) {
      state.accurateSearch.selectedYears = action.payload;
    },
    setSelectedHighlights(state, action) {
      state.generalSearch.selectedHighlights = action.payload;
    },
    setSelectedCarBodyType(state, action) {
      state.generalSearch.selectedCarBodyType = action.payload;
    },
    setSelectedAdvancement(state, action) {
      state.budgetSearch.selectedAdvancement = action.payload;
    },
    setSelectedMonthlyPayment(state, action) {
      state.budgetSearch.selectedMonthlyPayment = action.payload;
    },
    setBudgetSearch(state, action) {
      const { totalBudget, endOfPeriodPayment } = action.payload;
      state.budgetSearch.totalBudget = totalBudget;
      state.budgetSearch.endOfPeriodPayment = endOfPeriodPayment;
    },
    setTotalBudget(state, action) {
      state.budgetSearch.totalBudget = action.payload;
    },
    initSelectedFiltersState(state) {
      state.accurateSearch.selectedCategories = [];
      state.accurateSearch.selectedModels = [];
      state.accurateSearch.selectedBrands = [];
      state.accurateSearch.selectedYears = {
        minYear: '',
        maxYear: '',
      };
      state.generalSearch.selectedHighlights = [];
      state.generalSearch.selectedCarBodyType = [];
      state.budgetSearch.selectedAdvancement =
        BudgetStepInitialData.selectedAdvancement;
      state.budgetSearch.selectedMonthlyPayment =
        BudgetStepInitialData.selectedMonthlyPayment;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFiltersCategories.fulfilled, (state, action) => {
      state.accurateSearch.newCarCategories = action.payload.map((category) => {
        return {
          name: category.name,
          id: category.id,
          icon: category.icon?.url,
          selectedIcon: category.selectedIcon?.url,
          rank: category.rank,
        } as filterCategoriesType;
      });
    });
    builder.addCase(getDMVFiltersCategories.fulfilled, (state, action) => {
      state.accurateSearch.dmvCategories = action.payload.data
        .filter((category) => {
          const { attributes } = category;
          return attributes.icon.data && attributes.selectedIcon?.data;
        })
        .map((category) => {
          const { id, attributes } = category;
          return {
            id: id,
            name: attributes.name,
            icon: attributes.icon?.data.attributes.url,
            selectedIcon: attributes.selectedIcon?.data.attributes.url,
            isNewCar: Boolean(attributes.isNewCar),
            isUsedCar: Boolean(attributes.isUsedCar),
          } as filterCategoriesType;
        });
    });
    builder.addCase(getLikeNewsCategories.fulfilled, (state, action) => {
      state.accurateSearch.usedCarCategories = action.payload
        .filter(
          (category) =>
            category.icon?.url && category.selectedIcon?.url && category.name,
        )
        .filter((category, pos, self) => self.indexOf(category) === pos)
        .map((category) => {
          return {
            id: category.name,
            name: category.name,
            icon: category.icon.url,
            selectedIcon: category.selectedIcon.url,
          } as filterCategoriesType;
        });
    });
    builder.addCase(
      getAccurateFlowFiltersOptions.fulfilled,
      (state, action) => {
        try {
          const { manufactureWithModels, categories } = action.payload;
          state.accurateSearch.newCarBrandsAndModels = manufactureWithModels;
          state.accurateSearch.newCarCategories =
            getMappedCategoriesOptions(categories);
        } catch (error) {
          console.log(error);
        }
      },
    );
    builder.addCase(
      getAccurateLeaseFlowFiltersOptions.fulfilled,
      (state, action) => {
        try {
          const { manufactureWithModels, categories } = action.payload;
          state.accurateSearch.newCarBrandsAndModels = manufactureWithModels;
          state.accurateSearch.newCarCategories =
            getMappedCategoriesOptions(categories);
        } catch (error) {
          console.log(error);
        }
      },
    );
    builder.addCase(getPrimeRate.fulfilled, (state, action) => {
      try {
        state.budgetSearch.loanRange = action.payload;
        state.budgetSearch.selectedAdvancement =
          action.payload.newCarLoanRange.find(
            (s) => s.type === FUNDING_PAY_TYPE.advance,
          )?.defaultValue || state.budgetSearch.selectedAdvancement;
        state.budgetSearch.selectedMonthlyPayment =
          action.payload.newCarLoanRange.find(
            (s) => s.type === FUNDING_PAY_TYPE.monthly,
          )?.defaultValue || state.budgetSearch.selectedMonthlyPayment;
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { searchFilters } = action.payload;
      return {
        ...state,
        ...searchFilters,
      };
    });
    builder.addCase(
      getUsedCarAccurateFlowFiltersOptions.fulfilled,
      (state, action) => {
        try {
          const { manufactureWithModels, categories } = action.payload;
          state.accurateSearch.usedCarBrandsAndModels = manufactureWithModels;
          state.accurateSearch.usedCarCategories =
            getMappedCategoriesOptions(categories);
        } catch (error) {
          console.log(error);
        }
      },
    );
    builder.addCase(getUsedCarHighlightsOptions.fulfilled, (state, action) => {
      try {
        const carHighlights = action.payload;
        state.generalSearch.usedCarHighlights =
          getMappedFiltersOptions(carHighlights);
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getHighlightsOptions.fulfilled, (state, action) => {
      try {
        const carHighlights = action.payload;
        state.generalSearch.newCarHighlights =
          getMappedFiltersOptions(carHighlights);
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getLeaseHighlightsOptions.fulfilled, (state, action) => {
      try {
        const carHighlights = action.payload;
        state.generalSearch.newCarHighlights =
          getMappedFiltersOptions(carHighlights);
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getCarBodyTypeOptions.fulfilled, (state, action) => {
      try {
        const carBodyTypes = action.payload;
        const filteredBodyTypes = carBodyTypes
          .map((option) => {
            return {
              title: option.attributes.title,
              displayTitleName: option.attributes.displayTitleName,
              icon: option.attributes.icon?.data?.attributes.url,
              isNewCar: option.attributes.isNewCar || false,
              isUsedCar: option.attributes.isUsedCar || false,
            };
          })
          .filter((op) => op.isNewCar);
        const titles = filteredBodyTypes.map((bt) => bt.title);

        state.generalSearch.newCarBodyType = removeDuplicatesOfBodyType(
          filteredBodyTypes.filter(
            (bt) => bt.icon && !titles.includes(bt.displayTitleName),
          ),
        );
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getLeaseCarBodyTypeOptions.fulfilled, (state, action) => {
      try {
        const carBodyTypes = action.payload;
        const filteredBodyTypes = carBodyTypes
          .map((option) => {
            return {
              title: option.attributes.title,
              displayTitleName: option.attributes.displayTitleName,
              icon: option.attributes.icon?.data?.attributes.url,
              isNewCar: option.attributes.isNewCar || false,
              isUsedCar: option.attributes.isUsedCar || false,
              isLeaseCar: option.attributes.isLeaseCar || false,
            };
          })
          .filter((op) => op.isLeaseCar);
        const titles = filteredBodyTypes.map((bt) => bt.title);

        state.generalSearch.newCarBodyType = removeDuplicatesOfBodyType(
          filteredBodyTypes.filter(
            (bt) => bt.icon && !titles.includes(bt.displayTitleName),
          ),
        );
      } catch (error) {
        console.log(error);
      }
    });
    builder.addCase(getUsedCarBodyTypeOptions.fulfilled, (state, action) => {
      try {
        const carBodyTypes = action.payload;
        const filteredBodyTypes = carBodyTypes
          .map((option) => {
            return {
              title: option.attributes.title,
              displayTitleName: option.attributes.displayTitleName,
              icon: option.attributes.icon?.data?.attributes.url,
              isNewCar: option.attributes.isNewCar || false,
              isUsedCar: option.attributes.isUsedCar || false,
            };
          })
          .filter((op) => op.isUsedCar);
        const titles = filteredBodyTypes.map((bt) => bt.title);

        state.generalSearch.usedCarBodyType = removeDuplicatesOfBodyType(
          filteredBodyTypes.filter(
            (bt) => bt.icon && !titles.includes(bt.displayTitleName),
          ),
        );
      } catch (error) {
        console.log(error);
      }
    });
  },
});

export const {
  setSelectedCategories,
  setSelectedBrands,
  setSelectedModels,
  setSelectedYears,
  setSelectedHighlights,
  setSelectedCarBodyType,
  setSelectedAdvancement,
  setSelectedMonthlyPayment,
  initSelectedFiltersState,
  setBudgetSearch,
} = searchFiltersSlice.actions;

export default searchFiltersSlice.reducer;
