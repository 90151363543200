import Lottie, { LottieRef } from 'lottie-react';
import React, { useEffect, useRef } from 'react';
import LoaderAnimation from '~/animations/LoaderAnimation';
import { LOADER_SIZE } from './useStyles';

interface LoaderProps {
  size: {
    height: string;
  };
  customStyle?: React.CSSProperties;
}

const Loader = ({ size = LOADER_SIZE.md, customStyle }: LoaderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lottieRef: LottieRef | any = useRef();

  useEffect(() => {
    (lottieRef as LottieRef).current?.setSpeed(1.5);
  }, []);

  return (
    <div style={customStyle}>
      <Lottie
        animationData={LoaderAnimation}
        loop
        style={size}
        lottieRef={lottieRef}
      />
    </div>
  );
};

export default Loader;
