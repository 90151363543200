import { createSlice } from '@reduxjs/toolkit';
import { IHomePageData } from '~/data/types/homePageContent/homePage.types';
import { getIHomePageData } from '~/redux-toolkit/thunks/homePageContentThunks';

interface HomePageState {
  homePage: IHomePageData | null;
}

const initialState: HomePageState = {
  homePage: null,
};

const homePageSlice = createSlice({
  name: 'homePageContentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIHomePageData.fulfilled, (state, action) => {
      state.homePage = action.payload ?? null;
    });
  },
});

export const {} = homePageSlice.actions;

export default homePageSlice.reducer;
