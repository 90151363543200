import { EUrlParam } from '~/data/urlParams';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * Retrieves and optionally cleans application-specific URL parameters,
 * returning a URLSearchParams object for easy query manipulation.
 */
export const useCurrentUrlParams = (
  config: { cleanAppParams: boolean; preserveKeys?: string[] } = {
    cleanAppParams: false,
  },
) => {
  const router = useRouter();
  const { query } = router;

  // Create a URLSearchParams object
  const searchParams = new URLSearchParams();

  // Populate it with values from the router's query object
  Object.keys(query)?.forEach((key) => {
    if (key === 'params') return;
    const value = query[key];

    if (Array.isArray(value)) {
      value?.forEach((v) => {
        searchParams.append(key, v);
      });
    } else {
      searchParams.set(key, value as string); // type casting, maybe need here type guard
    }
  });

  // Clean the app params if needed
  const cleanedSearchParams = useMemo(() => {
    if (config.cleanAppParams) {
      const urlParams = Object.values(EUrlParam);

      urlParams?.forEach((param) => {
        // Skip the cleaning for keys specified in preserveKeys
        if (config.preserveKeys && config.preserveKeys.includes(param)) {
          return;
        }

        searchParams.delete(param);
      });
    }
    return searchParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.cleanAppParams, config.preserveKeys, query]);

  return useMemo(() => cleanedSearchParams, [cleanedSearchParams]);
};
