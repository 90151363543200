import { useMemo } from 'react';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';

export const useSelectedCarConfiguratorData = () => {
  const { selectedCar } = useAppSelector((state: RootState) => state.carFlow);

  return useMemo(() => {
    return selectedCar;
  }, [selectedCar]);
};
