/* eslint-disable class-methods-use-this */
import { MetaType } from '~/data/types/paginationTypes';
import { UsedCarDataType } from '~/data/types/usedCar/usedCarData';
import { HttpClientService } from '~/services/HttpsClientService';

class WishlistAdapter {
  static readonly endpoint = `/api/family-cars?`;

  static readonly endpointLease = `/api/family-lease-cars?`;

  static readonly endpointFilterQuery = `filters[id][$in]`;

  static readonly populateQuery = '&populate=*';

  static readonly endpointCarById = `/api/used-cars`;

  async getCarDetails(ids: number[]) {
    const queryFilters = ids
      .map(
        (id, index) => `${WishlistAdapter.endpointFilterQuery}[${index}]=${id}`,
      )
      .join('&');

    const res = await HttpClientService.get(
      `${WishlistAdapter.endpoint}${queryFilters}${WishlistAdapter.populateQuery}`,
    );

    return res;
  }

  async getLeaseCarDetails(ids: number[]) {
    const queryFilters = ids
      .map(
        (id, index) => `${WishlistAdapter.endpointFilterQuery}[${index}]=${id}`,
      )
      .join('&');

    const res = await HttpClientService.get(
      `${WishlistAdapter.endpointLease}${queryFilters}${WishlistAdapter.populateQuery}`,
    );

    return res;
  }
  async getUsedCarById(ids: number[]) {
    const queryFilters = ids
      .map(
        (id, index) => `${WishlistAdapter.endpointFilterQuery}[${index}]=${id}`,
      )
      .join('&');

    const res = await HttpClientService.get<{
      meta: MetaType;
      data: UsedCarDataType;
    }>(`${WishlistAdapter.endpointCarById}?${queryFilters}&populate=deep,4`);

    return res;
  }
}

const filterAdapter = new WishlistAdapter();
export default filterAdapter;
