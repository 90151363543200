import { CAR_TARGET } from '~/data/consts';
import { CarCardType } from '~/data/types/car/cars';
import { UsedCarType } from '~/data/types/usedCar/usedCarData';

const useCarEventDetails = () => {
  const GetUsedCarDetails = (usedCar: UsedCarType) => {
    return {
      carMake: usedCar?.carZoneManufacturerName,
      carModel: usedCar?.carZoneModelName,
      carYear: usedCar?.year,
      categories: usedCar?.categories,
      engine: usedCar?.engine,
      carHighlights: usedCar?.carHighlights
        ?.reduce((prev, curr) => `${prev} ${curr.title}`, '')
        .trim(),
      hand: usedCar?.carHand,
      image:
        (usedCar?.imagesKit &&
          usedCar?.imagesKit?.length > 0 &&
          usedCar?.imagesKit[0]?.url) ||
        (usedCar?.images?.length > 0 && usedCar?.images[0]?.url) ||
        '',
      previousCarOwnershipType: usedCar?.previousCarOwnershipHand,
      color: usedCar?.carZoneColor,
      kms: usedCar?.kms,
      price: usedCar?.carPrice,
      carBody:
        usedCar?.carBodyType?.displayTitleName ||
        usedCar?.carBodyType?.title ||
        '',
      carProgrammaticDescription: usedCar?.carProgrammaticDescription,
    };
  };
  const GetNewCarDetails = (car: CarCardType, businessLineTarget: string) => {
    return {
      businessLine: businessLineTarget,
      carMake: car?.dmvManufacturerName,
      carModel: car?.modelNameDisplayName || car?.dmvModelName,
      carYear: car?.year,
      categories: car?.categories,
      image: car?.images[0],
      price:
        businessLineTarget === CAR_TARGET.LEASING
          ? car?.leaseMinPrice
          : car?.newCarMinPrice,

      carBody:
        car?.carBodyType?.displayTitleName || car?.carBodyType?.title || '',
      color: car?.colors,
    };
  };
  return { GetUsedCarDetails, GetNewCarDetails };
};

export default useCarEventDetails;
