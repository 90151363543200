import { useRouter } from 'next/router';

const useShowNavigation = () => {
  const router = useRouter();

  // Define the paths where you don't want to show the navigation
  const pathsToHideNavigation = [
    '/new-car-for-sale/flow/[carFlowId]',
    '/private-leasing/flow/[carFlowId]',
    '/search-flow/[...type]',
  ];

  // Check if the current path is in the list
  const showNavigation = !pathsToHideNavigation.includes(router.pathname);

  return showNavigation;
};

export default useShowNavigation;
