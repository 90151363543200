import React from 'react';
import { ImageFile } from '~/data/types/images';

const threeStarsSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="22"
      viewBox="0 0 77 22"
    >
      <path
        d="M49.0729 9.93504C48.6734 9.67671 48.8392 9.05802 49.3143 9.03403L53.5123 8.82209C54.1754 8.7886 54.7413 8.33126 54.9132 7.68989L55.9983 3.64023C56.121 3.18239 56.7574 3.14804 56.9287 3.59002L58.4518 7.52134C58.6903 8.13684 59.2966 8.53005 59.9558 8.49677L64.1732 8.28384C64.6476 8.25989 64.8752 8.85733 64.5051 9.15515L61.2448 11.7785C60.7256 12.1963 60.5365 12.9021 60.7773 13.5235L62.2891 17.4256C62.4607 17.8685 61.9648 18.2721 61.5659 18.0142L58.0201 15.7211C57.4658 15.3626 56.7441 15.4 56.2298 15.8138L52.9451 18.4569C52.5758 18.754 52.0418 18.406 52.1645 17.9482L53.2496 13.8985C53.4214 13.2572 53.16 12.5782 52.6025 12.2176L49.0729 9.93504Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M30.2155 9.93553C29.816 9.6772 29.9818 9.05851 30.4569 9.03452L34.6549 8.82257C35.318 8.78909 35.8839 8.33175 36.0558 7.69038L37.1409 3.64072C37.2635 3.18288 37.9 3.14853 38.0713 3.59051L39.5944 7.52183C39.8329 8.13733 40.4391 8.53054 41.0984 8.49725L45.3157 8.28433C45.7902 8.26038 46.0178 8.85782 45.6477 9.15563L42.3874 11.779C41.8682 12.1968 41.6791 12.9026 41.9198 13.524L43.4316 17.426C43.6033 17.869 43.1074 18.2726 42.7085 18.0146L39.1626 15.7216C38.6084 15.3631 37.8867 15.4005 37.3724 15.8143L34.0877 18.4574C33.7184 18.7545 33.1844 18.4065 33.3071 17.9487L34.3922 13.899C34.564 13.2577 34.3026 12.5786 33.7451 12.2181L30.2155 9.93553Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
      <path
        d="M11.358 9.93504C10.9586 9.67671 11.1244 9.05802 11.5995 9.03403L15.7975 8.82209C16.4606 8.7886 17.0265 8.33126 17.1983 7.68989L18.2834 3.64023C18.4061 3.18239 19.0426 3.14804 19.2138 3.59002L20.737 7.52134C20.9754 8.13684 21.5817 8.53005 22.241 8.49677L26.4583 8.28384C26.9328 8.25989 27.1604 8.85733 26.7903 9.15515L23.53 11.7785C23.0108 12.1963 22.8217 12.9021 23.0624 13.5235L24.5742 17.4256C24.7458 17.8685 24.25 18.2721 23.8511 18.0142L20.3052 15.7211C19.7509 15.3626 19.0293 15.4 18.515 15.8138L15.2303 18.4569C14.861 18.754 14.327 18.406 14.4496 17.9482L15.5347 13.8985C15.7066 13.2572 15.4452 12.5782 14.8876 12.2176L11.358 9.93504Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="1.04008"
      />
    </Component>
  );
};

export default threeStarsSelectedIcon;
