import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const usePreloader = () => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    const handleStart = (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setLoading(true);
      }
    };

    const handleComplete = () => {
      if (loading) {
        document.getElementById('freesbe-logo')?.focus();
      }
      setLoading(false);
    };

    const handleBackButton = () => {
      document.getElementById('freesbe-logo')?.focus();
    };

    if (typeof window !== 'undefined') {
      window.onpopstate = handleBackButton;
    }
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [router, query]);

  return loading;
};
