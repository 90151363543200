import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Box, SxProps, Typography } from '@mui/material';
import {
  BannerPromotionSkinColor,
  IBannerPromotion,
} from '~/data/types/car/cars';
import Button from '~/components/Button';
import RESOURCES from '~/resources';
import TooltipInfo from '~/components/TooltipInfo';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import giftCampaign from '~/assets/icons/jsx/giftCampaign';
import yellowLightCampaignBannerIcon from '~/assets/icons/jsx/yellowLightCampaignBannerIcon';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { useContactUsPopup } from '~/hooks/useContactUsPopup';
import Timer from '~/components/Timer';
import { ITimer } from '~/components/Timer/Timer';
import { EContactUsFlow } from '~/data/ContactUsPopup/enums';
import { useCurrentUrlParams } from '~/hooks/useCurrentUrlParams';
import { useIsMobile } from '~/hooks/useIsMobile';
import { isWithinDateRange } from '~/utils/isWithinDateRange';
import { calculateTimeLeft } from '~/utils/timeUtils';
import useStyles, {
  BoxBannerContainer,
  CloseButtonIcon,
  ImageBannerPromotionStyle,
  buttonBannerPromotionStyle,
  descriptionBannerPromotionStyle,
  legalContentStyle,
  titleBannerPromotionStyle,
  bannerContentStyle,
  giftIconStyle,
  yellowLightCampaignBannerIconStyle,
  tooltipStyle,
  boxContainer,
  toolTipText,
  mobileTooltip,
  PolicyLinkBoxStyle,
  ImageBannerPromotionOnlineBuyStyle,
  FreesbeLogo,
} from './useStyles';
import { LEAD_LOCATION } from '~/data/consts';
import { ESourceEventOptions } from '~/data/lead/enums';
import CloseIcon from '~/assets/images/jsx/close';
import { RootState } from '~/redux-toolkit/store/store';
import { useSelector } from 'react-redux';
import BlackFreesbeLogoIcon from '~/assets/images/jsx/blackFreesbeLogo';
import YellowStartIcon from '~/assets/icons/yellowStart.svg';
import ThreeSunLight from '~/assets/icons/threeSunLight.svg';

import Image from 'next/image';

interface IBannerPromotionProps {
  bannerPromotion: IBannerPromotion;
  close: (shouldClose: boolean) => void;
  isCarPage?: boolean;
  sx?: SxProps;
  showCloseButton?: boolean;
}

/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow, no-unused-vars
export enum BANNER_OPTION {
  LINK = 'link',
  LEAD = 'lead',
  BRANDS_LEAD = 'brandsLead',
}

/* eslint-enable no-unused-vars */
export enum CampaignBannerType {
  DEFAULT = 'default',
  TIMER = 'timer',
  ONLINE_BUY = 'online_buy',
}

const ResultsPageBannerPromotion: FC<IBannerPromotionProps> = ({
  bannerPromotion,
  close,
  isCarPage = false,
  sx,
  showCloseButton = true,
}) => {
  const router = useRouter();
  const events = useEvents();
  const isMobile = useIsMobile();
  const { openContactUsModal } = useContactUsPopup();
  const { isMoreFiltersOpen } = useSelector(
    (state: RootState) => state.resultPage,
  );

  const currentParams = useCurrentUrlParams({ cleanAppParams: true });

  const [timeLeft, setTimeLeft] = useState<ITimer | null>(null);

  const isCampaignBanner = useMemo(
    () => bannerPromotion.campaignBannerType === CampaignBannerType.TIMER,
    [bannerPromotion.campaignBannerType],
  );

  const classes = useStyles(isCampaignBanner, isCarPage);

  const isShowTimer = useMemo(() => {
    if (bannerPromotion.hasOwnProperty('showTimer')) {
      return !!bannerPromotion?.showTimer;
    }

    return true;
  }, [bannerPromotion]);

  const image = useMemo(() => {
    return (
      (bannerPromotion?.image &&
        bannerPromotion?.image?.length > 0 &&
        bannerPromotion?.image) ||
      (bannerPromotion?.carPageBannerImage &&
      'url' in bannerPromotion.carPageBannerImage
        ? (bannerPromotion.carPageBannerImage.url as string)
        : '') ||
      ''
    );
  }, [bannerPromotion]);

  useEffect(() => {
    if (!isCampaignBanner) {
      setTimeLeft(null);
      return;
    }

    const { startDate, endDate } = bannerPromotion;

    if (
      startDate &&
      endDate &&
      isWithinDateRange(new Date(startDate), new Date(endDate)) &&
      isShowTimer
    ) {
      const updateTimer = () => {
        const timeLeft = calculateTimeLeft(new Date(endDate));
        setTimeLeft(timeLeft);
      };

      updateTimer();

      const timer = setInterval(updateTimer, 1000);

      return () => clearInterval(timer);
    } else {
      close(true);
    }
  }, [
    bannerPromotion.startDate,
    bannerPromotion.endDate,
    isCampaignBanner,
    close,
    bannerPromotion,
    isShowTimer,
  ]);

  const handleBannerPromotionClicked = useCallback(() => {
    events.sendEvent(
      {
        action: EActionEvent.PROMOTION_BANNER,
        category: ECategoryEvent.PROMOTION_BANNER,
      },
      {
        leadLocation: LEAD_LOCATION.PromotionBanner,
      },
    );
    events.sendEvent(
      {
        action: EActionEvent.LEAD_LOAD,
        category: ECategoryEvent.LEAD,
      },
      {
        leadLocation: LEAD_LOCATION.PromotionBanner,
      },
    );
    if (
      bannerPromotion.bannerOption === BANNER_OPTION.LINK &&
      bannerPromotion.link
    ) {
      const bannerUrl = currentParams
        ? `${bannerPromotion.link}&${currentParams.toString()}`
        : bannerPromotion.link;
      router.push(bannerUrl);
    } else if (bannerPromotion.bannerOption === BANNER_OPTION.LEAD) {
      openContactUsModal({
        flow: EContactUsFlow.LEAVE_DETAILS,
        campaignNumber: bannerPromotion.regularLeadDetails?.campaignNumber,
        lineOfBusiness: bannerPromotion.regularLeadDetails?.lineOfBusiness,
        subMedia: bannerPromotion.regularLeadDetails?.subMedia,
        note: bannerPromotion.regularLeadDetails?.note,
        source: ESourceEventOptions.PROMOTION_BANNER,
      });
    } else if (bannerPromotion.bannerOption === BANNER_OPTION.BRANDS_LEAD) {
      openContactUsModal({
        flow: EContactUsFlow.LEAVE_DETAILS,
        leadLocation: LEAD_LOCATION.PromotionBanner,
        isBrandsLead:
          bannerPromotion.bannerOption === BANNER_OPTION.BRANDS_LEAD,
        brandsLeadDataDropdownOptions:
          bannerPromotion.brandsLeadDropdownOptions,
        source: ESourceEventOptions.PROMOTION_BANNER,
      });
    }
  }, [bannerPromotion, events, openContactUsModal, currentParams, router]);

  // Check if the banner should be shown
  if (
    isCampaignBanner &&
    (!bannerPromotion.startDate ||
      !bannerPromotion.endDate ||
      !isWithinDateRange(
        new Date(bannerPromotion.startDate),
        new Date(bannerPromotion.endDate),
      ))
  ) {
    return null;
  }

  const titleStyle = titleBannerPromotionStyle(
    isCampaignBanner ?? false,
    isCarPage,
  );

  const descriptionStyle = descriptionBannerPromotionStyle(
    isCampaignBanner ?? false,
    isCarPage,
  );
  const renderOnlineBuyBanner = () => {
    return (
      <Box sx={classes.onlineBuyBg} position={'relative'}>
        {image && <ImageBannerPromotionOnlineBuyStyle src={image} alt="" />}
        <Box sx={classes.onlineBuyContentSection}>
          {!isMobile ? (
            <Box sx={classes.onlineBuyTitleContainer}>
              {bannerPromotion.bannerTitle && (
                <Typography variant="h2" sx={classes.onlineBuyTitleStyle}>
                  {bannerPromotion.bannerTitle}
                  {bannerPromotion.bannerDescription && (
                    <Typography sx={classes.onlineBuyDescriptionStyle}>
                      {bannerPromotion.bannerDescription}
                      {bannerPromotion.isDescriptionIncludeFreesbeLogo && (
                        <Box component="span" sx={classes.onlineBuyFreesbeLogo}>
                          <BlackFreesbeLogoIcon
                            Component={FreesbeLogo}
                            style={{ height: '32px' }}
                          />
                        </Box>
                      )}
                    </Typography>
                  )}
                </Typography>
              )}
            </Box>
          ) : (
            <>
              {bannerPromotion.bannerTitle && (
                <Box sx={classes.onlineBuyTitleContainer}>
                  <Typography variant="h3" sx={classes.onlineBuyTitleStyle}>
                    {bannerPromotion.bannerTitle}
                  </Typography>
                </Box>
              )}

              {bannerPromotion.bannerDescription && (
                <Box sx={classes.onlineBuyTitleContainer}>
                  <Typography
                    variant="h5"
                    sx={classes.onlineBuyDescriptionStyle}
                  >
                    {bannerPromotion.bannerDescription}
                    {bannerPromotion.isDescriptionIncludeFreesbeLogo && (
                      <Box component="span" sx={classes.onlineBuyFreesbeLogo}>
                        <BlackFreesbeLogoIcon Component={FreesbeLogo} />
                      </Box>
                    )}
                  </Typography>
                </Box>
              )}
            </>
          )}
          {bannerPromotion.subtitle && (
            <Box sx={classes.onlineBuySubTitleContainer}>
              <Box sx={classes.sunLightIcon}>
                <Image
                  src={ThreeSunLight}
                  alt="sun light icon"
                  objectFit="fill"
                  style={{ height: '100%', width: '100%' }}
                />
              </Box>
              <Typography variant="h5" sx={classes.onlineBuySubTitleStyle}>
                {bannerPromotion.subtitle}
              </Typography>
              <Box sx={classes.yellowStartIcon}>
                <Image
                  src={YellowStartIcon}
                  alt="yellow start icon"
                  objectFit="fill"
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderBanners = () => {
    switch (bannerPromotion.campaignBannerType) {
      case CampaignBannerType.ONLINE_BUY:
        return renderOnlineBuyBanner();
      default:
        return (
          <BoxBannerContainer
            sx={sx}
            isMoreFiltersOpen={isMoreFiltersOpen}
            isCampaignBanner={isCampaignBanner ?? false}
            isCarPage={isCarPage}
            skinColor={bannerPromotion.skinColor}
          >
            {showCloseButton ||
              (!isCarPage && (
                <span onClick={() => close(true)} tabIndex={0}>
                  <CloseIcon Component={CloseButtonIcon} />
                </span>
              ))}
            <>
              {image ? (
                <Box sx={classes.imageSection}>
                  <ImageBannerPromotionStyle src={image} alt="" />
                </Box>
              ) : (
                <Box
                  sx={{
                    ...classes.imageSection,
                    minHeight: '100px',
                    backgroundColor: '#f0f0f0',
                  }}
                ></Box>
              )}
              <Box sx={classes.bannerDetails}>
                <Box sx={classes.contentSection}>
                  <Typography variant="h4" sx={titleStyle}>
                    {bannerPromotion.bannerTitle}
                  </Typography>
                  <Typography variant="h5" sx={descriptionStyle}>
                    {bannerPromotion.bannerDescription}
                  </Typography>

                  {!isMobile && !isCarPage && bannerPromotion.tooltip && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                    >
                      <Typography sx={toolTipText}>
                        {bannerPromotion.policyLink ? (
                          <Box
                            component="a"
                            href={bannerPromotion.policyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={PolicyLinkBoxStyle}
                          >
                            {RESOURCES.TOOLTIP_BANNER}
                          </Box>
                        ) : (
                          RESOURCES.TOOLTIP_BANNER
                        )}
                      </Typography>
                      {bannerPromotion.tooltip && (
                        <TooltipInfo
                          infoText={bannerPromotion.tooltip}
                          placement="bottom-start"
                          customStyle={tooltipStyle}
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={bannerContentStyle(
                    isCarPage,
                    isCampaignBanner,
                    isShowTimer,
                  )}
                >
                  {isCampaignBanner && timeLeft && isShowTimer && (
                    <Box sx={boxContainer(isCarPage)}>
                      {!isCarPage && (
                        <Typography
                          sx={{
                            fontSize: '16px',
                            marginRight: isMobile ? '8px' : '0',
                            marginTop: isMobile ? '10px' : 'unset',
                          }}
                        >
                          {isMobile
                            ? RESOURCES.TIME_TO_END_MOBILE
                            : RESOURCES.TIME_TO_END}
                        </Typography>
                      )}
                      <Timer
                        hours={timeLeft.hours}
                        minutes={timeLeft.minutes}
                        seconds={timeLeft.seconds}
                        days={timeLeft?.days}
                      />
                    </Box>
                  )}

                  {!isMobile &&
                    isCampaignBanner &&
                    (bannerPromotion.skinColor ===
                    BannerPromotionSkinColor.AquaSkin ? (
                      <Box
                        component={giftCampaign}
                        sx={{
                          ...giftIconStyle(isCarPage, isShowTimer),
                        }}
                      />
                    ) : (
                      <Box
                        component={yellowLightCampaignBannerIcon}
                        sx={{
                          ...yellowLightCampaignBannerIconStyle(isShowTimer),
                        }}
                      />
                    ))}
                </Box>
                <Box
                  sx={{
                    marginTop: isMobile && isCampaignBanner ? '20px' : 'unset',
                  }}
                >
                  {isCampaignBanner && isCarPage && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}
                    >
                      <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                        {bannerPromotion.policyLink ? (
                          <Box
                            component="a"
                            href={bannerPromotion.policyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={PolicyLinkBoxStyle}
                          >
                            {RESOURCES.TOOLTIP_BANNER}
                          </Box>
                        ) : (
                          RESOURCES.TOOLTIP_BANNER
                        )}
                      </Typography>
                      {bannerPromotion.tooltip && (
                        <TooltipInfo
                          infoText={bannerPromotion.tooltip}
                          placement="bottom-start"
                          customStyle={tooltipStyle}
                        />
                      )}
                      {!isMobile &&
                        bannerPromotion.legalContent &&
                        !bannerPromotion.tooltip && (
                          <Typography sx={legalContentStyle}>
                            {bannerPromotion.legalContent}
                          </Typography>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
              {!isCarPage && bannerPromotion.bannerButton && (
                <Button
                  label={bannerPromotion.bannerButton}
                  onClickFunc={handleBannerPromotionClicked}
                  size={BUTTON_SIZES.LARGE}
                  type={BUTTON_TYPES.MAIN_YELLOW}
                  customStyle={buttonBannerPromotionStyle(isCampaignBanner)}
                />
              )}
              {isMobile && !isCarPage && bannerPromotion.tooltip && (
                <Box sx={mobileTooltip}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {bannerPromotion.legalContent}
                  </Typography>
                  {bannerPromotion.tooltip && (
                    <TooltipInfo
                      infoText={bannerPromotion.tooltip}
                      placement="bottom-start"
                      customStyle={tooltipStyle}
                    />
                  )}
                </Box>
              )}
              {isMobile &&
                isCampaignBanner &&
                (bannerPromotion.skinColor ===
                BannerPromotionSkinColor.AquaSkin ? (
                  <Box
                    component={giftCampaign}
                    sx={{
                      ...giftIconStyle(isCarPage, isShowTimer),
                    }}
                  />
                ) : (
                  <Box
                    component={yellowLightCampaignBannerIcon}
                    sx={{
                      ...yellowLightCampaignBannerIconStyle(isShowTimer),
                    }}
                  />
                ))}
            </>
          </BoxBannerContainer>
        );
    }
  };

  return renderBanners();
};

export default ResultsPageBannerPromotion;
