export const getCarId = (params: string | string[], pathname: string) => {
  const car = typeof params === 'string' ? params : params[0];

  const pathnameArr = car.split(`-c`);
  const regex = /^-?\d+(\.\d+)?$/;
  const newId = pathnameArr[pathnameArr.length - 1];

  const carId = regex.test(newId) ? newId : pathname.split(`/`)[2];

  return carId;
};

export const getCarIdByPath = (path: string) => {
  const match = path.match(/-c(\d+)/);
  return match ? parseInt(match[1], 10) : null;
};
