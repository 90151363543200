import { HttpClientService } from '~/services/HttpsClientService';
import {
  IOrderDetails,
  IUsedCarOrderDetails,
} from '~/data/types/carFlow/carFlowTypes';
import {
  ICreateTransactionResponse,
  ITransactionCrypto,
} from '~/data/PaymentLayout/interfaces';

class PaymentAdapter {
  static readonly endpointTransactions = `/api/transactions`;

  static readonly getTransactionByIdEndpoint = `${PaymentAdapter.endpointTransactions}/getById`;

  // eslint-disable-next-line class-methods-use-this
  async createTransaction(
    orderDetails: IOrderDetails | IUsedCarOrderDetails,
  ): Promise<ICreateTransactionResponse> {
    const res = await HttpClientService.post<ICreateTransactionResponse>(
      `${PaymentAdapter.endpointTransactions}`,
      {
        orderDetails,
        withHandshake: true,
      },
    );

    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getTransactionById(
    transactionId: string,
  ): Promise<ITransactionCrypto | null> {
    try {
      const res = await HttpClientService.post<ITransactionCrypto>(
        PaymentAdapter.getTransactionByIdEndpoint,
        {
          transactionId,
        },
      );

      return res.data ?? null;
    } catch (err) {
      console.log(
        'Error - [getIsFinishCallbackById:PaymentLayoutService.ts]',
        err,
      );
      return null;
    }
  }
}

const paymentAdapter = new PaymentAdapter();

export default paymentAdapter;
