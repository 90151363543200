import React from 'react';
import { ImageFile } from '~/data/types/images';

const CheckedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill="#00D6D1"
        stroke="#00D6D1"
      />
      <rect
        x="3.1665"
        y="3.16663"
        width="9.66667"
        height="9.66667"
        fill="#00D6D1"
      />
      <path
        d="M5.81314 8.00004C5.68804 7.88753 5.52502 7.82649 5.35679 7.82915C5.18856 7.83182 5.02756 7.898 4.90608 8.01441C4.7846 8.13083 4.71164 8.28887 4.70182 8.45683C4.692 8.6248 4.74606 8.79027 4.85314 8.92004L6.33314 10.4734C6.39511 10.5384 6.46956 10.5902 6.55205 10.6257C6.63453 10.6612 6.72334 10.6797 6.81314 10.68C6.90247 10.6806 6.99099 10.6631 7.07345 10.6288C7.1559 10.5944 7.23061 10.5438 7.29314 10.48L11.8131 5.81337C11.8744 5.75034 11.9227 5.67585 11.9552 5.59416C11.9877 5.51247 12.0038 5.42519 12.0025 5.33728C12.0013 5.24937 11.9828 5.16257 11.948 5.08183C11.9132 5.00109 11.8628 4.92799 11.7998 4.86671C11.7368 4.80542 11.6623 4.75716 11.5806 4.72466C11.4989 4.69216 11.4116 4.67607 11.3237 4.67731C11.2358 4.67855 11.149 4.69709 11.0683 4.73188C10.9875 4.76666 10.9144 4.81701 10.8531 4.88004L6.81981 9.05337L5.81314 8.00004Z"
        fill="#262626"
      />
      <rect
        x="3.1665"
        y="3.16663"
        width="9.66667"
        height="9.66667"
        stroke="#00D6D1"
      />
    </Component>
  );
};

export default CheckedIcon;
