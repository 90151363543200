import { HttpClientService } from '~/services/HttpsClientService';
import { AxiosResponse } from 'axios';
import { MetaType } from '~/data/types/paginationTypes';
import {
  BannerPromotionData,
  CarBodyTypeData,
  carDescriptionHighlights,
  FamilyCar,
  IPromotionCardData,
  ISearchCar,
} from '~/data/types/car/cars';
import {
  IFamilyCarsPromoRes,
  ISearchCarsQuery,
} from '~/data/types/resultPageType';
import { seoResultPage } from '~/data/types/seo/seoResutlsPages';

interface getResultPageContentResType {
  meta: MetaType;
  data: FamilyCar[];
}
interface getResultPageCarsContentResType {
  data: ISearchCar[];
}

interface getBannerPromotionResType {
  meta: MetaType;
  data: BannerPromotionData[];
}
interface getPromotionCardsResType {
  meta: MetaType;
  data: IPromotionCardData[];
}
class ResultPageAdapter {
  static readonly endpointCars = `/api/family-cars?populate=*`;

  static readonly endpointSearchCars = `/api/search-cars`;

  static readonly endpointSearchLeaseCars = `/api/search-lease-cars`;

  static readonly endpointFilters = `/api/filters/`;

  static readonly endpointBanner = `/api/promoton-banners?populate=*`;

  static readonly endpointCardsPromotion = `/api/promotion-cards?populate=*`;

  static readonly endpointHighlights =
    '/api/car-highlights?filters[isNewCar][$eqi]=true&populate[0]=icon';

  static readonly endpointNewCarBodyType =
    '/api/car-body-types?filters[isNewCar][$eqi]=true&populate[0]=icon';

  static readonly endpointLeaseCarBodyType =
    '/api/car-body-types?filters[isLeaseCar][$eqi]=true&populate[0]=icon';

  static readonly endpointSeoResultsPage = `/api/seo-results-page/?populate=deep,4`;

  static readonly endpointPromotionCampaignCards =
    '/api/promotion-campaign-cards?populate=*';
  static readonly endpointSeoProgModelContent = `/api/used-car-programmatic`;
  static readonly endpointFamilyCarPromos = `/api/family-car-promos?populate=*`;

  // eslint-disable-next-line class-methods-use-this
  async getResultPageContent(
    params: string,
  ): Promise<getResultPageContentResType> {
    const res = await HttpClientService.get(
      `${ResultPageAdapter.endpointCars}${params}`,
    );

    return res.data as getResultPageContentResType;
  }

  // eslint-disable-next-line class-methods-use-this
  async getResultPageCarsContent(
    query: ISearchCarsQuery,
  ): Promise<getResultPageCarsContentResType> {
    const res = await HttpClientService.post(
      ResultPageAdapter.endpointSearchCars,
      query,
    );

    return res.data as getResultPageCarsContentResType;
  }

  // eslint-disable-next-line class-methods-use-this
  async getFiltersOptions(filterType: string): Promise<AxiosResponse> {
    const res = await HttpClientService.get(
      `${ResultPageAdapter.endpointFilters}${filterType}`,
    );

    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  async getBannerPromotion(target: string): Promise<BannerPromotionData[]> {
    const res = await HttpClientService.get<getBannerPromotionResType>(
      `${ResultPageAdapter.endpointBanner}&filters[target][$eq]=${target}&sort=rank:asc&pagination[limit]=4`,
    );
    return res.data.data.map((data: BannerPromotionData) => ({
      ...data,
      regularLeadDetails: data?.attributes?.regularLeadDetails || null,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  async getCardsPromotion(target: string): Promise<IPromotionCardData[]> {
    const res = await HttpClientService.get<getPromotionCardsResType>(
      `${ResultPageAdapter.endpointCardsPromotion}&filters[target][$eq]=${target}`,
    );

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getHighlightsFilterOptions(): Promise<carDescriptionHighlights[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: carDescriptionHighlights[];
    }>(`${ResultPageAdapter.endpointHighlights}`);
    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCarBodyTypeFilterOptions(): Promise<CarBodyTypeData[]> {
    const res = await HttpClientService.get<{
      meta: MetaType;
      data: CarBodyTypeData[];
    }>(`${ResultPageAdapter.endpointNewCarBodyType}&populate=deep,2`);

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSeoContent(target: string): Promise<any> {
    const res = await HttpClientService.get<seoResultPage>(
      `${ResultPageAdapter.endpointSeoResultsPage}&filters[page][name]=${target}`,
    );

    return res.data.data;
  }

  async getPromotionCampaignCards(): Promise<any> {
    const res = await HttpClientService.get(
      ResultPageAdapter.endpointPromotionCampaignCards,
    );
    return res.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getSeoProgModelContent(modelName: string): Promise<any> {
    const res = await HttpClientService.post<seoResultPage>(
      `${ResultPageAdapter.endpointSeoProgModelContent}`,
      { modelName },
    );

    return res.data.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getFamilyCarsPromo(): Promise<any> {
    const res = await HttpClientService.get<IFamilyCarsPromoRes>(
      `${ResultPageAdapter.endpointFamilyCarPromos}`,
    );

    return res.data.data;
  }
}

const resultPageAdapter = new ResultPageAdapter();

export default resultPageAdapter;
