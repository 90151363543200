import { COLORS } from '~/data/ui/colors';
import BannerDesktop from '~/assets/images/BannerDesktop.svg';
import BannerMobile from '~/assets/images/BannerMobile.svg';
import theme from '~/theme';
import React from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { SxProps, Theme } from '@mui/material/styles';
import { BannerPromotionSkinColor } from '~/data/types/car/cars';

const useStyles = (isCampaignBanner: boolean, isCarPage: boolean) => ({
  root: () => ({
    display: 'flex',
    backgroundColor: `${COLORS.primary.main}`,
    backgroundImage: `url(${BannerMobile.src})`,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: isCarPage ? 'column' : 'row',
    width: '100%',
    minHeight: '100px',
    maxHeight: '240px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    gap: '3%',
    [theme.breakpoints.up('md')]: {
      gap: '16px',
      padding: '8px 0',
      width: '100%',
      backgroundImage:
        isCarPage || isCampaignBanner ? '#00D6D1' : `url(${BannerDesktop.src})`,
      marginTop: '0',
    },
  }),
  bannerDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '50px',
    padding: 'unset',
    flex: 5,
    [theme.breakpoints.up('md')]: {
      flexDirection: isCarPage ? 'column' : 'row',
      alignItems: isCarPage ? 'unset' : 'center',
      padding: '0',
    },
  },
  imageSection: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '120px',
    height: '100%',
    marginRight: '20px',
    flex: 2,

    [theme.breakpoints.up('md')]: {
      display: isCampaignBanner ? 'none' : 'flex',
      maxWidth: '200px',
      margin: '0px 2px ',
      flex: 1,
    },
  },
  contentSection: {
    marginTop: '10px',
    maxWidth: '100%',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
    },
  },
  buttonSection: {
    display: 'flex',

    marginTop: '6px',
    width: 'fit-content',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      marginLeft: '56px',
    },
  },
  imageBannerPromotionStyle: {
    maxWidth: '100px',
    maxHeight: '80px',
    borderRadius: '6px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '150px',
    },
  },
  onlineBuyBg: {
    display: 'flex',
    width: '100%',
    height: '100%',
    minHeight: '125px',
    backgroundColor: '#00D6D1',
    overflow: 'hidden',
    '&::after': {
      transform: 'rotate(335deg)',
      bottom: '-54px',
      content: '""',
      position: 'absolute',
      right: '-92px',
      width: '356.32px',
      height: '117.11px',
      left: '-1151.54px',
      background: ' #FFF9CC',
    },
    [theme.breakpoints.down('md')]: {
      '&::after': {
        content: 'none',
      },
    },
  },
  onlineBuyContentSection: {
    padding: '20px 280px 20px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      padding: '17px 24px',
      gap: '4px',
      alignItems: 'flex-start',
    },
  },
  onlineBuyTitleContainer: {
    padding: '0px 8px',
    gap: '10px',
    borderRadius: '4px',
    background: '#FFFEFE',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      padding: '0px 8px',
      height: 'auto',
    },
  },
  onlineBuyTitleStyle: {
    fontSize: '30px',
    fontWeight: '400',

    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      minHeight: '24px',
    },
  },
  onlineBuyDescriptionStyle: {
    fontSize: '32px',
    fontWeight: 'bold',
    display: 'inline',
    alignItems: 'center',
    gap: '8px',
    marginRight: '8px',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
      minHeight: '36px',
      margin: '0',
    },
  },
  onlineBuyFreesbeLogo: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    top: '8px',
    right: '-4px',
    [theme.breakpoints.down('md')]: {
      right: '4px',
    },
  },
  onlineBuySubTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  onlineBuySubTitleStyle: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      marginTop: '10px',
    },
  },
  sunLightIcon: {
    transform: 'rotate(354deg)',
    position: 'absolute',
    right: '-24px',
    top: '-8px',
    [theme.breakpoints.down('md')]: {
      top: '-4px',
      right: '-15px',
      height: '25px',
      width: '16px',
    },
  },
  yellowStartIcon: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginRight: '4px',
    },
  },
});

export const CloseButtonIcon = styled('svg')(() => ({
  position: 'absolute',
  maxHeight: '32px',
  maxWidth: '32px',
  top: '10px',
  left: '8px',
  cursor: 'pointer',
}));
export const BoxBannerContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isMoreFiltersOpen' &&
    prop !== 'isCampaignBanner' &&
    prop !== 'isCarPage' &&
    prop !== 'skinColor',
})<{
  isMoreFiltersOpen: boolean;
  isCampaignBanner: boolean;
  isCarPage: boolean;
  skinColor?: BannerPromotionSkinColor;
}>(({ isMoreFiltersOpen, isCampaignBanner, isCarPage, skinColor }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor:
    isCarPage || isCampaignBanner
      ? skinColor === BannerPromotionSkinColor.LightYellowSkin
        ? `${COLORS.yellow.light1}`
        : '#00D6D1'
      : `${COLORS.primary.main}`,
  backgroundImage:
    isCarPage || isCampaignBanner ? 'none' : `url(${BannerMobile.src})`,
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  height: 'fix-content',
  minHeight: '100px',
  maxHeight: '240px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  gap: '3%',
  padding:
    isCampaignBanner && !isCarPage
      ? '12px 21px'
      : isCarPage
      ? '6px 24px'
      : '8px 24px',
  paddingBottom: isCampaignBanner && isCarPage ? '10px' : '',
  marginTop: '0px !important',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    gap: '16px',
    padding:
      isCampaignBanner && !isCarPage
        ? '15px 0'
        : isCarPage && isCampaignBanner
        ? '6px 16px'
        : '8px 0',
    width: '100%',
    backgroundColor:
      isCarPage || isCampaignBanner
        ? skinColor === BannerPromotionSkinColor.LightYellowSkin
          ? `${COLORS.yellow.light1}`
          : '#00D6D1'
        : `${COLORS.primary.main}`,
    backgroundImage:
      isCarPage || isCampaignBanner ? 'none' : `url(${BannerDesktop.src})`,
    marginTop: isMoreFiltersOpen
      ? 'unset'
      : !isCarPage && isCampaignBanner
      ? '8px !important'
      : '0px',
    flexDirection: 'row',
  },
}));

export const buttonBannerPromotionStyle = (
  isCampaignBanner: boolean,
): React.CSSProperties => ({
  width: '130px',
  height: '32px !important',
  padding: '10px',
  marginTop: isCampaignBanner ? '12px' : 'unset',
  [theme.breakpoints.up('md')]: {
    width: '230px',
    height: '48px !important',
    marginLeft: '100px',
    marginTop: 'unset',
    marginRight: 'unset',
  },
});

export const giftIconStyle = (isCarPage: boolean, isTimer: boolean) => ({
  position: isTimer ? 'relative' : 'absolute',
  width: '70px',
  marginTop: isCarPage ? 'unset' : '10px',
  transform:
    isCarPage && !isTimer
      ? 'translate(-300px, 31%)'
      : isCarPage
      ? 'translateY(90%)'
      : 'translateY(60%)',
  [theme.breakpoints.down('md')]: {
    transform: isCarPage ? 'translateY(60%)' : 'translateY(70%)',

    position: 'absolute',
    width: !isCarPage ? '80px' : '70px',
    marginTop: '5px',
    bottom: isCarPage ? 'unset' : '45px',
    left: '10px',
  },
});

export const yellowLightCampaignBannerIconStyle = (
  isTimer: boolean,
): SxProps<Theme> => ({
  position: 'absolute',
  zIndex: 0,
  height: '114px',
  left: '-15px',
  top: isTimer ? '-55px' : '-61px',
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    height: '130px',
    left: '0px',
    top: '0px',
  },
  '@media (max-width: 425px)': {
    width: isTimer ? '160px' : 'auto',
    top: isTimer ? '-10px' : '0px',
  },
});

export const boxContainer = (isCarPage: boolean) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: isCarPage ? '12px 0px' : 'unset',
  [theme.breakpoints.down('md')]: {
    padding: '12px 0px',
    flexDirection: 'row-reverse',
  },
});

export const bannerContentStyle = (
  isCarPage: boolean,
  isCampaignBanner: boolean,
  isTimer: boolean,
) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: '30px',
  // paddingLeft: isCarPage ? 'unset' : '125px',
  [theme.breakpoints.down('md')]: {
    gap: '5px',
    alignItems: 'flex-start',
    height: isCampaignBanner && isTimer ? '48px !important' : 'auto',
    paddingLeft: '20px',
    flexDirection: 'column',
    paddingTop: !isCampaignBanner && !isCarPage ? '10px' : 'unset',
  },
});

export const mobileTooltip = {
  display: 'flex',
  alignItems: 'center',
  padding: '5px 0px',
  gap: '6px',
  [theme.breakpoints.up('md')]: {
    padding: '2px 0',
  },
};

export const titleBannerPromotionStyle = (
  isCampaignBanner: boolean,
  isCarPage: boolean,
): React.CSSProperties => ({
  fontWeight: isCampaignBanner ? '400px' : 'bold',
  fontSize: isCampaignBanner ? '14px' : isCarPage ? '14px' : '18px',
  [theme.breakpoints.up('md')]: {
    fontSize:
      isCampaignBanner && !isCarPage ? '16px' : isCarPage ? '14px' : '22px',
  },
});

export const descriptionBannerPromotionStyle = (
  isCampaignBanner: boolean,
  isCarPage: boolean,
): React.CSSProperties => ({
  fontWeight:
    isCarPage && isCampaignBanner
      ? '600'
      : isCampaignBanner && !isCarPage
      ? 'bold'
      : '400',
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    fontSize:
      isCampaignBanner && isCarPage
        ? '16px'
        : isCampaignBanner && !isCarPage
        ? '32px'
        : '18px',
  },
});
export const legalContentStyle = {
  fontSize: '10px',
  paddingTop: '8px',
};

export const tooltipStyle = {
  width: '12px',
  height: '12px',
  marginBottom: '2px',
};

export const ImageBannerPromotionStyle = styled('img')(() => ({
  maxWidth: '100px',
  maxHeight: '80px',
  borderRadius: '6px',
  [theme.breakpoints.up('md')]: {
    maxWidth: '150px',
  },
}));
export const ImageBannerPromotionOnlineBuyStyle = styled('img')(() => ({
  width: '180px',
  maxHeight: '370px',
  overflow: 'hidden',
  // marginTop: '20px',
  // marginRight: '20px',
  zIndex: 1,
  position: 'absolute',
  right: '20px',
  bottom: '0px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const PolicyLinkBoxStyle = {
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'inherit',
};

export const toolTipText = {
  fontSize: '14px',
  fontWeight: 400,
};
export const OnlineBuyBg = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
export const FreesbeLogo = styled('svg')({
  width: '125.6px',
  height: '36.58px',
  [theme.breakpoints.down('md')]: {
    width: '98.32px',
    height: '28.63px',
  },
});
export default useStyles;
