import { Box, FormGroup, Typography } from '@mui/material';
import RESOURCES from '~/resources';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import {
  setEmail,
  setFirstName,
  setLastName,
  setMailsApprove,
  setPhoneNumber,
} from '~/redux-toolkit/slices/approvalFlowSlice';
import {
  EOnKeyDownValidation,
  useValidateOnKeyDown,
} from '~/hooks/useValidateOnKeyDown';
import { useCallback } from 'react';
import FormValidation from '~/Validators/FormValidation';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux-toolkit/store/store';
import TermsCheckbox from '~/components/TermsCheckbox';
import useStyles, {
  buttonCustomStyle,
  formGroupStyle,
  helperTextStyle,
  iAgreeTextStyle,
} from './useStyles';
import { IUseStyle } from '~/components/ContactUsPopup/components/ScheduleMeeting/useStyles';
import CustomTextField from '~/components/CustomTextFieldV2';
import PhoneIconIcon from '~/assets/icons/jsx/PhoneIcon';
import EmailIcon from '~/assets/images/jsx/email';

const ContactUsStepForm = () => {
  const classes: IUseStyle = useStyles();
  const dispatch = useAppDispatch();

  const { firstName, lastName, email, phone } = useSelector(
    (state: RootState) => state.approvalFlow.details,
  );

  const validateOnKeyDownLettersOnly = useValidateOnKeyDown(
    EOnKeyDownValidation.LETTERS,
  );
  const validateOnKeyDownDigitsOnly = useValidateOnKeyDown(
    EOnKeyDownValidation.DIGITS,
  );

  const onChangeFirstName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setFirstName(e.target.value));
    },
    [dispatch],
  );
  const onChangeLastName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setLastName(e.target.value));
    },
    [dispatch],
  );

  const onChangePhoneNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPhoneNumber(e.target.value));
    },
    [dispatch],
  );
  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setEmail(e.target.value));
    },
    [dispatch],
  );
  const onMailsApproved = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setMailsApprove(e.target.checked));
    },
    [dispatch],
  );

  return (
    <FormGroup sx={formGroupStyle}>
      <fieldset style={classes.fieldSetStyle}>
        <legend style={classes.visuallyHidden}>
          {RESOURCES.LEAVE_DETAILS_FORM}
        </legend>
        <Box sx={classes.inputsWrapper}>
          <div style={buttonCustomStyle}>
            <CustomTextField
              required
              fullWidth
              label={RESOURCES.DETAILS_FIRST_NAME}
              ariaLabel={RESOURCES.DETAILS_FIRST_NAME}
              placeholder={RESOURCES.DETAILS_FIRST_NAME}
              onChange={onChangeFirstName}
              onKeyDown={validateOnKeyDownLettersOnly}
              error={!FormValidation.validateTextAndEmptySpaces(firstName)}
              helperText={RESOURCES.VALIDATION_HELPER_TEXT_INVALID_FIRST_NAME}
              value={firstName}
              customStyle={helperTextStyle}
            />
          </div>
          <div style={buttonCustomStyle}>
            <CustomTextField
              required
              fullWidth
              label={RESOURCES.DETAILS_LAST_NAME}
              ariaLabel={RESOURCES.DETAILS_LAST_NAME}
              placeholder={RESOURCES.DETAILS_LAST_NAME}
              onChange={onChangeLastName}
              onKeyDown={validateOnKeyDownLettersOnly}
              error={!FormValidation.validateTextAndEmptySpaces(lastName)}
              helperText={RESOURCES.VALIDATION_HELPER_TEXT_INVALID_LAST_NAME}
              value={lastName}
              customStyle={helperTextStyle}
            />
          </div>
        </Box>
        <Box sx={classes.inputsWrapper}>
          <div style={buttonCustomStyle}>
            <CustomTextField
              required
              fullWidth
              label={RESOURCES.APPROVAL_CONTACT_US_PHONE}
              ariaLabel={RESOURCES.APPROVAL_CONTACT_US_PHONE}
              placeholder={RESOURCES.APPROVAL_CONTACT_US_PHONE}
              type="tel"
              icon={<PhoneIconIcon style={classes.iconStyle} />}
              onChange={onChangePhoneNumber}
              onKeyDown={validateOnKeyDownDigitsOnly}
              error={!FormValidation.validateIsraeliCellPhone(phone)}
              helperText={RESOURCES.VALIDATION_HELPER_TEXT_INVALID_PHONE_NUMBER}
              value={phone}
              customStyle={helperTextStyle}
              inputMode="numeric"
            />
          </div>
          <div style={buttonCustomStyle}>
            <CustomTextField
              fullWidth
              label={RESOURCES.APPROVAL_CONTACT_US_EMAIL}
              ariaLabel={RESOURCES.APPROVAL_CONTACT_US_EMAIL}
              placeholder={RESOURCES.APPROVAL_CONTACT_US_EMAIL}
              icon={<EmailIcon style={classes.iconStyle} />}
              onChange={onChangeEmail}
              error={
                (email && !FormValidation.validateEmailEnglishOnly(email)) ||
                false
              }
              helperText={RESOURCES.VALIDATION_HELPER_TEXT_INVALID_EMAIL}
              value={email}
              customStyle={helperTextStyle}
            />
          </div>
        </Box>
        <Box sx={classes.checkboxesContainer}>
          <TermsCheckbox
            title={RESOURCES.PAYMENT_FLOW_USER_DETAILS_CHECKBOX_EMAIL}
            onClick={(e) => onMailsApproved(e)}
          />
          <Typography
            variant="body2"
            sx={iAgreeTextStyle}
            id="contact-declaimer"
          >
            {RESOURCES.FORM_CHECKBOX_APPROVE_PHONE_CALLS}
          </Typography>
        </Box>
      </fieldset>
    </FormGroup>
  );
};

export default ContactUsStepForm;
