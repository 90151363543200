import RESOURCES from '~/resources';

export const AGE_OPTIONS = [
  { label: RESOURCES.APPROVAL_ANSWER_YES, value: 'כן' },
  { label: RESOURCES.APPROVAL_ANSWER_NO, value: 'לא' },
];

export const YOUR_PAST_OPTIONS = [
  { label: RESOURCES.APPROVAL_ANSWER_YES_WAS, value: 'כן' },
  { label: RESOURCES.APPROVAL_ANSWER_NO_WAS, value: 'לא' },
];

export const EMPLOY_STATUS = {
  title: RESOURCES.APPROVAL_STATUS_TITLE,
  options: [
    RESOURCES.APPROVAL_OPTION_EMPLOYEE,
    RESOURCES.APPROVAL_OPTION_SELF_EMPLOYEE,
    RESOURCES.APPROVAL_OPTION_PENSIONER,
    RESOURCES.APPROVAL_OPTION_UNEMPLOYED,
  ],
};
export const SALARY_INCOME = {
  title: RESOURCES.APPROVAL_SALARY_INCOM_TITLE,
  options: ['ללא', '₪עד 5,500 ', '₪5,500-10,000', 'מעל ₪10,000'],
};
