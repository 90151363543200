import { createSlice } from '@reduxjs/toolkit';
import { resultPageCarFilterOptions } from '~/data/car/carFilterDataAndTypes';
import { PAYMENT_METHOD } from '~/data/searchFlowData';
import { mappedCarsCards, mappedSearchCarsCards } from '~/data/resultPage';
import {
  BrandAndModel,
  categoryType,
  IAllColors,
  IColorOptionData,
  OptionType,
  ResultPageState,
} from '~/data/types/resultPageType';
import {
  getBannerPromotion,
  getCardsPromotion,
  getFiltersOptions,
  getResultPageAppend,
  getResultPageCarsContent,
  getCarBodyTypeFilterOptions,
  getHighlightsFilterOptions,
  getSeoContent,
  getPromotionCampaignCards,
  getFamilyCarsPromo,
  getBannersPromotion,
} from '~/redux-toolkit/thunks/resultPageThunks';
import { optionsMockRP } from '~/data/resultsCarsDefaultData';
import { CAR_COLOR_HEX, CAR_COLOR_TEXT } from '~/data/car/carColors';
import { useSortObjectArr as sortObjectArr } from '~/hooks/useSortArr';
import { ECarData } from '~/data/ResultsPage/enums';
import RESOURCES from '~/resources';
import { removeDuplicatesOfBodyType } from '~/redux-toolkit/utils/filtersUtils';
import {
  CarBodyTypeData,
  ISearchCar,
  carDescriptionHighlights,
} from '~/data/types/car/cars';
import { HYDRATE } from 'next-redux-wrapper';
import { brandNamesFilterParams } from '~/utils/populateFiltersFromQuery';
import { CarLabelData } from '~/data/types/usedCar/usedCarData';

const initialState: ResultPageState = {
  query: {
    filters: {
      budget: {
        paymentMethod: PAYMENT_METHOD.monthly,
        priceRange: {
          minPrice: null,
          maxPrice: null,
        },
      },
      colors: [],
      categories: [],
      models: [],
      brands: [],
      carDescriptionHighlights: [],
      carBodyType: [],
    },
    pagination: {
      page: 1,
      pageSize: 200,
    },
    sortBy: resultPageCarFilterOptions[0],
    isAdditionFiltersSelected: false,
  },
  brandsAndModels: [],
  allModels: [],
  isOptionsFetched: false,
  options: optionsMockRP,
  pagination: {
    page: 1,
    pageSize: 20,
    pageCount: 0,
    total: 0,
  },
  prevPage: 0,
  carsCurrentPage: [],
  isFetch: true,
  cars: [],
  newCarsSortType: '',
  allColors: {},
  bannerPromotion: {
    isBannerAppears: false,
    bannerTitle: '',
    bannerDescription: '',
    bannerOption: '',
    bannerButton: '',
    campaign: '',
    image: '',
    legalContent: '',
  },
  bannersPromotion: null,
  seoPageData: null,
  promotionCards: [],
  isMoreFiltersOpen: false,
  paramType: 'new',
  manufacturerEnum: {},
  familyCarsPromo: [],
  familyCarPromo: null,
};

const resultPageSlice = createSlice({
  name: 'resultPageData',
  initialState,
  reducers: {
    setCars(state, action) {
      state.cars = action.payload;
    },
    setFilterOptionsModels(state, action) {
      const modelOptions = sortObjectArr([...action.payload], 'title');
      state.options.models.values = modelOptions;
      state.query.filters.models = state.query.filters.models.filter(
        (model) => {
          return action.payload.some(
            (option: OptionType) => option.title === model,
          );
        },
      );
    },
    setFiltersBudget(state, action) {
      state.query.filters.budget = action.payload;
    },
    setFiltersPriceRange(state, action) {
      state.query.filters.budget.priceRange = action.payload;
    },
    setFiltersColors(state, action) {
      state.query.filters.colors = action.payload;
    },
    setOptionsColorsValues(state, action) {
      state.options.colors.values = action.payload;
    },
    setFiltersBrands(state, action) {
      state.query.filters.brands = action.payload;
    },
    setFiltersModels(state, action) {
      state.query.filters.models = action.payload;
    },
    setFiltersHighLights(state, action) {
      state.query.filters.carDescriptionHighlights = action.payload;
    },
    setFiltersBodyType(state, action) {
      state.query.filters.carBodyType = action.payload;
    },
    setIsFetch(state, action) {
      state.isFetch = action.payload;
    },
    setFiltersSortBy(state, action) {
      state.query.sortBy = action.payload;
    },
    setPaginationPage(state, action) {
      state.pagination.page = action.payload;
      state.prevPage = action.payload - 1;
    },
    setFiltersCategories(state, action) {
      state.query.filters.categories = action.payload;
    },
    setNewCarsSortType(state, action) {
      state.newCarsSortType = action.payload;
    },
    cleanBudget(state) {
      state.query.filters.budget.priceRange.maxPrice = null;
      state.query.filters.budget.priceRange.minPrice = null;
    },
    setIsAdditionFiltersSelected(state) {
      state.query.isAdditionFiltersSelected =
        state.query.filters.colors.length > 0 ||
        state.query.filters.carDescriptionHighlights.length > 0 ||
        state.query.filters.carBodyType.length > 0;
    },
    setIsMoreFiltersOpen(state, action) {
      state.isMoreFiltersOpen = action.payload;
    },
    setFamilyCarPromoCard(state, action) {
      state.familyCarPromo = action.payload;
    },
    cleanAllFilters(state) {
      state.query.filters.colors = [];
      state.query.sortBy.value = '';
      state.query.sortBy.title = '';
      state.query.filters.categories = [];
      state.query.filters.models = [];
      state.query.filters.brands = [];
      state.query.filters.carDescriptionHighlights = [];
      state.query.filters.carBodyType = [];
      state.query.filters.budget.priceRange.maxPrice = null;
      state.query.filters.budget.priceRange.minPrice = null;
      state.query.isAdditionFiltersSelected = false;
    },
    setParamType(state, action) {
      state.paramType = action.payload || 'new';
    },
    setResultPageCarsContent(state, action) {
      try {
        const data = action.payload;

        state.prevPage = 0;

        const paramType = state.paramType;

        state.cars =
          RESOURCES.NAV_PARAM_PRIVATE_LEASING_CAR === paramType
            ? data.filter(
                (car: ISearchCar) => car[ECarData.LEASE_CAR_MIN_PRICE],
              )
            : data;
      } catch (e) {
        console.log('Error', e);
      }
      state.isFetch = true;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setFilterOptions(state, action) {
      try {
        const { manufactureWithModels, categories, colors } = action.payload;
        state.options.brands.values = sortObjectArr(
          manufactureWithModels.map((car: BrandAndModel) => {
            return { title: car.ManufacturerName };
          }),
          'title',
        );
        const modelOptions = manufactureWithModels.flatMap(
          (car: BrandAndModel) => {
            return car.models.map((model: string) => {
              return { title: model };
            });
          },
        );

        state.options.models.values = sortObjectArr(modelOptions, 'title');
        state.allModels = modelOptions;
        state.isOptionsFetched = true;
        state.brandsAndModels = manufactureWithModels;
        if (state.query.filters.brands.length > 0) {
          const filteredBrandsAndModels: BrandAndModel[] =
            manufactureWithModels.filter((brandAndModel: BrandAndModel) =>
              state.query.filters.brands.includes(
                brandAndModel.ManufacturerName,
              ),
            );

          const options: OptionType[] = filteredBrandsAndModels.flatMap(
            (brandsAndModel) =>
              brandsAndModel.models.map((model) => {
                return { title: model };
              }),
          );
          const modelsOptions = sortObjectArr([...options], 'title');
          state.options.models.values = modelsOptions;
          state.query.filters.models = state.query.filters.models.filter(
            (model) => {
              return options.some(
                (option: OptionType) => option.title === model,
              );
            },
          );
        }

        state.options.categories.values = sortObjectArr(
          categories.map((cat: categoryType) => {
            return {
              title: cat.name,
              icon: cat.icon.url,
              nameEn: cat.nameEn,
              url: cat.url,
            };
          }),
          'title',
        );

        const allColors: IAllColors = {};

        colors?.forEach((color: IColorOptionData) => {
          const title = CAR_COLOR_TEXT[color.mainColor];
          allColors[title] = {
            title: title,
            hex: CAR_COLOR_HEX[title],
            allColorsFamily: color.value.map((val) => val.colorTitle),
          };
        });
        state.allColors = allColors;

        state.options.colors.values = sortObjectArr(
          Object.keys(allColors).map((color) => {
            return {
              title: allColors[color].title,
              icon: allColors[color].hex,
            };
          }),
          'title',
        );
      } catch (e) {
        console.log('Error', e);
      }
    },
    setHighlightFilterOptions(state, action) {
      const carHighlights = action.payload;
      state.options.carHighlights.values = sortObjectArr(
        carHighlights.map((highlight: carDescriptionHighlights) => {
          return {
            title: highlight.attributes.title,
            icon: highlight.attributes.icon?.data?.attributes.url,
          };
        }),
        'title',
      );
    },
    setBodyTypeOptions(state, action) {
      const carBodyTypes = action.payload;
      const filteredBodyTypes = carBodyTypes
        .map((option: CarBodyTypeData) => {
          return {
            title: option.attributes.title,
            displayTitleName: option.attributes.displayTitleName,
            icon: option.attributes.icon?.data?.attributes.url,
            isNewCar: option.attributes.isNewCar || false,
            isUsedCar: option.attributes.isUsedCar || false,
          };
        })
        .filter((op: { isNewCar: boolean }) => op.isNewCar);

      const titles = filteredBodyTypes.map((bt: { title: string }) => bt.title);

      const bodyTypeWithoutDuplicate = removeDuplicatesOfBodyType(
        filteredBodyTypes.filter(
          (bt: { icon: string | undefined; displayTitleName: string }) =>
            bt.icon && !titles.includes(bt.displayTitleName),
        ),
      );

      state.options.carBodyType.values = bodyTypeWithoutDuplicate.map(
        (bodyType) => {
          return {
            title: bodyType.displayTitleName || bodyType.title,
            icon: bodyType.icon,
          };
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResultPageCarsContent.pending, (state) => {
      state.isFetch = false;
    });

    builder.addCase(getResultPageCarsContent.fulfilled, (state, action) => {
      try {
        const { data } = action.payload;

        state.prevPage = 0;

        let paramType = state.paramType;

        if (typeof window !== 'undefined') {
          paramType = new URLSearchParams(window.location.search).get('type');
        }
        const cars = mappedSearchCarsCards(data, state.query.filters.budget);

        // // TODO: control it from back and delete the 'sortCarsAUSTRALPromotedFirst' function
        // const sortedCars = sortCarsByAsdPrices(cars, paramType);
        // const carsPromotedFirst = sortCarsXTRAILLPromotedFirst(sortedCars);

        state.cars =
          RESOURCES.NAV_PARAM_PRIVATE_LEASING_CAR === paramType
            ? cars.filter((car) => car[ECarData.LEASE_CAR_MIN_PRICE])
            : cars;
      } catch (e) {
        console.log('Error', e);
      }
      state.isFetch = true;
    });
    builder.addCase(getFiltersOptions.fulfilled, (state, action) => {
      try {
        const { manufactureWithModels, categories, colors } = action.payload;
        state.options.brands.values = sortObjectArr(
          manufactureWithModels.map((car: BrandAndModel) => {
            return { title: car.ManufacturerName };
          }),
          'title',
        );
        const modelOptions = manufactureWithModels.flatMap(
          (car: BrandAndModel) => {
            return car.models.map((model: string) => {
              return { title: model };
            });
          },
        );

        const manufacturerEnumObj = brandNamesFilterParams(
          manufactureWithModels,
        );

        state.manufacturerEnum = manufacturerEnumObj;
        state.options.models.values = sortObjectArr(modelOptions, 'title');
        state.allModels = modelOptions;
        state.isOptionsFetched = true;
        state.brandsAndModels = manufactureWithModels;
        if (state.query.filters.brands.length > 0) {
          const filteredBrandsAndModels: BrandAndModel[] =
            manufactureWithModels.filter((brandAndModel: BrandAndModel) =>
              state.query.filters.brands.includes(
                brandAndModel.ManufacturerName,
              ),
            );

          const options: OptionType[] = filteredBrandsAndModels.flatMap(
            (brandsAndModel) =>
              brandsAndModel.models.map((model) => {
                return { title: model };
              }),
          );
          const modelsOptions = sortObjectArr([...options], 'title');
          state.options.models.values = modelsOptions;
          state.query.filters.models = state.query.filters.models.filter(
            (model) => {
              return options.some(
                (option: OptionType) => option.title === model,
              );
            },
          );
        }

        state.options.categories.values = sortObjectArr(
          categories.map((cat: categoryType) => {
            return {
              title: cat.name,
              icon: cat.icon.url,
              nameEn: cat.nameEn,
              seo: cat.seo,
              url: cat.url ?? '',
            };
          }),
          'title',
        );

        const allColors: IAllColors = {};

        colors?.forEach((color: IColorOptionData) => {
          const title = CAR_COLOR_TEXT[color.mainColor];
          allColors[title] = {
            title: title,
            hex: CAR_COLOR_HEX[title],
            allColorsFamily: color.value.map((val) => val.colorTitle),
          };
        });
        state.allColors = allColors;

        state.options.colors.values = sortObjectArr(
          Object.keys(allColors).map((color) => {
            return {
              title: allColors[color].title,
              icon: allColors[color].hex,
            };
          }),
          'title',
        );
      } catch (e) {
        console.log('Error', e);
      }
    });
    builder.addCase(getResultPageAppend.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.pagination = meta.pagination;
      state.cars = [
        ...state.cars,
        ...mappedCarsCards(data, state.query.filters.budget),
      ];
    });
    builder.addCase(getBannersPromotion.fulfilled, (state, action) => {
      if (!action.payload) return;

      const attributesArray = action.payload.map((item) => item.attributes);

      state.bannersPromotion = attributesArray.map((attributes) => ({
        ...attributes,
        image: attributes?.bannerIcon?.data?.attributes?.url || '',
        regularLeadDetails: attributes?.regularLeadDetails ?? null,
      }));
    });

    builder.addCase(getBannerPromotion.fulfilled, (state, action) => {
      if (!action.payload) return;

      const { attributes } = action.payload;

      state.bannerPromotion = {
        ...attributes,
        image: attributes?.bannerIcon?.data?.attributes?.url || '',
        regularLeadDetails: attributes?.regularLeadDetails ?? null,
      };
    });

    builder.addCase(getCardsPromotion.fulfilled, (state, action) => {
      state.promotionCards =
        action.payload.map((promotionCards) => promotionCards.attributes) || [];
    });
    builder.addCase(getHighlightsFilterOptions.fulfilled, (state, action) => {
      const carHighlights = action.payload;
      state.options.carHighlights.values = sortObjectArr(
        carHighlights
          .map((highlight) => {
            return {
              title: highlight.attributes.title,
              icon: highlight.attributes.icon?.data?.attributes.url,
            };
          })
          .filter((a) => a.title && a.icon),
        'title',
      );
    });
    builder.addCase(getCarBodyTypeFilterOptions.fulfilled, (state, action) => {
      const carBodyTypes = action.payload;
      const filteredBodyTypes = carBodyTypes.map((option) => {
        return {
          title: option.attributes.title,
          displayTitleName: option.attributes.displayTitleName,
          icon: option.attributes.icon?.data?.attributes.url,
          isNewCar: option.attributes.isNewCar || false,
          isUsedCar: option.attributes.isUsedCar || false,
        };
      });
      const titles = filteredBodyTypes.map((bt) => bt.title);

      const bodyTypeWithoutDuplicate = removeDuplicatesOfBodyType(
        filteredBodyTypes.filter(
          (bt) => bt.icon && !titles.includes(bt.displayTitleName),
        ),
      );

      state.options.carBodyType.values = bodyTypeWithoutDuplicate.map(
        (bodyType) => {
          return {
            title: bodyType.displayTitleName || bodyType.title,
            icon: bodyType.icon,
          };
        },
      );
    });
    builder.addCase(getSeoContent.fulfilled, (state, action) => {
      if (!action.payload) return;

      const { attributes } = action.payload;

      state.seoPageData = {
        ...attributes,
        advantages: attributes.advantages?.map((advantage) => {
          return {
            title: advantage.Title,
            paragraph: advantage.Paragraph,
            id: advantage.id,
            icon: advantage.Icon.data?.attributes?.url ?? '',
          };
        }),
        faq: attributes.faq?.map((fa) => {
          return {
            title: fa.Title,
            paragraph: fa.Paragraph,
            id: fa.id,
            icon: fa.Icon.data?.attributes?.url ?? '',
          };
        }),
      };
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { resultPage } = action.payload;

      return {
        ...state,
        ...resultPage,
      };
    });
    builder.addCase(getPromotionCampaignCards.fulfilled, (state, action) => {
      state.promotionCampaignCards = action.payload.map((promotionCard) => {
        return {
          ...promotionCard,
          image: promotionCard.iconbanner?.url || '',
        };
      });
      // state.promotionCampaignCards = action.payload;
    });
    builder.addCase(getFamilyCarsPromo.fulfilled, (state, action) => {
      state.familyCarsPromo = action.payload.map((promotionCard) => {
        const carLabelsData: CarLabelData[] =
          promotionCard.attributes.carLabels?.data.map((l) => ({
            id: l.id,
            ...l.attributes,
          })) || [];

        return {
          id: promotionCard.id,
          promoTitle: promotionCard.attributes?.promoTitle || '',
          manufacturerName: promotionCard.attributes.manufacturerName,
          modelName: promotionCard.attributes.modelName,
          categories: promotionCard.attributes.categories,
          bottomLabelTitle: promotionCard.attributes.bottomLabelTitle,
          createdAt: promotionCard.attributes.createdAt,
          updatedAt: promotionCard.attributes.updatedAt,
          publishedAt: promotionCard.attributes.publishedAt,
          images: promotionCard.attributes.images?.data?.map(
            (i) => i.attributes.url,
          ),
          carLabels: {
            data: carLabelsData,
          },
          leadDetails: promotionCard.attributes.leadDetails,
        };
      });
    });
  },
});

export const {
  setCars,
  setFilterOptionsModels,
  setFiltersBudget,
  setFiltersPriceRange,
  setFiltersColors,
  setFiltersSortBy,
  setFiltersCategories,
  setFiltersModels,
  setFiltersBrands,
  cleanAllFilters,
  cleanBudget,
  setPaginationPage,
  setIsFetch,
  setOptionsColorsValues,
  setFiltersHighLights,
  setFiltersBodyType,
  setNewCarsSortType,
  setIsAdditionFiltersSelected,
  setIsMoreFiltersOpen,
  setParamType,
  setResultPageCarsContent,
  setQuery,
  setBodyTypeOptions,
  setHighlightFilterOptions,
  setFilterOptions,
  setFamilyCarPromoCard,
} = resultPageSlice.actions;

export default resultPageSlice.reducer;
