import { createAsyncThunk } from '@reduxjs/toolkit';
import { carDealRes } from '~/data/types/car/carDeals';
import searchFlowAdapter from '~/redux-toolkit/adapters/searchFlowAdapter';

export const getCarsDealBullets = createAsyncThunk(
  '/api/search-flow-contents',
  async (): Promise<carDealRes[]> => {
    try {
      const response = await searchFlowAdapter.getCarDealsBullets();
      const { data } = response.data;

      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
