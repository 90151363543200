import WhatsApp from '~/assets/icons/whatsappIcon.png';
import Call from '~/assets/icons/call.png';
import Calender from '~/assets/icons/calendar.png';
import bulletCar from '~/assets/icons/bulletCar.svg';
import bulletDet from '~/assets/icons/bulletDet.svg';
import bulletKey from '~/assets/icons/bulletKey.svg';
import Prev from '~/assets/icons/Next.png';
import Next from '~/assets/icons/Prev.png';
import Dot from '~/assets/icons/dot.png';
import chosenDot from '~/assets/icons/chosenDot.png';
import Exit from '~/assets/icons/exit.svg';
import scrollUpBtn from '~/assets/icons/scrollUpBtn.svg';
import TalkWithUsIcon from '~/assets/icons/TalkWithUsIcon.svg';
import AquaBullet from '~/assets/icons/AquaBullet.svg';
import CheckboxNotChecked from '~/assets/icons/CheckBoxNotChecked.svg';
import CheckboxChecked from '~/assets/icons/CheckBoxChecked.svg';

const ICONS = {
  WhatsApp,
  Call,
  Calender,
  bulletCar,
  bulletDet,
  bulletKey,
  Next,
  Prev,
  Dot,
  chosenDot,
  scrollUpBtn,
  Exit,
  TalkWithUsIcon,
  AquaBullet,
  CheckboxNotChecked,
  CheckboxChecked,
};

export default ICONS;
