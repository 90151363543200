import { css } from '@emotion/react';

export const useStyles = {
  root: css`
    &:hover {
      cursor: pointer;
    }
  `,
  image: css`
    width: 143px;
    position: relative;
    top: 5px;
    /* height: 37px; */
  `,
};
