/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICommunityPageRes,
  IFreeSbeActivityRes,
} from '~/data/types/communityPage/communityPageType';
import communityPageAdapter from '~/redux-toolkit/adapters/communityPageAdapter';

export const getCommunityPageData = createAsyncThunk(
  '/getCommunityPageData',
  async (): Promise<ICommunityPageRes | null> => {
    try {
      const res = await communityPageAdapter.getCommunityPage();

      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const getFreesbeActivities = createAsyncThunk(
  '/getFreesbeActivities',
  async (): Promise<IFreeSbeActivityRes[]> => {
    try {
      const res = await communityPageAdapter.getFreesbeActivities();

      return res;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
);
