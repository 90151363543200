import { ERequestType } from '../enums';
import { BookSlot } from '../interfaces';
import { LeadRequest } from './LeadRequest';

export interface BookSlotResponse {
  bookSlot: {
    isBookSuccessInSF: string;
    errorDescription: string;
    isActiveMeetingForLead: string;
  };
  errorMessage: string;
}

export class BookSlotRequest extends LeadRequest {
  bookSlot: BookSlot;

  constructor(bookSlot: BookSlot) {
    super(ERequestType.BOOK_SLOT);

    this.bookSlot = bookSlot;
  }
}
