/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import newCarForSalePageContentAdapter from '~/redux-toolkit/adapters/newCarForSalePageContentAdapter';
import { INewCarForSalePageData } from '~/data/types/newCarForSalePageContent/newCarForSalePage.types';

export const getNewCarForSalePageData = createAsyncThunk(
  '/api/new-car-for-sale-page/content?populate=*',
  async (): Promise<INewCarForSalePageData | null> => {
    try {
      const response =
        await newCarForSalePageContentAdapter.getNewCarForSalePageContent();
      const { data } = response;
      return data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  },
);
