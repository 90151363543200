import { HttpClientService } from '~/services/HttpsClientService';
import { INewCarForSalePageData } from '~/data/types/newCarForSalePageContent/newCarForSalePage.types';

class NewCarForSalePageContentAdapter {
  static readonly endpointGetNewCarForSalePage = `/api/new-car-for-sale-page/content`;

  // eslint-disable-next-line class-methods-use-this
  async getNewCarForSalePageContent() {
    const res = await HttpClientService.get<INewCarForSalePageData | null>(
      `${NewCarForSalePageContentAdapter.endpointGetNewCarForSalePage}?populate=*`,
    );
    return res;
  }
}

const newCarForSalePageContentAdapter = new NewCarForSalePageContentAdapter();
export default newCarForSalePageContentAdapter;
