import { getCampaignNumberByCar } from '~/data/lead/campaignNumber';
import { ROUTES } from '~/data/routes';
import {
  FUNDING_ROUTE,
  INSURANCE_ROUTE,
  NAV_PARAM_TYPE,
  SOLUTIONS_ROUTE,
} from '~/data/useServices';
import { useMemo } from 'react';
import { useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';
import { CampaignNumber } from '~/services/LeadService/enums';
import { useRouter } from 'next/router';
import { CarCardType } from '~/data/types/car/cars';

export const useCampaignNumber = (failure?: boolean): CampaignNumber => {
  const router = useRouter();

  const { isApprovalFlowOpen } = useAppSelector(
    (state: RootState) => state.approvalFlow,
  );

  const { car, dealType } = useAppSelector((state: RootState) => state.car);
  const { isExistOpenLeadUsedCar } = useAppSelector(
    (state: RootState) => state.usedCarSlice,
  );

  return useMemo(() => {
    const currentPath = decodeURIComponent(router.asPath); // 'asPath' gives the current path including the query
    switch (true) {
      case currentPath.includes(FUNDING_ROUTE) || isApprovalFlowOpen:
        return CampaignNumber.FUNDING;
      case currentPath.includes(INSURANCE_ROUTE):
        return CampaignNumber.INSURANCE;
      case currentPath.includes(SOLUTIONS_ROUTE):
        return CampaignNumber.SOLUTIONS;
      case currentPath.includes(ROUTES.USED_CAR):
        return isExistOpenLeadUsedCar
          ? CampaignNumber.USED_CAR_SHIRYUN
          : CampaignNumber.USED_CAR;
      case car?.leadType === 'Trucks':
        return failure ? CampaignNumber.FAILED_TRUCK : CampaignNumber.TRUCK;
      case currentPath.includes(NAV_PARAM_TYPE.NAV_PARAM_PRIVATE_LEASING_CAR):
        return CampaignNumber.LEASING;

      default:
        return getCampaignNumberByCar(car as CarCardType, dealType, failure);
    }
  }, [
    car,
    dealType,
    failure,
    isApprovalFlowOpen,
    isExistOpenLeadUsedCar,
    router.asPath,
  ]);
};
