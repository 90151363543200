import React from 'react';
import { ImageFile } from '~/data/types/images';

const greenListBulletIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      width="8"
      height="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="#00D6D1" />
    </Component>
  );
};

export default greenListBulletIcon;
