// Utility functions for cookie management

// Encrypt data (Basic encoding using btoa)
function encryptData(data: string): string {
  const utf8Encoder = new TextEncoder();
  const byteArray = utf8Encoder.encode(data);
  let binaryString = '';
  byteArray.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return btoa(binaryString);
}

// Decrypt data (Basic decoding using atob)
function decryptData(encryptedData: string): string {
  const binaryString = atob(encryptedData);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  const utf8Decoder = new TextDecoder();
  return utf8Decoder.decode(byteArray);
}

// Set a cookie
export function setCookie(name: string, value: any, days: number) {
  if (typeof window !== 'undefined') {
    const encryptedValue = encryptData(JSON.stringify(value));
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encryptedValue}; expires=${date.toUTCString()}; path=/`;
  }
}

// Get a cookie
export function getCookie(
  name: string,
  parse?: boolean,
  cookieString?: string, // Optional, used on server side
): any {
  const cookies = (
    typeof window === 'undefined' ? cookieString : document.cookie
  )?.split(';');

  if (!cookies) return null;
  const nameEQ = name + '=';
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(nameEQ) === 0) {
      const encryptedValue = cookie.substring(nameEQ.length);

      if (!parse) return JSON.parse(decryptData(encryptedValue));

      return JSON.parse(JSON.parse(decryptData(encryptedValue)));
    }
  }
  return null;
}

// Delete a cookie
export function deleteCookie(name: string) {
  if (typeof window !== 'undefined') {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}

// Update a cookie (just a wrapper for setCookie)
export function updateCookie(name: string, newValue: any, days: number) {
  setCookie(name, newValue, days);
}

// Use for simple data
export function appendToCookieIfNotExists(
  name: string,
  value: any,
  days: number,
): void {
  // Retrieve the current cookie value
  const existingValue = getCookie(name);

  // Parse the cookie value into an array
  const values = existingValue ? existingValue.split(',') : [];

  // Append the new value only if it does not already exist
  if (!values.includes(String(value))) {
    values.push(String(value));
  }

  // Set the updated value back into the cookie
  const updatedValue = values.join(',');
  setCookie(name, updatedValue, days);
}

// Code to Check for a Value in
export function isValueInCookie(
  name: string,
  value: any,
  cookieString?: any,
): boolean {
  const cookieValue = getCookie(name, false, cookieString);

  if (!cookieValue) return false; // Cookie doesn't exist

  // Split the cookie string into an array
  const values = cookieValue.split(',');

  // Check if the value exists in the array
  return values.includes(String(value));
}

// Example usage
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function exampleUsage() {
  const userData = {
    token: Math.random().toString(36),
    expiry: new Date().getTime() + 12 * 60 * 60 * 1000,
    leadId: 'exampleLeadId',
  };

  // Set a cookie (valid for 1 day)
  setCookie('userData', userData, 1);

  // Get a cookie
  const retrievedData = getCookie('userData');
  console.log('Retrieved Data:', retrievedData);

  // Update a cookie
  updateCookie('userData', { ...retrievedData, leadId: 'newLeadId' }, 1);

  // Delete a cookie
  deleteCookie('userData');

  // Test with stringify
  const userDataWithStringify = JSON.stringify(userData);

  // Set a cookie with stringify
  setCookie('userDataWithStringify', userDataWithStringify, 1);

  // Get a cookie with stringify
  const retrievedDataWithStringify = getCookie('userDataWithStringify', true);
  console.log('userDataWithStringify', retrievedDataWithStringify);
}

// exampleUsage();
