import { HttpClientService } from '~/services/HttpsClientService';
import { IHomePageData } from '~/data/types/homePageContent/homePage.types';

class HomePageContentAdapter {
  static readonly endpointGetHomePage = `/api/home-page/content`;

  // eslint-disable-next-line class-methods-use-this
  async getHomePageContent() {
    const res = await HttpClientService.get<IHomePageData | null>(
      `${HomePageContentAdapter.endpointGetHomePage}?populate=*`,
    );
    return res;
  }
}

const homePageContentAdapter = new HomePageContentAdapter();
export default homePageContentAdapter;
