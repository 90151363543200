import RESOURCES from '~/resources';
import {
  ICarPollutionAmerican,
  ICarPollutionRegular,
  ICarSafetyAndPollution,
} from './safetyAndPollution/safetyAndPollution.interfaces';

export const formatRegularCarPollution = (
  carsSafetyAndPollution: ICarSafetyAndPollution,
) => {
  return carsSafetyAndPollution.carPollution.regular.map(
    (singleCarPollutionData: ICarPollutionRegular) => {
      const newPollutionData = { ...singleCarPollutionData };
      newPollutionData.consumptiomType = RESOURCES.MERGED_CONSUPTION_TYPE;

      return newPollutionData;
    },
  );
};

export const formatAmericanCarPollution = (
  carsSafetyAndPollution: ICarSafetyAndPollution,
) => {
  return carsSafetyAndPollution.carPollution.american.map(
    (singleCarPollutionData: ICarPollutionAmerican) => {
      const newPollutionData = { ...singleCarPollutionData };
      newPollutionData.intercityFuel = `${singleCarPollutionData.intercityFuel} / ${RESOURCES.INTERCITY}`;
      newPollutionData.urbanFuel = `${singleCarPollutionData.urbanFuel} / ${RESOURCES.URBAN}`;

      return newPollutionData;
    },
  );
};
