import React from 'react';
import { ImageFile } from '~/data/types/images';

const WhatsappIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="56"
      height="55"
      viewBox="0 0 56 55"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="55" height="55" rx="27.5" fill="#00D6D1" />
      <g clipPath="url(#clip0_7984_226041)">
        <path
          d="M17.5195 35.7779L17.6323 35.4414L17.4234 35.1545C15.8581 33.0048 14.9375 30.3579 14.9375 27.5C14.9375 20.2964 20.795 14.4375 27.9966 14.4375H28.0034C35.2049 14.4375 41.0625 20.2981 41.0625 27.5C41.0625 34.7019 35.2049 40.5625 28.0034 40.5625C25.3459 40.5625 22.8838 39.7725 20.8189 38.4047L20.544 38.2225L20.2299 38.323L16.2375 39.5992L17.5195 35.7779Z"
          fill="#00D6D1"
          stroke="#262626"
          strokeWidth="1.375"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.2936 27.1202C29.8918 29.7176 30.4812 26.7127 32.1355 28.3658C33.7303 29.9602 34.6469 30.2796 32.6263 32.2997C32.3732 32.5031 30.7651 34.9502 25.1136 29.3003C19.4614 23.6497 21.9072 22.0399 22.1106 21.7869C24.1362 19.7613 24.4501 20.6832 26.0449 22.2776C27.6992 23.9314 24.6955 24.5227 27.2936 27.1202Z"
          stroke="#262626"
          strokeWidth="1.53099"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7984_226041">
          <rect
            width="27.5"
            height="27.5"
            fill="white"
            transform="translate(14.25 13.75)"
          />
        </clipPath>
      </defs>
    </Component>
  );
};

export default WhatsappIconIcon;
