import { Box, Link, Typography } from '@mui/material';
import ScrollUpButton from './components/ScrollUpButton/ScrollUpButton';
import SocialMediaSection from './components/SocialMediaSection/SocialMediaSection';
import RESOURCES from '~/resources';
import classes, {
  linkStyle,
  poweredByText,
  FooterDiv,
  ScrollUpBtnSection,
  ContainerStyle,
  ChildContainer,
  FooterContentContainer,
  StyledFreesbeLogo,
  StyledFreesbeLogoMobile,
} from './useStyles';
import MoveoIcon from '~/assets/icons/jsx/moveoIcon';
import FooterMenu from './components/FooterMenu';
import FreesbeLogoWithText from '~/assets/icons/jsx/FreesbeLogoWithText';

const Footer = () => {
  return (
    <>
      <ContainerStyle>
        <ChildContainer>
          <FooterContentContainer>
            <ScrollUpBtnSection>
              <ScrollUpButton />
              <FreesbeLogoWithText Component={StyledFreesbeLogo} />
            </ScrollUpBtnSection>
            <FooterMenu />
          </FooterContentContainer>
        </ChildContainer>
        <FreesbeLogoWithText Component={StyledFreesbeLogoMobile} />
      </ContainerStyle>
      <FooterDiv>
        <SocialMediaSection />
        <Box sx={classes.poweredByMoveoDiv}>
          <Box sx={classes.poweredByMoveo}>
            <Link href="https://www.moveo.group/" sx={linkStyle}>
              <Typography sx={poweredByText}>{RESOURCES.CREATED_BY}</Typography>

              <MoveoIcon />
            </Link>
          </Box>
        </Box>
      </FooterDiv>
    </>
  );
};

export default Footer;
