/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography, Box, Drawer, Paper, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
// Components
import RESOURCES from '~/resources';
import AppLogo from '../AppLogo';
import Button from '../Button';
const WishlistLinkButton = dynamic(() => import('./WishlistLinkButton'), {
  ssr: false,
});
import ContactUs from './ContactUs';
// Hooks
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { useCurrentUrlParams } from '~/hooks/useCurrentUrlParams';
// Store
import { RootState } from '~/redux-toolkit/store/store';
// Data
import {
  IServicesLinks,
  SERVICES_LINKS_TYPE,
  useServices,
} from '~/data/useServices';
import { ROUTES } from '~/data/routes';
import { BUTTON_TYPES, BUTTON_SIZES } from '~/data/constants';

// Style
import {
  menuIconStyle,
  classes,
  root,
  navWrapper,
  wishlistWrapper,
  popupMenu,
  mobileMenuContentStyle,
  boldFont,
  skipToContentLink,
  navbarTextColor,
  wishlistStyles,
  menuButton,
  menuTypography,
  Service,
  NavContainer,
  StyledAnchor,
  MenuContainer,
  MenuItem,
  MenuItemsDeskContainer,
  MenuItemContainer,
  LogoStyle,
  MenuDeskContainer,
  RowContainer,
  normalFont,
} from './useStyles';

import YellowLine from '~/assets/icons/jsx/yellowLineMenu';
import CloseButton from '~/assets/images/jsx/close';
import DownArrow from '~/assets/icons/jsx/downArrow';
import LeftArrow from '~/assets/icons/jsx/leftArrow';
import { useIsMobile } from '~/hooks/useIsMobile';
const ComparePopover = dynamic(() => import('../ComparePopover'), {
  ssr: false,
});
import { UsedCarType } from '~/data/types/usedCar/usedCarData';
import { getUsedCarsForComparison } from '~/redux-toolkit/thunks/compareThunks';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
const LobbyNavigation = dynamic(() => import('./LobbyNavigation'), {
  ssr: false,
});

const NavBar = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { usedCarComparison } = useSelector(
    (state: RootState) => state.compare,
  );
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { ids, usedCarIds, leaseIds } = useSelector(
    (state: RootState) => state.wishlist,
  );
  const services = useServices();
  const events = useEvents();

  // Auto close mobile menu
  useEffect(() => {
    if (!isMobile && isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobile, setIsMobileMenuOpen, isMobileMenuOpen]);

  const currentUrlParams = useCurrentUrlParams({ cleanAppParams: true });
  const paramsString = currentUrlParams.toString();
  const queryParams = paramsString ? `?${paramsString}` : '';

  const wishListUrl = useMemo(
    () => `${ROUTES.WISHLIST_PAGE}${queryParams}`,
    [queryParams],
  );

  const queryParamNavbarType = useMemo(() => router.query.type, [router.query]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuRef = useRef<any>();

  const openMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(true);
  }, [setIsMobileMenuOpen]);

  const closeMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, [setIsMobileMenuOpen]);

  // allow us to close the menu on click outside the menu.
  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsMobileMenuOpen(false);
      }
    });
  }, [menuRef]);

  const onClickEvent = useCallback(
    (title: string) => {
      setIsMobileMenuOpen(false);

      events.sendEvent(
        {
          category: ECategoryEvent.PRIMARY_MENU,
          action: EActionEvent.PRIMARY_MENU_LINK,
        },
        { linkTitle: title },
      );
    },
    [events],
  );

  const handleNavigateToMainContent = () => {
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.focus();
    }
  };

  const renderMobileServices = (
    mobileServices: IServicesLinks[],
    isMenuItems: boolean,
  ) => {
    const renderItemTitle = (item: IServicesLinks) => {
      if (isMenuItems) {
        return (
          <Box component="p" sx={classes.menuItemTitle}>
            {item.title}
          </Box>
        );
      }
      return <Typography variant="h4">{item.title}</Typography>;
    };

    return (
      <NavContainer isMenuItems={isMenuItems}>
        {mobileServices.map((item: IServicesLinks) => {
          let renderedItem;

          if (item.type === SERVICES_LINKS_TYPE.ROUTE) {
            renderedItem = (
              <StyledAnchor
                href={item.path as string}
                key={item.title}
                onClick={() => onClickEvent(item.title)}
              >
                {renderItemTitle(item)}
              </StyledAnchor>
            );
          } else if (item.type === SERVICES_LINKS_TYPE.MENU) {
            renderedItem = (
              <MenuContainer>
                <Button
                  label={item.title}
                  type={BUTTON_TYPES.TRANSPARENT}
                  size={BUTTON_SIZES.LARGE}
                  icon={!item.isOpen ? <LeftArrow /> : <DownArrow />}
                  customStyle={menuButton}
                  onClickFunc={() => {
                    if (item.setIsOpen) item.setIsOpen(!item.isOpen);
                  }}
                  typographyStyle={menuTypography}
                />
                {item.isOpen &&
                  renderMobileServices(item.menuItems || [], true)}
              </MenuContainer>
            );
          } else {
            renderedItem = (
              <MenuItem>
                <StyledAnchor
                  href={item.path as string}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onClickEvent(item.title)}
                >
                  {renderItemTitle(item)}
                </StyledAnchor>
                {item.mark && <YellowLine />}
              </MenuItem>
            );
          }

          return (
            <Service key={item.title} isMenuItems={isMenuItems}>
              {renderedItem}
            </Service>
          );
        })}
      </NavContainer>
    );
  };

  const renderTriangle = () => (
    <Box sx={classes.triangleContainer}>
      <Box sx={classes.triangle} />
    </Box>
  );

  const extractBasePath = (url: string): string => {
    const path = url.split('?')[0]; // Split the URL at '?' and take the first part
    const segments = path.split('/').filter(Boolean); // Split by '/' and filter out empty segments
    return `/${segments[0]}`; // Return the base path with leading '/'
  };

  useEffect(() => {
    if (
      usedCarComparison.compareIds.length > 0 &&
      usedCarComparison.compareIds.length !== usedCarComparison.cars.length
    ) {
      dispatch(getUsedCarsForComparison(usedCarComparison.compareIds));
    }
  }, [dispatch, usedCarComparison]);

  const renderDesktopServices = (
    desktopServices: IServicesLinks[],
    isMenuItems: boolean,
    isContact?: boolean,
  ) => {
    const handleBlur = (
      event: React.FocusEvent<HTMLDivElement>,
      link: IServicesLinks,
    ) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        link.setIsOpen?.(false);
      }
    };

    return (
      <MenuDeskContainer isMenuItems={isMenuItems}>
        {isMenuItems && renderTriangle()}
        <RowContainer isMenuItems={isMenuItems}>
          <Box
            component={'nav'}
            sx={isMenuItems ? classes.columnContainer : classes.navList}
            role="navigation"
          >
            {desktopServices.map((link: IServicesLinks) => {
              const isCurrentPage =
                (queryParamNavbarType && queryParamNavbarType === link.value) ||
                (!queryParamNavbarType && router.asPath.includes(link.path)) ||
                extractBasePath(link.path) === extractBasePath(router.asPath);

              // Regular menu item (Non-dropdown)
              if (link.type === SERVICES_LINKS_TYPE.ROUTE) {
                return (
                  <MenuItemContainer key={link.title}>
                    <MenuItem key={link.title}>
                      <Box
                        tabIndex={0}
                        component={Link}
                        onClick={() => onClickEvent(link.title)}
                        href={link.path as string}
                        prefetch={false}
                        sx={
                          isMenuItems
                            ? classes.menuItemTitle
                            : queryParamNavbarType === link.value
                            ? classes.activeLink
                            : classes.listItemStyle
                        }
                      >
                        {isMenuItems ? (
                          <Typography
                            sx={classes.menuItemTitle}
                            style={{
                              ...(isCurrentPage ? boldFont : ''),
                              ...navbarTextColor,
                            }}
                          >
                            {link.title}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              ...(isCurrentPage ? boldFont : normalFont),
                              ...navbarTextColor,
                            }}
                          >
                            {link.title}
                          </Typography>
                        )}
                      </Box>
                    </MenuItem>
                  </MenuItemContainer>
                );
              }

              // Dropdown menu with sub-items
              if (link.type === SERVICES_LINKS_TYPE.MENU) {
                return (
                  <MenuItemsDeskContainer
                    key={link.title}
                    onMouseOver={() => link.setIsOpen?.(true)}
                    onFocus={() => link.setIsOpen?.(true)} // Keep open on focus
                    onMouseLeave={() => link.setIsOpen?.(false)}
                    onBlur={(event) => handleBlur(event, link)} // Close only if focus moves away
                    tabIndex={0}
                  >
                    <MenuContainer>
                      <Typography sx={navbarTextColor}>{link.title}</Typography>
                      <DownArrow />
                    </MenuContainer>

                    {/* Submenu */}
                    {link.isOpen && (
                      <div
                        onFocus={() => link.setIsOpen?.(true)} // Keep open on submenu focus
                        onBlur={(event) => handleBlur(event, link)} // Close only when focus moves away
                      >
                        {renderDesktopServices(
                          link.menuItems || [],
                          true,
                          link.isContact,
                        )}
                      </div>
                    )}
                  </MenuItemsDeskContainer>
                );
              }

              // External links and other items
              return (
                <MenuItemContainer key={link.title}>
                  <MenuItem>
                    {link?.icon ? <img src={link.icon} alt="" /> : ''}
                    <Box
                      component={'a'}
                      target="_blank"
                      rel="noreferrer"
                      sx={isMenuItems ? classes.menuItemTitle : classes.linked}
                      onClick={() => onClickEvent(link.title)}
                      href={link.path}
                      tabIndex={0}
                    >
                      {isMenuItems ? (
                        <Typography sx={classes.menuItemTitle}>
                          {link.title}
                        </Typography>
                      ) : (
                        <Typography sx={navbarTextColor}>
                          {link.title}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                  {link.mark && <YellowLine />}
                </MenuItemContainer>
              );
            })}
          </Box>
          {isContact && <ContactUs isNavBar />}
        </RowContainer>
      </MenuDeskContainer>
    );
  };

  const pathToHideNavbarOnly = ['/car-fleet', '/car-fleet/listings'];
  if (pathToHideNavbarOnly.includes(router.pathname)) {
    return (
      <Box sx={root}>
        <Box
          sx={navWrapper}
          style={{ justifyContent: `${isMobile ? 'center' : 'space-between'}` }}
        >
          <LogoStyle>
            <AppLogo />
          </LogoStyle>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={root}>
        <Box sx={navWrapper}>
          <LogoStyle>
            <AppLogo />
          </LogoStyle>
          <Button
            onClickFunc={handleNavigateToMainContent}
            customStyle={skipToContentLink}
            label={RESOURCES.SKIP_TO_CONTENT}
            type={BUTTON_TYPES.MAIN}
            size={BUTTON_SIZES.LARGE}
            tabbable
          />

          <Box sx={wishlistWrapper}>
            {renderDesktopServices(services, false, false)}
            <Stack sx={classes.actionsContainer}>
              <ComparePopover
                carsToCompare={usedCarComparison.cars as UsedCarType[]}
                counter={usedCarComparison.compareIds.length}
              />
              <Link
                href={wishListUrl}
                onClick={() => onClickEvent('wishlist')}
                aria-label={RESOURCES.FAVORITES}
                style={wishlistStyles}
                prefetch={false}
              >
                <WishlistLinkButton
                  savedNumber={
                    (ids?.length || 0) +
                    (usedCarIds?.length || 0) +
                    (leaseIds?.length || 0)
                  }
                />
              </Link>
            </Stack>
          </Box>
          <MenuIcon
            fontSize="medium"
            sx={menuIconStyle}
            onClick={openMobileMenu}
          />
        </Box>
        {/* Lobby Navigation Bar */}
        <LobbyNavigation />
        {/* Lobby Navigation Bar ENDS*/}
      </Box>
      <Drawer
        anchor="right"
        open={isMobileMenuOpen}
        sx={popupMenu}
        id="popupOverlay"
        ref={menuRef}
        onClose={closeMobileMenu}
        transitionDuration={300}
      >
        <Paper sx={mobileMenuContentStyle}>
          <span onClick={closeMobileMenu}>
            {' '}
            <CloseButton />
          </span>
          {renderMobileServices(services, false)}
          <ContactUs isNavBar />
        </Paper>
      </Drawer>
    </>
  );
};

export default NavBar;
