import { createAsyncThunk } from '@reduxjs/toolkit';
import maskyooAdapter, { GetMaskyooResult } from '../adapters/maskyooAdaptor';

export const getMaskyoo = createAsyncThunk(
  '/api/maskyoo',
  async (): Promise<GetMaskyooResult> => {
    const response = await maskyooAdapter.getMaskyoo();
    return response.data;
  },
);
