import React from 'react';
import { ImageFile } from '~/data/types/images';

const ArrowLeftIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      width="21"
      height="20"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.04199 10.2292L16.542 10.2292"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08301 15.2498L4.04134 10.2298L9.08301 5.20898"
        stroke="#130F26"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default ArrowLeftIcon;
