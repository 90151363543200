import {
  localStorageLeaseCar,
  localStorageNewCar,
  localStorageUsedCar,
  WishlistState,
} from '~/redux-toolkit/slices/wishlistSlice';
import { getCookie } from './cookieStorageHandlers';

export const initiateWishlistIds = () => {
  if (typeof window === 'undefined') return []; // Return early if not on client-side

  try {
    const wishedItems = getCookie(localStorageNewCar)
      ? (
          JSON.parse(
            getCookie(localStorageNewCar) || '{ids: []}',
          ) as WishlistState
        ).ids
      : [];

    return wishedItems || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const initiateWishlistLeaseIds = () => {
  if (typeof window === 'undefined') return []; // Return early if not on client-side

  try {
    const wishedItems = getCookie(localStorageLeaseCar)
      ? (
          JSON.parse(
            getCookie(localStorageLeaseCar) || '{leaseIds: []}',
          ) as WishlistState
        ).leaseIds
      : [];

    return wishedItems || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const initiateWishlistUsedCarIds = () => {
  if (typeof window === 'undefined') return []; // Return early if not on client-side

  try {
    const wishedItems = getCookie(localStorageUsedCar)
      ? (
          JSON.parse(
            getCookie(localStorageUsedCar) || '{usedCarIds: []}',
          ) as WishlistState
        ).usedCarIds
      : [];

    return wishedItems || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};
