export const root = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};
export const loaderTitle = {};
export const loaderDescription = {
  textAlign: 'center',
  maxWidth: '300px',
  paddingBottom: '20px',
};
