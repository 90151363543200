import React from 'react';
import { ImageFile } from '~/data/types/images';

const normalRoadSelectedIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
    >
      <path
        d="M56.1183 22.5883C52.0115 21.0267 42.13 19.5076 47.9381 12.0593H43.6817C38.3136 18.7868 47.4365 23.9521 51.4846 25.2133C64.3329 29.2165 70.0823 40.9493 62.7488 72H68.1463C70.0041 60.5684 76.0654 29.7956 56.1183 22.5883Z"
        fill="#00D6D1"
      />
      <path
        d="M37.2283 38.5468C36.1136 46.7158 22.6786 63.091 13.5264 72H20.5079C51.2499 37.2248 41.0417 33.5625 31.5375 27.1953C25.0253 22.8327 26.316 14.742 30.4814 12.0593H24.1146C13.5544 29.838 37.2283 31.1594 37.2283 38.5468Z"
        fill="#00D6D1"
      />
      <path
        d="M56.1183 22.5883C52.0115 21.0267 42.13 19.5076 47.9381 12.0593H43.6817C38.3136 18.7868 47.4365 23.9521 51.4846 25.2133C64.3329 29.2165 70.0823 40.9493 62.7488 72H68.1463C70.0041 60.5684 76.0654 29.7956 56.1183 22.5883Z"
        stroke="#262626"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M37.2283 38.5468C36.1136 46.7158 22.6786 63.091 13.5264 72H20.5079C51.2499 37.2248 41.0417 33.5625 31.5375 27.1953C25.0253 22.8327 26.316 14.742 30.4814 12.0593H24.1146C13.5544 29.838 37.2283 31.1594 37.2283 38.5468Z"
        stroke="#262626"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M36.8177 12C34.9501 13.2615 33.7085 18.6067 35.351 21.3695C39.3052 28.0204 54.8288 29.838 53.3034 43.3517C52.0831 54.1627 45.813 65.5337 42.7427 72"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 3"
      />
    </Component>
  );
};

export default normalRoadSelectedIcon;
