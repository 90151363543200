import React from 'react';
import { ImageFile } from '~/data/types/images';

const arrowDownIconIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="12"
      height="6"
      viewBox="0 0 12 6"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6668 0.664063L6.00016 5.33073L1.3335 0.664062"
        stroke="#130F26"
        strokeWidth="0.85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default arrowDownIconIcon;
